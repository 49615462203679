import { useContext } from 'react';
import style from './style.module.css';
import { UserContext } from '../../providers/UserProvider';

interface ProfilePictureProps {
    picture?: string;
    initials?: string;
    className?: string;
};

const ProfilePicture = ({ picture, initials, className }: ProfilePictureProps) => {
    const userDataProp = useContext(UserContext);
    const letters = initials ? initials : (userDataProp?.user?.firstName ?? "").charAt(0).toUpperCase() + (userDataProp?.user?.lastName ?? "").charAt(0).toUpperCase();

    return (
        <div className={className}>
            {picture && <img src={picture as string} alt="Picture" className={style.profilePicture} />}
            {!picture && <div className={style.profilePicture}>
                <span className={style.profileInitials}>{letters}</span>
            </div>}
        </div>
    );
};

export default ProfilePicture;