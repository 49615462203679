import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/User';
import { Typography } from '../Typography';
import statsIconGrows from '../../assets/statsIconGrows.svg';
import pictureIcon from '../../assets/pictureIcon.svg';
import videoIcon from '../../assets/videoIcon.svg';
import youtubeIcon from '../../assets/youtubeIcon.svg';
import littleArrow from '../../assets/littleArrow.svg';
import { Select } from '../Select';

import {
    SelectTimeline,
    StatToolkit,
    StatsArray,
    addData,
    calcEvol,
    changetimeLine,
    classes,
    getLabelFromDate,
    getWeekNumber,
    lstMedias
} from '../ProfileStatisticsStyle';
import { lstGamesForSelect } from '../ProfileStatisticsStyle';
import { ExerciseType } from '../../models/ExerciseType';
import {
    getExerciseItemCreatedByUser,
    getGamesCreatedByUser,
    getNumberOfExercises,
    getVideoPersonalCreatedByUser,
    getVideoYoutubeCreatedByUser
} from '../../services/cloudFirestore';
import { Button } from '../Button';
// import '../../utilities/utils.css';

import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from 'chart.js';
import Chart from 'chart.js/auto';
import { Doughnut, Line } from 'react-chartjs-2';
import { Patient } from '../../models/Patient';
import { getDateFromTimestamp2, secToStringMinSec } from '../../utilities/utils';
import { Timestamp } from 'firebase/firestore';
import { StimbotInfo } from './StimbotInfo';

import { StatEvol } from './StatEvol';
import { labelsMonth } from '../ProfileStatisticsStyle';
import { CardWatchTime } from './CardWatchTime';

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

interface Props {
    userDataList: Patient[];
}

export function StatsLookedMedia({ userDataList }: Props): JSX.Element {
    const { t } = useTranslation();
    const [typeDataView, setTypeDataView] = useState<
        'all' | 'picture' | 'personalVideo' | 'youtubeVideo'
    >('all');
    const [importEvol, setImportEvol] = useState<string>('');
    const [nbMediaLooked, setNbMediaLooked] = useState<number>(0);
    const [watchTimePersonal, setWatchTimePersonal] = useState<string>('0');
    const [watchTimeYoutube, setWatchTimeYoutube] = useState<string>('0');

    const [endDate, setEndDate] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    );
    const [startDate, setStartDate] = useState<Date>(
        new Date(endDate.getFullYear(), endDate.getMonth() - 3, 1)
    );
    const [timeScale, setTimeScale] = useState<'day' | 'week' | 'month' | 'year'>('month');

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                ticks: {
                    precision: 0,
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                border: { dash: [4, 4] },
                grid: {
                    color: '#D8D8D8'
                },
                min: 0
                // max: 10,
                // ticks: {
                //     stepSize: 1
                // }
            },
            x: {
                ticks: {
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                grid: {
                    display: false,
                    lineWidth: 0
                }
            }
        }
    };

    const [data, setData] = useState<any | undefined>({
        labels: labelsMonth,
        datasets: [
            {
                fill: true,
                label: t('profile.statistics.lookedMedia'),
                data: [0, 0, 0, 0],
                borderColor: '#FF9E0D',
                borderWidth: 2,
                pointBackgroundColor: '#FF9E0D',
                pointRadius: 6,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#FF9E0D',
                pointHoverBorderColor: '#FFFFFF',
                pointHoverBorderWidth: 2,
                pointHitRadius: 10,
                pointBorderWidth: 2,
                pointBorderColor: '#FFFFFF',
                pointStyle: 'circle',
                backgroundColor: ({ chart: { ctx } }: any) => {
                    const bg = ctx.createLinearGradient(0, 0, 0, 250);
                    bg.addColorStop(0, 'rgba(255, 158, 13, 0.40)');
                    bg.addColorStop(1, 'rgba(255, 158, 13, 0.00)');

                    // More config for your gradient
                    return bg;
                }
            }
        ]
    });

    const calcData = async () => {
        // const thisDataStatsByMonth = statsByMonth;
        let thisData: StatsArray = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: []
        };
        const dataStatsByMonth2: number[] = [];
        const tempWatchTimePersonal: number[] = [];
        const tempWatchTimeYoutube: number[] = [];
        await Promise.all(
            userDataList.map(async (userData) => {
                if (userData && userData.id && userData.mediaStatistics) {
                    userData.mediaStatistics.forEach((stat) => {
                        const date = getDateFromTimestamp2(stat.date);

                        if (
                            date >= startDate &&
                            date <= endDate &&
                            (typeDataView === 'all' || stat.typeOfMedia === typeDataView)
                        ) {
                            thisData = addData(timeScale, startDate, date, thisData);
                            if (
                                stat.videoDetails !== undefined &&
                                stat.videoDetails.watchTime &&
                                stat.videoDetails.videoUrl
                            ) {
                                if (stat.videoDetails.videoUrl.includes('firebase')) {
                                    tempWatchTimePersonal.push(stat.videoDetails.watchTime);
                                } else {
                                    tempWatchTimeYoutube.push(stat.videoDetails.watchTime);
                                }
                            }
                        }
                    });
                }
            })
        );

        Object.keys(thisData).forEach((key) => {
            if (
                key === '1' ||
                key === '2' ||
                key === '3' ||
                key === '4' ||
                key === '5' ||
                key === '6' ||
                key === '7'
            ) {
                dataStatsByMonth2.push(thisData[key].length);
            }
        });

        setImportEvol(calcEvol(dataStatsByMonth2, timeScale));

        setWatchTimePersonal(
            secToStringMinSec(
                Math.round(
                    tempWatchTimePersonal.reduce((a, b) => a + b, 0) / tempWatchTimePersonal.length
                )
            )
        );

        setWatchTimeYoutube(
            secToStringMinSec(
                Math.round(
                    tempWatchTimeYoutube.reduce((a, b) => a + b, 0) / tempWatchTimeYoutube.length
                )
            )
        );

        setNbMediaLooked(dataStatsByMonth2.reduce((a, b) => a + b, 0));

        // getLabelFromDate(startDate, endDate, timeScale)
        setData({
            ...data,
            labels: getLabelFromDate(startDate, endDate, timeScale),
            datasets: [
                {
                    ...data.datasets[0],
                    data: dataStatsByMonth2
                }
            ]
        });
    };

    useEffect(() => {
        if (userDataList && userDataList.length > 0) {
            calcData();
        }
    }, [userDataList, typeDataView, startDate, endDate]);

    return (
        <div style={{ ...classes['stat-card-container'] }}>
            <div style={{ ...classes['stat-card-title-container'] }}>
                <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                    {t('profile.statistics.lookedMediaTitle')}
                </Typography>
                <StatToolkit
                    value={nbMediaLooked}
                    tooltipText={t('profile.statistics.lookedMediaTooltip', { nb: nbMediaLooked })}
                />
            </div>
            <div style={{ ...classes['stat-card'] }}>
                <StimbotInfo text={t('profile.statistics.lookedMediaInfo')} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '1rem'
                    }}>
                    <Select
                        defaultValue="all"
                        onChange={(e) => {
                            setTypeDataView(
                                e.target.value as
                                    | 'all'
                                    | 'picture'
                                    | 'personalVideo'
                                    | 'youtubeVideo'
                            );
                        }}
                        sx={{
                            border: '1px solid #E8E8E8',
                            borderRadius: '1.5rem'
                        }}
                        opts={[
                            ...lstMedias.map((media) => ({
                                value: media.type,
                                label: t(media.name)
                            }))
                        ]}
                    />
                    <SelectTimeline
                        onChange={(data: {
                            val: string;
                            startDate: Date;
                            endDate: Date;
                            timeScale: 'day' | 'week' | 'month' | 'year';
                        }) => {
                            setEndDate(data.endDate);
                            setStartDate(data.startDate);
                            setTimeScale(data.timeScale);
                        }}
                    />
                </div>
                {data && <Line options={options} data={data} />}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1.5rem',
                        width: '100%'
                    }}>
                    <Button
                        sx={{ ...classes['white-btn'] }}
                        onClick={() => {
                            changetimeLine(
                                'prev',
                                timeScale,
                                endDate,
                                setEndDate,
                                startDate,
                                setStartDate
                            );
                        }}>
                        <img src={littleArrow} alt="littleArrow" />
                        {t('prev')}
                    </Button>
                    <Button
                        sx={{ ...classes['white-btn'] }}
                        onClick={() => {
                            changetimeLine(
                                'next',
                                timeScale,
                                endDate,
                                setEndDate,
                                startDate,
                                setStartDate
                            );
                        }}>
                        {t('next')}
                        <img
                            src={littleArrow}
                            alt="littleArrow"
                            style={{ transform: 'rotate(180deg)' }}
                        />
                    </Button>
                </div>

                <CardWatchTime time={watchTimePersonal} type="personal" />
                <CardWatchTime time={watchTimeYoutube} type="youtube" />
                <StatEvol evolNb={importEvol} />
            </div>
        </div>
    );
}
