import { Timestamp } from "firebase/firestore";

export class StatisticsDetails {
    timeTakenToCompleteExercise: number;

    difficultyLevel: number;

    date: Timestamp;

    errorCount: number;

    clueCount: number;

    memoryCardFind: number | undefined;

    memoryCardReturn: number | undefined;

    pongPlayerScore: number | undefined;

    pongComputerScore: number | undefined;

    constructor(data: {
        timeTakenToCompleteExercise: number;
        difficultyLevel: number;
        date: Timestamp;
        errorCount: number;
        clueCount: number;
        memoryCardFind: number | undefined;
        memoryCardReturn: number | undefined;
        pongPlayerScore: number | undefined;
        pongComputerScore: number | undefined;
    }) {
        this.timeTakenToCompleteExercise = data.timeTakenToCompleteExercise;
        this.difficultyLevel = data.difficultyLevel;
        this.date = data.date;
        this.errorCount = data.errorCount;
        this.clueCount = data.clueCount;
        this.memoryCardFind = data.memoryCardFind;
        this.memoryCardReturn = data.memoryCardReturn;
        this.pongPlayerScore = data.pongPlayerScore;
        this.pongComputerScore = data.pongComputerScore;
    }
}
