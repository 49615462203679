import React, { CSSProperties } from 'react';
import subscribeStepGift from '../assets/subscribeStepGift.png';
import { Typography } from './Typography';
import { Button } from './Button';
import { t } from 'i18next';

const classes: { [key: string]: CSSProperties } = {
    'plans-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '1rem',
        paddingBottom: '5px',
        width: '100%',
    },
    plans: {
        padding: '1rem',
        width: '50%',
        height: '100%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px'
    },
    'plans-title': {
        fontWeight: '800',
        fontSize: '0.875rem',
        textAlign: 'center',
        color: '#002F55'
    },
    'plans-subtitle': {
        fontWeight: '500',
        fontSize: '0.625rem',
        height: '2rem',
        textAlign: 'center',
        color: '#939393'
    },
    'plans-price': {
        fontWeight: '800',
        fontSize: '0.875rem',
        textAlign: 'center',
        color: '#E5007B'
    },
    'plans-btn': {
        textTransform: 'uppercase'
    }
};

interface Props {
    offer: 'monthly' | 'yearly' | 'free' | undefined;
    setOffer: (offer: 'monthly' | 'yearly' | 'free') => void;
    free?: boolean;
}

export function ListOffer({ offer, setOffer, free }: Props): JSX.Element {
    return (
        <>
            {free && (
                <div
                    style={{
                        ...classes['plans'],
                        width: '100%',
                        height: 'fit-content',
                        border: offer === 'free' ? '2px solid #E5007B' : '1px solid #C4C4C4',
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                        marginBottom: '2rem'
                    }}
                    onClick={() => setOffer('free')}>
                    <div style={{ width: '60%' }}>
                        <Typography
                            variant="p"
                            sx={{
                                ...classes['plans-title'],
                                marginBottom: '2rem'
                                // color: '#5BBCEF'
                            }}>
                            {t('freeOffer')}
                        </Typography>
                        <Typography
                            variant="p"
                            sx={{
                                ...classes['plans-price'],
                                color: '#5BBCEF'
                            }}>
                            {t('priceOfferFree')}
                        </Typography>
                        <Button
                            sx={{
                                ...classes['plans-btn'],
                                fontWeight: 'bold',
                                backgroundColor: '#F4BE38'
                            }}>
                            {t('Choise')}
                        </Button>
                    </div>
                    <img
                        src={subscribeStepGift}
                        alt="subscribeStepGift"
                        style={{ width: '40%', height: 'auto' }}
                    />
                </div>
            )}
            <div style={classes['plans-container']}>
                <div
                    style={{
                        ...classes['plans'],
                        border: offer === 'monthly' ? '2px solid #E5007B' : '1px solid #C4C4C4'
                    }}
                    onClick={() => setOffer('monthly')}>
                    <Typography
                        variant="p"
                        sx={{
                            ...classes['plans-title'],
                            marginBottom: '2rem'
                        }}>
                        {t('Offer')}
                        <br />
                        {t('monthlyOffer')}
                    </Typography>
                    <Typography variant="p" sx={classes['plans-price']}>
                        {t('priceOfferByMonth')}
                    </Typography>
                    <Button
                        sx={{
                            ...classes['plans-btn'],
                            marginTop: '0.5rem'
                        }}>
                        {t('Choise')}
                    </Button>
                </div>
                <div
                    style={{
                        ...classes['plans'],
                        border: offer === 'yearly' ? '2px solid #E5007B' : '1px solid #C4C4C4'
                    }}
                    onClick={() => setOffer('yearly')}>
                    <Typography variant="p" sx={classes['plans-title']}>
                        {t('Offer')}
                        <br />
                        {t('yearlyOffer')}
                    </Typography>
                    <Typography variant="p" sx={classes['plans-subtitle']}>
                        {t('win20%')}
                    </Typography>
                    <Typography variant="p" sx={classes['plans-price']}>
                        {t('priceOfferByYear')}
                    </Typography>
                    <Button
                        sx={{
                            ...classes['plans-btn'],
                            marginTop: '0.5rem'
                        }}>
                        {t('Choise')}
                    </Button>
                </div>
            </div>
        </>
    );
}
