import React, { CSSProperties, ReactElement, useContext, useEffect } from 'react';
import { Typography } from './Typography';
import cross from '../assets/cross.svg';
import useWindowDimensions from '../utilities/useWindowDimensions';
import infoLogo from '../assets/infoLogo.svg';
import { getCommunityId, getImageKitUrlFrom } from '../utilities/utils';
import { CheckBox } from './CheckBox';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';
import { Establishment } from '../models/Establishment';
import {
    addPersonalVideo,
    addPersonalYoutubeVideo,
    deletePersonalVideo,
    deletePersonalYoutubeVideo,
    getEstablishmentList,
    saveExerciseItem
} from '../services/cloudFirestore';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { Loader } from './Loader';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 1000
    },
    modal: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0.8rem',
        zIndex: 1001,
        // padding: '10px',
        overflowY: 'auto',
        backgroundColor: '#F5F5F5',
        height: '90%',
        width: '90%'
    },
    media: {
        width: '100%',
        // height: '100%',
        aspectRatio: '1/1',
        objectFit: 'cover'
    }
};

interface ModalSendAllMediaProps {
    isOpen: boolean;
    closeModal?: Function;
    mediaList: any[];
}

export function ModalSendAllMedia({
    isOpen,
    closeModal,
    mediaList
}: ModalSendAllMediaProps): JSX.Element {
    const { t } = useTranslation();
    const { width, height } = useWindowDimensions();
    const [step, setStep] = React.useState(0);
    const [selectedMedia, setSelectedMedia] = React.useState<any[]>([]);
    const [establishmentList, setEstablishmentList] = React.useState<Establishment[]>([]);
    const [selectedEstablishment, setSelectedEstablishment] = React.useState<Establishment[]>([]);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [loading, setLoading] = React.useState(false);

    // const getMediaId = (media: any): string => {
    //     if (Object.prototype.hasOwnProperty.call(media, 'videoUrl')) {
    //         return media.videoUrl;
    //     }
    //     return media.id;
    // };
    useEffect(() => {
        const getEstablishment = async () => {
            const result = await getEstablishmentList();
            setEstablishmentList(result);
        };
        getEstablishment();
        setSelectedMedia([]);
        setSelectedEstablishment([]);
        setLoading(false);
    }, [isOpen]);

    useEffect(() => {
        if (selectedMedia.length === 0) setStep(0);
    }, [selectedMedia]);

    const saveMedia = async () => {
        setLoading(true);
        const etsIdList = selectedEstablishment.map((ets) => ets.id);
        const communityId = getCommunityId(user);
        for (const media of selectedMedia) {
            // selectedMedia.forEach(async (media) => {
            if (!Object.prototype.hasOwnProperty.call(media, 'videoUrl')) {
                // image
                await saveExerciseItem({ ...media, etsList: etsIdList }, '');
            } else if (!media.videoUrl.includes('firebase') && communityId && media.videoUrl) {
                // video youtube
                if (media?.id && media?.videoUrl) {
                    await deletePersonalYoutubeVideo(media?.id, media?.videoUrl);
                    await addPersonalYoutubeVideo(
                        { ...media, etsList: etsIdList, communityId: communityId },
                        media.videoUrl
                    );
                }
            } else if (media.videoUrl.includes('firebase') && communityId && media.videoUrl) {
                // video firebase
                if (media?.id && media?.videoUrl) {
                    await deletePersonalVideo(media?.id, media?.videoUrl, false);
                    await addPersonalVideo(
                        { ...media, etsList: etsIdList, communityId: communityId },
                        media?.videoUrl
                    );
                }
            }
        }
        setLoading(false);
    };

    return (
        <div style={{ ...classes['div-root'], display: isOpen ? 'flex' : 'none' }}>
            {loading && <Loader sx={{zIndex: 1003}} />}
            <div
                style={{
                    ...classes['modal'],
                    overflowY: 'hidden'
                }}>
                <Typography variant="h1" sx={{ textAlign: 'center', padding: '1rem' }}>
                    {t(`selectMedia.step${step + 1}`)}
                </Typography>
                {closeModal && (
                    <img
                        src={cross}
                        alt="close"
                        onClick={(): void => closeModal()}
                        style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1rem',
                            cursor: 'pointer',
                            border: '2px solid #E3E6E8',
                            borderRadius: '50%',
                            height: '2.5rem',
                            width: '2.5rem',
                            zIndex: 1002
                        }}
                    />
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '0.5rem',
                        width: '100%',
                        padding: '0 1rem 1rem 1rem'
                        // justifyContent: '',
                        // padding: '1rem'
                    }}>
                    <Button
                        disabled={step === 0}
                        sx={{
                            width: '25%'
                        }}
                        onClick={(): void => setStep(0)}>
                        {t('prev')}
                    </Button>
                    <Button
                        pink
                        sx={{
                            width: '50%'
                        }}
                        onClick={(): void => {
                            if (step === 0) {
                                if (selectedMedia.length !== mediaList.length) {
                                    setSelectedMedia(mediaList.map((media) => media));
                                } else {
                                    setSelectedMedia([]);
                                }
                            } else if (step === 1) {
                                if (selectedEstablishment.length !== establishmentList.length) {
                                    setSelectedEstablishment(establishmentList);
                                } else {
                                    setSelectedEstablishment([]);
                                }
                            }
                        }}>
                        {t('selectAll')}
                    </Button>
                    <Button
                        disabled={step === 0 && selectedMedia.length === 0}
                        sx={{
                            width: '25%'
                        }}
                        onClick={async (): Promise<void> => {
                            if (step === 0) setStep(1);
                            else if (step === 1 && selectedMedia.length !== 0) {
                                await saveMedia();
                                closeModal && closeModal();
                            }
                        }}>
                        {t(step === 0 ? 'next' : 'Validate')}
                    </Button>
                </div>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        overflowY: 'auto',
                        flexDirection: 'row',
                        // justifyContent: 'space-between',
                        alignItems: 'center',
                        textAlign: 'center',
                        flexWrap: 'wrap',
                        height: '100%',
                        width: '100%',
                        padding: '1rem',
                        gap: '0.2rem'
                    }}>
                    {step === 0 &&
                        mediaList.map((media, index) => (
                            <div
                                key={`media-${index}`}
                                style={{
                                    width: '8rem',
                                    height: '8rem',
                                    position: 'relative',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    if (selectedMedia.includes(media)) {
                                        setSelectedMedia(
                                            selectedMedia.filter((id) => id !== media)
                                        );
                                    } else {
                                        setSelectedMedia([...selectedMedia, media]);
                                    }
                                }}>
                                <CheckBox
                                    sx={{
                                        position: 'absolute',
                                        top: '0.5rem',
                                        right: '0.5rem'
                                    }}
                                    checked={selectedMedia.includes(media)}
                                    setChecked={(value) => {
                                        if (value) {
                                            setSelectedMedia([...selectedMedia, media]);
                                        } else {
                                            setSelectedMedia(
                                                selectedMedia.filter((id) => id !== media)
                                            );
                                        }
                                    }}
                                />
                                {!Object.prototype.hasOwnProperty.call(media, 'videoUrl') && (
                                    <img
                                        src={getImageKitUrlFrom(media.exerciseImage, 512, 512)}
                                        alt="media"
                                        style={classes['media']}
                                    />
                                )}
                                {Object.prototype.hasOwnProperty.call(media, 'videoUrl') &&
                                    !media.videoUrl.includes('firebase') && (
                                        <img
                                            style={classes['media']}
                                            src={`https://img.youtube.com/vi/${media.videoUrl.match(
                                                /[\w\\-]{11,}/
                                            )}/0.jpg`}
                                            alt="SampleImg"
                                        />
                                    )}
                                {Object.prototype.hasOwnProperty.call(media, 'videoUrl') &&
                                    media.videoUrl.includes('firebase') && (
                                        <img
                                            style={classes['media']}
                                            src={
                                                media.thumbnail
                                                    ? getImageKitUrlFrom(
                                                          media.thumbnail,
                                                          1024,
                                                          1024
                                                      )
                                                    : ''
                                            }
                                            alt="SampleImg"
                                        />
                                    )}
                            </div>
                        ))}
                    {step === 1 &&
                        establishmentList.map((ets, index) => (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    width: width && width > 768 ? 'calc(50% - 1rem)' : '100%',
                                    height: '4rem'
                                }}
                                key={`ets-${index}`}>
                                <div
                                    style={{
                                        width: '80%',
                                        height: '3rem',
                                        borderRadius: '0.5rem',
                                        border: '1px solid #E3E6E8',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0.5rem',
                                        position: 'relative',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (selectedEstablishment.includes(ets)) {
                                            setSelectedEstablishment(
                                                selectedEstablishment.filter((id) => id !== ets)
                                            );
                                        } else {
                                            setSelectedEstablishment([
                                                ...selectedEstablishment,
                                                ets
                                            ]);
                                        }
                                    }}>
                                    <CheckBox
                                        sx={{
                                            position: 'absolute',
                                            top: '0.5rem',
                                            right: '0.5rem'
                                        }}
                                        checked={selectedEstablishment.includes(ets)}
                                        setChecked={(value) => {
                                            if (value) {
                                                setSelectedEstablishment([
                                                    ...selectedEstablishment,
                                                    ets
                                                ]);
                                            } else {
                                                setSelectedEstablishment(
                                                    selectedEstablishment.filter((id) => id !== ets)
                                                );
                                            }
                                        }}
                                    />
                                    <Typography>{ets.establishmentName}</Typography>
                                </div>
                            </div>
                        ))}
                    {/* here */}
                </div>
            </div>
        </div>
    );
}
