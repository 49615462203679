/* eslint-disable no-nested-ternary */
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import { createStyles, makeStyles, styled } from '@mui/styles';
import { blueGrey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { Container, CardMedia, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import wantToPause from '../../../../assets/wantToPause.png';
import validate from '../../../../assets/validate.png';
import cross from '../../../../assets/cross.svg';
import wantAnotherExercise from '../../../../assets/wantAnotherExercise.png';
import badReview from '../../../../assets/badReview .png';
import averageReview from '../../../../assets/averageReview.png';
import goodReview from '../../../../assets/goodReview.png';
import heart from '../../../../assets/heart.png';
import { ModalContainer } from '..//ModalContainer';
import { ModalContainerFullScreen } from '../ModalContainerFullScreen';
import { Roles } from '../../../../models/Roles';
import { UserContext } from '../../../../providers/UserProvider';
import { Exercise } from '../../../../models/Exercise';
import { Review } from '../../../../models/Review';
import {
    getFamilyUser,
    getPatientUser,
    getTherapistUser,
    saveExerciseReview,
    saveGamesData
} from '../../../../services/cloudFirestore';
import { Therapist } from '../../../../models/Therapist';
import { capitalizeFirstLetter } from '../../../../utilities/utils';
import { ProfileImage } from '../ProfileImage';
import { ExerciseItem } from '../../../../models/ExerciseItem';
// import { sendThankYouMail } from '../../services/storageFunctions';
import { UserDataProp } from '../../../../models/userDataProp';
import { Patient } from '../../../../models/Patient';
import { Family } from '../../../../models/Family';
import { ExerciseType } from '../../../../models/ExerciseType';
import { formatVideoUrl } from '../../../../services/formatFunctions';
import styled from '@emotion/styled';
import './ReviewExerciseAlerts.css';
import useWindowDimensions from '../../../../utilities/useWindowDimensions';
import { isPlatform } from '@ionic/react';
import { SeniorStatistics } from '../../../../models/SeniorStatistics';
import { StatisticsDetails } from '../../../../models/StatisticsDetails';
import { Timestamp } from 'firebase/firestore';
import { VideoPlayer } from '../../../../components/VideoPlayer';

const classes: { [key: string]: CSSProperties } = {
    avatarProfile: {
        height: '120px',
        width: '120px',
        margin: 'auto',
        marginTop: '0px',
        marginBottom: '0px'
    },
    avatarImage: {
        height: '250px',
        width: '250px'
    },
    btnLayout: {
        width: '100%',
        marginTop: '5%',
        marginLeft: '0px'
        // '@media (max-width: 598px)': {
        //     cursor: 'pointer',
        //     width: '40%',
        //     margin: '16px 5px 30px 5px'
        // }
    },
    input: {
        color: '#002F55'
    },
    singleBtnLayout: {
        width: '100%',
        marginTop: '5%',
        marginLeft: '0px'
        // '@media (max-width: 598px)': {
        //     cursor: 'pointer',
        //     width: '80%',
        //     margin: '16px 10px'
        // }
    },
    startExerciseBtn: {
        width: '100%',
        marginTop: '5%',
        marginLeft: '0px'
        // '@media (max-width: 598px)': {
        //     cursor: 'pointer',
        //     width: '45%',
        //     margin: '16px 5px 30px 5px'
        // }
    },
    anotherExerciseBtnLayout: {
        width: '100%',
        marginTop: '5%',
        marginLeft: '0px'
        // '@media (max-width: 598px)': {
        //     cursor: 'pointer',
        //     width: '60%',
        //     margin: '16px 10px'
        // }
    },
    selectLayout: {
        width: '100%',
        marginTop: '0px',
        color: 'rgba(0, 47, 85, 1)'
    },
    selectPlaceholder: {
        width: '100%',
        marginTop: '0px',
        color: 'rgba(172, 188, 199, 1)'
    },
    fontBlue: {
        // '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        //     color: 'rgba(0, 47, 85, 1)'
        // }
    },
    videoModalHeading: {
        // '@media (max-width: 1024px)': {
        //     width: '100%'
        // },
        // '@media (min-width: 1024px)': {
        //     width: '100%'
        // }
    },
    headingLayout: {
        // '@media (min-width: 1024px)': {
        //     width: '70% !important'
        // }
    },
    therapistHeadingLayout: {
        // '@media (min-width: 1025px)': {
        //     marginLeft: '15%'
        // }
    },
    infoLayout: {
        // '@media (min-width: 1024px)': {
        //     margin: '0px !important',
        //     marginRight: '15% !important'
        // }
    },
    groupBtnContainer: {
        display: 'flex',
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: '2%'
    },
    profileImage: {
        marginRight: '10px',
        alignSelf: 'center',
        justifySelf: 'center',
        display: 'inline-block',
        width: '100%',
        padding: '45%'
    },
    currentUserInitials: {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: '0'
    }
};

const DefaultLogo = styled('div')({
    borderRadius: '50%',
    background: '#efe7d7',
    position: 'relative'
});

interface ReviewExerciseAlertsProps {
    exercise: Exercise | undefined;
    exerciseType: ExerciseType;
    difficultyLevel: number;
    nbTurn?: number;
    nbFind?: number;
    pongPlayerScore?: number;
    pongCpuScore?: number;
    errorCount: number;
    clueCount: number;
}

export function ReviewExerciseAlerts({
    exercise,
    exerciseType,
    difficultyLevel,
    nbTurn,
    nbFind,
    pongPlayerScore,
    pongCpuScore,
    errorCount,
    clueCount
}: ReviewExerciseAlertsProps): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [openMsgFromAutor, setOpenMsgFromAutor] = useState(false);
    const [openVideoPersoAfterExercise, setOpenVideoPersoAfterExercise] = useState(false);
    const [openVideoYoutubeAfterExercise, setOpenVideoYoutubeAfterExercise] = useState(false);

    const [openReviewModal, setOpenReview] = useState(false);
    const [openThankModal, setOpenThankModal] = useState(false);
    const [openAnotherExercice, setOpenAnotherExerciceModal] = useState(false);

    const [hiddenTitle, setHiddenTitle] = useState<boolean>(false);
    const [hiddenTitleYt, setHiddenTitleYt] = useState<boolean>(false);
    const [numberOfPlaing, setNumberOfPlaing] = useState<number>();
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [patientData, setPatientData] = useState<Patient | null>();
    const [familyAuthor, setFamilyAuthor] = useState<Family>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        relationship: undefined,
        establishmentName: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const { height, width } = useWindowDimensions();

    const addExerciseReview = async (value: Review): Promise<void> => {
        if (exercise && user && user.id) {
            await saveExerciseReview(exercise, value, user.id, exerciseType);
        }
    };

    // Save statistics for SENIOR
    useEffect(() => {
        if (user && user.id && exercise && (user.role === Roles.SENIOR || user.role === Roles.FAMILY)) {
            const startTimer = localStorage.getItem('startTime');
            const endTimer = new Date();
            if (startTimer && endTimer && exercise.id && exercise.exerciseType) {
                const timeTakenToCompleteExercise = Math.floor(
                    Math.abs(endTimer.getTime() - new Date(startTimer).getTime()) / 1000
                );
                saveGamesData(
                    new SeniorStatistics(
                        exercise.id,
                        exercise.exerciseType,
                        [new StatisticsDetails({
                            timeTakenToCompleteExercise: timeTakenToCompleteExercise,
                            difficultyLevel: difficultyLevel,
                            date: Timestamp.now(),
                            errorCount: errorCount,
                            clueCount: clueCount,
                            memoryCardFind: exercise.exerciseType === ExerciseType.MEMORIZ ? nbFind : undefined,
                            memoryCardReturn: exercise.exerciseType === ExerciseType.MEMORIZ ? nbTurn : undefined,
                            pongPlayerScore: exercise.exerciseType === ExerciseType.PONG ? pongPlayerScore : undefined,
                            pongComputerScore: exercise.exerciseType === ExerciseType.PONG ? pongCpuScore : undefined
                        })]
                    ), user
                )
            }
        }
    }, []);

    useEffect(() => {
        const personaInfo = async (): Promise<void> => {
            if (exercise && exercise.authorId && user && user?.id && user.role === Roles.SENIOR) {
                const data = await getPatientUser(user.id);
                if (data) {
                    setPatientData(data);
                    if (data.statistics) {
                        let tempNumberOfPlaing = 0;
                        // eslint-disable-next-line array-callback-return
                        data.statistics.map((stat): void => {
                            if (exercise && stat.exerciseId === exercise.id) {
                                tempNumberOfPlaing = stat.details.length;
                            }
                        });
                        setNumberOfPlaing(tempNumberOfPlaing);
                    }
                }
                if (exercise.authorType === Roles.PRO) {
                    const authorData = await getTherapistUser(exercise.authorId);
                    if (authorData) {
                        setAuthor(authorData);
                    }
                } else if (exercise.authorType === Roles.FAMILY) {
                    const authorData = await getFamilyUser(exercise.authorId);
                    if (authorData) {
                        setFamilyAuthor(authorData);
                    }
                }
            }
        };
        personaInfo();
    }, [exercise]);

    useEffect(() => {
        if (numberOfPlaing !== undefined) next();
    }, [numberOfPlaing]);

    const [step, setStep] = useState(1);
    const next = (): void => {
        if (user?.role === Roles.SENIOR && exercise?.authorId !== user?.id) {
            if (numberOfPlaing === 0) {
                switch (step) {
                    case 1:
                        setOpenReview(true);
                        break;
                    case 2:
                        if (exercise?.authorType === Roles.ADMIN) {
                            history.push('/games');
                            break;
                        } else if (
                            !exercise?.congratulationsMessage ||
                            (!exercise?.rewardPhoto && !exercise?.rewardVideo)
                        ) {
                            setOpenThankModal(true);
                            setStep(4);
                            break;
                        }
                        setOpenMsgFromAutor(true);
                        break;
                    case 3:
                        if (exercise?.rewardPhoto) {
                            setOpenVideoPersoAfterExercise(true);
                        } else if (exercise?.rewardVideo) {
                            setOpenVideoYoutubeAfterExercise(true);
                        } else {
                            history.push('/games');
                        }
                        break;
                    case 4:
                        setOpenThankModal(true);
                        break;
                    case 5:
                        // setOpenAnotherExerciceModal(true);
                        history.push('/games');
                        break;
                    default:
                        break;
                }
                setStep(step + 1);
            } else {
                history.push('/games');
            }
        } else {
            history.push('/games');
        }
    };

    const exerciseReview = (
        <div
            style={{
                minHeight: '75vh',
                // maxHeight: '90vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            <Typography
                variant="h3"
                color={blueGrey[800]}
                sx={{
                    fontWeight: 600,
                    textAlign: 'center',
                    fontFamily: 'var(--ion-font-family)',
                    fontSize: '1.875rem'
                }}>
                {t('likeExercise?')}
            </Typography>
            <Typography
                variant="h3"
                color={blueGrey[800]}
                sx={{
                    fontWeight: 500,
                    textAlign: 'center',
                    marginTop: '2%',
                    fontFamily: 'var(--ion-font-family)',
                    fontSize: width && width < 480 ? '1.5rem' : '1.875rem'
                }}>
                {t('clickOnReviewOption')}
            </Typography>
            <Container
                sx={{
                    color: '#004680',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: width && width < 480 ? 'column-reverse' : ''
                }}>
                <Card
                    sx={{
                        width: width && width < 480 ? '100%' : '30%',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <CardMedia
                        onClick={(): void => {
                            addExerciseReview(Review.DISLIKED);
                            next();
                        }}
                        sx={{
                            backgroundColor: 'rgba(200, 65, 65, 0.1)',
                            width: width && width < 480 ? '50%' : '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={badReview}
                            sx={{
                                width: '95%',
                                padding: '28%',
                                height: 'auto',
                                margin: 'auto',
                                borderRadius: '50%'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: '1.5rem',
                            color: '#c84141',
                            fontWeight: 600,
                            fontFamily: 'var(--ion-font-family)',
                            marginTop: '5%'
                        }}>
                        {t('iDidNotLike')}
                    </Typography>
                </Card>
                <Card
                    onClick={(): void => {
                        addExerciseReview(Review.SATISFACTORY);
                        next();
                    }}
                    sx={{
                        width: width && width < 480 ? '100%' : '30%',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <CardMedia
                        sx={{
                            backgroundColor: 'rgba(231, 166, 0, 0.1)',
                            width: width && width < 480 ? '50%' : '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={averageReview}
                            sx={{
                                width: '95%',
                                padding: '28%',
                                height: 'auto',
                                margin: 'auto',
                                borderRadius: '50%'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: '1.5rem',
                            color: '#e7a600',
                            fontWeight: 600,
                            fontFamily: 'var(--ion-font-family)',
                            marginTop: '5%'
                        }}>
                        {t('iHaveNoOpinion')}
                    </Typography>
                </Card>
                <Card
                    onClick={(): void => {
                        addExerciseReview(Review.LOVED);
                        next();
                    }}
                    sx={{
                        width: width && width < 480 ? '100%' : '30%',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <CardMedia
                        sx={{
                            backgroundColor: 'rgba(137, 204, 51, 0.1)',
                            width: width && width < 480 ? '50%' : '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={goodReview}
                            sx={{
                                width: '95%',
                                padding: '28%',
                                height: 'auto',
                                margin: 'auto',
                                borderRadius: '50%'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: '1.5rem',
                            color: '#89cc33',
                            fontWeight: 600,
                            fontFamily: 'var(--ion-font-family)',
                            marginTop: '5%'
                        }}>
                        {t('iLoved')}
                    </Typography>
                </Card>
            </Container>
        </div>
    );

    const msgFromAutor = (
        <div
            style={{
                minHeight: '75vh',
                maxHeight: '90vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            {exercise?.authorType === Roles.FAMILY && !familyAuthor.profilePhoto && (
                <DefaultLogo style={classes.avatarProfile}>
                    <div style={{ height: '5vmin' }}>
                        <Typography
                            variant="h1"
                            sx={{ ...classes.currentUserInitials, fontWeight: 'bold' }}>
                            {familyAuthor?.firstName?.charAt(0).toUpperCase() ??
                                '' + familyAuthor?.lastName?.charAt(0).toUpperCase()}
                        </Typography>
                    </div>
                </DefaultLogo>
            )}
            {exercise?.authorType === Roles.PRO && !author.profilePhoto && (
                <DefaultLogo style={classes.avatarProfile}>
                    <div style={{ height: '5vmin' }}>
                        <Typography
                            variant="h1"
                            sx={{ ...classes.currentUserInitials, fontWeight: 'bold' }}>
                            {author?.firstName?.charAt(0).toUpperCase() ??
                                '' + author?.lastName?.charAt(0).toUpperCase()}
                        </Typography>
                    </div>
                </DefaultLogo>
            )}
            {exercise?.authorType === Roles.FAMILY && familyAuthor.profilePhoto && (
                <ProfileImage
                    width={width && height && (width < 550 || height < 550) ? 80 : 158}
                    height={width && height && (width < 550 || height < 550) ? 80 : 158}
                    name={`${familyAuthor.firstName} ${familyAuthor.lastName}`}
                    url={familyAuthor.profilePhoto}
                />
            )}
            {exercise?.authorType === Roles.PRO && author.profilePhoto && (
                <ProfileImage
                    width={width && height && (width < 550 || height < 550) ? 80 : 158}
                    height={width && height && (width < 550 || height < 550) ? 80 : 158}
                    name={`${author.firstName} ${author.lastName}`}
                    url={author.profilePhoto}
                />
            )}
            <Container
                sx={{
                    color: '#004680',
                    textAlign: 'center'
                    // marginTop: width && width < 480 ? '2rem' : '10px'
                }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'normal',
                        fontFamily: 'var(--ion-font-family)',
                        fontSize:
                            !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios'))
                                ? '1rem'
                                : width && width < 480
                                ? '1rem'
                                : 'auto'
                    }}>
                    {exercise?.congratulationsMessage}
                </Typography>
                <Button
                    onClick={(): void => {
                        next();
                    }}
                    variant="outlined"
                    size="large"
                    color="primary"
                    sx={{
                        ...classes.startExerciseBtn,
                        border: '1px solid #8b004b',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        fontSize: '24px',
                        lineHeight: '29px',
                        fontWeight: '600',
                        width:
                            !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios'))
                                ? '100%'
                                : width && width < 480
                                ? '80%'
                                : '400px !important',
                        marginBottom:
                            width &&
                            height &&
                            (width < 550 || height < 550 || (height <= 768 && height > 551))
                                ? '0px'
                                : '20px',
                        marginTop:
                            width &&
                            height &&
                            (width < 550 || height < 550 || (height <= 768 && height > 551))
                                ? '2% !important'
                                : '5%',
                        fontFamily: 'var(--ion-font-family)',
                        textTransform: 'none'
                    }}>
                    {t('toThank')}
                </Button>
            </Container>
        </div>
    );

    const videoPersoAfterExercise = (
        <div
            style={{
                minHeight: '95vh',
                maxHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
            <div
                style={{
                    paddingTop: width && width < 480 ? '5rem' : '',
                    display: hiddenTitle ? 'none' : 'flex',
                    justifyContent: 'center',
                    paddingLeft: width && width < 480 ? '' : '4rem'
                }}>
                <Typography
                    variant="h3"
                    color={blueGrey[800]}
                    sx={{
                        textAlign: 'center',
                        // marginTop: '2%',
                        paddingLeft: '2%',
                        width: '80%',
                        '@media (max-width: 780px)': {
                            width: '100%'
                        },
                        fontWeight: 'bold',
                        justifyContent: 'space-around',
                        fontFamily: 'var(--ion-font-family)',
                        textTransform: 'none',
                        fontSize:
                            !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios'))
                                ? '1rem'
                                : width && width < 480
                                ? '1.5rem'
                                : '2rem'
                    }}>
                    {exercise?.authorType === Roles.FAMILY
                        ? t('clickOnPlayFami')
                        : t('clickOnPlayThera')}
                </Typography>
            </div>
            <Container
                sx={{
                    color: '#004680',
                    textAlign: 'center',
                    width: '100%',
                    margin: 'auto',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex'
                }}>
                {exercise?.rewardPhoto && (
                    <Box
                        sx={{
                            width: '100%',
                            height:
                                width && width < 480
                                    ? hiddenTitle
                                        ? '25rem'
                                        : '20rem'
                                    : hiddenTitle
                                    ? 'calc(100vh - 130px)'
                                    : 'calc(100vh - 230px)',
                            maxHeight: 'inherit',
                            maxWidth: 'inherit',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'var(--ion-font-family)'
                        }}>
                        <VideoPlayer
                            url={formatVideoUrl(exercise.rewardPhoto.toString())}
                            autoPlay={true}
                        />
                    </Box>
                )}
            </Container>
            <Button
                onClick={(): void => {
                    setOpenVideoPersoAfterExercise(false);
                    next();
                }}
                variant="outlined"
                size="large"
                color="primary"
                sx={{
                    ...classes.singleBtnLayout,
                    border: '1px solid #8b004b',
                    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                    fontSize: width && width < 480 ? '1rem' : '24px',
                    lineHeight: '29px',
                    fontFamily: 'var(--ion-font-family)',
                    width: width && width < 480 ? '80%' : '400px !important',
                    marginTop: '0% !important',
                    marginLeft: 'auto !important',
                    marginRight: 'auto',
                    marginBottom: '10px',
                    paddingLeft: '0',
                    paddingRight: '0',
                    textTransform: 'none'
                }}>
                {t('discoverAnotherAttention')}
            </Button>
        </div>
    );

    const videoYoutubeAfterExercise = (
        <div
            style={{
                minHeight: '95vh',
                maxHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            <div style={{ display: 'flex' }}>
                <Box
                    sx={{
                        display: hiddenTitleYt ? 'none' : 'flex',
                        flexDirection: 'column',
                        paddingTop: width && width < 480 ? '5rem' : '',
                        paddingLeft: width && width < 480 ? '' : '4rem'
                    }}
                    style={classes.videoModalHeading}>
                    <Typography
                        variant="h3"
                        color={blueGrey[800]}
                        sx={{
                            width: user?.role === Roles.SENIOR ? '90%' : 'auto',
                            margin: 'auto',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            justifyContent: 'space-around',
                            '@media (max-width: 780px)': {
                                width: '100%'
                            },
                            // marginTop: '2%',
                            paddingLeft: '2%',
                            fontFamily: 'var(--ion-font-family)',
                            ...(user?.role === Roles.SENIOR
                                ? classes.headingLayout
                                : classes.therapistHeadingLayout),
                            textTransform: 'none',
                            fontSize:
                                !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios'))
                                    ? '1rem'
                                    : width && width < 480
                                    ? '1.5rem'
                                    : '2rem'
                        }}>
                        {exercise?.authorType === Roles.FAMILY
                            ? t('clickOnPlayFami')
                            : t('clickOnPlayThera')}
                    </Typography>
                </Box>
            </div>
            <Container
                sx={{
                    color: '#004680',
                    textAlign: 'center',
                    width: '100%',
                    margin: 'auto',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex'
                }}>
                {exercise?.rewardVideo && (
                    <Box
                        sx={{
                            width: '100%',
                            height:
                                width && width < 480
                                    ? hiddenTitle
                                        ? '25rem'
                                        : '20rem'
                                    : hiddenTitleYt
                                    ? 'calc(100vh - 130px)'
                                    : 'calc(100vh - 230px)',
                            maxHeight: 'inherit',
                            maxWidth: 'inherit',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'var(--ion-font-family)'
                        }}>
                        <VideoPlayer
                            url={formatVideoUrl(exercise.rewardVideo)}
                            autoPlay={true}
                        />
                    </Box>
                )}
            </Container>
            <Button
                onClick={(): void => {
                    setOpenVideoYoutubeAfterExercise(false);
                    next();
                }}
                variant="outlined"
                size="large"
                color="primary"
                sx={{
                    ...classes.anotherExerciseBtnLayout,
                    border: '1px solid #8b004b',
                    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                    fontSize: '24px',
                    lineHeight: '29px',
                    fontFamily: 'var(--ion-font-family)',
                    marginTop: '1% !important',
                    width: width && width < 480 ? '80%' : '400px !important',
                    marginLeft: 'auto !important',
                    marginRight: 'auto',
                    marginBottom: '10px',
                    textTransform: 'none'
                }}>
                {t('toThank')}
            </Button>
        </div>
    );

    const thankAfterVideo = (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '5%'
            }}>
            <Typography
                variant="h3"
                color={blueGrey[800]}
                sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontFamily: 'var(--ion-font-family)',
                    fontSize: !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? '1rem' : '1.875rem'
                }}>
                {t('placeFingreOnHeart')}{' '}
                {exercise?.authorType === Roles.FAMILY && familyAuthor.firstName
                    ? capitalizeFirstLetter(familyAuthor.firstName)
                    : author.firstName
                    ? capitalizeFirstLetter(author.firstName)
                    : ''}{' '}
                {t('forAllAttentions')}
            </Typography>
            <Box
                display="flex"
                justifyContent="center"
                sx={{ color: '#004680', marginTop: '5%', alignItems: 'center' }}>
                <Box
                    style={{
                        width: !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? '7rem' : '40%',
                        height: 'auto'
                    }}>
                    <ProfileImage
                        width={!isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? 16 * 7 : 250}
                        height={!isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? 16 * 7 : 250}
                        name={`${user!.firstName} ${user!.lastName}`}
                        url={user!.profilePhoto}
                    />
                </Box>
                <Avatar
                    className="heartbeatAnimation"
                    sx={{
                        display: 'inline-block',
                        width: !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? '7rem' : '40%',
                        height: 'auto',
                        cursor: 'pointer'
                    }}
                    alt="Heart Img"
                    src={heart}
                    onClick={(): void => {
                        next();
                        // sendThanksEmail();
                    }}
                />
                {exercise?.authorType === Roles.PRO && !author.profilePhoto && (
                    <DefaultLogo style={classes.avatarImage}>
                        <div
                            style={{
                                height:
                                    !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? '7rem' : '5vmin'
                            }}>
                            <Typography
                                variant="h1"
                                sx={{ ...classes.currentUserInitials, fontWeight: 'bold' }}>
                                {author?.firstName?.charAt(0).toUpperCase() ??
                                    '' + author?.lastName?.charAt(0).toUpperCase()}
                            </Typography>
                        </div>
                    </DefaultLogo>
                )}
                {exercise?.authorType === Roles.PRO && author.profilePhoto && (
                    <ProfileImage
                        width={!isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? 16 * 7 : 250}
                        height={!isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? 16 * 7 : 250}
                        name={`${author.firstName} ${author.lastName}`}
                        url={author.profilePhoto}
                    />
                )}
                {exercise?.authorType === Roles.FAMILY && !familyAuthor.profilePhoto && (
                    <DefaultLogo style={classes.avatarImage}>
                        <div
                            style={{
                                height:
                                    !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? '7rem' : '5vmin'
                            }}>
                            <Typography
                                variant="h1"
                                sx={{ ...classes.currentUserInitials, fontWeight: 'bold' }}>
                                {familyAuthor?.firstName?.charAt(0).toUpperCase() ??
                                    '' + familyAuthor?.lastName?.charAt(0).toUpperCase()}
                            </Typography>
                        </div>
                    </DefaultLogo>
                )}
                {exercise?.authorType === Roles.FAMILY && familyAuthor.profilePhoto && (
                    <ProfileImage
                        width={!isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? 16 * 7 : 250}
                        height={!isPlatform('tablet') && (isPlatform('android') || isPlatform('ios')) ? 16 * 7 : 250}
                        name={`${familyAuthor.firstName} ${familyAuthor.lastName}`}
                        url={familyAuthor.profilePhoto}
                    />
                )}
            </Box>
        </Box>
    );

    const anotherExercice = (
        <div
            style={{
                minHeight: '75vh',
                maxHeight: '90vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            <Typography
                variant="h3"
                fontSize="lg"
                color={blueGrey[800]}
                sx={{
                    textAlign: 'center',
                    fontFamily: 'var(--ion-font-family)',
                    fontSize: '1.645rem'
                }}>
                {t('whatDoYouWantToDoNow')}
            </Typography>
            <Typography
                variant="h4"
                fontSize="lg"
                color={blueGrey[800]}
                sx={{
                    textAlign: 'center',
                    fontFamily: 'var(--ion-font-family)',
                    fontSize: '1.25rem'
                }}>
                {t('placeYourFingerForChoice')}
            </Typography>
            <Container
                style={{
                    ...classes['groupBtnContainer'],
                    ...{
                        flexDirection: width && width < 480 ? 'column' : 'row'
                    }
                }}>
                <Card
                    sx={{
                        width: width && width < 480 ? '100%' : '30%',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <CardMedia
                        onClick={(): void => {
                            next();
                        }}
                        sx={{
                            backgroundColor: 'rgba(0, 70, 128, 0.07)',
                            width: width && width < 480 ? '50%' : '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={wantToPause}
                            sx={{
                                width: '95%',
                                padding: '15%',
                                height: 'auto',
                                margin: 'auto'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#004680',
                            fontWeight: 'bold',
                            fontFamily: 'var(--ion-font-family)',
                            marginTop: '10%',
                            fontSize: '1.25rem'
                        }}>
                        {t('wantToTakeABreak')}
                    </Typography>
                </Card>
                <Card
                    sx={{
                        width: width && width < 480 ? '100%' : '30%',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <CardMedia
                        onClick={(): void => {
                            next();
                        }}
                        sx={{
                            backgroundColor: 'rgba(208, 0, 112, 0.07)',
                            width: width && width < 480 ? '50%' : '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={wantAnotherExercise}
                            sx={{
                                width: '95%',
                                padding: '15%',
                                height: 'auto',
                                margin: 'auto'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#D00070',
                            fontWeight: 'bold',
                            fontFamily: 'var(--ion-font-family)',
                            marginTop: '10%',
                            fontSize: '1.25rem'
                        }}>
                        {t('wantToDoAnotherExercise')}
                    </Typography>
                </Card>
            </Container>
        </div>
    );

    return user?.role !== Roles.SENIOR ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                position: 'fixed',
                backgroundColor: 'rgba(0,0,0,0.7)',
                zIndex: 20000
            }}>
            <div
                style={{
                    backgroundColor: '#fff',
                    width: '80%',
                    height: '80%',
                    borderRadius: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                    padding: '1rem',
                    position: 'relative'
                }}>
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        width: '100%',
                        padding: '1rem'
                    }}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: '600',
                            textAlign: 'left',
                            color: '#333333',
                            fontSize: '1rem'
                        }}>
                        {t('testAGame')}
                    </Typography>
                    <img
                        style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1rem',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            width: '2rem',
                            height: '2rem',
                            border: '1px solid #e4e7e9'
                        }}
                        src={cross}
                        alt="cross"
                        onClick={(): void => history.push('/games')}
                    />
                </div>
                <img
                    src={validate}
                    alt="validate"
                    style={{
                        width: !isPlatform('tablet') && (!isPlatform('tablet') && (isPlatform('android') || isPlatform('ios'))) ? '5rem' : '8rem',
                        height: 'auto',
                        paddingBottom: '1rem'
                    }}
                />
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: '600',
                        textAlign: 'center',
                        color: '#333333',
                        fontSize: '1.5rem'
                    }}>
                    {t('testAGameTitle')}
                </Typography>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: '400',
                        textAlign: 'center',
                        color: '#333333',
                        fontSize: '1rem'
                    }}>
                    {t('testAGameText1')}
                    <br />
                    {user?.role === Roles.PRO ? (
                        t('testAGameText2.pro')
                    ) : (
                        <>
                            {t('testAGameText2')}
                            <br />
                            {t('testAGameText3')}
                            <br />
                            {t('testAGameText4')}
                        </>
                    )}
                </Typography>
                <Button
                    onClick={(): void => history.push('/games')}
                    sx={{
                        marginTop: '1rem',
                        width: '50%',
                        minWidth: '10rem',
                        height: '3rem',
                        color: '#fff',
                        backgroundColor: '#E5007B',
                        borderRadius: '1rem',
                        fontSize: '1rem',
                        fontWeight: '600',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#E5007B'
                        }
                    }}>
                    {t('testAGameButton')}
                </Button>
            </div>
        </div>
    ) : (
        <Container>
            <ModalContainer openModal={openReviewModal} renderBody={exerciseReview} />
            <ModalContainer
                // handleClose={(): void => {
                //     history.push('/games');
                // }}
                openModal={openMsgFromAutor}
                renderBody={msgFromAutor}
                // hasCloseButton
            />
            <ModalContainerFullScreen
                openModal={openVideoPersoAfterExercise}
                renderBody={videoPersoAfterExercise}
                // hasBackButton
                // handleGoBack={(): void => {
                //     setOpenVideoPhotoAfterExercise(true);
                //     setOpenPhotoAfterExercise(false);
                // }}
            />
            <ModalContainerFullScreen
                openModal={openVideoYoutubeAfterExercise}
                renderBody={videoYoutubeAfterExercise}
                // hasBackButton
                // handleGoBack={(): void => {
                //     setOpenVideoAfterExercise(false);
                //     setOpenVideoPhotoAfterExercise(true);
                //     // setOpenPhotoAfterExercise(true);
                // }}
            />
            <ModalContainer openModal={openThankModal} renderBody={thankAfterVideo} />
            <ModalContainer openModal={openAnotherExercice} renderBody={anotherExercice} />
        </Container>
    );
}
