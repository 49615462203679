/* eslint-disable */
// Dan Zen - made in one day along with hosting a wild party at the PagodaScope in VR
// Cheers to Ami Hanya who made a similar puzzle way back in ZIM
// https://www.emanueleferonato.com/2018/03/13/build-a-html5-jigsaw-puzzle-game-with-zim-framework/

// This version adds the following and comments:
// ES6, sequenced start, Emitter, rotating pieces, drop shadow raise, border,
// hint and picture interface option, madeWith, restart
// and is the same amount of code - yay ZIM progress!
// The pieces class still looks complicated - actually, Generator() might work to use relative path drawing
// but am unsure about using Generator graphics as masks, for dragging, etc.
// as generator graphics have been moved outside normal x and y due to matrix manipulation - so did not bother.
// This does mean we have to do some calculations to handle 4 sides of the puzzle slightly differently
// But it was do it once and then switch up x, y, and a few things

// There are a fair number of advanced concepts in here to make it all work
// Unfortunately, a Jigsaw puzzle is often the first thing people want to make - sigh.
// I had vowed to never make one...  but whatever... at least I held out for 30 years.
// There is also the ZIM Scrambler() for a quick puzzle along the same lines
// https://zimjs.com/cat/scrambler.html - less jig and more saw.

export const Jigsaw = (
    width,
    height,
    imageName,
    imagePath,
    xPieces,
    yPieces,
    onSuccess,
    onCancel,
    clueImagePath,
    isMobile
) => {
    const root = document.getElementById('root');
    let script = document.getElementById('script');
    if (!script) {
        console.log('script not found, creating new script');
        script = document.createElement('script');
        script.src = 'https://zimjs.org/cdn/1.3.3/createjs.js';
        script.id = 'ZIM';
        script.async = false;
        document.body.appendChild(script);
    }
    let clueCount = 0;
    let setPiece;

    console.log('Jigsaw called');

    const createFrame = () => {
        const frame = new Frame(
            MIDDLE,
            width,
            height,
            '#fff',
            '#fff',
            imageName,
            imagePath,
            clueImagePath
        );

        frame.on('ready', () => {
            console.log('ready');
            const canvas = document.getElementById('middleCanvas');
            canvas.style.display = 'block';
            canvas.style.backgroundColor = '';
            // often need below - so consider it part of the template
            const { stage } = frame;
            const stageW = frame.width;
            const stageH = frame.height;

            // REFERENCES for ZIM at http://zimjs.com

            // CODE HERE

            const mob = mobile();

            // ~~~~~~~~~~~~~~~~~~~~~
            // CLASS to make JigSaw piece with bumps out or in or no bump on any four edges
            class Piece extends Shape {
                // format is 1 for bump out, -1 for bump in and 0 for no bump
                constructor(
                    w = 100,
                    h = 100,
                    format = [1, 1, 1, 1],
                    s = black,
                    ss = 4,
                    f = white
                ) {
                    super(w, h);
                    const p = Piece.part; // static property - defined below class
                    const g = Piece.gap;
                    this.s(s).ss(ss).f(f).mt(0, 0);
                    if (format[0] == 0) this.lt(w, 0);
                    // top left-right
                    else {
                        this.lt(w * p, 0);
                        const s = format[0] == 1 ? -1 : 1; // sign
                        this.ct(w * (p - g / 2), s * w * g, w / 2, s * w * g); // curve left to middle
                        this.ct(w * (p + g + g / 2), s * w * g, w * (1 - p), 0); // curve middle to right
                        this.lt(w, 0);
                    }
                    if (format[1] == 0) this.lt(w, h);
                    // right top-bottom
                    else {
                        this.lt(w, h * p);
                        const s = format[1] == 1 ? 1 : -1;
                        this.ct(w + s * w * g, h * (p - g / 2), w + s * w * g, h / 2);
                        this.ct(w + s * w * g, h * (p + g + g / 2), w, h * (1 - p));
                        this.lt(w, h);
                    }
                    if (format[2] == 0) this.lt(0, h);
                    // bottom right-left
                    else {
                        this.lt(w * (1 - p), h);
                        const s = format[2] == 1 ? 1 : -1;
                        this.ct(w * (p + g + g / 2), h + s * w * g, w / 2, h + s * w * g);
                        this.ct(w * (p - g / 2), h + s * w * g, w * p, h + 0);
                        this.lt(0, h);
                    }
                    if (format[3] == 0) this.lt(0, 0);
                    // left bottom-top
                    else {
                        this.lt(0, h * (1 - p));
                        const s = format[3] == 1 ? -1 : 1;
                        this.ct(s * w * g, h * (p + g + g / 2), s * w * g, h / 2);
                        this.ct(s * w * g, h * (p - g / 2), 0, h * p);
                        this.lt(0, 0);
                    }
                    this.cp(); // close path
                }
            }
            Piece.part = 0.37; // part of the edge with no gap ratio
            Piece.gap = 1 - Piece.part * 2; // gap ratio of edge

            // ~~~~~~~~~~~~~~~~~~~~~
            // PUZZLE SIZE

            let numX = xPieces;
            let numY = yPieces;
            // const obj = getQueryString(); // note, getQueryString returns {} now if no query string
            // if (obj.col) numX = Math.min(5, Number(obj.col)); // or we would have to start chaching things...
            // if (obj.row) numY = Math.min(1, Number(obj.row));

            // ~~~~~~~~~~~~~~~~~~~~~
            // PICTURE
            const clueBtnImg = asset(clueImagePath);
            const img = asset(imageName);
            const pic = img.clone().center().alp(0.3).vis(true);
            const w = pic.width / numX;
            const h = pic.height / numY;

            // chop the picture into bitmaps
            // false is for an array rather than the default Tile output
            // extra is because bumps go outside the width and height
            const extra = Math.max(w, h) * Piece.gap;
            const pics = chop(img, numX, numY, false, extra);

            // ~~~~~~~~~~~~~~~~~~~~~
            // PIECES

            // makePieces gets called from Tile - for each piece
            let count = 0;
            let lastX = rand() > 0.5 ? 1 : -1; // 1 or -1 for out or in horizontally
            const lastYs = []; // 1 or -1 vertically - remember with array and modulus
            loop(numX, (i) => {
                lastYs.push(rand() > 0.5 ? 1 : -1);
            });
            const totalPieces = xPieces * yPieces;
            function makePiece() {
                // prepare format for jig piece [1,0,-1,0]
                // 1 bump out, 0 no bump, -1 bump in, etc.
                const currentX = lastX * -1; // opposite of last x
                const currentY = lastYs[count % numX] * -1; // opposite of last y
                const nextX = rand() > 0.5 ? 1 : -1; // randomize the next 1 or -1 for out or in horizontally
                let nextY = rand() > 0.5 ? 1 : -1; // and vertically
                // top, right, bottom, left
                if (totalPieces === 6) {
                    nextY = -1;
                }
                const format = [currentY, nextX, nextY, currentX];
                lastX = nextX;
                lastYs[count % numX] = nextY;

                // override edges to 0
                if (count < numX) format[0] = 0;
                else if (count >= numX * numY - numX) format[2] = 0;
                if (count % numX == 0) format[3] = 0;
                else if ((count - numX + 1) % numX == 0) format[1] = 0;

                // make a container to hold jig shape and later picture part
                const piece = new Container(w, h).centerReg({ add: false });
                piece.puzzle = new Piece(w, h, format).addTo(piece);
                piece.mouseChildren = false;
                count++;
                return piece;
            }

            const pieces = new Tile({
                obj: makePiece,
                cols: numX,
                rows: numY,
                clone: false // otherwise makes clone of piece
            })
                .center()
                .drag(stage)
                .animate({
                    props: { alpha: 1 },
                    time: 0.1,
                    sequence: 0.05
                });

            // ~~~~~~~~~~~~~~~~~~~~~
            // HINT AND SNAP HIT BOX

            // tidy up alpha setting on hint around border
            // const outline = new Rectangle(pic.width, pic.height, clear, mist, 4).center().ord(-1); // under pieces
            const hint = pieces
                .clone(true) // exact
                .center()
                .ord(-1) // under pieces
                .cache(-5, -5, pic.width + 10, pic.height + 10) // cache by default does not include outside border
                .alp(0.2); // checkbox below to show

            // make a little box to do hit test to see if in right place
            const snap = 100; // pixel distance considered correct | magnet
            loop(hint, (h) => {
                h.box = new Rectangle(snap, snap).centerReg(h).vis(0); // do not use alpha=0 as that will make it not hittable
            });

            // ~~~~~~~~~~~~~~~~~~~~~
            // ADD PICTURE TO PIECES, ADD EVENTS, ROTATE AND SCRAMBLE

            function getRandomInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
            }

            const rotate = false;
            let locations = [];
            let pieceSize = 50;
            if (totalPieces === 144) {
                pieceSize = 70;
                locations.push({ x: -190, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: -190, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: -190, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -190, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: -190, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -190, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: -190, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: -190, y: pic.height / 2 });
                locations.push({ x: -190, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: -190, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: -190, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -190, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: -190, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: -190, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: -190, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: -120, y: pic.height / 2 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: -50, y: pic.height / 2 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: pic.width + 190, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 2, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 1, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 0.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 0.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 1, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 4, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 3.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 3, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 1, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 0.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 0.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 1, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 3, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 3.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 4, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 4, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 3.5, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 3, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 1, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 0.5, y: -110 });
                locations.push({ x: pic.width / 2, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 0.5, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 1, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 3, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 3.5, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 4, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 2, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 1, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 0.5, y: -45 });
                locations.push({ x: pic.width / 2, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 0.5, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 1, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5, y: -45 });
            } else if (totalPieces === 100) {
                pieceSize = 70;
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: -120, y: pic.height / 2 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: -50, y: pic.height / 2 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 3 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 3 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 2 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 1 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 0.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 0.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 1 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 2 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 2, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 1, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 0.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 0.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 1, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 3, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 1, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 0.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 0.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 1, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 3, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 3, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 1, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 0.5, y: -110 });
                locations.push({ x: pic.width / 2, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 0.5, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 1, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5, y: -110 });
                locations.push({ x: pic.width / 2 + pieceSize * 3, y: -110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 2, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 1, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 0.5, y: -45 });
                locations.push({ x: pic.width / 2, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 0.5, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 1, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5, y: -45 });
            } else if (totalPieces === 49) {
                pieceSize = 60;
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -120, y: pic.height / 2 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 - pieceSize });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 45, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width / 2 - pieceSize * 3 - 50, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 2 - 10, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize + 30, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize + 40, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 2 + 60, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 + pieceSize * 3 + 80, y: pic.height + 110 });
                locations.push({ x: pic.width / 2 - pieceSize * 3 - 50, y: -120 });
                locations.push({ x: pic.width / 2 - pieceSize * 2 - 10, y: -120 });
                locations.push({ x: pic.width / 2 - pieceSize + 30, y: -120 });
                locations.push({ x: pic.width / 2 + pieceSize + 40, y: -120 });
                locations.push({ x: pic.width / 2 + pieceSize * 2 + 60, y: -120 });
                locations.push({ x: pic.width / 2 + pieceSize * 3 + 120, y: -120 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -50, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -50, y: pic.height / 2 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: -50, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 - pieceSize });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 120, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width / 2 - pieceSize * 3 - 50, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 2 - 10, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize + 30, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize + 40, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2 + 60, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 + pieceSize * 3 + 80, y: pic.height + 45 });
                locations.push({ x: pic.width / 2 - pieceSize * 3 - 50, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize * 2 - 10, y: -45 });
                locations.push({ x: pic.width / 2 - pieceSize + 30, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize + 40, y: -45 });
                locations.push({ x: pic.width / 2 + pieceSize * 2 + 60, y: -45 });
            } else if (totalPieces === 25) {
                pieceSize = 60;
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -120, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -120, y: pic.height / 2 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: -120, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize * 3.5 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize });
                locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize });
                locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize * 3.5 });
                locations.push({ x: pic.width / 2 - pieceSize * 3 - 50, y: pic.height + 100 });
                locations.push({ x: pic.width / 2 - pieceSize * 2 - 10, y: pic.height + 100 });
                locations.push({ x: pic.width / 2 - pieceSize + 30, y: pic.height + 100 });
                locations.push({ x: pic.width / 2 + pieceSize + 40, y: pic.height + 100 });
                locations.push({ x: pic.width / 2 + pieceSize * 2 + 60, y: pic.height + 100 });
                locations.push({ x: pic.width / 2 + pieceSize * 3 + 80, y: pic.height + 100 });
                locations.push({ x: pic.width / 2 - pieceSize * 3 - 50, y: -80 });
                locations.push({ x: pic.width / 2 - pieceSize * 2 - 10, y: -80 });
                locations.push({ x: pic.width / 2 - pieceSize + 30, y: -80 });
                locations.push({ x: pic.width / 2 + pieceSize + 40, y: -80 });
                locations.push({ x: pic.width / 2 + pieceSize * 2 + 60, y: -80 });
                locations.push({ x: pic.width / 2 + pieceSize * 3 + 80, y: -80 });
            } else if (totalPieces === 20) {
                pieceSize = 75;
                locations.push({ x: -80, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: -80, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: -80, y: pic.height / 2 });
                locations.push({ x: -80, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: -80, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize * 2.5 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize * 1.5 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize * 1.5 });
                locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize * 2.5 });
                locations.push({
                    x: pic.width / 2 - pieceSize * 2.5 - 30,
                    y: pic.height + 100
                });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: pic.height + 100 });
                locations.push({ x: pic.width / 2, y: pic.height + 100 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: pic.height + 100 });
                locations.push({
                    x: pic.width / 2 + pieceSize * 2.5 + 30,
                    y: pic.height + 100
                });
                locations.push({ x: pic.width / 2 - pieceSize * 2.5 - 30, y: -80 });
                locations.push({ x: pic.width / 2 - pieceSize * 1.5, y: -80 });
                locations.push({ x: pic.width / 2, y: -80 });
                locations.push({ x: pic.width / 2 + pieceSize * 1.5, y: -80 });
                locations.push({ x: pic.width / 2 + pieceSize * 2.5 + 30, y: -80 });
            } else if (totalPieces === 16) {
                pieceSize = 80;
                if (!isMobile) {
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize * 2.5 });
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize / 2 - 10 });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize / 2 + 10 });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize * 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize * 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize / 2 - 10 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize / 2 + 10 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize * 2 });
                    locations.push({ x: pic.width / 2 - pieceSize * 2 - 30, y: pic.height + 100 });
                    locations.push({ x: pic.width / 2 - pieceSize / 2 - 20, y: pic.height + 100 });
                    locations.push({ x: pic.width / 2 + pieceSize / 2 + 20, y: pic.height + 100 });
                    locations.push({ x: pic.width / 2 + pieceSize * 2 + 30, y: pic.height + 100 });
                    locations.push({ x: pic.width / 2 - pieceSize * 2 - 30, y: -80 });
                    locations.push({ x: pic.width / 2 - pieceSize / 2 - 20, y: -80 });
                    locations.push({ x: pic.width / 2 + pieceSize / 2 + 20, y: -80 });
                    locations.push({ x: pic.width / 2 + pieceSize * 2 + 30, y: -80 });
                } else {
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize });
                    locations.push({ x: -80, y: pic.height / 2 });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize });
                    locations.push({ x: -180, y: pic.height / 2 - pieceSize });
                    locations.push({ x: -180, y: pic.height / 2 });
                    locations.push({ x: -180, y: pic.height / 2 + pieceSize });
                    locations.push({ x: -130, y: pic.height / 2 - pieceSize / 2 });
                    locations.push({ x: -130, y: pic.height / 2 + pieceSize / 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize });
                    locations.push({ x: pic.width + 180, y: pic.height / 2 - pieceSize });
                    locations.push({ x: pic.width + 180, y: pic.height / 2 });
                    locations.push({ x: pic.width + 180, y: pic.height / 2 + pieceSize });
                    locations.push({ x: pic.width + 130, y: pic.height / 2 - pieceSize / 2 });
                    locations.push({ x: pic.width + 130, y: pic.height / 2 + pieceSize / 2 });
                }
            } else if (totalPieces === 12) {
                pieceSize = 80;
                if (!isMobile) {
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize * 2 });
                    locations.push({ x: -80, y: pic.height / 2 });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize * 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize * 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize * 2 });
                    locations.push({ x: pic.width / 2 - pieceSize * 2, y: pic.height + 100 });
                    locations.push({ x: pic.width / 2, y: pic.height + 100 });
                    locations.push({ x: pic.width / 2 + pieceSize * 2, y: pic.height + 100 });
                    locations.push({ x: pic.width / 2 - pieceSize * 2, y: -100 });
                    locations.push({ x: pic.width / 2, y: -80 });
                    locations.push({ x: pic.width / 2 + pieceSize * 2, y: -100 });
                } else {
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize });
                    locations.push({ x: -80, y: pic.height / 2 });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize });
                    locations.push({ x: -180, y: pic.height / 2 - pieceSize });
                    locations.push({ x: -180, y: pic.height / 2 });
                    locations.push({ x: -180, y: pic.height / 2 + pieceSize });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize });
                    locations.push({ x: pic.width + 180, y: pic.height / 2 - pieceSize });
                    locations.push({ x: pic.width + 180, y: pic.height / 2 });
                    locations.push({ x: pic.width + 180, y: pic.height / 2 + pieceSize });
                }
            } else if (totalPieces === 9) {
                pieceSize = 95;
                if (!isMobile) {
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize * 2 });
                    locations.push({ x: -80, y: pic.height / 2 });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize * 2 });
                    locations.push({ x: -240, y: pic.height / 2 - pieceSize });
                    locations.push({ x: -240, y: pic.height / 2 + pieceSize });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize + 30 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize + 30 });
                    locations.push({ x: pic.width + 240, y: pic.height / 2 - pieceSize });
                    locations.push({ x: pic.width + 240, y: pic.height / 2 + pieceSize });
                } else {
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize });
                    locations.push({ x: -80, y: pic.height / 2 });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize });
                    locations.push({ x: -240, y: pic.height / 2 - pieceSize / 2 });
                    locations.push({ x: -240, y: pic.height / 2 + pieceSize / 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize });
                    locations.push({ x: pic.width + 240, y: pic.height / 2 - pieceSize / 2 });
                    locations.push({ x: pic.width + 240, y: pic.height / 2 + pieceSize / 2 });
                }
            } else if (totalPieces === 6) {
                pieceSize = 150;
                if (!isMobile) {
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize });
                    locations.push({ x: -220, y: pic.height / 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize });
                    locations.push({ x: pic.width + 220, y: pic.height / 2 });
                } else {
                    locations.push({ x: -80, y: pic.height / 2 - pieceSize + 70 });
                    locations.push({ x: -80, y: pic.height / 2 + pieceSize - 70 });
                    locations.push({ x: -220, y: pic.height / 2 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 - pieceSize + 70 });
                    locations.push({ x: pic.width + 80, y: pic.height / 2 + pieceSize - 70 });
                    locations.push({ x: pic.width + 220, y: pic.height / 2 });
                }
            } else if (totalPieces === 4) {
                pieceSize = 150;
                if (!isMobile) {
                    locations.push({ x: -140, y: pic.height / 2 - pieceSize });
                    locations.push({ x: -140, y: pic.height / 2 + pieceSize });
                    locations.push({ x: pic.width + 140, y: pic.height / 2 - pieceSize });
                    locations.push({ x: pic.width + 140, y: pic.height / 2 + pieceSize });
                } else {
                    locations.push({ x: -140, y: pic.height / 2 - pieceSize + 70 });
                    locations.push({ x: -140, y: pic.height / 2 + pieceSize - 70 });
                    locations.push({ x: pic.width + 140, y: pic.height / 2 - pieceSize + 70 });
                    locations.push({ x: pic.width + 140, y: pic.height / 2 + pieceSize - 70 });
                }

            }

            loop(pieces, (piece, i) => {
                piece.alp(0); // sequence animation above will animate in alpha
                pics[i].addTo(piece).setMask(piece.puzzle);
                // test on mobile and see if you need to cache...
                // usually this is just cache() but the bumps are outside the piece
                // and the cache size really does not make a difference if rest is background transparent
                if (mob) piece.cache(-100, -100, piece.width + 200, piece.width + 200);
                if (rotate) {
                    piece.rotation = shuffle([0, 90, 180, 270])[0];
                    piece.tap({
                        time: 0.5, // within .5 seconds
                        call: () => {
                            pieces.noMouse(); // do not let anything happen while animating until done
                            piece.animate({
                                props: { rotation: String(frame.shiftKey ? -90 : 90) }, // string makes relative
                                time: 0.2,
                                call: () => {
                                    pieces.mouse();
                                    test(piece);
                                }
                            });
                            stage.update();
                        },
                        call2: () => {
                            // if no tap
                            test(piece);
                        }
                    });
                } else {
                    piece.on('pressup', () => {
                        test(piece);
                    });
                }
                piece.on('pressdown', () => {
                    // shadows are expensive on mobile
                    // could add it to container so shadow inside container
                    // then cache the container but might not be worth it
                    if (!mob) piece.sha('rgba(0,0,0,.4)', 5, 5, 5);
                });

                // scramble location
                const pieceLocation = locations[Math.floor(Math.random() * locations.length)];
                locations = locations.filter((e) => e !== pieceLocation);
                piece.loc(pieceLocation.x, pieceLocation.y);
            });

            // ~~~~~~~~~~~~~~~~~~~~~
            // EMITTER

            const emitter = new Emitter({
                obj: new Poly({ min: 40, max: 70 }, [5, 6], 0.5, [orange, blue, green]),
                num: 2,
                force: 6,
                startPaused: true
            });

            // ~~~~~~~~~~~~~~~~~~~~~
            // MESSAGE LABEL

            const num = numX * numY;
            let placed = 0;
            STYLE = { color: blue.darken(0.3), size: 24 };

            // ~~~~~~~~~~~~~~~~~~~~~
            // TEST FOR PIECE IN RIGHT PLACE AND END

            function test(piece) {
                piece.sha(-1);
                const { box } = hint.items[piece.tileNum];
                if (piece.rotation % 360 == 0 && box.hitTestReg(piece)) {
                    piece.loc(box).bot().noMouse();
                    emitter.loc(box).spurt(30);
                    placed++;
                    if (placed == num) {
                        onSuccess(clueCount);
                    }
                } else stage.update();
            }

            setPiece = function () {
                const random = Math.floor(Math.random() * totalPieces);
                const piece = pieces.items[random];
                const { box } = hint.items[piece.tileNum];
                if (piece.rotation % 360 == 0 && box.hitTestReg(piece)) {
                    setPiece();
                } else {
                    piece.loc(box);
                    test(piece);
                }
            }

            // ~~~~~~~~~~~~~~~~~~~~~
            // CHECKBOXES AND FINISHING TOUCHES

            Style.addType('CheckBox', { borderColor: blue.darken(0.3) });
            // const cancleExercise = new Button({
            //     width: 230,
            //     height: 52,
            //     label: 'Quitter mon jeu',
            //     color: '#374653',
            //     backgroundColor: '#FFFFFF',
            //     rollBackgroundColor: '#FFFFFF',
            //     corner: 3,
            //     fontSize: '20px',
            //     fontFamily: 'var(--ion-font-family)',
            //     fontStyle: 'normal',
            //     fontWeight: 400,
            //     border: '1px solid #ACBCC7 !important',
            //     padding: '10px'
            // })
            //     .alp(0.8)
            //     .pos(0, 0, LEFT, top)
            //     .on('click', () => {
            //         onCancel(clueCount);
            //     });

            // let clueButtonEnabled = true;
            // const clue = new Button({
            //     fontSize: '24px',
            //     fontFamily: 'var(--ion-font-family)',
            //     fontStyle: 'normal',
            //     fontWeight: 400,
            //     width: 440,
            //     height: 0,
            //     corner: 3,
            //     waitTime: 5,
            //     icon: clueBtnImg
            // })
            //     .alp(0.8)
            //     .pos(30, 0, RIGHT, top)
            //     .on('click', () => {
            //         if (clueButtonEnabled) {
            //             // onClueClick();
            //             clueCount++;
            //             setPiece();
            //             clueButtonEnabled = false;
            //             setTimeout(() => {
            //                 clueButtonEnabled = true;
            //             }, 5000);
            //         }
            //     });

            pieces.top(); // add pieces above everything

            stage.update(); // needed to view changes
        });
    };

    script.onload = () => {
        console.log('script loaded');
        let zim = document.getElementById('zimjs');
        if (!zim) {
            console.log('loading zim');
            zim = document.createElement('script');
            zim.src = 'https://zimjs.org/cdn/cat/04/zim.js';
            zim.id = 'zimjs';
            zim.async = false;
            document.body.insertBefore(zim, root);
        } else {
            console.log('zim already loaded');
            createFrame();
        }
        zim.onload = () => {
            console.log('zim loaded');
            createFrame();
        };
    };

    const onClueClick = () => {
        clueCount++;
        setPiece();
    };

    return {
        onClueClick,
    }
};
