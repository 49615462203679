import { useRef } from "react";
import { melimots } from "../../../models/Games";
import { MeliMots } from "../../../components/MeliMots";
import { GameRef, GameProvider } from "../../../providers/GameProvider";
import { DndProvider } from "react-dnd";
import { Capacitor } from "@capacitor/core";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useParams } from "react-router-dom";

export default () => {
    const melimotsloRef = useRef<GameRef>(null);
    const platform = Capacitor.getPlatform();
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);

    const getTips = () => {
        if (!melimotsloRef.current) return;
        melimotsloRef.current?.tips();
    };

    const resetGame = () => {
        if (!melimotsloRef.current) return;
        melimotsloRef.current?.reset();
    };

    const colors = {
        primary: melimots.color,
        secondary: melimots.secondaryColor,
        border: melimots.border,
    };

    const backend = platform === 'web' ? HTML5Backend : TouchBackend;

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            onReset={resetGame}
            tipsEnabled
            instructionEnabled
            instructionMessage={melimots.instructions?.find((instruction) => instruction.maxLvl >= lvl)?.text}
            background={melimots.background}
        >
            <DndProvider backend={backend}>
                <MeliMots ref={melimotsloRef} />
            </DndProvider>
        </GameProvider>
    );
};