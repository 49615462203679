import React, { useState, useEffect, useContext, useMemo, useImperativeHandle } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Grid, NewGameButton, Numbers } from './components';
import { configureStore, register } from './core';
import { theme } from './styles';
import { compareArrays } from './utils';
import global from './global';
import { Timestamp } from 'firebase/firestore';
import { Exercise } from '../../models/Exercise';
import { UserDataProp } from '../../models/userDataProp';
import { UserContext } from '../../providers/UserProvider';
import { ExerciseItem } from '../../models/ExerciseItem';
import { Patient } from '../../models/Patient';
import { Roles } from '../../models/Roles';
import { getPatientUser, getExerciseItem } from '../../services/cloudFirestore';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import { GameRef } from '../../providers/GameProvider';
import { useGame } from '../../contexts/GameContext';

const { persistor, store } = configureStore();

interface LocationState {
    exercises: Exercise;
}

// eslint-disable-next-line jest/no-export
export const Sudok = React.forwardRef((_, ref) => {
    const { endAnimation, startTimer, stopTimer, endGame, displayInstruction } = useGame();
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);
    const gridSize = lvl <= 2 ? 4 : 9;
    const { t } = useTranslation();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [exercise, setExercise] = useState<Exercise>();
    const location = useLocation<LocationState>();
    const [exerciseItem, setExerciseItem] = useState<ExerciseItem>();
    const [openReview, setOpenReview] = useState<boolean>(false);
    const [sudokuClues, setSudokuClues] = useState<number>(0);
    const [sudokuErrors, setSudokuErrors] = useState<number>(0);
    let testInterval: NodeJS.Timeout;
    const { height, width } = useWindowDimensions();
    const isPortrait = useMemo(() => {
        if (height && width) {
            return height > width;
        }
        return false;
    }, [height, width]);

    const [stopTest, setStopTest] = useState<boolean>(false);
    const [patient, setPatient] = useState<Patient>();

    useImperativeHandle(ref, () => ({
        tips: (): void => {
            clue();
        },
    }));

    useEffect(() => {
        // Cleaning function activated when component is dismounted
        return () => {
            store.dispatch({ type: 'CREATE_GRID' });
        };
    }, [store]);

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user?.id) {
                const patientData = await getPatientUser(user?.id);
                if (patientData && patientData.sudokuDifficultyLevel) {
                    global.difficultyLevel = lvl;
                    global.gridSize = gridSize;
                    setPatient(patientData);
                    // localStorage.setItem('difficultyLevel', `${patientData.sudokuDifficultyLevel}`);
                    if (
                        global.difficultyLevel &&
                        global.difficultyLevel !== patientData.sudokuDifficultyLevel
                    ) {
                        store.dispatch({ type: 'CREATE_GRID', payload: { gridSize } });
                    }
                }
                store.dispatch({ type: 'CREATE_GRID', payload: { gridSize } }); // create new grid to see replay modal
            }

            if (location.state && location.state.exercises) {
                setExercise(location.state.exercises);
                if (
                    location.state.exercises.exerciseImages &&
                    location.state.exercises.exerciseImages[0] &&
                    typeof location.state.exercises.exerciseImages[0] === 'string'
                ) {
                    const item = await getExerciseItem(location.state.exercises.exerciseImages[0]);
                    if (item) {
                        setExerciseItem(item);
                    }
                }
            }
        };
        getExerciseList();
    }, []);

    useEffect(() => {
        if (user?.role === Roles.SENIOR) {
            localStorage.setItem('startTime', new Date().toString());
        }
    }, []);

    useEffect(() => {
        if (!testInterval) {
            startTimer();
            displayInstruction();
            testInterval = setInterval(test, 1000);
            return () => clearInterval(testInterval);
        }
    }, []);

    const test = (): void => {
        const { solvedGrid } = store.getState();
        if (!solvedGrid || !store.getState().workingGrid) return;
        if (compareArrays(solvedGrid, store.getState().workingGrid)) {
            clearInterval(testInterval);
            stopTimer();
            endAnimation(async () => endGame({
                errorCount: sudokuErrors,
                clueCount: sudokuClues,
            }));
        }
    };

    const clue = (): void => {
        // get first empty cell and fill it with the correct value
        const { workingGrid } = store.getState();
        const { solvedGrid } = store.getState();
        if (workingGrid && solvedGrid) {
            for (let i = 0; i < global.gridSize; i++) {
                for (let j = 0; j < global.gridSize; j++) {
                    if (workingGrid[i][j] === 0) {
                        store.dispatch({
                            type: 'FILL_BLOCK',
                            value: solvedGrid[i][j],
                            coords: [i, j]
                        });
                        setSudokuClues(sudokuClues + 1);
                        return;
                    }
                }
            }
        }
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <div
                            style={{
                                height: isPortrait ? 'auto' : 'calc(100% - 10rem)',
                                width: '100%',
                                display: 'flex',
                                flexDirection: isPortrait ? 'column' : 'row',
                                alignItems: 'center',
                                padding: '0.5rem',
                                gap: '1rem',
                                justifyContent: 'center',
                                paddingBottom: isPortrait ? '5rem' : '0'
                            }}>
                            <div
                                style={{
                                    height: '100%',
                                    width: isPortrait ? '100%' : '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <div
                                    style={{
                                        height: isPortrait ? 'auto' : '100%',
                                        width: isPortrait ? '100%' : 'auto',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        aspectRatio: '1/1',
                                        position: 'relative'
                                    }}>

                                    <Grid gridSize={gridSize} />
                                </div>
                            </div>
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    padding: 'var(--space-s)',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    placeContent: 'center',
                                    gap: 'var(--space-s)',
                                    justifyContent: 'flex-start'
                                }}>
                                <Numbers
                                    onClick={() => {
                                        console.log('store.getState().errorCount', store.getState().errorCount)
                                        return setSudokuErrors(store.getState().errorCount)

                                    }
                                    }
                                />
                                <NewGameButton gridSize={gridSize} />
                            </div>
                        </div>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </>
    );
});
