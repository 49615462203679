import firestore, { Timestamp } from 'firebase/firestore';
import { ExerciseType } from './ExerciseType';
import { Review } from './Review';
import { Roles } from './Roles';
import { User, UserInfo } from 'firebase/auth';

export class Exercise {
    id?: string | undefined;

    exerciseType?: ExerciseType | undefined;

    patientList?: (string | undefined)[];

    proList?: ({[key: string]: 'read' | 'write'} | undefined)[];

    exerciseName?: string | undefined;

    authorId?: string | undefined;

    authorType?: Roles | undefined;

    exerciseImages?: (string | undefined)[];

    review?: Review[] | undefined;

    encouragementMessage?: string | undefined;

    congratulationsMessage?: string | undefined;

    rewardPhoto?: File | string | undefined;

    rewardVideo?: string | undefined;

    createdDate?: Timestamp | null;

    establishmentList?: (string | undefined)[];

    establishmentCode?: string | undefined;

    familyCode?: string | undefined;

    theme?: string | undefined;

    authorPhotoURL?: string | undefined;

    authorName?: string | undefined;

    constructor(
        id: string,
        exerciseType: ExerciseType,
        patientList: string[],
        proList: {[key: string]: 'read' | 'write'}[],
        exerciseName: string,
        authorId: string,
        authorType: Roles,
        exerciseImages: string[],
        review: Review[],
        encouragementMessage: string,
        congratulationsMessage: string,
        rewardPhoto: File | string,
        rewardVideo: string,
        createdDate: Timestamp,
        establishmentList: string[],
        establishmentCode: string,
        familyCode: string,
        theme: string,
        authorPhotoURL?: string,
        authorName?: string
    ) {
        this.id = id;
        this.exerciseType = exerciseType;
        this.patientList = patientList;
        this.proList = proList;
        this.exerciseName = exerciseName;
        this.authorId = authorId;
        this.authorType = authorType;
        this.exerciseImages = exerciseImages;
        this.review = review;
        this.encouragementMessage = encouragementMessage;
        this.congratulationsMessage = congratulationsMessage;
        this.rewardPhoto = rewardPhoto;
        this.rewardVideo = rewardVideo;
        this.createdDate = createdDate;
        this.establishmentList = establishmentList;
        this.establishmentCode = establishmentCode;
        this.familyCode = familyCode;
        this.theme = theme;
        this.authorPhotoURL = authorPhotoURL;
        this.authorName = authorName;
    }
}
export const ExerciseConverter: firestore.FirestoreDataConverter<Exercise> = {
    toFirestore(exercise) {
        return {
            id: exercise.id,
            exerciseType: exercise.exerciseType,
            patientList: exercise.patientList,
            proList: exercise.proList,
            exerciseName: exercise.exerciseName,
            authorId: exercise.authorId,
            authorType: exercise.authorType,
            exerciseImages: exercise.exerciseImages,
            review: exercise.review,
            encouragementMessage: exercise.encouragementMessage,
            congratulationsMessage: exercise.congratulationsMessage,
            rewardPhoto: exercise.rewardPhoto,
            rewardVideo: exercise.rewardVideo,
            createdDate: exercise.createdDate,
            establishmentList: exercise.establishmentList,
            establishmentCode: exercise.establishmentCode,
            familyCode: exercise.familyCode,
            theme: exercise.theme,
            authorPhotoURL: exercise.authorPhotoURL,
            authorName: exercise.authorName
        };
    },
    fromFirestore(snapshot, options): Exercise {
        const data = snapshot.data(options);
        return new Exercise(
            snapshot.id,
            data.exerciseType,
            data.patientList,
            data.proList,
            data.exerciseName,
            data.authorId,
            data.authorType,
            data.exerciseImages,
            data.review,
            data.encouragementMessage,
            data.congratulationsMessage,
            data.rewardPhoto,
            data.rewardVideo,
            data.createdDate,
            data.establishmentList,
            data.establishmentCode,
            data.familyCode,
            data.theme,
            data.authorPhotoURL,
            data.authorName
        );
    }
};
