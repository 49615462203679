import Albert from './assets/albert.svg?react';
import AlbertMobile from './assets/albert-mobile.svg?react';

import styles from './styles.module.css';
import Heading from '../../components/Heading';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button/index';

interface NextGameWindowProps {
    colors: {
        primary: string;
        secondary: string;
        border: string;
    },
    onClose: () => void;
    onReset?: () => void;
}

export const NextGameWindow = ({ onClose, onReset, colors }: NextGameWindowProps) => {
    const history = useHistory();

    const handleOnClick = (path: string) => {
        history.push(path);
        onClose();
    };

    return (
        <div className={styles.root}>
            <Albert className={styles.albert} />
            <AlbertMobile className={styles.albertMobile} />
            <Heading className={styles.title}>Choisissez une action</Heading>
            <div className={styles.ctaContainer}>
                <Button style={{ backgroundColor: colors.primary, border: 0, color: '#FFF' }} className={styles.cta} label="Rejouer à ce jeu" action={() => onReset && onReset()} />
                <Button className={styles.cta} label="Jouer à un autre jeu" action={() => handleOnClick('/games')} />
            </div>
            <Button className={styles.cta} theme='SECONDARY' label="Retourner à l'accueil" action={() => handleOnClick('/home')} />
        </div>
    );
}