import React from 'react';
import './login.css';
import closedEye from '../../assets/closedEye.svg'
import openEye from '../../assets/openedEye.svg';
import { t } from 'i18next';

interface LoginFormProps {
    setDisplayPage: (t: 'login' | 'singup' | 'forgot') => void;
    login: (email: string, pass: string) => void;
}

export function LoginForm({ setDisplayPage, login }: LoginFormProps): JSX.Element {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <>
            <h3>{t('login.title')}</h3>
            <h5>{t('login.subtitle')}</h5>
            <form id="login-form" onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <label htmlFor="name">Email</label>
                    <input
                        type="email"
                        className="form-control input"
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        placeholder="ex : jean.dupont@hotmail.com"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Mot de passe</label>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control input password-input"
                            id="password"
                            placeholder="********"
                        />
                        <input
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            id="show-password-login"
                            style={{
                                backgroundColor: 'transparent',
                                backgroundImage: `url(${showPassword ? openEye : closedEye})`,
                                width: '1.6rem',
                                height: '1.6rem',
                                border: 'none',
                                position: 'absolute',
                                right: '0.6rem',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                padding: '0px',
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                </div>
                {/* <div className="btn-group"> */}
                <button
                    type="submit"
                    onClick={() => {
                        login(email, password);
                    }}
                    id="btn-login"
                    className="">
                    Me connecter
                </button>
                <button
                    type="button"
                    onClick={() => setDisplayPage('singup')}
                    id="see-signup-form"
                    className="">
                    Activer mon accès
                </button>
                {/* </div> */}
                <p
                    id="login-forgot-password"
                    onClick={() => setDisplayPage('forgot')}
                    style={{
                        textAlign: 'center',
                        marginTop: '1rem',
                        cursor: 'pointer'
                    }}>
                    Mot de passe oublié ?
                </p>
                <p
                    style={{
                        color: '#000000',
                        fontSize: '0.8rem',
                        fontWeight: '500',
                        margin: '0px',
                        paddingTop: '0.69rem',
                        paddingBottom: '1.63rem',
                        width: '100%',
                        textAlign: 'left'
                    }}>
                    <span>En vous connectant, vous acceptez les </span>
                    <span style={{ textDecoration: 'underline' }}>
                        <a
                            href="https://appv1.stimulin.fr/cgu.html"
                            target="_blank"
                            rel="noreferrer"
                            color="secondary">
                            conditions générales d'utilisation
                        </a>
                    </span>
                    <span> et </span>
                    <span style={{ textDecoration: 'underline' }}>
                        <a
                            href="https://appv1.stimulin.fr/politique-de-confidentialite.html"
                            target="_blank"
                            rel="noreferrer"
                            color="secondary">
                            politique de confidentialité
                        </a>
                    </span>
                    <span> de Stimul'in.</span>
                </p>
            </form>
        </>
    );
}
