import { useContext, useEffect, useRef, useState } from "react";
import { Capacitor } from '@capacitor/core';
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { reordon } from "../../../models/Games";
import { GameRef, GameProvider, GameProps } from "../../../providers/GameProvider";
import { Reordon } from "../../../components/Reordon";
import { UserDataProp } from "../../../models/userDataProp";
import { UserContext } from "../../../providers/UserProvider";
import { getExercise, getPatientUser, getUserById } from "../../../services/cloudFirestore";
import { Roles } from "../../../models/Roles";
import { Loader } from "../../../components/Loader";
import { useParams } from "react-router-dom";

export default () => {
    const reordonRef = useRef<GameRef>(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const platform = Capacitor.getPlatform();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [encouragement, setEncouragement] = useState<GameProps['encouragement'] | undefined>(undefined);
    const [congratulation, setCongratulation] = useState<GameProps['congratulation'] | undefined>(undefined);
    const [endloading, setEndLoading] = useState<boolean>(false);
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);
    useEffect(() => {
        const getGames = async (): Promise<void> => {
            const gameId = window.location.pathname.split('/')[2]
            if (!gameId) return;
            const game = await getExercise(gameId);
            const seniorData = await getPatientUser(user?.id ?? '');
            const author = await getUserById(game?.authorId ?? '');
            let tempNbRealisation = 0;
            seniorData?.statistics?.map((statistic) => {
                if (statistic.exerciseId === game?.id) {
                    tempNbRealisation = statistic.details.length;
                }
            });
            setCongratulation({
                msg: game?.congratulationsMessage,
                video: game?.rewardVideo || game?.rewardPhoto as string || undefined,
                image: author?.profilePhoto as string ?? '',
                firstName: author?.firstName ?? '',
                lastName: author?.lastName ?? '',
            });
            setEncouragement({
                msg: game?.encouragementMessage ?? '',
                nbRealisation: tempNbRealisation,
                image: author?.profilePhoto as string ?? '',
                firstName: author?.firstName ?? '',
                lastName: author?.lastName ?? '',
            });

            setEndLoading(true);
        };

        if (user && (user.role === Roles.SENIOR || user.role === Roles.FAMILY)) getGames();
        else setEndLoading(true);
    }, []);


    const getTips = () => {
        if (!reordonRef.current) return;
        reordonRef.current?.tips();
    };

    const resetGame = () => {
        if (!reordonRef.current) return;
        reordonRef.current?.reset();
        setRefreshKey(refreshKey + 1);
    };

    const colors = {
        primary: reordon.color,
        secondary: reordon.secondaryColor,
        border: reordon.border,
    };

    const backend = platform === 'web' ? HTML5Backend : TouchBackend;

    if (!endloading) return (<Loader />);

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            onReset={resetGame}
            encouragement={encouragement}
            congratulation={congratulation}
            background={reordon.background}
            instructionMessage={reordon.instructions?.find((instruction) => instruction.maxLvl >= lvl)?.text}
        >
            <DndProvider backend={backend}>
                <Reordon key={refreshKey} ref={reordonRef} />
            </DndProvider>
        </GameProvider>
    );
};