import React, { useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import update from 'immutability-helper';
import { XYCoord, useDrop } from "react-dnd";

import { GameRef } from "../../providers/GameProvider";
import { CustomDragLayer } from "./components/CustomDragLayer";
import { getRandomWord, setGlowStatus, shuffleCards } from "./utils";
import { useGame } from "../../contexts/GameContext";
import styles from './styles.module.css';
import DraggableCard from "./components/DraggableCard";
import Hand from "./components/Hand";
import { DragItem, Word } from "./models";
import { indexOf } from "lodash";

export const MeliMots = React.forwardRef<GameRef>((_, ref) => {
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);
    const { endAnimation, startTimer, writeMessage, stopTimer, endGame, resetTimer, displayInstruction, displayFunfact, closeFunfact } = useGame();
    const [word, setWord] = useState<Word>(getRandomWord(lvl));
    const boardRef = React.useRef<HTMLDivElement>(null);
    const [won, setWon] = useState(false);
    const [cards, setCards] = useState<DragItem[]>([]);
    const [clueCount, setClueCount] = useState(0);
    const [errorCount, setErrorCount] = useState(0);

    const placeCardsOnBoard = (word: Word) => {
        const height = boardRef.current?.clientHeight || 0;
        const width = boardRef.current?.clientWidth || 0;
        const cardHeight = lvl <= 5 ? 88 : lvl <= 7 ? 70 : 55;
        const cardWidth = lvl <= 5 ? 80 : lvl <= 7 ? 64 : 50;
        setCards(shuffleCards(
            word.letters.map((letter, idx) => ({
                id: idx,
                letter,
                left: 0,
                top: 0,
                zIndex: 0,
                status: 'idle',
            })),
            height,
            width,
            cardHeight,
            cardWidth,
        ));
    }

    useImperativeHandle(ref, () => ({
        tips: () => {
            if (cards.some(card => card.status === 'glow')) {
                const id = cards.find(card => card.status === 'glow')!.id;
                setCards(currentCards => {
                    let fromIdx;
                    // Replaces findLast with a loop that traverses the currentCards array in reverse to find the last matching element
                    for (let i = currentCards.length - 1; i >= 0; i--) {
                        if (currentCards[i].handIdx !== undefined && currentCards[i].letter === word.letters[id]) {
                            fromIdx = currentCards[i].handIdx;
                            break;
                        }
                    }
                    const handIdx = word.letters.indexOf(currentCards[id].letter, fromIdx !== undefined ? fromIdx + 1 : 0);
                    if (currentCards.some(card => card.handIdx === handIdx)) {
                        return currentCards;
                    }
                    const newSet = update(currentCards, {
                        [id]: {
                            $merge: { handIdx, status: 'placed' },
                        },
                    });
                    return newSet.map((card) => ({
                        ...card,
                        status: ['correct', 'placed'].includes(card.status) ? card.status : 'idle',
                    }));
                });
                setClueCount((count) => count + 1);
                return;
            }
            if (lvl >= 8 && cards.filter(card => card.status === 'idle' && !card.handIdx).length > 3) {
                setClueCount((count) => count + 1);
                setCards(currentCards => setGlowStatus(currentCards, 3));
            } else if (lvl >= 5 && lvl < 8 && cards.filter(card => card.status === 'idle' && !card.handIdx).length > 2) {
                setClueCount((count) => count + 1);
                setCards(currentCards => setGlowStatus(currentCards, 2));
            } else if (cards.filter(card => card.status === 'idle' && !card.handIdx).length >= 1) {
                setClueCount((count) => count + 1);
                setCards(currentCards => setGlowStatus(currentCards, 1));
            }
        },
        reset: () => {
            resetTimer();
            setWon(false);
            startTimer();
            setWord((currentWord) => {
                let newWord = getRandomWord(lvl);
                while (newWord.word === currentWord.word) {
                    newWord = getRandomWord(lvl);
                }
                placeCardsOnBoard(newWord);
                return newWord;
            });

        },
    }));

    useEffect(() => {
        if (!won && cards.length !== 0 && !cards.some((card) => card.handIdx === undefined || card.status === 'wrong')) {
            const isCorrect = word.letters.join('') === [...cards].sort((a, b) => a.handIdx! - b.handIdx!).map(card => card.letter).join('');
            if (isCorrect) {
                stopTimer();
                setTimeout(() => {
                    endAnimation(async () => displayFunfact({
                        title: word.word,
                        text: word.funFact,
                        onClose: () => {
                            closeFunfact();
                            endGame({
                                clueCount,
                                errorCount,
                            });
                        },
                    }));
                }, 4000);
                setWon(isCorrect);
                writeMessage({ color: 'success', text: 'Bravo !' });
                setCards(currentCards => {
                    return currentCards.map(card => ({
                        ...card,
                        status: 'correct',
                    }));
                });
            } else {
                setErrorCount((count) => count + 1);
                writeMessage({ color: 'wrong', text: `Retentez votre chance ! Choisissez une autre proposition.` });
                setCards(currentCards => {
                    return currentCards.map(card => ({
                        ...card,
                        status: 'wrong',
                    }));
                });
            }
        }
    }, [cards]);

    const moveCard = (id: number, left: number, top: number, handIdx?: number) => {
        setCards(currentCards => {
            if (handIdx !== undefined && currentCards.some(card => card.handIdx === handIdx)) return currentCards;
            const maxZIndex = currentCards.reduce((acc, card) => Math.max(acc, card.zIndex), 0);
            const newSet = update(currentCards, {
                [id]: {
                    $merge: { left, top, handIdx, zIndex: maxZIndex + 1 },
                },
            });
            return newSet.map((card) => ({
                ...card,
                status: [handIdx ? 'idle' : 'glow', 'correct', 'placed'].includes(card.status) ? card.status : 'idle',
            }));
        });
    };

    const [, drop] = useDrop(() => ({
        accept: 'CARD',
        drop(item: DragItem, monitor) {
            const didDrop = monitor.didDrop();
            if (didDrop) {
                return;
            }
            const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
            const left = Math.round(item.left + delta.x);
            const top = Math.round(item.top + delta.y);
            moveCard(item.id, left, top);
            return;
        },
    }), [moveCard]);

    const handleCardHold = (id: DragItem['id'], position: XYCoord, handIdx: DragItem['handIdx']) => {
        moveCard(id, position.x, position.y, handIdx);
    };

    useEffect(() => {
        placeCardsOnBoard(word);
        startTimer();
        displayInstruction();
        return () => {
            stopTimer();
        };
    }, []);

    return <div className={styles.container} ref={drop}>
        <div className={styles.board} ref={boardRef}>
            {cards.filter(card => card.handIdx === undefined).map(card => (
                <DraggableCard key={card.id} card={card} />
            ))}
        </div>
        <div className={[styles.hand, won ? styles.center : ''].join(' ')}>
            <Hand size={word.letters.length} cards={cards} onCardHold={handleCardHold} word={word.letters} />
        </div>
        <CustomDragLayer />
    </div>;
});