import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Typography } from './Typography';
import cross from '../assets/cross.svg';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { Roles } from '../models/Roles';
import onBoardingMobile1 from '../assets/onBoardingMobile1.png';
import onBoardingMobile2 from '../assets/onBoardingMobile2.png';
import onBoardingMobile3 from '../assets/onBoardingMobile3.png';
import onBoardingMobile4 from '../assets/onBoardingMobile4.png';
import onBoardingMobile5 from '../assets/onBoardingMobile5.png';
import onBoardingMobile51 from '../assets/onBoardingMobile5-1.png';
import { isPlatform } from '@ionic/core';
import { Footer } from './Footer';
import { Button } from './Button';

const classes: { [key: string]: CSSProperties } = {
    'root-background': {
        background: 'rgba(0, 0, 0, 0.7)',
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: '1302'
    },
    'root-container': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        background: 'white',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        height: '90%',
        alignSelf: 'center',
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem',
        paddingTop: '0.5rem',
        borderRadius: '16px',
        gap: '1rem'
    },
    header: {
        display: 'flex',
        width: '100%',
        height: 'fit-content',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    'header-title': {
        textAlign: 'start',
        width: 'fit-content',
        alignItems: 'flex-start',
        height: 'auto',
        fontSize: '0.875rem',
        color: '#0D224B'
    },
    'icon-nav': {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer'
    },
    body: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        alignItems: 'center'
    },
    'body-title': {
        textAlign: 'center'
    },
    footer: {
        position: 'absolute',
        bottom: '1rem',
        left: '1rem',
        right: '1rem',
        display: 'flex',
        height: 'fit-content',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
};

interface Props {
    setOnboarding: (onboarding: boolean) => void;
    runImport: () => void;
}

export function Onboarding({ setOnboarding, runImport }: Props): JSX.Element {
    const { t } = useTranslation();
    const [step, setStep] = useState<number>(0);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [page, setPage] = useState<'forMe' | 'games' | 'album'>('forMe');
    const screens = [
        onBoardingMobile1,
        onBoardingMobile2,
        onBoardingMobile3,
        onBoardingMobile4,
        onBoardingMobile51
    ];
    const mobileScreens = [
        onBoardingMobile1,
        onBoardingMobile2,
        onBoardingMobile3,
        onBoardingMobile4,
        onBoardingMobile51
    ];
    // const mobileScreens = [forMeMobile, myGamesMobile, myAlbumMobile, createScreenMobile];
    const titles = ['ForMe', 'GameGallery', 'Album', 'myAccount', 'letsStart'];
    const bodies = ['OB.ForMe', 'OB.GameGallery', 'OB.Album', 'OB.myAccount', 'OB.letsStart'];
    const { width } = useWindowDimensions();

    useEffect(() => {
        switch (step) {
            case 0:
                setPage('forMe');
                break;
            case 1:
                setPage('games');
                break;
            case 2:
            case 3:
            case 4:
                setPage('album');
                break;
            default:
                setPage('forMe');
                break;
        }
    }, [step]);

    return (
        <div style={{ ...classes['root-background'] }}>
            <div style={{ ...classes['root-container'] }}>
                <div
                    style={{
                        ...classes['root'],
                        width:
                            width && width < 380
                                ? '95%'
                                : width && width < 600
                                ? '80%'
                                : width && width < 800
                                ? '70%'
                                : width && width < 1000
                                ? '60%'
                                : '50%',
                        paddingBottom: step === 4 ? '1rem' : '3rem'
                    }}>
                    <div style={{ ...classes['header'] }}>
                        <Typography variant="h3" sx={{ ...classes['header-title'] }}>
                            {t('YourFirstSteps', { nb: step + 1 })}
                        </Typography>
                        <img
                            src={cross}
                            alt="close"
                            onClick={() => {
                                setOnboarding(false);
                            }}
                            style={classes['icon-nav']}
                        />
                    </div>
                    <div
                        className="disable-scroll"
                        style={{ ...classes['body'], overflowY: 'auto' }}>
                        <img
                            src={
                                !isPlatform('tablet') &&
                                (isPlatform('android') || isPlatform('ios'))
                                    ? mobileScreens[step]
                                    : screens[step]
                            }
                            alt="screenIcon"
                            style={{
                                height: `calc(${
                                    width && width < 380
                                        ? '10rem'
                                        : width && width < 600
                                        ? '12rem'
                                        : width && width < 800
                                        ? '14rem'
                                        : '15rem'
                                }${step === 4 ? ' - 2rem' : ''})`,
                                // height: 'max-content',
                                width: 'auto',
                                objectFit: 'contain'
                            }}
                        />
                        <Typography
                            variant="h2"
                            sx={{
                                ...classes['body-title'],
                                ...(step === 4 && { marginTop: '1rem' })
                            }}>
                            {t(titles[step])}
                        </Typography>
                        <Typography
                            variant="p"
                            sx={{
                                width: '100%',
                                textAlign: 'center'
                            }}>
                            <p dangerouslySetInnerHTML={{ __html: t(bodies[step]) || '' }} />
                            {step === 4 && (
                                <p
                                    onClick={() => setOnboarding(false)}
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    dangerouslySetInnerHTML={{
                                        __html: t('OB.letsStart2') || ''
                                    }}
                                />
                            )}
                        </Typography>
                        {step === 4 && (
                            <Button
                                sx={{
                                    width: '60%'
                                }}
                                onClick={() => {
                                    setOnboarding(false);
                                    runImport();
                                }}>
                                {t('import')}
                            </Button>
                        )}
                    </div>
                    <div style={{ ...classes['footer'] }}>
                        <Typography
                            sx={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                if (step > 0) {
                                    setStep(step - 1);
                                }
                            }}>
                            {t('prev')}
                        </Typography>
                        {step !== 4 && (
                            <Typography
                                sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    color: 'var(--main-pink-color)',
                                    fontWeight: '600'
                                }}
                                onClick={() => {
                                    if (step < screens.length - 1) {
                                        setStep(step + 1);
                                    }
                                }}>
                                {t('next')}
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
            <Footer
                setPage={(page: 'forMe' | 'games' | 'album') => {}}
                page={page}
                displayImport={() => {}}
                isOnBoarding={true}
                stepOnBoarding={step + 1}
            />
        </div>
    );
}
