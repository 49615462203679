import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, CardActionArea, Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLongPress } from 'use-long-press';
import { useHistory, useParams } from 'react-router-dom';
import greenTickIcon from '../../assets/greenTickIcon.png';
import { Exercise } from '../../models/Exercise';
import { ExerciseItem } from '../../models/ExerciseItem';
import { getExercise, getExerciseItem, getTherapistUser } from '../../services/cloudFirestore';
import { getImageKitUrlFrom, randomPicture } from '../../utilities/utils';
import { UserContext } from '../../providers/UserProvider';
import { Roles } from '../../models/Roles';
import { Therapist } from '../../models/Therapist';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseType } from '../../models/ExerciseType';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import { QuizExercise2 } from './QuizExercise2';
import { useGame } from '../../contexts/GameContext';
import { GameRef } from '../../providers/GameProvider';

const ImageBox = styled(Box)({
    position: 'relative'
});

const ImageContainer = styled('img')({
    width: '100%',
    objectFit: 'cover'
});

const OverlayContainer = styled('img')({
    borderRadius: '9px',
    position: 'absolute',
    height: '98%',
    width: '98%',
    objectFit: 'cover',
    verticalAlign: 'center',
    top: '29%',
    left: '30%'
});

const Root = styled(Typography)({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0
});

export const Quizz = React.forwardRef((_, ref) => {
    const { t } = useTranslation();
    const quizz2Ref = useRef<GameRef>();
    const { endGame, startTimer, stopTimer, displayFunfact, closeFunfact, endAnimation } = useGame();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { lvlId } = useParams<{ lvlId: string }>();
    const level = parseInt(lvlId);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [initials, setInitials] = useState<string>('');
    const [exercise, setExercise] = useState<Exercise>();
    const [errorCount, setErrorCount] = useState<number>(0);
    const [clueCount, setClueCount] = useState<number>(0);
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [completedExercises, setCompletedExercises] = useState<string[] | null>();
    const [openReview, setOpenReview] = useState(false);
    const [exerciseItemList, setExerciseItemList] = useState<ExerciseItem[]>([]);
    const [exerciseItemIdList, setExerciseItemIdList] = useState<string[]>([]);
    const [submittedCorrectAnswers, setSubmittedCorrectAnswers] = useState<(string | undefined)[]>(
        []
    );
    const { height, width } = useWindowDimensions();

    useImperativeHandle(ref, () => ({
        tips: () => {
            if (quizz2Ref.current) {
                quizz2Ref.current?.tips();
            }
        },
    }));

    useEffect(() => {
        if (completedExercises && completedExercises?.length > 0) {
            const list: (string | undefined)[] = [];
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < exerciseItemList.length; i += 1) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < completedExercises.length; j += 1) {
                    if (
                        completedExercises[j] === exerciseItemList[i].id &&
                        exerciseItemList[i].answer
                    ) {
                        list.push(exerciseItemList[i].answer);
                    }
                }
            }
            setSubmittedCorrectAnswers(list);
        }
    }, [exerciseItemList, userDataProp]);

    useEffect(() => {
        startTimer();
        if (user?.role === Roles.SENIOR) {
            localStorage.setItem('startTime', new Date().toString());
        }
    }, []);

    const getItemList = async (globalExercise: Exercise): Promise<void> => {
        let listArray: number[] = [];
        const list: ExerciseItem[] = [];
        const listIds: string[] = [];
        listArray = randomPicture(level, ExerciseType.QUIZ);
        if (globalExercise.exerciseImages) {
            await Promise.all(
                listArray.map(async (element) => {
                    if (globalExercise.exerciseImages && globalExercise.exerciseImages[element]) {
                        const string = globalExercise.exerciseImages[element];
                        if (string) {
                            const item = await getExerciseItem(string);
                            if (user && user.role === Roles.SENIOR && item && item.id) {
                                list.push(item);
                                listIds.push(item.id);
                            }
                            if (
                                user &&
                                (user.role === Roles.PRO || user.role === Roles.FAMILY) &&
                                item &&
                                item.id
                            ) {
                                list.push(item);
                                listIds.push(item.id);
                            }
                        }
                    }
                })
            );
            setExerciseItemList(list);
            setExerciseItemIdList(listIds);
        }
    };

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user.id) {
                const globalExercise = await getExercise(id);
                if (
                    globalExercise &&
                    id !== undefined &&
                    id !== null
                ) {
                    setExercise(globalExercise);
                    await getItemList(globalExercise);
                    if (globalExercise && globalExercise.authorId) {
                        const authorData = await getTherapistUser(globalExercise.authorId);
                        if (authorData) {
                            setAuthor(authorData);
                            if (authorData.firstName && authorData.lastName) {
                                setInitials(
                                    authorData.firstName.charAt(0).toUpperCase() +
                                    authorData.lastName.charAt(0).toUpperCase()
                                );
                            }
                        }
                    }
                }
            }
        };
        getExerciseList();
    }, [id, userDataProp]);

    const checker = (completedExerciseList: string[], exerciseIdList: string[]): boolean => {
        return exerciseIdList.every((v) => completedExerciseList.includes(v));
    };

    useEffect(() => {
        if (
            exerciseItemIdList &&
            completedExercises &&
            completedExercises.length > 0 &&
            exerciseItemIdList.length > 0 &&
            checker(completedExercises, exerciseItemIdList)
        ) {
            setOpenReview(true);
            stopTimer();
            setCompletedExercises([]);
            const funFact = exerciseItemList.find(item => item.anecdote) || exerciseItemList[Math.floor(Math.random() * exerciseItemList.length)];
            endAnimation(async () => displayFunfact({
                title: funFact?.title || '',
                text: funFact?.anecdote,
                Picture: funFact?.exerciseImage as string,
                onClose: () => {
                    closeFunfact();
                    endGame({
                        clueCount,
                        errorCount,
                    });
                },
            }));
        }
    }, [completedExercises, exerciseItemIdList]);

    const isHeightOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return true;
        }
        if (height <= 768 && height > 551) {
            return true;
        }
        return false;
    };

    const isWidthOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 1000 || height < 800) {
            return true;
        }
        return false;
    };

    const [viewSecondPage, setViewSecondPage] = useState<boolean>(false);
    const [itemSecondPage, setItemSecondPage] = useState<ExerciseItem | undefined>();
    const onImageClick = (item: ExerciseItem): void => {
        if (item.id) {
            if (!completedExercises || completedExercises?.indexOf(item.id?.toString()) === -1) {
                setViewSecondPage(true);
                setItemSecondPage(item);
            }
        }
    };

    const bindLongPress = useLongPress(
        (e, index) => {
            onImageClick(exerciseItemList[Number(index.context)]);
        },
        { threshold: 1000 }
    );

    const getExercises = (): JSX.Element[] | null => {
        if (exerciseItemList) {
            if (exerciseItemList.length === 3) {
                return exerciseItemList.map((item, index) => {
                    return (
                        <Grid
                            item
                            xs={4}
                            key={item.id}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPress(index)}
                            onClick={(): void => onImageClick(item)}>
                            <CardActionArea>
                                <ImageBox>
                                    <ImageContainer
                                        src={
                                            typeof item.exerciseImage === 'string'
                                                ? getImageKitUrlFrom(item.exerciseImage, 512, 512)
                                                : ''
                                        }
                                        alt="selectedImage"
                                    />
                                    {completedExercises &&
                                        item &&
                                        item.id &&
                                        completedExercises.indexOf(item.id) !== -1 && (
                                            <Box
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    display: 'block',
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: '#0F004678',
                                                    opacity: 0.6
                                                }}
                                            />
                                        )}
                                    <OverlayContainer
                                        src={greenTickIcon}
                                        alt="puzzleOverlay"
                                        style={{
                                            display:
                                                completedExercises &&
                                                    item &&
                                                    item.id &&
                                                    completedExercises?.indexOf(item.id) !== -1
                                                    ? 'inline'
                                                    : 'none',
                                            width: '40%',
                                            height: '40%'
                                        }}
                                    />
                                </ImageBox>
                            </CardActionArea>
                        </Grid>
                    );
                });
            }
            return exerciseItemList.map((item, index) => {
                return (
                    <Grid
                        item
                        xs={exerciseItemList.length === 4 ? 12 : 4}
                        gridColumn="span 1"
                        key={item.id}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPress(index)}
                        onClick={(): void => onImageClick(item)}>
                        <CardActionArea>
                            <ImageBox>
                                <ImageContainer
                                    src={
                                        typeof item.exerciseImage === 'string'
                                            ? getImageKitUrlFrom(item.exerciseImage, 512, 512)
                                            : ''
                                    }
                                    alt="selectedImage"
                                    style={{
                                        opacity:
                                            completedExercises &&
                                                item &&
                                                item.id &&
                                                completedExercises.indexOf(item.id) !== -1
                                                ? 0.6
                                                : 1.0
                                    }}
                                />
                                <OverlayContainer
                                    src={greenTickIcon}
                                    alt="puzzleOverlay"
                                    style={{
                                        display:
                                            completedExercises &&
                                                item &&
                                                item.id &&
                                                completedExercises?.indexOf(item.id) !== -1
                                                ? 'inline'
                                                : 'none',
                                        width: '39%',
                                        height: '39%'
                                    }}
                                />
                            </ImageBox>
                        </CardActionArea>
                    </Grid>
                );
            });
        }
        return null;
    };

    console.log('exerciseItemList', exerciseItemList);

    return viewSecondPage && itemSecondPage && exercise ? (
        <QuizExercise2
            ref={quizz2Ref}
            author={author}
            memoryLevel={level ?? 1}
            exerciseItemList={exerciseItemList}
            currentExercise={itemSecondPage}
            exercise={exercise}
            completedExercises={completedExercises ?? []}
            setCompletedExercises={(item: string[]) => setCompletedExercises(item)}
            goBack={(): void => {
                setViewSecondPage(false);
                setItemSecondPage(undefined);
            }}
            incrementErrorCount={() => setErrorCount(errorCount + 1)}
            incrementClueCount={() => setClueCount(clueCount + 1)}
        />
    ) : (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            height="60vh"
            sx={{ backgroundColor: '#FFFFFF', height: '100%' }}>
            {exerciseItemList && exerciseItemList.length === 3 ? (
                <Box margin="auto" px={3} pt={5}>
                    <Root>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'stretch',
                                marginBottom: '1%'
                            }}>
                            {getExercises()}
                        </Grid>
                    </Root>
                </Box>
            ) : (
                <Box
                    maxWidth={isWidthOfXS() ? 900 : 1000}
                    sx={{
                        margin: 'auto',
                        overflowY: 'auto',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        }
                    }}>
                    <Root>
                        <Grid
                            container
                            spacing={1}
                            display={exerciseItemList.length === 4 ? 'grid' : 'flex'}
                            gridTemplateColumns="repeat(2, 1fr)"
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'stretch',
                                marginBottom: '1%',
                                width: exerciseItemList.length === 4 ? '70%' : '100%'
                            }}>
                            {getExercises()}
                        </Grid>
                    </Root>
                </Box>
            )}
        </Box>
    );
});
