import React, { CSSProperties, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../utilities/utils.css';
import { ExerciseType } from '../models/ExerciseType';
import { Typography } from './Typography';
import { Button } from './Button';
import * as games from '../models/Games';
import useWindowDimensions from '../utilities/useWindowDimensions';

const classes = {
    divRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px'
    },
    squareDivContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'scroll',
        scrollBehavior: 'smooth',
        height: 'fit-content',
        width: 'fit-content',
        gap: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        borderRadius: '16px'
    },
    squareDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px'
    },
    btnScroll: {
        width: 'fit-content',
        height: '5rem',
        padding: '8px',
        borderRadius: '16px'
    },
    square: {
        width: '250px',
        height: '5rem',
        backgroundColor: '#fff',
        padding: '8px',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        cursor: 'pointer'
    },
    textExoType: {
        width: 'fit-content',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    }
} as Record<string, CSSProperties>;

interface ExerciseTypeSrollProps {
    setExerciseType: Function;
    displayExerciceType: ExerciseType | null;
    countExerciceType: {
        ['allGames']: number;
        [ExerciseType.QUIZ]: number;
        [ExerciseType.MEMORIZ]: number;
        [ExerciseType.PUZZLE]: number;
        [ExerciseType.SUDOKU]: number;
        [ExerciseType.ORDER]: number;
        [ExerciseType.PONG]: number;
        [ExerciseType.TOURBILLON]: number;
        [ExerciseType.MELIMOTS]: number;
    };
}

export function ExerciseTypeSroll({
    setExerciseType,
    displayExerciceType,
    countExerciceType
}: ExerciseTypeSrollProps): JSX.Element {
    const { t } = useTranslation();

    const { width } = useWindowDimensions();
    const imgTypeExercice = [
        { allGames: null },
        { [ExerciseType.QUIZ]: games.quizz.icon },
        { [ExerciseType.MEMORIZ]: games.memory.icon },
        { [ExerciseType.PUZZLE]: games.puzzle.icon },
        { [ExerciseType.SUDOKU]: games.sudok.icon },
        { [ExerciseType.MELIMOTS]: games.melimots.icon },
        { [ExerciseType.PONG]: games.pingpong.icon },
        { [ExerciseType.TOURBILLON]: games.tourbillon.icon },
        { [ExerciseType.ORDER]: games.reordon.icon },
    ];

    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;
        setScrollPosition(target.scrollLeft);
    };

    const handleScrollButtonClick = (increment: number) => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += increment;
            setScrollPosition(containerRef.current.scrollLeft);
        }
    };

    return (
        <div style={classes.divRoot}>
            <Button
                disabled={scrollPosition === 0}
                sx={{
                    ...classes.btnScroll,
                    ...(scrollPosition === 0 ? { backgroundColor: '#D0D0D0' } : {})
                }}
                onClick={() => handleScrollButtonClick(-250)}>
                &lt;
            </Button>
            <div
                className="disable-scroll"
                style={classes.squareDivContainer}
                onScroll={handleScroll}
                ref={containerRef}>
                <div style={classes.squareDiv}>
                    {imgTypeExercice.map((_, index) => {
                        const GameIcon: React.FunctionComponent = Object.values(imgTypeExercice[index])[0];
                        return (
                        <div
                            key={index}
                            className="limit-width"
                            style={{
                                ...classes.square,
                                ...{
                                    ...width && width < 600 ? { minWidth: '200px'} : {},
                                    width:
                                        width && width < 600
                                            ? '100%'
                                            : '250px',
                                    paddingLeft: index === 0 ? '12px' : '8px'
                                },
                                ...(displayExerciceType ===
                                    Object.keys(imgTypeExercice[index])[0] ||
                                (index === 0 && !displayExerciceType)
                                    ? { backgroundColor: '#1C0D4B' }
                                    : {})
                            }}
                            onClick={(): void => {
                                setExerciseType(Object.keys(imgTypeExercice[index])[0]);
                            }}>
                            {Object.values(imgTypeExercice[index])[0] && (
                                <GameIcon />
                            )}
                            <div style={classes.textExoType}>
                                <Typography
                                    sx={{
                                        color:
                                            displayExerciceType ===
                                                Object.keys(imgTypeExercice[index])[0] ||
                                            (index === 0 && !displayExerciceType)
                                                ? '#fff'
                                                : '#1C0D4B',
                                        fontSize: '1.25rem',
                                        fontWeight: '600',
                                        width: 'fit-content'
                                    }}>
                                    {t(Object.keys(imgTypeExercice[index])[0])}
                                </Typography>
                                <Typography
                                    sx={{
                                        color:
                                            displayExerciceType ===
                                                Object.keys(imgTypeExercice[index])[0] ||
                                            (index === 0 && !displayExerciceType)
                                                ? '#fff'
                                                : '#195BBE',
                                        fontSize: '1rem',
                                        fontWeight: '500'
                                    }}>
                                    {'('}
                                    {Object.keys(imgTypeExercice[index])[0] === 'allGames' && (
                                        <>{countExerciceType.allGames}</>
                                    )}
                                    {Object.keys(imgTypeExercice[index])[0] ===
                                        ExerciseType.PUZZLE && (
                                        <>{countExerciceType[ExerciseType.PUZZLE]}</>
                                    )}
                                    {Object.keys(imgTypeExercice[index])[0] ===
                                        ExerciseType.QUIZ && (
                                        <>{countExerciceType[ExerciseType.QUIZ]}</>
                                    )}
                                    {Object.keys(imgTypeExercice[index])[0] ===
                                        ExerciseType.MEMORIZ && (
                                        <>{countExerciceType[ExerciseType.MEMORIZ]}</>
                                    )}
                                    {Object.keys(imgTypeExercice[index])[0] ===
                                        ExerciseType.PONG && (
                                        <>{countExerciceType[ExerciseType.PONG]}</>
                                    )}
                                    {Object.keys(imgTypeExercice[index])[0] ===
                                        ExerciseType.ORDER && (
                                        <>{countExerciceType[ExerciseType.ORDER]}</>
                                    )}
                                    {Object.keys(imgTypeExercice[index])[0] ===
                                        ExerciseType.SUDOKU && (
                                        <>{countExerciceType[ExerciseType.SUDOKU]}</>
                                    )}
                                    {Object.keys(imgTypeExercice[index])[0] ===
                                        ExerciseType.TOURBILLON && (
                                        <>{countExerciceType[ExerciseType.TOURBILLON]}</>
                                    )}
                                    {Object.keys(imgTypeExercice[index])[0] ===
                                        ExerciseType.MELIMOTS && (
                                        <>{countExerciceType[ExerciseType.MELIMOTS]}</>
                                    )}
                                    {')'}
                                </Typography>
                            </div>
                        </div>
                    )})}
                </div>
            </div>
            <Button
                disabled={
                    Math.trunc(scrollPosition) !== 0 &&
                    containerRef.current !== null &&
                    (Math.trunc(scrollPosition) ===
                        containerRef.current.scrollWidth - containerRef.current.offsetWidth ||
                        Math.trunc(scrollPosition) ===
                            containerRef.current.scrollWidth -
                                containerRef.current.offsetWidth -
                                1 ||
                        Math.trunc(scrollPosition) ===
                            containerRef.current.scrollWidth - containerRef.current.offsetWidth + 1)
                }
                sx={{
                    ...classes.btnScroll,
                    ...(Math.trunc(scrollPosition) !== 0 &&
                    containerRef.current !== null &&
                    (Math.trunc(scrollPosition) ===
                        containerRef.current.scrollWidth - containerRef.current.offsetWidth ||
                        Math.trunc(scrollPosition) ===
                            containerRef.current.scrollWidth -
                                containerRef.current.offsetWidth -
                                1 ||
                        Math.trunc(scrollPosition) ===
                            containerRef.current.scrollWidth - containerRef.current.offsetWidth + 1)
                        ? { backgroundColor: '#D0D0D0' }
                        : {})
                }}
                onClick={() => handleScrollButtonClick(250)}>
                &gt;
            </Button>
        </div>
    );
}
