import { IonContent, useIonRouter } from '@ionic/react';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { UserDataProp } from '../../models/userDataProp';
import { useContext } from 'react';
import { VariableSizeGrid as VGrid, VariableSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { UserContext } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../components/Footer';
import { Typography } from '../../components/Typography';
import albertAvatar from '../../assets/albert.svg';
import { Button } from '../../components/Button';
import { VignetteHome } from '../../components/VignetteHome';
import adminSendAllMedia from '../../assets/adminSendAllMedia.jpg';
import LoginButton from './LoginButton';
import LoginInfo from './LoginInfo';
import { useAuth0 } from '@auth0/auth0-react';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import {
    getDocId,
    getExerciseItem,
    getExercises,
    getPatientExerciseList,
    getPatientFromCode,
    getPatientUser,
    getUser,
    getUserById,
    loadAllVideoPersonal,
    // increaseExerciseStarted
} from '../../services/cloudFirestore';
import { updateNeedViewOnboarding } from '../../stores/User';
import { Exercise } from '../../models/Exercise';
import { PatientHomeData } from '../../models/PatientHomeData';
import { ExerciseType } from '../../models/ExerciseType';
import { Roles } from '../../models/Roles';
import { ExerciseTypeSroll } from '../../components/ExerciseTypeScroll';
import { VignetteGames } from '../../components/VignetteGames';
import { getAdminPictureVideoItem, getPatientVideoItem, getProPictureVideoItem } from './getMedia';
import { VignetteAlbum } from '../../components/VignetteAlbum';
import { MediaSelector } from '../../components/MediaSelector';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import { CreateImportHeader } from '../../components/CreateImportHeader';
import { CreateGames } from './createImport/CreateGames';
import { CongratulationsModal } from '../../components/CongratulationsModal';
import { useHistory } from 'react-router';
import { ChoiseCreateImport } from './ChoiseCreateImport';
import { ImportMedia } from './ImportMedia';
import { LvlChoiseModal } from '../games/tempComponents/LvlChoiseModal';
import { Patient } from '../../models/Patient';
import { Profile } from './profile/Profile';
import { ErrorLunchGameModal } from '../../components/ErrorLunchGameModal';
import { isPlatform } from '@ionic/react';
import { Header } from '../../components/Header';
import { MediaDetails } from '../../components/MediaDetails';
import { TryExerciseAlerts } from '../games/tempComponents/common/TryExerciseAlerts';
import { App } from '@capacitor/app';
import { PopupEndTestPeriod } from '../../components/PopupEndTestPeriod';
import notNews from '../../assets/notNews.png';
import diapo from '../../assets/diapo.png';
import { Onboarding } from '../../components/Onboarding';
import { ScreenOrientation } from '@capacitor/screen-orientation';

const classes: { [key: string]: CSSProperties } = {
    'title-div': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        paddingBottom: '1.5rem',
        gap: '0.5rem'
    },
    'home-root': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'top',
        padding: '0rem 2.5rem 6rem',
        // marginBottom: '',
        height: '100%',
        position: 'relative',
        overflowY: 'auto'
    },
    'vignette-container': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'start',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
        padding: '0 0.5rem'
    },
    'media-container': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'start',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
        padding: '0 0.5rem'
    },
    'exercice-container': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'start',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
        padding: '1rem 0.5rem'
    },
    'btn-create': {
        position: 'fixed',
        bottom: 'calc(1rem + 75px)',
        width: 'calc(100% - 6rem)',
        left: '3rem',
        backgroundColor: 'var(--btn-dark-bg-color)'
    },
    'create-game-page': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '100%',
        right: '0',
        bottom: '0',
        zIndex: 100,
        backgroundColor: '#fff',
        opacity: 1
    },
    'import-media-page': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '100%',
        right: '0',
        bottom: '0',
        zIndex: 100,
        backgroundColor: '#fff',
        opacity: 1
    },
    'profile-page': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        position: 'absolute',
        top: '100%',
        right: '0',
        bottom: '0',
        zIndex: 100,
        backgroundColor: '#fff',
        opacity: 1
    },
    titre: {
        fontSize: '1.7rem'
    }
};
export function Home(): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { height, width } = useWindowDimensions();
    const [page, setPage] = useState<'forMe' | 'games' | 'album'>('forMe');
    const [exerciseList, setExerciseList] = useState<Exercise[]>([]);
    const [exerciseListToShow, setExerciseListToShow] = useState<PatientHomeData[]>([]);
    const [dataListForHome, setDataListForHome] = useState<PatientHomeData[]>([]);
    const [displayLoadingNews, setDisplayLoadingNews] = useState<boolean>(true);
    const [displayExerciceType, setDisplayExerciceType] = useState<ExerciseType | null>(null);
    const [displayMediaType, setDisplayMediaType] = useState<
        'picture' | 'personal-video' | 'youtube-video' | null
    >(null);
    const [adminListId, setAdminListId] = useState<string[]>([]);
    const [lastAdminListId, setLastAdminListId] = useState<string[]>([]);
    // const [stepCreateGames, setStepCreateGames] = useState<number>(-1);
    const [step, setStep] = useState<number>(-1);
    const [stepMax, setStepMax] = useState<number>(-1);
    const [stepTitle, setStepTitle] = useState<string>(t('Iwant') ?? '');
    const [onboarding, setOnboarding] = useState<boolean>(false);
    const [displayModalSendAllMedia, setDisplayModalSendAllMedia] = useState<boolean>(false);
    const [countExerciceType, setCountExerciceType] = useState<{
        allGames: number;
        [ExerciseType.QUIZ]: number;
        [ExerciseType.MEMORIZ]: number;
        [ExerciseType.PUZZLE]: number;
        [ExerciseType.SUDOKU]: number;
        [ExerciseType.ORDER]: number;
        [ExerciseType.PONG]: number;
        [ExerciseType.TOURBILLON]: number;
        [ExerciseType.MELIMOTS]: number;
    }>({
        allGames: 0,
        [ExerciseType.QUIZ]: 0,
        [ExerciseType.MEMORIZ]: 0,
        [ExerciseType.PUZZLE]: 0,
        [ExerciseType.SUDOKU]: 0,
        [ExerciseType.ORDER]: 0,
        [ExerciseType.PONG]: 0,
        [ExerciseType.TOURBILLON]: 0,
        [ExerciseType.MELIMOTS]: 0
    });
    const [mediaList, setMediaList] = useState<any[]>([]);
    if (isPlatform('ios') || isPlatform('ipad') || isPlatform('iphone')) requestPermission();
    // const [stepImportMedia, setStepImportMedia] = useState<number>(-1);
    const [displayCreateImportMenu, setDisplayCreateImportMenu] = useState<boolean>(false);
    const [displayCreateGames, setDisplayCreateGames] = useState<boolean>(false);
    const [displayImportMedia, setDisplayImportMedia] = useState<boolean>(false);
    // const [displayProfile, setDisplayProfile] = useState<boolean>(false);
    const [importMediaType, setImportMediaType] = useState<
        null | 'picture' | 'personal-video' | 'youtube-video'
    >();
    const [openLvlModal, setOpenLvlModal] = useState<boolean>(false);
    const [alreaydyOpenLvlModal, setAlreadyOpenLvlModal] = useState<boolean>(false);
    const [dataExercise, setDataExercise] = useState<PatientHomeData>();
    const [lvlArray, setLvlArray] = useState<number[]>([]);
    const [seniorData, setSeniorData] = useState<Patient>();
    const [countNewGames, setCountNewGames] = useState<number>(0);
    const [displayErrorLunchGame, setDisplayErrorLunchGame] = useState<boolean>(false);
    const [displayMediaDetails, setDisplayMediaDetails] = useState<boolean>(false);
    const [mediaDetails, setMediaDetails] = useState<any>();
    const [mediaDetailsDiapo, setMediaDetailsDiapo] = useState<any[]>();
    const [displayEndTestPeriodModal, setDisplayEndTestPeriodModal] = useState<boolean>(false);
    const [seniorId, setSeniorId] = useState<string>('');
    const [gameToEdit, setGameToEdit] = useState<PatientHomeData | undefined>(undefined);
    const [mediaToEdit, setMediaToEdit] = useState<
        { id: string; videoUrl: string | undefined; thumbnail: string | undefined } | undefined
    >(undefined);

    useEffect(() => {
        try {
            ScreenOrientation.unlock();
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if (mediaDetailsDiapo && mediaDetailsDiapo.length > 0) {
            setMediaDetails(mediaDetailsDiapo[0]);
            setDisplayMediaDetails(true);
        }
    }, [mediaDetailsDiapo]);

    const [timeOut, setTimeOut] = useState<any>();
    useEffect(() => {
        if (mediaDetailsDiapo && mediaDetailsDiapo.length > 0) {
            if (timeOut) clearTimeout(timeOut);
            if (mediaDetails.videoUrl === undefined) {
                const tempTimeOut = setTimeout(() => {
                    const index = mediaDetailsDiapo.findIndex((item) => item === mediaDetails);
                    if (index !== -1 && index < mediaDetailsDiapo.length - 1) {
                        setMediaDetails(mediaDetailsDiapo[index + 1]);
                    } else {
                        setMediaDetails(null);
                        setDisplayMediaDetails(false);
                        setMediaDetailsDiapo([]);
                    }
                }, 5000);
                setTimeOut(tempTimeOut);
            }
        }
    }, [mediaDetails]);

    const refresh = (): void => {
        setup();
    };

    useEffect(() => {
        refresh();
    }, [userDataProp.token]);

    const ionRouter = useIonRouter();
    document.addEventListener('ionBackButton', (ev: Event) => {
        const customEvent = ev as CustomEvent;
        customEvent.detail.register(-1, () => {
            if (!ionRouter.canGoBack()) {
                App.exitApp();
            }
        });
    });

    useEffect(() => {
        // if (user && user.role !== Roles.SENIOR) {
        //     history.push('/games');
        // }
        const func = async (): Promise<void> => {
            if (user && user.id) {
                const tempSeniorData = await getPatientUser(user.id);
                if (tempSeniorData) {
                    setSeniorData(tempSeniorData);
                }
            }
        };
        func();
        const checkToDisplayEndTestPeriodModal = async (): Promise<void> => {
            let tempUserSenior: Patient | null = null;
            if (user && user.id && user.role === Roles.SENIOR) {
                tempUserSenior = await getPatientUser(user.id);
            } else if (user && user.role === Roles.FAMILY) {
                // get senior by familly code
                tempUserSenior = await getPatientFromCode(user.familyCode ?? '');
            }
            setSeniorId(tempUserSenior?.id ?? '');
            if (
                tempUserSenior &&
                tempUserSenior.endDate &&
                (tempUserSenior.establishmentCode === '94NANKuYqPHzYP5jbo9c' ||
                    tempUserSenior.establishmentCode === 'Ml32wYwgOL2zUWYKfFRN')
            ) {
                // const endTestPeriod = new Date(tempUserSenior.endDate);
                const today = new Date();
                // timestamp to date
                if (today >= new Date(tempUserSenior.endDate.seconds * 1000)) {
                    setDisplayEndTestPeriodModal(true);
                }
            }
        };
        checkToDisplayEndTestPeriodModal();
    }, [user]);

    useEffect(() => {
        if (
            seniorData &&
            seniorData.statistics &&
            seniorData.statistics.length > 0 &&
            dataListForHome.length > 0
        ) {
            let tempCount = 6;
            seniorData?.statistics?.forEach((statistic) => {
                dataListForHome.forEach((itemData) => {
                    if (
                        statistic.exerciseId === itemData.exercise?.id &&
                        statistic.details &&
                        statistic.details.length > 0
                    ) {
                        tempCount -= 1;
                    }
                });
            });
            setCountNewGames(tempCount);
        }
    }, [dataListForHome, seniorData]);

    const getRandomIndex = (noOfItems: number): number => {
        const randomIndex = Math.floor(Math.random() * noOfItems);
        return randomIndex;
    };

    useEffect(() => {
        const ionPage = document.getElementsByClassName('login-page-add-style');
        if (ionPage && ionPage.length > 0) {
            if (ionPage[0] !== undefined) ionPage[0].classList.remove('login-page-add-style');
            if (ionPage[1] !== undefined) ionPage[1].classList.remove('login-page-add-style');
        }
    }, [user]);

    const getExerciseListToShow = async (): Promise<void> => {
        if (user && (user.establishmentCode || (user && user.role === Roles.ADMIN))) {
            const dataList: PatientHomeData[] = [];
            const adminList: string[] = [];
            let countQuiz = 0;
            let countMemoriz = 0;
            let countPuzzle = 0;
            let countSudoku = 0;
            let countOrder = 0;
            let countPong = 0;
            let countTourbillon = 0;
            let countMeliMots = 0;
            // const adminList: string[] = [];
            await Promise.all(
                exerciseList.map(async (exercise) => {
                    if (exercise.exerciseImages && exercise.exerciseImages[0]) {
                        if (
                            exercise.exerciseType === ExerciseType.QUIZ ||
                            exercise.exerciseType === ExerciseType.MEMORIZ
                        ) {
                            const index = getRandomIndex(exercise.exerciseImages.length);
                            const item = await getExerciseItem(
                                exercise.exerciseImages[index] ?? exercise.exerciseImages[0]
                            );
                            if (
                                exercise &&
                                item &&
                                typeof item.exerciseImage === 'string' &&
                                index >= 0
                            ) {
                                if (exercise.exerciseType === ExerciseType.QUIZ) countQuiz++;
                                if (exercise.exerciseType === ExerciseType.MEMORIZ) countMemoriz++;
                                dataList.push({
                                    exercise,
                                    exerciseImage: item.exerciseImage,
                                    random: index
                                });
                            }
                        }
                        if (
                            exercise.exerciseType === ExerciseType.PUZZLE ||
                            exercise.exerciseType === ExerciseType.SUDOKU ||
                            exercise.exerciseType === ExerciseType.PONG ||
                            exercise.exerciseType === ExerciseType.ORDER ||
                            exercise.exerciseType === ExerciseType.TOURBILLON ||
                            exercise.exerciseType === ExerciseType.MELIMOTS
                        ) {
                            const item = await getExerciseItem(exercise.exerciseImages[0]);
                            if (exercise && item && typeof item.exerciseImage === 'string') {
                                if (exercise.exerciseType === ExerciseType.PUZZLE) countPuzzle++;
                                if (exercise.exerciseType === ExerciseType.SUDOKU) countSudoku++;
                                if (exercise.exerciseType === ExerciseType.ORDER) countOrder++;
                                if (exercise.exerciseType === ExerciseType.PONG) countPong++;
                                if (exercise.exerciseType === ExerciseType.TOURBILLON) countTourbillon++;
                                if (exercise.exerciseType === ExerciseType.MELIMOTS) countMeliMots++;
                                dataList.push({
                                    exercise,
                                    exerciseImage: item.exerciseImage
                                });
                            }
                        }
                        if (
                            exercise.authorType === Roles.ADMIN &&
                            exercise.authorId &&
                            !adminList.includes(exercise.authorId)
                        ) {
                            adminList.push(exercise.authorId);
                        }
                    }
                })
            );
            setCountExerciceType({
                allGames: dataList.length,
                [ExerciseType.QUIZ]: countQuiz,
                [ExerciseType.MEMORIZ]: countMemoriz,
                [ExerciseType.PUZZLE]: countPuzzle,
                [ExerciseType.SUDOKU]: countSudoku,
                [ExerciseType.ORDER]: countOrder,
                [ExerciseType.PONG]: countPong,
                [ExerciseType.TOURBILLON]: countTourbillon,
                [ExerciseType.MELIMOTS]: countMeliMots
            });
            dataList.sort((x: PatientHomeData, y: PatientHomeData): number => {
                if (x.exercise && x.exercise.createdDate && y.exercise && y.exercise.createdDate) {
                    const date1 = new Date(x.exercise.createdDate.toDate()).getTime();
                    const date2 = new Date(y.exercise.createdDate.toDate()).getTime();

                    return date2 - date1;
                }
                return 0;
            });
            // dataList.sort((x: PatientHomeData, y: PatientHomeData): number => {
            //     if (
            //         x.exercise.authorType === Roles.ADMIN &&
            //         y.exercise.authorType !== Roles.ADMIN
            //     ) {
            //         return 1;
            //     }
            //     if (
            //         x.exercise.authorType !== Roles.ADMIN &&
            //         y.exercise.authorType === Roles.ADMIN
            //     ) {
            //         return -1;
            //     }
            //     return 0;
            // });

            // let tempDataListForHome: PatientHomeData[] = [];
            const fetchDataArray = await Promise.all(
                dataList.map(async (item): Promise<PatientHomeData | undefined> => {
                    const tempData = item;
                    if (
                        tempData &&
                        tempData.exercise.authorId
                        // tempData.exercise.authorType !== Roles.ADMIN
                    ) {
                        // console.log('call db');
                        const tempUser = await getUserById(tempData.exercise.authorId);
                        if (tempUser) {
                            tempData.author = tempUser;
                            // if (
                            //     // (user.role === Roles.PRO || user.role === Roles.FAMILY) &&
                            //     // tempUser.userId === user.userId &&
                            //     tempDataListForHome.length < 6
                            // ) {
                            //     // tempDataListForHome.push(tempData);
                            // }
                            return tempData;
                            // else if (user.role === Roles.SENIOR) {
                            //     tempDataListForHome.push(tempData);
                            // }
                        }
                    }
                    // return;
                })
            );
            // delete all undefined into tempDataListForHome
            let tempDataListForHome: PatientHomeData[] = fetchDataArray.filter(
                (item): item is PatientHomeData => item !== undefined
            );
            tempDataListForHome.sort((x: PatientHomeData, y: PatientHomeData): number => {
                if (x.exercise && x.exercise.createdDate && y.exercise && y.exercise.createdDate) {
                    const date1 = new Date(x.exercise.createdDate.toDate()).getTime();
                    const date2 = new Date(y.exercise.createdDate.toDate()).getTime();

                    return date2 - date1;
                }
                return 0;
            });
            //  tempDataListForHome keep only 6 items
            tempDataListForHome = tempDataListForHome.slice(0, 6);
            setExerciseListToShow(dataList);
            setDataListForHome(tempDataListForHome);
            setDisplayLoadingNews(false);
            setAdminListId(adminList);
        }
    };
    const [setupAlreadyDone, setSetupAlreadyDone] = useState<boolean>(false);
    const [displayCongratulationsModal, setDisplayCongratulationsModal] = useState<boolean>(false);
    const [gameSaved, setGameSaved] = useState<Exercise>();
    const [displayProfile, setDisplayProfile] = useState<boolean>(
        window.location.pathname === '/profile'
    );

    useEffect(() => {
        if (window.location.pathname === '/profile') {
            setDisplayProfile(true);
            setStep(0);
        } else {
            setDisplayProfile(false);
            setStep(-1);
            switch (window.location.pathname) {
                case '/home':
                case '/news':
                    setPage('forMe');
                    break;
                case '/games':
                    setPage('games');
                    break;
                case '/album':
                    setPage('album');
                    break;
                default:
                    break;
            }
        }
    }, [window.location.pathname]);

    const [nbExerciseListChanged, setNbExerciseListChanged] = useState<number>(0);
    useEffect(() => {
        if (exerciseList.length > 0) {
            if (nbExerciseListChanged !== 1) getExerciseListToShow();
            setNbExerciseListChanged(nbExerciseListChanged + 1);
        }
    }, [exerciseList]);

    // let ppByUserId : [{[userId: string]: string}] = [{any: ''}];

    const addAuthorPP = async (tempArray: any): Promise<void> => {
        tempArray.forEach(async (itemData: any) => {
            if (itemData.authorType !== Roles.ADMIN) {
                // if (ppByUserId && ppByUserId.map((item) => Object.keys(item)[0]).includes(itemData.authorId)) {
                //     console.log(ppByUserId[itemData.authorId]);
                //     itemData.authorPP = ppByUserId[itemData.authorId];
                // }
                if (itemData.authorId) {
                    // console.log('test');
                    const tempUser = await getUser(itemData.authorId);
                    // console.log('tempUser',tempUser);
                    if (
                        tempUser &&
                        tempUser.profilePhoto &&
                        tempUser.profilePhoto !== '' &&
                        typeof tempUser.profilePhoto === 'string'
                    ) {
                        // ppByUserId.push({[itemData.authorId]: tempUser.profilePhoto});
                        // console.log(tempUser.profilePhoto);
                        itemData.authorPP = tempUser.profilePhoto;
                        itemData.authorType = tempUser.role;
                    } else if (tempUser) {
                        itemData.authorFirstName = tempUser.firstName;
                        itemData.authorLastName = tempUser.lastName;
                    }
                }
            }
            if (itemData.authorType === Roles.ADMIN) {
                itemData.authorPP = albertAvatar;
            }
        });
    };

    const setup = async () => {
        if (user && user.id) {
            if (user.role !== Roles.SENIOR && window.location.pathname === '/home')
                history.push('/games');
            if (user?.needViewOnboarding !== false && user?.firstName && user?.lastName) {
                setOnboarding(true);
            }

            setLastAdminListId(adminListId);
            setSetupAlreadyDone(true);

            if (user.role === Roles.ADMIN) {
                const ExerciseItem = await getExercises();
                setExerciseList(ExerciseItem.filter((item) => item.authorType === Roles.ADMIN));
            } else if (user.role !== Roles.PRO) {
                const ExerciseItem = await getPatientExerciseList(user);
                setExerciseList(ExerciseItem);
            } else {
                const exercisesData = await getExercises();
                const userIdFirebase = user.userId;
                let filteredExercises = exercisesData.filter(
                    (item) =>
                        (item.establishmentCode === user?.establishmentCode &&
                            item.authorType === user?.role &&
                            ((item?.createdDate &&
                                item.createdDate <
                                Timestamp.fromDate(new Date('2023-11-08 23:42:00'))) ||
                                (item?.createdDate &&
                                    item.createdDate >=
                                    Timestamp.fromDate(new Date('2023-11-08 23:42:00')) &&
                                    item.proList &&
                                    item.proList
                                        .map((pro) => pro && Object.keys(pro)[0])
                                        .includes(userIdFirebase)))) ||
                        item.authorId === user.id || item.authorId === userIdFirebase ||
                        (item.authorType === Roles.ADMIN &&
                            item.establishmentList &&
                            item.establishmentList.includes(user?.establishmentCode))
                );
                if (filteredExercises) {
                    setExerciseList(filteredExercises);
                }
            }
            // const temp = await getPatientVideoItem(user, ExerciseItem, adminListId);

            if (user && user.role === Roles.ADMIN) {
                const temp = await getAdminPictureVideoItem(user);
                if (temp) {
                    await addAuthorPP(temp);
                }
                setMediaList(temp);
            } else if (user && user.role === Roles.PRO) {
                const temp = await getProPictureVideoItem(user);
                if (temp) {
                    await addAuthorPP(temp);
                }
                setMediaList(temp);
            } else {
                const temp = await getPatientVideoItem(user);
                if (temp) {
                    await addAuthorPP(temp);
                }
                setMediaList(temp);
                // console.log('set media list patient');
            }
        }
    };

    useEffect(() => {
        if (!setupAlreadyDone || lastAdminListId[0] !== adminListId[0]) setup();
    }, [user, adminListId]);

    const gameAsCreated = (newGame: Exercise): void => {
        setGameSaved(newGame);
        // setStepCreateGames(-1);
        setStep(-1);
        setSetupAlreadyDone(false);
        setup();
        setDisplayCongratulationsModal(true);
    };

    const quizChoixByDificulty = [3, 4, 4, 4, 6, 4, 5, 5, 6, 6];
    const memorizCartesByDificulty = [4, 6, 8, 10, 12, 16, 20, 24, 30, 36];
    const puzzlePiecesByDificulty = [4, 6, 9, 12, 16, 20, 25, 50, 100, 150];
    const sudokuFindByDificulty = [10, 15, 20, 25, 30, 35, 45, 55, 65, 70];
    const pongFindByDificulty = [5, 7, 10, 15, 20, 20, 25, 25, 30, 30];
    const reorderFindByDifficulty = [2, 2, 3, 4, 5, 5, 5, 5, 5, 5];
    const [openEncouragement, setOpenEncouragement] = useState<boolean>(false);
    const [alreadyOpenEncouragement, setAlreadyOpenEncouragement] = useState<boolean>(false);
    const [encouragementExercise, setEncouragementExercise] = useState<PatientHomeData>();
    // const selectGames = async (itemData: PatientHomeData) => {
    //     switch (itemData.exercise.exerciseType) {
    //         case ExerciseType.MEMORIZ:
    //             history.push({
    //                 pathname: `/memoriz/${itemData.exercise.id}`,
    //                 state: { exercises: itemData.exercise, random: itemData.random }
    //             });
    //             break;
    //         case ExerciseType.PONG:
    //             history.push({
    //                 pathname: `/pong`,
    //                 state: { exercises: itemData.exercise }
    //             });
    //             break;
    //         case ExerciseType.PUZZLE:
    //             localStorage.setItem('reload', 'true');
    //             history.push({
    //                 pathname: `/puzzle`,
    //                 state: { exercises: itemData.exercise }
    //             });
    //             break;
    //         case ExerciseType.QUIZ:
    //             history.push({
    //                 pathname: `/quiz/${itemData.exercise.id}`,
    //                 state: { exercises: itemData.exercise, random: itemData.random }
    //             });
    //             break;
    //         case ExerciseType.ORDER:
    //             history.push({
    //                 pathname: `/reordon`,
    //                 state: { exercises: itemData.exercise }
    //             });
    //             break;
    //         case ExerciseType.SUDOKU:
    //             history.push({
    //                 pathname: `/sudok`,
    //                 state: { exercises: itemData.exercise }
    //             });
    //             break;
    //         default:
    //             break;
    //     }
    // };

    const deleteItemFromMediaList = (item: any) => {
        const newList = mediaList.filter((media) => media !== item);
        setMediaList(newList);
    };
    const deleteExerciseFromList = (item: PatientHomeData) => {
        const newList = exerciseListToShow.filter((exercise) => exercise !== item);
        setExerciseListToShow(newList);
    };

    const editMedia = (
        id: string,
        type: 'picture' | 'personal-video' | 'youtube-video',
        videoUrl: string | undefined,
        thumbnail: string | undefined
    ) => {
        setStep(1);
        setImportMediaType(type);
        setMediaToEdit({ id, videoUrl, thumbnail });
        setDisplayCreateImportMenu(true);
        setDisplayImportMedia(true);
    };

    const editGames = (itemData: PatientHomeData) => {
        setStep(1);
        setGameToEdit(itemData);
        setDisplayCreateImportMenu(true);
        setDisplayCreateGames(true);
    };

    const gridRef = useRef<VariableSizeGrid<any> | null>(null);
    const [autoSizeKey, setAutoSizeKey] = useState<number>(0);

    const onResize = () => {
        setAutoSizeKey(autoSizeKey + 1);
        // if (gridRef && gridRef.current) {
        //     console.log('onResize');
        //     gridRef.current.resetAfterIndices({
        //         columnIndex: 0,
        //         rowIndex: 0,
        //         shouldForceUpdate: true
        //     });
        // }
    };

    // useEffect(() => {
    //     window.addEventListener('resize', onResize);

    //     return () => {
    //         window.removeEventListener('resize', onResize);
    //     };
    // }, []);
    useEffect(() => {
        onResize();
    }, [width, height]);

    return (
        <>
            {/* <LoginButton />
            {window.location.origin}
            <LoginInfo /> */}
            {onboarding && (
                <Onboarding
                    setOnboarding={(val: boolean) => {
                        setOnboarding(val);
                        userDataProp.token && updateNeedViewOnboarding(userDataProp.token, false);
                    }}
                    runImport={() => {
                        setDisplayCreateImportMenu(true);
                        setStepTitle(t('Iwant') ?? '');
                        setStep(0);
                    }}
                />
            )}
            <Header refresh={(): void => refresh()} />
            <PopupEndTestPeriod
                isOpen={displayEndTestPeriodModal}
                seniorId={seniorId}
                closeModal={(): void => setDisplayEndTestPeriodModal(false)}
                height="80%"
            />
            {/* {encouragementExercise && (
                <TryExerciseAlerts
                    isModalOpen={openEncouragement}
                    closeModal={(): void => setOpenEncouragement(false)}
                    exerciseHomeData={encouragementExercise}
                    onExerciseSelect={selectGames}
                />
            )} */}
            {/* <LvlChoiseModal
                isModalOpen={openLvlModal}
                closeModal={(): void => {
                    setOpenLvlModal(false);
                    setAlreadyOpenLvlModal(false);
                }}
                exerciseType={dataExercise?.exercise.exerciseType}
                callback={async (): Promise<void> => {
                    // setOpenLvlModal(false);
                    if (dataExercise) {
                        await selectGames(dataExercise);
                        setOpenLvlModal(false);
                        setAlreadyOpenLvlModal(false);
                    }
                }}
                lvlArray={lvlArray}
                userId={user?.id}
                exoDifficultyLevel={{
                    memoryDifficultyLevel: seniorData?.memoryDifficultyLevel ?? 1,
                    puzzleDifficultyLevel: seniorData?.puzzleDifficultyLevel ?? 1,
                    memorinDifficultyLevel: seniorData?.memorinDifficultyLevel ?? 1,
                    sudokuDifficultyLevel: seniorData?.sudokuDifficultyLevel ?? 1,
                    pongDifficultyLevel: seniorData?.pongDifficultyLevel ?? 1,
                    reorderDifficultyLevel: seniorData?.reorderDifficultyLevel ?? 1
                }}
            /> */}
            {page === 'forMe' && (
                <div
                    style={{
                        ...classes['home-root'],
                        ...(width && width > 768
                            ? { padding: '0.5rem 7rem 1rem' }
                            : width && width > 554
                                ? { padding: '0.5rem 1.5rem 1rem' }
                                : { padding: '0.5rem .5rem 1rem' })
                    }}>
                    <div style={classes['title-div']}>
                        <Typography variant="h1" sx={{ ...classes['titre'] }}>
                            {t('HelloName', { name: user?.firstName })}
                        </Typography>
                        {!displayLoadingNews && (
                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: dataListForHome.length > 0 ? '1.7rem' : '1.5rem'
                                }}>
                                {/* {t('seeAgain')} */}
                                {/* {t('seeAgainNewEx', {
                                    nb: dataListForHome
                                        .map((e) =>
                                            e.exercise.review?.length === 0 ||
                                            e.exercise.review === undefined
                                                ? 1
                                                : 0
                                        )
                                        .filter((e) => e === 1).length
                                })} */}

                                {user?.role === Roles.SENIOR &&
                                    (countNewGames > 0 && dataListForHome.length > 0
                                        ? t(`seeAgainNewEx${countNewGames > 1 ? 's' : ''}`, {
                                            nb: countNewGames
                                        })
                                        : dataListForHome.length > 0
                                            ? t('seeAgainNewEx0.SENIOR')
                                            : user?.role === Roles.SENIOR && (
                                                <span>
                                                    {t('seeAgainNewNotGames.SENIOR1')}
                                                    <br />
                                                    {t('seeAgainNewNotGames.SENIOR2')}
                                                </span>
                                            ))}
                                {user?.role === Roles.PRO && dataListForHome.length > 0
                                    ? t('seeAgainNewEx.PRO')
                                    : user?.role === Roles.PRO && (
                                        <span>
                                            {t('seeAgainNewNotGames.PRO1')}
                                            <br />
                                            {t('seeAgainNewNotGames.PRO2')}
                                        </span>
                                    )}
                                {user?.role === Roles.FAMILY && dataListForHome.length > 0
                                    ? t('seeAgainNewEx.FAMILY')
                                    : user?.role === Roles.FAMILY && (
                                        <span>
                                            {t('seeAgainNewNotGames.FAMILY1')}
                                            <br />
                                            {t('seeAgainNewNotGames.FAMILY2')}
                                        </span>
                                    )}
                            </Typography>
                        )}
                    </div>
                    <div style={classes['vignette-container']}>
                        {/* {(user?.role === Roles.SENIOR ||
                            user?.role === Roles.PRO ||
                            user?.role === Roles.FAMILY) && */}
                        {dataListForHome.length > 0 ? (
                            dataListForHome.map((exercise) => (
                                <VignetteHome
                                    key={exercise.exercise.id}
                                    itemData={exercise}
                                    // runGames={(): Promise<void> => selectGames(exercise)}
                                    seniorData={seniorData}
                                />
                            ))
                        ) : displayLoadingNews ? (
                            <Loader />
                        ) : (
                            <img src={notNews} alt="notNews" style={{ position: 'relative' }} />
                        )}
                    </div>
                </div>
            )}
            {page === 'games' && (
                <div
                    style={{
                        ...classes['home-root'],
                        ...(width && width > 768
                            ? { padding: '0.5rem 7rem 6rem' }
                            : width && width > 554
                                ? { padding: '0.5rem 1.5rem 6rem' }
                                : { padding: '0.5rem .5rem 6rem' })
                    }}>
                    <div style={classes['title-div']}>
                        <Typography variant="h1" sx={{ ...classes['titre'] }}>
                            {user?.firstName}
                        </Typography>
                        <Typography variant="h1" sx={{ ...classes['titre'] }}>
                            {t('clickToNewGames')}
                        </Typography>
                    </div>
                    <ExerciseTypeSroll
                        countExerciceType={countExerciceType}
                        setExerciseType={(newExType: ExerciseType | 'allGames'): void => {
                            if (displayExerciceType !== newExType && newExType !== 'allGames') {
                                setDisplayExerciceType(newExType);
                            } else {
                                setDisplayExerciceType(null);
                            }
                        }}
                        displayExerciceType={displayExerciceType}
                    />
                    <div style={classes['exercice-container']}>
                        {exerciseListToShow.map((exercise) => (
                            // to add filtre by author
                            // (user?.role === Roles.SENIOR ||
                            //     exercise.exercise.authorId === user?.id) &&
                            <VignetteGames
                                key={exercise.exercise.id}
                                displayExerciceType={displayExerciceType}
                                itemData={exercise}
                                editGames={editGames}
                                // selectGames={selectGames}
                                deleteExerciseCardFromList={deleteExerciseFromList}
                            />
                        ))}
                    </div>
                </div>
            )}
            {page === 'album' && (
                <div
                    style={{
                        ...classes['home-root'],
                        width: '100%',
                        padding: '0.5rem 0px 0px'
                        // ...(width && width > 768
                        //     ? { padding: '0.5rem 7rem 0rem' }
                        //     : width && width > 554
                        //     ? { padding: '0.5rem 1.5rem 6rem' }
                        //     : { padding: '0.5rem .5rem 6rem' })
                    }}>
                    {user?.role === Roles.ADMIN && (
                        <>
                            <ModalSendAllMedia
                                mediaList={mediaList}
                                isOpen={displayModalSendAllMedia}
                                closeModal={() => setDisplayModalSendAllMedia(false)}
                            />
                            <Button
                                pink
                                onClick={(): void => setDisplayModalSendAllMedia(true)}
                                sx={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0px',
                                    zIndex: 99,
                                    width: '5.5rem',
                                    height: '5.5rem',
                                    margin: '0.25rem',
                                    padding: '0.25rem'
                                }}>
                                <img
                                    style={{
                                        height: '5rem',
                                        width: '5rem',
                                        borderRadius: '5%'
                                    }}
                                    src={adminSendAllMedia}
                                    alt="adminSendAllMedia"
                                />
                            </Button>
                        </>
                    )}
                    <div
                        style={{
                            ...classes['media-container'],
                            height: '100%',
                            justifyContent: 'start',
                            padding: '0px'
                        }}>
                        {mediaList && (
                            <AutoSizer key={`sizer${autoSizeKey}`}>
                                {
                                    // @ts-ignore
                                    ({ height, width }) => {
                                        let tempPadding = width > 768 ? 192 : width > 554 ? 48 : 16;
                                        let tempColumnCountGrid =
                                            width > 768 ? 3 : width > 554 ? 2 : 1;
                                        const thisMediaList: any[] = Array(
                                            tempColumnCountGrid + 1
                                        ).fill(0);
                                        mediaList.forEach((thisMedia, index) => {
                                            let thisType;
                                            if (
                                                Object.prototype.hasOwnProperty.call(
                                                    thisMedia,
                                                    'videoUrl'
                                                )
                                            ) {
                                                if (thisMedia.videoUrl.includes('firebase')) {
                                                    thisType = 'personal-video';
                                                } else {
                                                    thisType = 'youtube-video';
                                                }
                                            } else {
                                                thisType = 'picture';
                                            }
                                            if (displayMediaType === null) {
                                                thisMediaList.push(thisMedia);
                                            } else if (displayMediaType === thisType) {
                                                thisMediaList.push(thisMedia);
                                            }
                                        });

                                        let tempColumnWidthGrid = Math.floor(
                                            (width - tempPadding) / tempColumnCountGrid
                                        );
                                        let tempHeightGrid = 600;
                                        let tempRowCountGrid =
                                            thisMediaList.length % tempColumnCountGrid === 0
                                                ? Math.floor(
                                                    thisMediaList.length / tempColumnCountGrid
                                                )
                                                : Math.floor(
                                                    thisMediaList.length / tempColumnCountGrid
                                                ) + 1;
                                        let tempRowHeightGrid = tempColumnWidthGrid + 70;
                                        let tempWidthGrid = width - tempPadding;
                                        return (
                                            <VGrid
                                                ref={gridRef}
                                                columnCount={tempColumnCountGrid}
                                                columnWidth={(index) => tempColumnWidthGrid}
                                                height={height} // Hauteur du conteneur de la grille
                                                rowCount={tempRowCountGrid}
                                                rowHeight={(index) =>
                                                    index === 0
                                                        ? 130 + 55 + 32
                                                        : index === 1 && tempColumnCountGrid === 1
                                                            ? 270
                                                            : tempRowHeightGrid
                                                }
                                                width={width - tempPadding / 2}
                                                className="disable-scroll"
                                                style={{
                                                    marginLeft: tempPadding / 2,
                                                    willChange: 'unset'
                                                    // marginRight: tempPadding / 2
                                                }}>
                                                {({ columnIndex, rowIndex, style }) => {
                                                    if (rowIndex === 0 && columnIndex === 0) {
                                                        return (
                                                            <div
                                                                style={{
                                                                    ...style,
                                                                    width: style.width
                                                                        ? (style.width as number) *
                                                                        tempColumnCountGrid
                                                                        : 0,
                                                                    padding: '0.5rem'
                                                                }}>
                                                                {/* HERE */}
                                                                <div style={classes['title-div']}>
                                                                    <Typography
                                                                        variant="h1"
                                                                        sx={{
                                                                            ...classes['titre']
                                                                        }}>
                                                                        {user?.firstName}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="h1"
                                                                        sx={{
                                                                            ...classes['titre']
                                                                        }}>
                                                                        {t('clickToMedia')}
                                                                    </Typography>
                                                                </div>
                                                                {/* menu de sélection des média */}
                                                                <MediaSelector
                                                                    setMediaType={(
                                                                        newMediaType:
                                                                            | 'picture'
                                                                            | 'personal-video'
                                                                            | 'youtube-video'
                                                                            | null
                                                                    ): void => {
                                                                        if (
                                                                            displayMediaType !==
                                                                            newMediaType
                                                                        ) {
                                                                            setDisplayMediaType(
                                                                                newMediaType
                                                                            );
                                                                        } else {
                                                                            setDisplayMediaType(
                                                                                null
                                                                            );
                                                                        }
                                                                    }}
                                                                    displayMediaType={
                                                                        displayMediaType
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    } else if (rowIndex === 0) {
                                                        return (
                                                            <div
                                                                style={{ ...style, width: '0px' }}
                                                            />
                                                        );
                                                    }
                                                    if (rowIndex === 1 && columnIndex === 0) {
                                                        return (
                                                            <div
                                                                style={{
                                                                    ...style,
                                                                    padding: '0.5rem'
                                                                }}>
                                                                <div
                                                                    onClick={(): void => {
                                                                        if (
                                                                            displayMediaType ===
                                                                            null
                                                                        ) {
                                                                            setMediaDetailsDiapo(
                                                                                mediaList
                                                                            );
                                                                        } else if (
                                                                            displayMediaType ===
                                                                            'picture'
                                                                        )
                                                                            setMediaDetailsDiapo(
                                                                                mediaList.filter(
                                                                                    (item: any) =>
                                                                                        item.videoUrl ===
                                                                                        undefined
                                                                                )
                                                                            );
                                                                        else if (
                                                                            displayMediaType ===
                                                                            'personal-video'
                                                                        )
                                                                            setMediaDetailsDiapo(
                                                                                mediaList.filter(
                                                                                    (item: any) =>
                                                                                        item.videoUrl !==
                                                                                        undefined &&
                                                                                        item.videoUrl.includes(
                                                                                            'firebase'
                                                                                        )
                                                                                )
                                                                            );
                                                                        else if (
                                                                            displayMediaType ===
                                                                            'youtube-video'
                                                                        )
                                                                            setMediaDetailsDiapo(
                                                                                mediaList.filter(
                                                                                    (item: any) =>
                                                                                        item.videoUrl !==
                                                                                        undefined &&
                                                                                        !item.videoUrl.includes(
                                                                                            'firebase'
                                                                                        )
                                                                                )
                                                                            );
                                                                    }}
                                                                    style={{
                                                                        backgroundColor: 'white',
                                                                        color: 'white',
                                                                        padding: '5px',
                                                                        boxShadow:
                                                                            '0px 4px 8px rgba(226, 199, 199, 0.7)',
                                                                        borderRadius: '8px',
                                                                        marginBottom: '1rem',
                                                                        position: 'relative',
                                                                        // height: '100%',
                                                                        // aspectRatio:
                                                                        //     width && width < 554
                                                                        //         ? '2/1'
                                                                        //         : '4/5',
                                                                        height: 'calc(100% - 1rem)',
                                                                        // height: 'fit-content',
                                                                        cursor: 'pointer'
                                                                        // ...(width && width < 554
                                                                        //     ? { width: '100%' }
                                                                        //     : width && width < 768
                                                                        //     ? { width: 'calc(100% / 2 - 8px)' }
                                                                        //     : width && width < 1024
                                                                        //     ? { width: 'calc(100% / 3 - 12px)' }
                                                                        //     : { width: 'calc(100% / 3 - 12px)' })
                                                                    }}>
                                                                    <img
                                                                        src={diapo}
                                                                        alt="diapo"
                                                                        style={{
                                                                            position: 'relative',
                                                                            // aspectRatio:
                                                                            //     width && width < 554
                                                                            //         ? '2/1'
                                                                            //         : '4/5',
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            borderRadius: '8px',
                                                                            objectFit: 'cover',
                                                                            marginBottom: '0'
                                                                        }}
                                                                    />
                                                                    <Typography
                                                                        variant="p"
                                                                        sx={{
                                                                            color: 'white',
                                                                            position: 'absolute',
                                                                            top:
                                                                                width &&
                                                                                    height &&
                                                                                    width < height
                                                                                    ? '50%'
                                                                                    : '8px',
                                                                            left:
                                                                                width &&
                                                                                    height &&
                                                                                    width < height
                                                                                    ? 'calc(100% - 8px)'
                                                                                    : '50%',
                                                                            transform:
                                                                                width &&
                                                                                    height &&
                                                                                    width < height
                                                                                    ? 'translate(-100%, -50%)'
                                                                                    : 'translate(-50%, 0%)',
                                                                            width:
                                                                                width &&
                                                                                    height &&
                                                                                    (width < height ||
                                                                                        width > 1000)
                                                                                    ? '9rem'
                                                                                    : '8rem',
                                                                            lineHeight:
                                                                                width &&
                                                                                    height &&
                                                                                    (width < height ||
                                                                                        width > 1000)
                                                                                    ? '1.7rem'
                                                                                    : '1.3rem',
                                                                            height: 'fit-content',
                                                                            display: 'flex',
                                                                            justifyContent:
                                                                                'center',
                                                                            alignItems: 'center',
                                                                            fontSize:
                                                                                width &&
                                                                                    height &&
                                                                                    (width < height ||
                                                                                        width > 1000)
                                                                                    ? '1.5rem'
                                                                                    : '1.1rem',
                                                                            fontWeight: '600'
                                                                        }}>
                                                                        {t('albumRun1')}
                                                                        <br />
                                                                        {t('albumRun2')}
                                                                        <br />
                                                                        {t('albumRun3')}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    const index =
                                                        rowIndex * tempColumnCountGrid +
                                                        columnIndex;
                                                    if (index >= thisMediaList.length) return null;
                                                    const item = thisMediaList[index];
                                                    return (
                                                        <div
                                                            style={{ ...style, padding: '0.5rem' }}>
                                                            <VignetteAlbum
                                                                key={
                                                                    item.id
                                                                        ? `${item.id}-${index}`
                                                                        : `${item.videoUrl}-${index}`
                                                                }
                                                                displayAlbumType={displayMediaType}
                                                                itemData={item}
                                                                deleteItem={deleteItemFromMediaList}
                                                                editMedia={editMedia}
                                                                onClick={(): void => {
                                                                    setMediaDetails(item);
                                                                    setDisplayMediaDetails(true);
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }}
                                            </VGrid>
                                        );
                                    }
                                }
                            </AutoSizer>
                        )}
                    </div>
                </div>
            )}
            <div
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    display: displayCreateImportMenu || displayProfile ? 'block' : 'none'
                }}
                onClick={(): void => {
                    setDisplayProfile(false);
                    setStep(-1);
                    history.push(page === 'forMe' ? '/home' : page);
                    setDisplayCreateImportMenu(false);
                    setDisplayCreateGames(false);
                    setDisplayImportMedia(false);
                    setGameToEdit(undefined);
                    setMediaToEdit(undefined);
                    setStep(-1);
                    setStepTitle('');
                }}
            />
            <div
                style={{
                    ...classes['profile-page'],
                    transition:
                        height && width && height > width
                            ? 'opacity 0.3s ease-in-out, top 0.3s ease-in-out'
                            : 'opacity 0.3s ease-in-out, left 0.3s ease-in-out',
                    top: displayProfile ? 0 : '100%',
                    left: displayProfile ? (height && width && height > width ? 0 : '55%') : '100%',
                    width: height && width && height > width ? '100%' : '45%'
                }}>
                <CreateImportHeader
                    step={step}
                    stepMax={0}
                    close={(): void => {
                        setDisplayProfile(false);
                        setStep(-1);
                        history.push('/home');
                    }}
                    prev={(): void => {
                        if (step > 1) {
                            setStep(step - 1);
                        } else if (step === 1) {
                            setStep(0);
                        } else {
                            setDisplayProfile(false);
                            setStep(-1);
                            history.push('/home');
                        }
                    }}
                    title={stepTitle}
                />
                {displayProfile && (
                    <Profile
                        step={step}
                        setStep={setStep}
                        setStepTitle={setStepTitle}
                        displayOnboarding={() => setOnboarding(true)}
                    />
                )}
            </div>
            <div
                style={{
                    ...classes['create-game-page'],
                    transition:
                        height && width && height > width
                            ? 'opacity 0.3s ease-in-out, top 0.3s ease-in-out'
                            : 'opacity 0.3s ease-in-out, left 0.3s ease-in-out',
                    top: displayCreateImportMenu ? 0 : '100%',
                    left: displayCreateImportMenu
                        ? height && width && height > width
                            ? 0
                            : '55%'
                        : '100%',
                    width: height && width && height > width ? '100%' : '45%'
                }}>
                <CreateImportHeader
                    step={displayCreateGames ? step - 1 : step}
                    stepMax={stepMax}
                    close={(): void => {
                        setDisplayCreateImportMenu(false);
                        setDisplayCreateGames(false);
                        setGameToEdit(undefined);
                        setDisplayImportMedia(false);
                        setMediaToEdit(undefined);
                        setStep(-1);
                        setStepMax(0);
                    }}
                    prev={(): void => {
                        // setStepCreateGames(stepCreateGames - 1);
                        if (step > 1) {
                            setStep(step - 1);
                        } else if (step === 1) {
                            setDisplayCreateImportMenu(true);
                            setDisplayCreateGames(false);
                            setGameToEdit(undefined);
                            setMediaToEdit(undefined);
                            setDisplayImportMedia(false);
                            setStep(0);
                        } else {
                            setDisplayCreateImportMenu(false);
                            setDisplayCreateGames(false);
                            setGameToEdit(undefined);
                            setDisplayImportMedia(false);
                            setStep(-1);
                            setStepMax(0);
                        }
                    }}
                    title={stepTitle}
                />
                {displayCreateImportMenu && !displayCreateGames && !displayImportMedia && (
                    <ChoiseCreateImport
                        onSelectGames={() => {
                            setStep(1);
                            setDisplayCreateGames(true);
                            setImportMediaType(null);
                        }}
                        onSelectPicture={() => {
                            setStep(1);
                            setDisplayImportMedia(true);
                            setImportMediaType('picture');
                        }}
                        onSelectVideo={() => {
                            setStep(1);
                            setDisplayImportMedia(true);
                            setImportMediaType('personal-video');
                        }}
                        onSelectVideoYT={() => {
                            setStep(1);
                            setDisplayImportMedia(true);
                            setImportMediaType('youtube-video');
                        }}
                    />
                )}
                {displayCreateGames && (
                    <CreateGames
                        step={step - 1}
                        gameToEdit={gameToEdit}
                        setStep={(step: number): void => setStep(step + 1)}
                        setStepMax={(stepMax: number): void => setStepMax(stepMax)}
                        gameAsCreated={gameAsCreated}
                        setTitle={(title: string): void => setStepTitle(title)}
                        close={(): void => {
                            setDisplayCreateImportMenu(false);
                            setDisplayCreateGames(false);
                            setGameToEdit(undefined);
                            // setMediaToEdit('');
                            setDisplayImportMedia(false);
                            setStep(-1);
                            setStepMax(0);
                        }}
                    />
                )}
                {displayImportMedia && (
                    <ImportMedia
                        type={importMediaType ?? 'picture'}
                        step={step - 1}
                        mediaToEdit={mediaToEdit}
                        setMediaToEdit={(
                            value:
                                | {
                                    id: string;
                                    videoUrl: string | undefined;
                                    thumbnail: string | undefined;
                                }
                                | undefined
                        ): void => setMediaToEdit(value)}
                        setStep={(step: number): void => setStep(step + 1)}
                        setStepMax={(stepMax: number): void => setStepMax(stepMax)}
                        // gameAsCreated={gameAsCreated}
                        setTitle={(title: string): void => setStepTitle(title)}
                        close={(): void => {
                            setDisplayCreateImportMenu(false);
                            setDisplayCreateGames(false);
                            setDisplayImportMedia(false);
                            setMediaToEdit(undefined);
                            setStep(-1);
                            setStepMax(0);
                        }}
                        updateListPicture={refresh}
                    />
                )}
            </div>
            <Footer
                setPage={(page: string) => {
                    history.push(`/${page === 'forMe' ? 'news' : page}`);
                }}
                page={page}
                displayImport={(): void => {
                    if (page === 'forMe') {
                        // setStepCreateGames(0);
                        let tempLastItem: PatientHomeData = dataListForHome[0];
                        dataListForHome.forEach((item) => {
                            if (
                                item &&
                                    item.exercise.patientList &&
                                    item.exercise.patientList.length <= 1 &&
                                    ((item.exercise.review && item.exercise.review.length === 0) ||
                                        item.exercise.review === undefined)
                                    ? true
                                    : false
                            ) {
                                tempLastItem = item;
                            }
                        });
                        history.push(getUrlToGamePage(tempLastItem))
                    } else if (page === 'games') {
                        setDisplayCreateImportMenu(true);
                        setStep(1);
                        setDisplayCreateGames(true);
                        setImportMediaType(null);
                        // setStep(0);
                    } else if (page === 'album') {
                        setDisplayCreateImportMenu(true);
                        setStepTitle(t('Iwant') ?? '');
                        setStep(0);
                    }
                }}
            />
            {displayCongratulationsModal && (
                <CongratulationsModal
                    close={() => setDisplayCongratulationsModal(false)}
                    user={user ?? null}
                    game={gameSaved}
                />
            )}
            {/* <Button
                onClick={(): void => {
                    loadAllVideoPersonal();
                }}>
                set image video perso
            </Button> */}

            <div
                style={{
                    ...classes['create-game-page'],
                    transition: 'opacity 0.3s ease-in-out, top 0.3s ease-in-out',
                    top: displayMediaDetails ? 0 : '100%',
                    left: '0',
                    width: '100%'
                }}>
                {displayMediaDetails && mediaDetails && (
                    <MediaDetails
                        deleteItem={deleteItemFromMediaList}
                        element={mediaDetails}
                        close={(): void => {
                            setDisplayMediaDetails(false);
                            setMediaDetails(null);
                            setMediaDetailsDiapo([]);
                        }}
                        viewMode={
                            mediaDetailsDiapo && mediaDetailsDiapo.length > 0 ? 'diapo' : 'single'
                        }
                        next={(): void => {
                            if (mediaDetailsDiapo) {
                                const index = mediaDetailsDiapo.findIndex(
                                    (item) => item === mediaDetails
                                );
                                if (index !== -1 && index < mediaDetailsDiapo.length - 1) {
                                    setMediaDetails(mediaDetailsDiapo[index + 1]);
                                } else {
                                    setMediaDetails(null);
                                    setDisplayMediaDetails(false);
                                    setMediaDetailsDiapo([]);
                                }
                            }
                        }}
                        previous={(): void => {
                            if (mediaDetailsDiapo) {
                                const index = mediaDetailsDiapo.findIndex(
                                    (item) => item === mediaDetails
                                );
                                if (index !== -1 && index > 0) {
                                    setMediaDetails(mediaDetailsDiapo[index - 1]);
                                } else {
                                    setMediaDetails(null);
                                    setMediaDetailsDiapo([]);
                                    setDisplayMediaDetails(false);
                                }
                            }
                        }}
                    />
                )}
            </div>
        </>
    );
}

import {
    AppTrackingTransparency,
    AppTrackingStatusResponse
} from 'capacitor-plugin-app-tracking-transparency';
import { use } from 'i18next';
import { Loader } from '../../components/Loader';
import { Timestamp } from 'firebase/firestore';
import { set } from 'lodash';
import { ModalSendAllMedia } from '../../components/ModalSendAllMedia';
import { getUrlToGamePage } from '../../utilities/utils';

export const getStatus = async (): Promise<AppTrackingStatusResponse> => {
    const response = await AppTrackingTransparency.getStatus();

    // console.log(response);
    // { status: 'authorized' } for example

    return response;
};

export const requestPermission = async (): Promise<AppTrackingStatusResponse> => {
    const response = await AppTrackingTransparency.requestPermission();

    // console.log(response);
    // { status: 'authorized' } for example

    return response;
};
