import React, { CSSProperties, useEffect, useState } from 'react';
import { UserDataProp } from '../../../models/userDataProp';
import { useContext } from 'react';
import { UserContext } from '../../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../components/Typography';
import hatRabbit from '../../../assets/hat-rabbit.png';
import memorizIcon from '../../../assets/duo.svg';
import orderIcon from '../../../assets/reordon.svg';
import puzzleIcon from '../../../assets/puzzle.svg';
import quizIcon from '../../../assets/quizz.svg';
import whiteCheck from '../../../assets/whiteCheck.svg';
import arrow from '../../../assets/arrow.svg';
import gift from '../../../assets/gift.png';
import { ExerciseType } from '../../../models/ExerciseType';
import {
    getEstablishmentList,
    getExerciseItem,
    getExerciseItemsByAutor,
    getExerciseItemsForMedia,
    getExerciseItemsForMediaAdmin,
    getFamilyFromCode,
    getFamilyPicturesForMedia,
    getPatientFromCode,
    getPatientFromEstablishment,
    getProFromEstablishment,
    getUserById,
    saveExercise,
    saveExerciseItem
} from '../../../services/cloudFirestore';
import { Roles } from '../../../models/Roles';
import { ExerciseItem } from '../../../models/ExerciseItem';
import useWindowDimensions from '../../../utilities/useWindowDimensions';
import { CheckBox } from '../../../components/CheckBox';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { Exercise } from '../../../models/Exercise';
import { Input } from '../../../components/Input';
import { VideoPlayer } from '../../../components/VideoPlayer';
import { getAdminPictureVideoItem, getPatientVideoItem, getProPictureVideoItem } from '../getMedia';
import { set } from 'lodash';
import { getImageKitUrlFrom, getImageKitThumbnailFromVideo } from '../../../utilities/utils';
import { Patient } from '../../../models/Patient';
import { DisplayUser } from '../../../components/DisplayUser';
import { PatientHomeData } from '../../../models/PatientHomeData';
import { Loader } from '../../../components/Loader';
import { User } from '../../../models/User';
import { ModalInfo } from '../../../components/ModalInfo';
import { Timestamp } from 'firebase/firestore';
import { ShareRulesModal } from '../../../components/ShareRulesModal';
import { Establishment } from '../../../models/Establishment';
import { DisplayEts } from '../../../components/DisplayEts';
import { getFamilyMembers } from '../../../stores/User';

const classes: { [key: string]: CSSProperties } = {
    'root-step': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        paddingTop: '1rem'
    },
    element: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        gap: '1rem',
        padding: '0.5rem',
        borderRadius: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 12px rgba(164, 161, 196, 0.74)'
    },
    'img-element': {
        height: '124px',
        width: '124px',
        borderRadius: '8px'
    },
    'title-element': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        width: 'calc(100% - 124px)'
    },
    h2: {
        color: '#1C0D4B',
        fontWeight: '600'
    },
    h3: {
        color: '#DE358E',
        fontWeight: '500',
        fontSize: '1rem',
        textAlign: 'left',
        justifyContent: 'flex-start'
    },
    h4: {
        color: '#195BBE'
    },
    'list-picture': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // gap: '5px',
        flexWrap: 'wrap',
        width: '100%',
        overflowY: 'auto',
        paddingBottom: '5rem'
    },
    'element-picture': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '3px',
        paddingRight: '3px',
        paddingTop: '3px',
        paddingBottom: '3px',
        position: 'relative',
        cursor: 'pointer'
    },
    'checkbox-picture': {
        position: 'absolute',
        top: '0.5rem',
        left: '0.75rem',
        opacity: '1'
    },
    'button-next': {
        position: 'absolute',
        bottom: '1rem',
        left: '0',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        width: '100%',
        // width: 'calc(100% - 2rem)',
        height: '3rem',
        zIndex: 100
    },
    'game-finish': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem',
        gap: '1rem',
        width: '100%',
        height: '8.75rem',
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: '16px',
        boxShadow: '0px 4px 12px rgba(164, 161, 196, 0.74)'
    },
    'game-finish-picture': {
        height: '100%',
        width: 'auto',
        borderRadius: '8px'
    },
    'game-finish-message': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%'
    },
    'game-finish-title': {
        paddingTop: '1rem',
        textAlign: 'left',
        justifyContent: 'flex-start'
    },
    'game-finish-button': {
        marginBottom: '0.5rem',
        width: 'fit-content',
        padding: '13px 16px',
        backgroundColor: 'var(--color-primary)'
    },
    'give-name': {
        justifyContent: 'flex-start',
        marginTop: '2.5rem',
        fontSize: '1rem',
        width: '100%'
    },
    'input-name': {
        width: '100%',
        marginTop: '1rem'
    },
    details: {
        width: '100%',
        textAlign: 'left',
        marginTop: '2rem',
        fontWeight: '600'
    },
    'details-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '5rem'
    }
};

interface CreateGamesProps {
    step: number;
    setStep: (step: number) => void;
    gameToEdit?: PatientHomeData | undefined;
    setStepMax: (stepMax: number) => void;
    gameAsCreated: (newGame: Exercise) => void;
    setTitle: (title: string) => void;
    close: () => void;
}

export function CreateGames({
    step,
    setStep,
    gameToEdit,
    setStepMax,
    gameAsCreated,
    setTitle,
    close
}: CreateGamesProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { height, width } = useWindowDimensions();
    const [nbPicturePerRow, setNbPicturePerRow] = useState<number>(3);
    const [gameToCreate, setGameToCreate] = useState<
        ExerciseType.PUZZLE | ExerciseType.QUIZ | ExerciseType.MEMORIZ | ExerciseType.ORDER | null
    >(null);
    const listModel: {
        title: string;
        nbStep: number;
        img: string;
        gameType:
        | ExerciseType.PUZZLE
        | ExerciseType.QUIZ
        | ExerciseType.MEMORIZ
        | ExerciseType.ORDER
        | null;
    }[] = [
            // { title: t('makeMeAGame'), nbStep: -1, img: hatRabbit, gameType: null },
            {
                title: t('Quiz'),
                nbStep: user?.role === Roles.PRO ? 7 : 5,
                img: quizIcon,
                gameType: ExerciseType.QUIZ
            },
            {
                title: t('Memoriz'),
                nbStep: user?.role === Roles.PRO ? 6 : 4,
                img: memorizIcon,
                gameType: ExerciseType.MEMORIZ
            },
            {
                title: t('Puzzle'),
                nbStep: user?.role === Roles.PRO ? 6 : 4,
                img: puzzleIcon,
                gameType: ExerciseType.PUZZLE
            },
            {
                title: t('Réordon'),
                nbStep: user?.role === Roles.PRO ? 6 : 4,
                img: orderIcon,
                gameType: ExerciseType.ORDER
            },
        ];
    const nbPicture = {
        [ExerciseType.PUZZLE]: 1,
        [ExerciseType.QUIZ]: 6,
        [ExerciseType.MEMORIZ]: 18,
        [ExerciseType.ORDER]: 1
    };
    const [listPicture, setListPicture] = useState<ExerciseItem[]>([]);
    const [listVideo, setListVideo] = useState<any>(null);
    const [listPictureSelected, setListPictureSelected] = useState<ExerciseItem[]>([]);
    const [encouragementMessage, setEncouragementMessage] = useState<string>('');
    const [congratulationsMessage, setCongratulationsMessage] = useState<string>('');
    const [rewardVideo, setRewardVideo] = useState<any>();
    const [gameName, setGameName] = useState<string>('');
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [displayVideoSelect, setDisplayVideoSelect] = useState<boolean>(false);
    const [tempTitle, setTempTitle] = useState<string[]>([]);
    const [displayErrorTitle, setDisplayErrorTitle] = useState<boolean>(false);
    const [seniorsList, setSeniorsList] = useState<Patient[]>([]);
    const [seniorsListSelected, setSeniorsListSelected] = useState<string[]>([]);
    const [familyList, setFamilyList] = useState<User[]>([]);
    const [familyListSelected, setFamilyListSelected] = useState<string[]>([]);
    const [proList, setProList] = useState<User[]>([]);
    const [etsList, setEtsList] = useState<Establishment[]>([]);
    const [etsListSelected, setEtsListSelected] = useState<string[]>([]);
    const [proListSelected, setProListSelected] = useState<{ [key: string]: 'read' | 'write' }[]>(
        []
    );
    const [right, setRight] = useState<'write' | 'read' | null>(null);
    const [viewShareRules, setViewShareRules] = useState<boolean>(false);

    useEffect(() => {
        const thisStepMax = listModel.find((item) => item.gameType === gameToCreate)?.nbStep;
        if (right === 'read' && thisStepMax) {
            if (step === thisStepMax - 1) {
                close();
            } else {
                setStep(thisStepMax);
            }
        }
        if (
            user?.role === Roles.PRO &&
            thisStepMax &&
            step === thisStepMax - 1 &&
            proListSelected.length === 0
        ) {
            setStep(step - 1);
        }
    }, [right, step]);

    const [dataNewExercise, setDataNewExercise] = useState<Exercise>({
        id: undefined,
        exerciseType: undefined,
        patientList: undefined,
        exerciseName: undefined,
        exerciseImages: undefined,
        encouragementMessage: undefined,
        congratulationsMessage: undefined,
        rewardPhoto: undefined,
        rewardVideo: undefined,
        establishmentList: undefined,
        establishmentCode: user?.role === Roles.PRO ? user.establishmentCode : undefined,
        familyCode: user?.role === Roles.FAMILY ? user.familyCode : undefined,
        theme: undefined
    });
    const [displayLoader, setDisplayLoader] = useState<boolean>(false);
    const [viewModalCantUseMedia, setViewModalCantUseMedia] = useState<boolean>(false);

    useEffect(() => {
        if (
            gameToEdit &&
            listPicture &&
            listPicture.length > 0 &&
            listVideo &&
            // listVideo.length > 0 &&
            ((user?.role === Roles.PRO && seniorsList && seniorsList.length > 0) ||
                user?.role !== Roles.PRO)
        ) {
            setGameToCreate(
                ():
                    | ExerciseType.PUZZLE
                    | ExerciseType.QUIZ
                    | ExerciseType.MEMORIZ
                    | ExerciseType.ORDER
                    | null => {
                    switch (gameToEdit.exercise.exerciseType) {
                        case ExerciseType.PUZZLE:
                            return ExerciseType.PUZZLE;
                        case ExerciseType.QUIZ:
                            return ExerciseType.QUIZ;
                        case ExerciseType.MEMORIZ:
                            return ExerciseType.MEMORIZ;
                        case ExerciseType.ORDER:
                            return ExerciseType.ORDER;
                        default:
                            return null;
                    }
                }
            );
            setStep(step + 1);
            setGameName(gameToEdit.exercise.exerciseName ?? '');
            gameToEdit.exercise.exerciseImages &&
                setListPictureSelected(
                    gameToEdit.exercise.exerciseImages.map((item) => {
                        // const index = listPicture.findIndex((item2) => item2.id === item);
                        return listPicture[listPicture.findIndex((item2) => item2.id === item)];
                    })
                );
            setEncouragementMessage(gameToEdit.exercise.encouragementMessage ?? '');
            setCongratulationsMessage(gameToEdit.exercise.congratulationsMessage ?? '');
            setRewardVideo(
                listVideo[
                listVideo.findIndex(
                    (item: { videoUrl: string }) =>
                        item.videoUrl ===
                        (gameToEdit.exercise.rewardVideo
                            ? gameToEdit.exercise.rewardVideo
                            : gameToEdit.exercise.rewardPhoto)
                )
                ]
            );
            setVideoUrl(gameToEdit.exercise.rewardVideo ?? '');
            setDataNewExercise({
                ...dataNewExercise,
                id: gameToEdit.exercise.id,
                exerciseType: gameToEdit.exercise.exerciseType,
                patientList: gameToEdit.exercise.patientList,
                exerciseName: gameToEdit.exercise.exerciseName,
                exerciseImages: gameToEdit.exercise.exerciseImages,
                encouragementMessage: gameToEdit.exercise.encouragementMessage,
                congratulationsMessage: gameToEdit.exercise.congratulationsMessage,
                rewardPhoto: gameToEdit.exercise.rewardPhoto,
                rewardVideo: gameToEdit.exercise.rewardVideo,
                establishmentList: gameToEdit.exercise.establishmentList,
                establishmentCode: gameToEdit.exercise.establishmentCode,
                familyCode: gameToEdit.exercise.familyCode,
                theme: gameToEdit.exercise.theme,
                createdDate: gameToEdit.exercise.createdDate
            });
            const tempPatientList: string[] = [];
            gameToEdit.exercise.patientList &&
                gameToEdit.exercise.patientList.forEach((item) => {
                    if (item) tempPatientList.push(item);
                });
            const tempProList: { [key: string]: 'read' | 'write' }[] = [];
            gameToEdit.exercise.proList &&
                gameToEdit.exercise.proList.forEach((item) => {
                    if (item) tempProList.push({ [Object.keys(item)[0]]: Object.values(item)[0] });
                });
            if (user?.role === Roles.ADMIN) {
                const tempEtsLst: string[] = [];
                gameToEdit.exercise.establishmentList?.forEach((item) => {
                    if (item) tempEtsLst.push(item);
                })
                setEtsListSelected(tempEtsLst);
            } else if (user?.role === Roles.PRO) {
                setSeniorsListSelected(tempPatientList);
                gameToEdit.exercise.patientList && setSeniorsListSelected(tempPatientList);
                setProListSelected(tempProList);
                gameToEdit.exercise.proList && setProListSelected(tempProList);
                if (user && tempProList) {
                    tempProList.forEach((pro) => {
                        if (pro && Object.keys(pro)[0] === user.id) {
                            setRight(Object.values(pro)[0]);
                        }
                    });
                }
                const index2 = proList.findIndex(
                    (item) => item.id === gameToEdit?.exercise.authorId
                );
                if (index2 !== -1) {
                    proList.splice(index2, 1);
                }
            } else if (user?.role === Roles.FAMILY || user?.role === Roles.SENIOR) {
                setFamilyListSelected(tempPatientList);
                gameToEdit.exercise.patientList && setFamilyListSelected(tempPatientList);
            }

            setDisplayLoader(false);
        } else {
            if (gameToEdit) setDisplayLoader(true);
        }
    }, [gameToEdit, listPicture, listVideo, seniorsList]);

    useEffect(() => {
        setDataNewExercise({
            ...dataNewExercise,
            exerciseType: gameToCreate ? gameToCreate : undefined
        });
    }, [gameToCreate]);

    useEffect(() => {
        if (step !== 2 && displayVideoSelect) {
            setStep(2);
            setDisplayVideoSelect(false);
        }
        switch (step) {
            case 0:
                setListPictureSelected([]);
                setTitle(t('createGames.title-0'));
                setStepMax(0);
                break;
            case 1:
                setTitle(t('createGames.title-1'));
                break;
            case 2:
                setTitle(t('createGames.title-2'));
                if (listPictureSelected.length === 1 && !gameName) {
                    setGameName(listPictureSelected[0].answer ?? '');
                }
                break;
            case 3:
                setTitle(t('createGames.title-3'));
                break;
            case 4:
            case 5:
            case 6:
                setTitle(t('createGames.title-4'));
                break;
            default:
                setTitle(t('createGames.title-0'));
                break;
        }
    }, [step]);

    useEffect(() => {
        if (width) {
            let tempSize = width;
            if (height && height < width) {
                tempSize = (width * 45) / 100;
            }
            setNbPicturePerRow(tempSize < 480 ? 3 : tempSize < 768 ? 4 : 6);
        }
    }, [height, width]);

    useEffect(() => {
        if (user) {
            const getAllPicture = async (): Promise<void> => {
                let idForPicture = '';
                if (user.role === Roles.FAMILY || user.role === Roles.SENIOR) {
                    idForPicture = user.familyCode ?? '';
                } else if (user.role === Roles.PRO) {
                    idForPicture = user.establishmentCode ?? '';
                }
                let response;
                if (user.role === Roles.ADMIN) {
                    response = await getExerciseItemsForMediaAdmin();
                } else if (user.role === Roles.PRO && user.userId && user.id && user.establishmentCode) {
                    response = await getExerciseItemsByAutor(user.userId, user.id, user.establishmentCode);
                    if (gameToEdit) {
                        // add the picture of the game to the list
                        if (gameToEdit.exercise.exerciseImages) {
                            for (const imageId of gameToEdit.exercise.exerciseImages) {
                                // check if image is not already in the list
                                if (response.findIndex((item) => item.id === imageId) === -1) {
                                    const image = await getExerciseItem(imageId ?? '');
                                    if (image) response = [...response, image];
                                }
                            }
                        }
                    }
                } else if (
                    (user.role === Roles.SENIOR || user.role === Roles.FAMILY) &&
                    user.familyCode &&
                    user.id
                ) {
                    response = (
                        await getFamilyPicturesForMedia(
                            user.familyCode,
                            user.id,
                            user?.userId ?? '',
                            user?.establishmentCode ?? ''
                        )
                    ).map((item) => item.data);
                }
                if (response) {
                    const response2 = response.sort((x: ExerciseItem, y: ExerciseItem): number => {
                        if (x.createdDate && y.createdDate) {
                            const date1 = new Date(x.createdDate.toDate()).getTime();
                            const date2 = new Date(y.createdDate.toDate()).getTime();

                            return date2 - date1;
                        }
                        return 0;
                    });
                    setListPicture(response);
                }
            };
            const getAllVideo = async (): Promise<void> => {
                let temp;
                // user.role === Roles.PRO
                //     ? await getProPictureVideoItem(user)
                //     : await getPatientVideoItem(user);
                if (user.role === Roles.ADMIN) {
                    temp = await getAdminPictureVideoItem(user);
                } else if (user.role === Roles.PRO && user.userId && user.id) {
                    temp = await getProPictureVideoItem(user);
                } else if (
                    (user.role === Roles.SENIOR || user.role === Roles.FAMILY) &&
                    user.familyCode &&
                    user.id
                ) {
                    temp = await getPatientVideoItem(user);
                }
                const temp2 = temp
                    .filter((item: { videoUrl: any }) => item.videoUrl)
                    .sort(
                        (
                            x: {
                                authorType: Roles | undefined;
                                createdDate: Timestamp | null | undefined;
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                data: any;
                            },
                            y: {
                                authorType: Roles | undefined;
                                createdDate: Timestamp | null | undefined;
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                data: any;
                            }
                        ): number => {
                            if (x.createdDate && y.createdDate) {
                                const date1 = new Date(x.createdDate.toDate()).getTime();
                                const date2 = new Date(y.createdDate.toDate()).getTime();

                                return date2 - date1;
                            }
                            return 0;
                        }
                    );
                setListVideo(temp2);
            };
            getAllVideo();
            getAllPicture();
        }
        if (user && user.role === Roles.ADMIN) {
            const getEtsList = async (): Promise<void> => {
                const etsList = await getEstablishmentList();
                if (etsList) {
                    setEtsList(etsList);
                }
            };
            getEtsList();
        } else if (user && user.role === Roles.PRO && user.establishmentCode) {
            const getSenior = async (): Promise<void> => {
                const seniors = await getPatientFromEstablishment(user.establishmentCode ?? '');
                if (seniors) {
                    setSeniorsList(seniors);
                }
            };
            const getPro = async (): Promise<void> => {
                const pro = await getProFromEstablishment(user.establishmentCode ?? '');
                if (pro) {
                    //  delete this user
                    const index = pro.findIndex((item) => item.id === user.id || item.id === user.userId);
                    if (index !== -1) {
                        pro.splice(index, 1);
                    }
                    setProList(pro);
                }
            };
            getSenior();
            getPro();
        } else if (
            user &&
            (user.role === Roles.FAMILY || user.role === Roles.SENIOR)
        ) {
            const getFamily = async (): Promise<void> => {
                const family = await getFamilyMembers(userDataProp.token ?? '');
                if (family) {
                    //  delete this user
                    const index = family.findIndex((item) => item.id === user.id);
                    if (index !== -1) {
                        family.splice(index, 1);
                    }
                    setFamilyList(family);
                }
            };
            getFamily();
        }
    }, [user]);

    useEffect(() => {
        if (listPictureSelected.length > 0) {
            setTempTitle(
                listPictureSelected.map((item) => {
                    return item.answer ?? '';
                })
            );
        }
    }, [listPictureSelected]);

    const saveGame = async (): Promise<void> => {
        setDisplayLoader(true);
        const thisUser =
            right === null ? user : await getUserById(gameToEdit?.exercise.authorId ?? '');
        const thisProListSelected = proListSelected;
        if (right === 'write') {
            thisProListSelected.push({ [user?.id ?? '']: 'write' });
        }
        const tempProListSelected: { [key: string]: 'read' | 'write' }[] = [];
        thisProListSelected.forEach((item) => {
            tempProListSelected
                .map((thisItem) => Object.keys(thisItem)[0])
                .indexOf(Object.keys(item)[0]) === -1 && tempProListSelected.push(item);
        });
        if (thisUser) {
            let tempfamilyCode = undefined;
            let tempEstablishmentCode = undefined;
            if (thisUser.role === Roles.FAMILY || thisUser.role === Roles.SENIOR) {
                tempfamilyCode = thisUser.familyCode;
            } else if (thisUser.role === Roles.PRO) {
                tempEstablishmentCode = thisUser.establishmentCode;
            }
            const newExercise: Exercise = {
                ...dataNewExercise,
                exerciseName: gameName,
                encouragementMessage: encouragementMessage,
                congratulationsMessage: congratulationsMessage,
                rewardVideo:
                    rewardVideo &&
                        rewardVideo.videoUrl &&
                        !rewardVideo.videoUrl.includes('firebase')
                        ? rewardVideo.videoUrl
                        : undefined,
                rewardPhoto:
                    rewardVideo && rewardVideo.videoUrl && rewardVideo.videoUrl.includes('firebase')
                        ? rewardVideo.videoUrl
                        : undefined,
                exerciseImages: listPictureSelected.map((item) => item.id),
                patientList:
                    thisUser.role === Roles.PRO
                        ? seniorsListSelected
                        : [...familyListSelected, thisUser.id],
                proList: thisUser.role === Roles.PRO ? tempProListSelected : undefined,
                theme: '',
                establishmentCode: tempEstablishmentCode,
                familyCode: tempfamilyCode,
                createdDate: gameToEdit?.exercise.createdDate ?? undefined,
                authorType:
                    thisUser.role === Roles.PRO
                        ? Roles.PRO
                        : thisUser.role === Roles.SENIOR
                            ? Roles.SENIOR
                            : thisUser.role === Roles.FAMILY
                                ? Roles.FAMILY
                                : Roles.ADMIN,
                establishmentList:
                    thisUser.role === Roles.ADMIN
                        ? etsListSelected
                        : undefined
            };
            await saveExercise(thisUser, newExercise);
            gameAsCreated(newExercise);
            close();
        }
    };

    const knowMore = (): JSX.Element => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                    width: '100%'
                }}
                onClick={() => setViewShareRules(true)}>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '1.8rem',
                        margin: '1rem',
                        width: '1.8rem',
                        borderRadius: '50%',
                        backgroundColor: '#E5007B',
                        color: '#FFFFFF',
                        cursor: 'pointer'
                    }}>
                    i
                </div>
                <div
                    style={{
                        color: '#fff',
                        backgroundColor: '#0D224B',
                        borderRadius: '0.5rem',
                        height: '2.125rem',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 1rem',
                        cursor: 'pointer'
                    }}>
                    {t('knowMore')}
                </div>
            </div>
        );
    };

    return (
        <>
            <ShareRulesModal
                isOpen={viewShareRules}
                closeModal={() => setViewShareRules(false)}
                type="games"
            />
            {displayLoader && <Loader sx={{ zIndex: '1000' }} />}
            {/* {viewModalCantUseMedia && ( */}
            <ModalInfo
                isOpen={viewModalCantUseMedia}
                closeModal={() => setViewModalCantUseMedia(false)}
                title={t('oups') ?? ''}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem'
                    }}>
                    <Typography sx={{ color: '#374653', fontWeight: '600' }}>
                        {t('ModalCantUseMedia.pro1')}
                    </Typography>
                    <Typography sx={{ color: '#374653', fontWeight: '600' }}>
                        {t('ModalCantUseMedia.pro2')}
                    </Typography>
                    <Typography sx={{ color: '#374653', fontWeight: '600' }}>
                        {t('ModalCantUseMedia.pro3')}
                        <ul
                            style={{
                                listStylePosition: 'inside',
                                margin: '0px'
                            }}>
                            <li>{t('ModalCantUseMedia.pro3.ul1')}</li>
                            {user?.role === Roles.PRO && <li>{t('ModalCantUseMedia.pro3.ul2')}</li>}
                        </ul>
                    </Typography>
                </div>
            </ModalInfo>
            {step === 0 && (
                <div style={{ ...classes['root-step'], padding: '40px 32px' }}>
                    {listModel.map((model, index) => {
                        return (
                            <div
                                onClick={(): void => {
                                    setGameToCreate(model.gameType);
                                    setStepMax(model.nbStep);
                                    setStep(step + 1);
                                }}
                                key={index}
                                style={{
                                    ...classes['element']
                                    // ...(index === 0 ? { backgroundColor: '#1C0D4B' } : {})
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        gap: '10px'
                                    }}>
                                    <img
                                        src={model.img}
                                        alt={model.title}
                                        style={classes['img-element']}
                                    />
                                </div>
                                <div style={classes['title-element']}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            ...classes['h2'],
                                            textAlign: 'left'
                                            // ...(index === 0 ? { color: '#fff' } : {})
                                        }}>
                                        {model.title}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            ...classes['h4']
                                            // ...(index === 0 ? { color: '#E8E8E8' } : {})
                                        }}>
                                        {model.gameType &&
                                            t(
                                                `nbPicture${nbPicture[model.gameType] > 1 ? 's' : ''
                                                }`,
                                                { nbPicture: nbPicture[model.gameType] }
                                            )}
                                    </Typography>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            {step === 1 && gameToCreate && (
                <div style={classes['root-step']}>
                    <Typography variant="h2" sx={classes['h2']}>
                        {t(nbPicture[gameToCreate] > 1 ? 'choiseXpictures' : 'choiseXpicture', {
                            nb: nbPicture[gameToCreate] - listPictureSelected.length
                        })}
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'center' }}>
                        {t('cantFindHappiness')}
                        <br />
                        {t('importNewPicture')}
                    </Typography>
                    <div style={classes['list-picture']}>
                        {listPicture.map((picture, index) => {
                            const proCantView =
                                picture.proList?.findIndex(
                                    (item) =>
                                        item &&
                                        user &&
                                        user.id &&
                                        Object.keys(item)[0] === user.id &&
                                        Object.values(item)[0] === 'read' &&
                                        (picture.answer === '' || !picture.answer)
                                ) !== -1 && picture.authorId !== user?.userId;
                            const familyCanView =
                                picture.authorId === user?.userId ||
                                (picture.patientList?.includes(user?.id ?? '') &&
                                    picture.answer !== '' &&
                                    picture.answer) ||
                                (picture.createdDate &&
                                    picture.createdDate <
                                    Timestamp.fromDate(new Date('2023-11-15 23:42:00')) &&
                                    picture.answer !== '' &&
                                    picture.answer);
                            return (
                                <div
                                    key={picture.id}
                                    onClick={(): void => {
                                        if (
                                            listPictureSelected.findIndex(
                                                (item) => item.id === picture.id
                                            ) !== -1
                                        ) {
                                            setListPictureSelected(
                                                listPictureSelected.filter(
                                                    (item) => item.id !== picture.id
                                                )
                                            );
                                        } else if (
                                            listPictureSelected.length < nbPicture[gameToCreate]
                                        ) {
                                            setListPictureSelected([
                                                ...listPictureSelected,
                                                picture
                                            ]);
                                        } else if (
                                            listPictureSelected.length ===
                                            nbPicture[gameToCreate] &&
                                            nbPicture[gameToCreate] === 1
                                        ) {
                                            setListPictureSelected([
                                                ...listPictureSelected.slice(1),
                                                picture
                                            ]);
                                        }
                                    }}
                                    style={{
                                        ...classes['element-picture'],
                                        width: `calc(100% / ${nbPicturePerRow.toString()})`
                                    }}>
                                    {gameToCreate === ExerciseType.QUIZ &&
                                        ((user?.role === Roles.PRO && proCantView) ||
                                            ((user?.role === Roles.SENIOR ||
                                                user?.role === Roles.FAMILY) &&
                                                !familyCanView)) && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    left: '0',
                                                    width: 'calc(100% - 6px)',
                                                    height: 'calc(100% - 6px)',
                                                    backgroundColor: 'rgba(4, 5, 5, 0.70)',
                                                    zIndex: 100,
                                                    margin: '3px'
                                                }}
                                                onClick={(e): void => {
                                                    e.stopPropagation();
                                                    setViewModalCantUseMedia(true);
                                                }}
                                            />
                                        )}
                                    <CheckBox
                                        sx={{
                                            ...classes['checkbox-picture'],
                                            pointerEvents: 'none'
                                        }}
                                        checked={
                                            listPictureSelected.findIndex(
                                                (item) => item.id === picture.id
                                            ) !== -1
                                        }
                                        disabled={
                                            listPictureSelected.length >= nbPicture[gameToCreate] &&
                                            listPictureSelected.findIndex(
                                                (item) => item.id === picture.id
                                            ) === -1
                                        }
                                    // setChecked={(): void => {
                                    //     if (
                                    //         listPictureSelected.findIndex(
                                    //             (item) => item.id === picture.id
                                    //         ) !== -1
                                    //     ) {
                                    //         setListPictureSelected(
                                    //             listPictureSelected.filter(
                                    //                 (item) => item.id !== picture.id
                                    //             )
                                    //         );
                                    //     } else if (
                                    //         listPictureSelected.length < nbPicture[gameToCreate]
                                    //     ) {
                                    //         setListPictureSelected([
                                    //             ...listPictureSelected,
                                    //             picture
                                    //         ]);
                                    //     }
                                    // }}
                                    />
                                    <img
                                        src={
                                            typeof picture.exerciseImage === 'string'
                                                ? getImageKitUrlFrom(
                                                    picture.exerciseImage,
                                                    512,
                                                    512
                                                )
                                                : ''
                                        }
                                        alt={picture.title}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div style={classes['button-next']}>
                        <Button
                            pink
                            disabled={nbPicture[gameToCreate] - listPictureSelected.length !== 0}
                            onClick={(): void => {
                                if (gameToCreate === ExerciseType.QUIZ) {
                                    const tempListPictureSelected: React.SetStateAction<
                                        ExerciseItem[]
                                    > = [];
                                    listPictureSelected.forEach((item) => {
                                        if (
                                            item.answer === null ||
                                            item.answer === undefined ||
                                            item.answer.length === 0
                                        ) {
                                            tempListPictureSelected.push(item);
                                        }
                                    });
                                    listPictureSelected.forEach((item) => {
                                        if (
                                            item.answer !== null &&
                                            item.answer !== undefined &&
                                            item.answer.length !== 0
                                        ) {
                                            tempListPictureSelected.push(item);
                                        }
                                    });
                                    setListPictureSelected(tempListPictureSelected);
                                    setStep(step + 1);
                                } else {
                                    setStep(step + 1);
                                }
                            }}>
                            {t('Next')}
                        </Button>
                    </div>
                </div>
            )}
            {step === 2 && gameToCreate === ExerciseType.QUIZ && (
                <div style={{ ...classes['root-step'], paddingLeft: '1rem', paddingRight: '1rem' }}>
                    <Typography variant="h2" sx={classes['h2']}>
                        {t('completeTitles')}
                        {/* {listPictureSelected
                            .map((item) => item.answer)
                            .filter(
                                (item) => item === null || item === undefined || item.length === 0
                            ).length -
                            tempTitle.filter(
                                (item) => item !== '' && item !== null && item !== undefined
                            ).length ===
                        0
                            ? t('completeTitleVerif')
                            : t(
                                  `completeTitle${
                                      listPictureSelected
                                          .map((item) => item.answer)
                                          .filter(
                                              (item) =>
                                                  item === null ||
                                                  item === undefined ||
                                                  item.length === 0
                                          ).length -
                                          tempTitle.filter(
                                              (item) =>
                                                  item !== '' && item !== null && item !== undefined
                                          ).length >
                                      1
                                          ? 's'
                                          : ''
                                  }`,
                                  {
                                      nb:
                                          listPictureSelected
                                              .map((item) => item.answer)
                                              .filter(
                                                  (item) =>
                                                      item === null ||
                                                      item === undefined ||
                                                      item.length === 0
                                              ).length -
                                          tempTitle.filter(
                                              (item) =>
                                                  item !== '' && item !== null && item !== undefined
                                          ).length
                                  }
                              )} */}
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'center' }}>
                        <span style={{ color: '#000' }}>
                            {t(
                                `titleMissingDescNb${listPictureSelected
                                    .map((item) => item.answer)
                                    .filter(
                                        (item) =>
                                            item === null ||
                                            item === undefined ||
                                            item.length === 0
                                    ).length -
                                    tempTitle.filter(
                                        (item) => item !== '' && item !== null && item !== undefined
                                    ).length
                                    ? 's'
                                    : ''
                                }`,
                                {
                                    nb:
                                        nbPicture[gameToCreate] -
                                        tempTitle.filter(
                                            (item) =>
                                                item !== '' && item !== null && item !== undefined
                                        ).length
                                }
                            )}
                        </span>
                        {t('titleMissingDesc')}
                    </Typography>
                    {listPictureSelected.map((picture, index) => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: '10px',
                                    width: '100%'
                                }}>
                                <div
                                    key={picture.id}
                                    style={{
                                        ...classes['element-picture'],
                                        width: '6rem'
                                    }}>
                                    <img
                                        style={{ borderRadius: '0.25rem' }}
                                        src={
                                            typeof picture.exerciseImage === 'string'
                                                ? getImageKitUrlFrom(
                                                    picture.exerciseImage,
                                                    120,
                                                    120
                                                )
                                                : ''
                                        }
                                        alt={picture.title}
                                    />
                                    {(picture.answer || tempTitle[index]) && (
                                        <>
                                            <div
                                                style={{
                                                    height: 'calc(100% - 6px)',
                                                    width: 'calc(100% - 6px)',
                                                    position: 'absolute',
                                                    top: '3px',
                                                    left: '3px',
                                                    borderRadius: '0.25rem',
                                                    backgroundColor: 'rgba(0,0,0,0.4)'
                                                }}
                                            />
                                            <img
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '0.5rem',
                                                    right: '0.5rem',
                                                    width: '1.5rem',
                                                    height: '1.5rem'
                                                }}
                                                src={whiteCheck}
                                                alt="check"
                                            />
                                        </>
                                    )}
                                </div>
                                <Input
                                    placeholder={t('titlePlaceholder') ?? ''}
                                    value={picture.answer ? picture.answer : tempTitle[index]}
                                    onChange={(str): void => {
                                        setTempTitle((prev) => {
                                            const temp = [...prev];
                                            temp[index] = str;
                                            return temp;
                                        });
                                    }}
                                    disabled={picture.answer ? true : false}
                                />
                            </div>
                        );
                    })}
                    {displayErrorTitle && (
                        <Typography
                            variant="h4"
                            sx={{ ...classes['h4'], color: '#E5007B', textAlign: 'center' }}>
                            {t('titleMissingError')}
                        </Typography>
                    )}
                    <Button
                        pink
                        sx={{ marginBottom: '1rem' }}
                        disabled={
                            nbPicture[gameToCreate] -
                            tempTitle.filter(
                                (item) => item !== null && item !== undefined && item !== ''
                            ).length !==
                            0
                        }
                        onClick={async (): Promise<void> => {
                            const tempListPictureSelected: React.SetStateAction<ExerciseItem[]> =
                                listPictureSelected;

                            await Promise.all(
                                listPictureSelected.map(async (picture, index): Promise<void> => {
                                    if (tempTitle[index]) {
                                        await saveExerciseItem(
                                            { ...picture, answer: tempTitle[index] },
                                            typeof picture.exerciseImage === 'string'
                                                ? picture.exerciseImage
                                                : ''
                                        ).then((res) => {
                                            if (res) {
                                                tempListPictureSelected[index].answer =
                                                    tempTitle[index];
                                                setListPictureSelected([
                                                    ...tempListPictureSelected
                                                ]);
                                            }
                                        });
                                    }
                                })
                            );
                            setTempTitle([]);
                            if (!tempListPictureSelected.map((item) => item.answer).includes('')) {
                                setStep(step + 1);
                            } else {
                                setDisplayErrorTitle(true);
                            }
                        }}>
                        {t('Validate')}
                    </Button>
                </div>
            )}
            {((step === 2 &&
                gameToCreate !== ExerciseType.QUIZ &&
                gameToCreate !== null &&
                !displayVideoSelect) ||
                (step === 3 && gameToCreate === ExerciseType.QUIZ && !displayVideoSelect)) && (
                    <div style={{ ...classes['root-step'], padding: '24px' }}>
                        <Typography variant="h2" sx={classes['h2']}>
                            {t('addMessage')}
                        </Typography>
                        <Typography variant="h3" sx={classes['h3']}>
                            {'- '}
                            {t('Option')}
                            {' -'}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'left' }}>
                            {t('encouragementMessage')}
                        </Typography>
                        <Typography
                            variant="h3"
                            sx={{ justifyContent: 'flex-start', paddingLeft: '5px' }}>
                            {t('encouragementMessageTitle')}
                        </Typography>
                        <TextField
                            placeholder={t('encouragementMessagePlaceholder') ?? ''}
                            value={encouragementMessage}
                            onChange={(str): void => {
                                setEncouragementMessage(str);
                            }}
                            maxLength={250}
                        />

                        <Typography variant="h2" sx={classes['h2']}>
                            {t('addVideo')}
                        </Typography>
                        <Typography variant="h3" sx={classes['h3']}>
                            {'- '}
                            {t('Option')}
                            {' -'}
                            <img
                                style={{ paddingLeft: '0.2rem', height: '1.5rem' }}
                                src={gift}
                                alt="gift"
                            />
                        </Typography>
                        <Button onClick={(): void => setDisplayVideoSelect(true)}>
                            {t('addVideoBtn')}
                        </Button>
                        {rewardVideo && (
                            <div
                                style={{
                                    width: '100%',
                                    height: 'fit-content',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    border: '1px solid #D8D8D8',
                                    backgroundColor: '#F2F2F2',
                                    padding: '1rem'
                                }}>
                                <VideoPlayer url={rewardVideo.videoUrl} />
                            </div>
                        )}

                        <Typography
                            variant="h3"
                            sx={{ justifyContent: 'flex-start', paddingLeft: '5px' }}>
                            {t('congratulationsMessageTitle')}
                        </Typography>
                        <TextField
                            placeholder={t('congratulationsMessagePlaceholder') ?? ''}
                            value={congratulationsMessage}
                            sx={{ marginBottom: '5rem' }}
                            onChange={(str): void => {
                                setCongratulationsMessage(str);
                            }}
                            maxLength={250}
                        />
                        <div style={classes['button-next']}>
                            <Button
                                pink
                                disabled={nbPicture[gameToCreate] - listPictureSelected.length !== 0}
                                onClick={(): void => {
                                    setStep(step + 1);
                                }}>
                                {t('Next')}
                            </Button>
                        </div>
                    </div>
                )}
            {((step === 2 &&
                gameToCreate !== ExerciseType.QUIZ &&
                gameToCreate !== null &&
                displayVideoSelect) ||
                (step === 3 && gameToCreate === ExerciseType.QUIZ && displayVideoSelect)) && (
                    <div style={classes['root-step']}>
                        <Typography variant="h2" sx={classes['h2']}>
                            {t('Choise1Video')}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'center' }}>
                            {t('Choise1VideoDesc')}
                        </Typography>
                        <div style={classes['list-picture']}>
                            {/* herre */}
                            {listVideo.map(
                                (
                                    video: {
                                        videoUrl: string;
                                        title: string | undefined;
                                        thumbnail: string | undefined;
                                    },
                                    index: any
                                ) => {
                                    return (
                                        <div
                                            key={video.videoUrl}
                                            onClick={(): void => {
                                                setRewardVideo(video);
                                            }}
                                            style={{
                                                ...classes['element-picture'],
                                                width: `calc(100% / ${nbPicturePerRow.toString()})`
                                                // height: `calc(100vw / ${nbPicturePerRow.toString()})`
                                            }}>
                                            <CheckBox
                                                sx={{
                                                    ...classes['checkbox-picture'],
                                                    pointerEvents: 'none'
                                                }}
                                                checked={rewardVideo === video}
                                            // setChecked={(): void => {
                                            //     setRewardVideo(video);
                                            // }}
                                            />
                                            {video &&
                                                Object.prototype.hasOwnProperty.call(video, 'videoUrl') &&
                                                !video.videoUrl.includes('firebase') ? (
                                                <img
                                                    src={`https://img.youtube.com/vi/${video.videoUrl.match(
                                                        /[\w\\-]{11,}/
                                                    )}/0.jpg`}
                                                    alt="SampleImg"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        aspectRatio: '1/1'
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    // src={getImageKitThumbnailFromVideo(
                                                    //     video.videoUrl,
                                                    //     512,
                                                    //     512
                                                    // )}
                                                    src={
                                                        video.thumbnail
                                                            ? getImageKitUrlFrom(
                                                                video.thumbnail,
                                                                512,
                                                                512
                                                            )
                                                            : ''
                                                    }
                                                    alt={video.title}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        aspectRatio: '1/1'
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                }
                            )}
                        </div>
                        <div style={classes['button-next']}>
                            <Button pink onClick={(): void => setDisplayVideoSelect(false)}>
                                {t('Next')}
                            </Button>
                        </div>
                    </div>
                )}
            {((step === 3 && gameToCreate !== ExerciseType.QUIZ && gameToCreate !== null) ||
                (step === 4 && gameToCreate === ExerciseType.QUIZ)) && (
                    <div style={{ ...classes['root-step'], padding: '24px' }}>
                        <Typography variant="h2" sx={classes['h2']}>
                            {t('yourCreation')}
                        </Typography>
                        <div style={classes['game-finish']}>
                            <img
                                style={classes['game-finish-picture']}
                                src={listModel.find((item) => item.gameType === gameToCreate)?.img}
                                alt={listModel.find((item) => item.gameType === gameToCreate)?.title}
                            />
                            <div
                                style={{ ...classes['game-finish-message'], justifyContent: 'center' }}>
                                <Typography
                                    variant="h3"
                                    sx={{ ...classes['game-finish-title'], paddingTop: '0px' }}>
                                    {t(gameToCreate)}
                                </Typography>
                                {/* <Button sx={classes['game-finish-button']} onClick={(): void => {}}>
                                {t('testGame')}
                            </Button> */}
                            </div>
                        </div>
                        <Typography variant="h3" sx={classes['give-name']}>
                            {t('giveName')}
                        </Typography>
                        <Input
                            sx={classes['input-name']}
                            value={gameName}
                            placeholder={t('gameNamePlaceholder') ?? ''}
                            onChange={(str): void => setGameName(str)}
                        />
                        <Typography variant="h4" sx={classes['details']}>
                            {t('details')}
                        </Typography>
                        <div style={classes['details-container']}>
                            <Typography sx={{ color: '#6B6B6B' }}>
                                {t(nbPicture[gameToCreate] > 1 ? 'Xpictures' : 'Xpicture', {
                                    nb: nbPicture[gameToCreate]
                                })}
                            </Typography>
                            <Typography
                                onClick={(): void => {
                                    setStep(1);
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                <span style={{ paddingRight: '0.8rem' }}>{t('edit')}</span>
                                <img
                                    style={{
                                        height: '0.4rem',
                                        width: 'auto',
                                        transform: 'rotate(-90deg)'
                                    }}
                                    src={arrow}
                                    alt="arrow"
                                />
                            </Typography>
                        </div>
                        <div style={classes['button-next']}>
                            <Button
                                pink
                                disabled={gameName.length === 0}
                                onClick={() => setStep(step + 1)}>
                                {t('Continue')}
                            </Button>
                        </div>
                    </div>
                )}
            {((user?.role === Roles.PRO &&
                step === 4 &&
                gameToCreate !== ExerciseType.QUIZ &&
                gameToCreate !== null) ||
                (user?.role === Roles.PRO && step === 5 && gameToCreate === ExerciseType.QUIZ)) && (
                    <div style={{ ...classes['root-step'], padding: '24px', paddingBottom: '4rem' }}>
                        <Typography variant="h2" sx={{ ...classes['h2'], textAlign: 'left' }}>
                            {t('sendGameToPro')}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'left' }}>
                            {t('sendGameDescToPro.pro')}
                        </Typography>
                        <DisplayUser
                            checkbox={true}
                            selectAll
                            selectAllTitle={t('sendAllToPro.pro') ?? ''}
                            checked={proListSelected.length === proList.length}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    const temp: { [key: string]: 'read' | 'write' }[] = [];
                                    proList.forEach((item) => {
                                        temp.push({ [item.id ?? '']: 'read' });
                                    });
                                    setProListSelected(temp);
                                } else {
                                    setProListSelected([]);
                                }
                            }}
                        />
                        {/* delete user as created gameToEdit */}
                        {proList.map((senior) => (
                            <DisplayUser
                                checkbox={true}
                                user={senior}
                                checked={proListSelected
                                    .map((item) => Object.keys(item)[0])
                                    .includes(senior.id ?? '')}
                                onCheck={(checked): void => {
                                    if (checked === true) {
                                        setProListSelected([
                                            ...proListSelected,
                                            { [senior.id ?? '']: 'read' }
                                        ]);
                                    } else {
                                        setProListSelected(
                                            proListSelected.filter(
                                                (item) => Object.keys(item)[0] !== senior.id
                                            )
                                        );
                                    }
                                }}
                            />
                        ))}
                        <div style={classes['button-next']}>
                            <Button
                                pink
                                onClick={() => {
                                    const tempProListSelected = proListSelected;
                                    const index = tempProListSelected.findIndex(
                                        (item) => Object.keys(item)[0] === user?.id
                                    );
                                    if (index !== -1) {
                                        tempProListSelected.splice(index, 1);
                                    }
                                    proListSelected.length === 0
                                        ? setStep(step + 2)
                                        : setStep(step + 1);
                                }}>
                                {proListSelected.length === 0 ? t('notNowThanks') : t('Continue')}
                            </Button>
                        </div>
                    </div>
                )}

            {((user?.role === Roles.PRO &&
                step === 5 &&
                gameToCreate !== ExerciseType.QUIZ &&
                gameToCreate !== null) ||
                (user?.role === Roles.PRO && step === 6 && gameToCreate === ExerciseType.QUIZ)) && (
                    <div style={{ ...classes['root-step'], padding: '24px', paddingBottom: '4rem' }}>
                        <Typography variant="h2" sx={{ ...classes['h2'], textAlign: 'left' }}>
                            {t('sendGameToPro')}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'left' }}>
                            {t('sendGameDescToProRight')}
                        </Typography>
                        {knowMore()}
                        <DisplayUser
                            selectPermission
                            selectAll
                            selectAllTitle={t('sendAllToProRight') ?? ''}
                            selectValue={
                                proListSelected.filter((item) => Object.values(item)[0] === 'write')
                                    .length === proListSelected.length
                                    ? 'write'
                                    : 'read'
                            }
                            onChange={(str): void => {
                                const temp: { [key: string]: 'read' | 'write' }[] = [];
                                proListSelected.forEach((item) => {
                                    const itemKey = Object.keys(item)[0];
                                    temp.push({ [itemKey]: str === 'write' ? 'write' : 'read' });
                                });
                                setProListSelected(temp);
                            }}
                        />
                        {/* {proList.map((senior) => ( */}
                        {proListSelected
                            .map((senior) => proList.find((item) => item.id === Object.keys(senior)[0]))
                            .map(
                                (senior) =>
                                    senior &&
                                    senior.id !== user?.id && (
                                        <DisplayUser
                                            user={senior}
                                            selectPermission
                                            selectValue={
                                                proListSelected.filter(
                                                    (item) =>
                                                        Object.keys(item)[0] === senior?.id &&
                                                        Object.values(item)[0] === 'write'
                                                ).length === 1
                                                    ? 'write'
                                                    : 'read'
                                            }
                                            onChange={(str: string): void => {
                                                const temp: { [key: string]: 'read' | 'write' }[] =
                                                    proListSelected;
                                                const index = temp.findIndex(
                                                    (item) => Object.keys(item)[0] === senior?.id
                                                );
                                                if (index !== -1) {
                                                    temp[index] = {
                                                        [Object.keys(temp[index])[0] ?? '']:
                                                            str === 'write' ? 'write' : 'read'
                                                    };
                                                }
                                                setProListSelected([...temp]);
                                            }}
                                        />
                                    )
                            )}
                        <div style={classes['button-next']}>
                            <Button pink onClick={() => setStep(step + 1)}>
                                {proListSelected.length === 0 ? t('notNowThanks') : t('Continue')}
                            </Button>
                        </div>
                    </div>
                )}

            {((user?.role === Roles.PRO &&
                step === 6 &&
                gameToCreate !== ExerciseType.QUIZ &&
                gameToCreate !== null) ||
                (user?.role === Roles.PRO && step === 7 && gameToCreate === ExerciseType.QUIZ)) && (
                    <div style={{ ...classes['root-step'], padding: '24px', paddingBottom: '4rem' }}>
                        <Typography variant="h2" sx={{ ...classes['h2'], textAlign: 'left' }}>
                            {t('sendGame')}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'left' }}>
                            {t('sendGameDesc.pro')}
                        </Typography>
                        <DisplayUser
                            checkbox={true}
                            selectAll
                            selectAllTitle={t('sendAllSenior.pro') ?? ''}
                            checked={seniorsListSelected.length === seniorsList.length}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setSeniorsListSelected(
                                        seniorsList.map((senior) => senior.id ?? '')
                                    );
                                } else {
                                    setSeniorsListSelected([]);
                                }
                            }}
                        />
                        {seniorsList.map((senior) => (
                            <DisplayUser
                                checkbox={true}
                                user={senior}
                                checked={seniorsListSelected.includes(senior.id ?? '')}
                                onCheck={(checked): void => {
                                    if (checked === true) {
                                        setSeniorsListSelected([
                                            ...seniorsListSelected,
                                            senior.id ?? ''
                                        ]);
                                    } else {
                                        setSeniorsListSelected(
                                            seniorsListSelected.filter((id) => id !== senior.id)
                                        );
                                    }
                                }}
                            />
                        ))}
                        <div style={classes['button-next']}>
                            <Button
                                pink
                                // disabled={seniorsListSelected.length === 0}
                                onClick={() => saveGame()}>
                                {seniorsListSelected.length === 0 ? t('noThanks') : t('Share')}
                            </Button>
                        </div>
                    </div>
                )}

            {(((user?.role === Roles.FAMILY || user?.role === Roles.SENIOR) &&
                step === 4 &&
                gameToCreate !== ExerciseType.QUIZ &&
                gameToCreate !== null) ||
                ((user?.role === Roles.FAMILY || user?.role === Roles.SENIOR) &&
                    step === 5 &&
                    gameToCreate === ExerciseType.QUIZ)) && (
                    <div style={{ ...classes['root-step'], padding: '24px', paddingBottom: '4rem' }}>
                        <Typography variant="h2" sx={{ ...classes['h2'], textAlign: 'left' }}>
                            {t('sendGame')}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'left' }}>
                            {t('sendGameDesc.family')}
                        </Typography>
                        <DisplayUser
                            checkbox={true}
                            selectAll
                            selectAllTitle={t('sendAllSenior.family') ?? ''}
                            checked={familyListSelected.length === familyList.length}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setFamilyListSelected(familyList.map((senior) => senior.id ?? ''));
                                } else {
                                    setFamilyListSelected([]);
                                }
                            }}
                        />
                        {familyList.map((senior) => (
                            <DisplayUser
                                checkbox={true}
                                user={senior}
                                checked={familyListSelected.includes(senior.id ?? '')}
                                onCheck={(checked): void => {
                                    if (checked === true) {
                                        setFamilyListSelected([...familyListSelected, senior.id ?? '']);
                                    } else {
                                        setFamilyListSelected(
                                            familyListSelected.filter((id) => id !== senior.id)
                                        );
                                    }
                                }}
                            />
                        ))}
                        <div style={classes['button-next']}>
                            <Button pink onClick={() => saveGame()}>
                                {familyListSelected.length === 0 ? t('noThanks') : t('Share')}
                            </Button>
                        </div>
                    </div>
                )}

            {((user?.role === Roles.ADMIN &&
                step === 4 &&
                gameToCreate !== ExerciseType.QUIZ &&
                gameToCreate !== null) ||
                (user?.role === Roles.ADMIN &&
                    step === 5 &&
                    gameToCreate === ExerciseType.QUIZ)) && (
                    <div style={{ ...classes['root-step'], padding: '24px', paddingBottom: '4rem' }}>
                        <Typography variant="h2" sx={{ ...classes['h2'], textAlign: 'left' }}>
                            {t('sendGameToEts')}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{ ...classes['h4'], color: '#6B6B6B', textAlign: 'left' }}>
                            {t('sendGameDescToEts.admin')}
                        </Typography>
                        <DisplayUser
                            checkbox={true}
                            selectAll
                            selectAllTitle={t('sendAllToEts.admin') ?? ''}
                            checked={etsListSelected.length === etsList.length}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setEtsListSelected(etsList.map((ets) => ets.id ?? ''));
                                } else {
                                    setEtsListSelected([]);
                                }
                            }}
                        />
                        {etsList.map((ets) => (
                            <DisplayEts
                                checkbox={true}
                                ets={ets}
                                checked={etsListSelected.includes(ets.id ?? '')}
                                onCheck={(checked): void => {
                                    if (checked === true) {
                                        setEtsListSelected([...etsListSelected, ets.id ?? '']);
                                    } else {
                                        setEtsListSelected(etsListSelected.filter((id) => id !== ets.id));
                                    }
                                }}
                            />
                        ))}
                        <div style={classes['button-next']}>
                            <Button pink onClick={() => saveGame()}>
                                {etsListSelected.length === 0 ? t('noThanks') : t('Share')}
                            </Button>
                        </div>
                    </div>
                )}
        </>
    );
}
