import React, { CSSProperties, ReactElement } from 'react';
import validateSaveGame from '../assets/validate-save-game.png';
import applause from '../assets/applause.png';
import { useTranslation } from 'react-i18next';
import { Typography } from './Typography';
import { Exercise } from '../models/Exercise';
import { User } from '../models/User';
import { Roles } from '../models/Roles';

import memorizIcon from '../assets/duo.svg';
import pongIcon from '../assets/pong.svg';
import puzzleIcon from '../assets/puzzle.svg';
import quizIcon from '../assets/quizz.svg';
import sudokuIcon from '../assets/sudok.svg';
import orderIcon from '../assets/reordon.svg';
import closeIcon from '../assets/closeIcon.png';
import { ExerciseType } from '../models/ExerciseType';

const classes: { [key: string]: CSSProperties } = {
    root: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    },
    'modal-root': {
        width: '90%',
        maxWidth: '350px',
        padding: '1rem',
        height: '80%',
        backgroundColor: 'var(--color-white)',
        borderRadius: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
    },
    applause: {
        width: '3rem',
        height: 'auto'
    },
    h2: {
        textAlign: 'center',
        width: '100%'
    },
    h3: {
        textAlign: 'center',
        width: '100%',
        marginTop: '1rem',
        fontSize: '1rem',
        fontWeight: 500
    },
    'display-game': {
        width: '100%',
        height: '7.5rem',
        borderRadius: '1rem',
        boxShadow: '0px 4px 12px rgba(164, 161, 196, 0.74)',
        marginTop: '3rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        padding: '0.5rem'
    },
    'display-game-img': {
        height: '100%',
        width: 'auto',
        borderRadius: '0.25rem'
    },
    'display-game-text': {
        paddingLeft: '1rem',
        width: '100%',
        textAlign: 'left'
    }
};

interface Props {
    close: () => void;
    game: Exercise | undefined;
    user: User | null;
}

export function CongratulationsModal({ close, game, user }: Props): JSX.Element {
    const { t } = useTranslation();

    const getImage = (): string => {
        switch (game?.exerciseType) {
            case ExerciseType.MEMORIZ:
                return memorizIcon;
            case ExerciseType.PONG:
                return pongIcon;
            case ExerciseType.PUZZLE:
                return puzzleIcon;
            case ExerciseType.QUIZ:
                return quizIcon;
            case ExerciseType.SUDOKU:
                return sudokuIcon;
            case ExerciseType.ORDER:
                return orderIcon;
            default:
                return '';
        }
    };

    setTimeout(() => {
        close();
    }, 10000);

    return (
        <div style={classes['root']} onClick={() => close()}>
            <div style={classes['modal-root']}>
                <div
                    style={{
                        position: 'absolute',
                        top: '1rem',
                        right: '1rem',
                        cursor: 'pointer'
                    }}>
                    <img src={closeIcon} alt="close" style={{
                        width: '2rem',
                        height: 'auto'
                    }}/>
                </div>
                <img style={classes['applause']} src={applause} alt="applause" />
                <Typography variant="h2" sx={classes['h2']}>
                    {t('Congratulations')}
                </Typography>
                <Typography variant="h2" sx={classes['h2']}>
                    {t('gamesSaved')}
                </Typography>
                <Typography variant="h3" sx={classes['h3']}>
                    {game?.authorType === Roles.SENIOR
                        ? t('validateSaveGameMe')
                        : game?.authorType === Roles.PRO
                        ? t('validateSaveGamePro')
                        : t('validateSaveGameOther')}
                </Typography>
                <div style={classes['display-game']}>
                    <img
                        style={classes['display-game-img']}
                        src={getImage()}
                        alt="validate save game"
                    />
                    <Typography
                        variant="h2"
                        sx={{ ...classes['h2'], ...classes['display-game-text'] }}>
                        {t(game?.exerciseType ?? 'Game')}
                    </Typography>
                </div>
            </div>
        </div>
    );
}
