import { useRef } from 'react';

import { pingpong } from '../../../models/Games';
import { GameProvider, GameRef } from '../../../providers/GameProvider';
import { PingPong } from '../../../components/PingPong';

export default () => {
    const pingPongRef = useRef<GameRef>(null);

    const getTips = () => {
        if (!pingPongRef.current) return;
        pingPongRef.current?.tips();
    };

    const resetGame = () => {
        if (!pingPongRef.current) return;
        pingPongRef.current?.reset();
    }

    const colors = {
        primary: pingpong.color,
        secondary: pingpong.secondaryColor,
        border: pingpong.border,
    };

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            onReset={resetGame}
            background={pingpong.background}
        >
            <PingPong ref={pingPongRef} />
        </GameProvider>
    );
};
