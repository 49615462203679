import React, { useContext } from 'react';
import styled from 'styled-components';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import closeIcon from '../assets/closeIcon.png';
import { UserDataProp } from '../../../models/userDataProp';
import { UserContext } from '../../../providers/UserProvider';
import { Roles } from '../../../models/Roles';

const isHeightOfXS = (): boolean => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width < 550 || height < 550) {
        return true;
    }
    if (height < 768 && height > 551) {
        return true;
    }
    return false;
};

const ModalDialog = styled(Dialog)(() => ({
    // borderRadius: '24px',
    // '& .MuiDialogContent-root': {
    //     padding: isHeightOfXS() ? '5px 30px !important' : '30px !important'
    // },
    // '& .MuDialogActions-root': {
    //     padding: '5px'
    // },
    '& .MuiDialog-paper': {
        boxShadow: 'none',
        maxWidth: '100%',
        borderRadius: '0px',
        backgroundColor: '#ffffff',
        // minWidth: '85%',
        height: '100%',
        margin: '0px',
        minHeight: '100%',
        minWidth: '100%',
        marginTop: 'auto',
        marginBottom: 'auto'
    }
    // '& .MuiDialogTitle-root': {
    //     padding: 'none'
    // }
}));
const LogoClose = styled.img`
    height: 50px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
`;
const GiftLogo = styled.img`
    height: 90px;
    padding: 12px;
`;

const BackButton = styled(Button)(() => ({
    // border: '3px solid #D00070',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#D00070',
    padding: '7%',
    margin: '15% 0%'
    // '&:hover': {
    //     border: '3px solid #D00070'
    // }
}));

interface ModalDialogProps {
    openModal: boolean;
    renderBody: JSX.Element;
    hasCloseButton?: boolean;
    hasGiftLogo?: boolean;
    hasBackButton?: boolean;
    handleGoBack?: () => void;
}

export function ModalContainerFullScreen({
    openModal,
    renderBody,
    hasCloseButton,
    hasGiftLogo,
    hasBackButton,
    handleGoBack
}: ModalDialogProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    const ModalDialogTitle = (): JSX.Element => {
        return (
            <div>
                {/* {hasCloseButton && (
                    <DialogTitle sx={{ padding: '0px 10px', position: 'absolute', zIndex: 2 }}>
                        <IconButton
                            size="large"
                            onClick={handleClose}
                            sx={{ padding: '12px 12px 0px 12px' }}>
                            <LogoClose src={closeIcon} alt="CloseButton" />
                        </IconButton>
                    </DialogTitle>
                )} */}
                {hasBackButton && (
                    <DialogTitle sx={{ padding: '0px 10px', position: 'absolute', zIndex: 2 }}>
                        <BackButton
                            onClick={handleGoBack}
                            style={{
                                background: 'white',
                                marginTop: '0%',
                                marginBottom: '0%',
                                height: '60px',
                                width: '60px',
                                padding: '0%',
                                top: '20px',
                                left: '30px',
                                borderRadius: '5px',
                                border: '1px solid #CDCDCD'
                            }}
                            fullWidth
                            variant="outlined"
                            size="medium"
                            sx={{ fontFamily: 'var(--ion-font-family)' }}>
                            <ArrowBackIcon sx={{ color: '#E5007B', fontSize: '36px' }} />
                        </BackButton>
                    </DialogTitle>
                )}
            </div>
        );
    };

    return (
        <div>
            <ModalDialog
                open={openModal}
                BackdropProps={{ style: { backgroundColor: 'grey', height: '100vh' } }}>
                <ModalDialogTitle />
                {hasGiftLogo && (
                    <DialogContent
                        dividers
                        sx={{
                            border: '0px',
                            textAlign: 'center',
                            width: '100%',
                            marginTop: '40%',
                            margin: 'auto',
                            whiteSpace: 'pre-line'
                        }}>
                        {renderBody}
                    </DialogContent>
                )}
                {hasCloseButton && (
                    <DialogContent
                        dividers
                        sx={{
                            border: '0px',
                            margin: '0px',
                            textAlign: 'center',
                            whiteSpace: 'pre-line'
                        }}>
                        {renderBody}
                    </DialogContent>
                )}
                {!hasCloseButton && !hasGiftLogo && (
                    <DialogContent
                        dividers
                        sx={{
                            border: '0px',
                            textAlign: 'center',
                            whiteSpace: 'pre-line'
                        }}>
                        {renderBody}
                    </DialogContent>
                )}
            </ModalDialog>
        </div>
    );
}
