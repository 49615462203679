import React from 'react';
import styled from 'styled-components';
import DialogContent from '@mui/material/DialogContent';
import { useLongPress } from 'use-long-press';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import closeIcon from '../../../assets/closeIcon.png';

const isHeightOfXS = (): boolean => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width < 550 || height < 550) {
        return true;
    }
    if (height < 768 && height > 551) {
        return true;
    }
    return false;
};

const ModalDialog = styled(Dialog)(() => ({
    borderRadius: '24px',
    '& .MuiDialogContent-root': {
        padding: isHeightOfXS() ? '5px 20px !important' : '30px !important'
    },
    '& .MuDialogActions-root': {
        padding: '5px'
    },
    '& .MuiDialog-paper': {
        boxShadow: 'none',
        maxWidth: '100%',
        borderRadius: '24px',
        backgroundColor: '#ffffff',
        minWidth: '85%'
    },
    '& .MuiDialogTitle-root': {
        padding: 'none'
    }
}));
const LogoClose = styled.img`
    height: 50px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
`;
const GiftLogo = styled.img`
    height: 90px;
    padding: 12px;
`;

interface ModalDialogProps {
    handleClose?: () => void;
    openModal: boolean;
    renderBody: JSX.Element;
    hasCloseButton?: boolean;
    hasGiftLogo?: boolean;
}

export function ModalContainer({
    handleClose,
    openModal,
    renderBody,
    hasCloseButton,
    hasGiftLogo
}: ModalDialogProps): JSX.Element {
    const ModalDialogTitle = (): JSX.Element => {
        const bindLongPress = useLongPress(
            () => {
                if (handleClose) {
                    handleClose();
                }
            },
            { threshold: 1000 }
        );

        return (
            <div>
                {hasCloseButton && (
                    <DialogTitle sx={{ padding: '0px 10px', position: 'absolute', zIndex: 2 }}>
                        <IconButton
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPress()}
                            onClick={handleClose}
                            sx={{ padding: '12px 12px 0px 12px' }}>
                            <LogoClose src={closeIcon} alt="CloseButton" />
                        </IconButton>
                    </DialogTitle>
                )}
            </div>
        );
    };

    return (
        <div>
            <ModalDialog
                open={openModal}
                BackdropProps={{ style: { backgroundColor: 'grey', height: '100vh' } }}>
                <ModalDialogTitle />
                {hasGiftLogo && (
                    <DialogContent
                        dividers
                        sx={{
                            border: '0px',
                            textAlign: 'center',
                            width: '100%',
                            marginTop: '40%',
                            margin: 'auto',
                            whiteSpace: 'pre-line'
                        }}>
                        {renderBody}
                    </DialogContent>
                )}
                {hasCloseButton && (
                    <DialogContent
                        dividers
                        sx={{
                            border: '0px',
                            margin: '0px',
                            textAlign: 'center',
                            whiteSpace: 'pre-line'
                        }}>
                        {renderBody}
                    </DialogContent>
                )}
                {!hasCloseButton && !hasGiftLogo && (
                    <DialogContent
                        dividers
                        sx={{
                            border: '0px',
                            textAlign: 'center',
                            whiteSpace: 'pre-line'
                        }}>
                        {renderBody}
                    </DialogContent>
                )}
            </ModalDialog>
        </div>
    );
}
