import React, {
    CSSProperties,
    Dispatch,
    MutableRefObject,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import ReactPlayer from 'react-player';
import { Typography } from './Typography';
import { Button } from './Button';
import { VideoPlayer } from './VideoPlayer';
import cross from '../assets/crossPink.svg';
import accountBox from '../assets/accountBox.svg';
import backArrow from '../assets/backArrow.svg';
import { isPlatform } from '@ionic/core';
import useWindowDimensions from '../utilities/useWindowDimensions';
import {
    getDate,
    getDateFromTimestamp,
    getImageKitUrlFrom,
    strDateTolongStr,
    translateDate
} from '../utilities/utils';
// import 'capacitor-video-player';
// import { useVideoPlayer } from 'react-video-player-hook';
// import * as PluginsLibrary from 'capacitor-video-player';
import '../utilities/utils.css';
import skip_previous from '../assets/skip_previous.svg';
import { t } from 'i18next';
import { UserContext } from '../providers/UserProvider';
import { UserDataProp } from '../models/userDataProp';
import { Roles } from '../models/Roles';
import { ModalInfo } from './ModalInfo';
import { DeleteModal } from './DeleteModal';
import infoLogo from '../assets/infoLogo.svg';
import {
    deleteExerciseItemCard,
    deletePersonalVideo,
    deletePersonalYoutubeVideo,
    saveMediaStats
} from '../services/cloudFirestore';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        position: 'relative'
        // overflowY: 'auto'
    },
    'btn-close': {
        // position: 'absolute',
        // top: '1rem',
        // left: '1rem',
        maxHeight: '3rem',
        minHeight: '3rem',
        maxWidth: '3rem',
        minWidth: '3rem',
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid #CDCDCD',
        alignItems: 'center',
        padding: '0.1rem'
    },
    h1: {
        color: '#3387CC',
        // fontSize: '2rem',
        fontWeight: 700
        // lineHeight: '2.5rem',
    },
    h2: {
        color: '#0F0046',
        // fontSize: '1.5rem',
        fontWeight: 400
    }
};

interface Props {
    element: any;
    close: () => void;
    viewMode: 'diapo' | 'single';
    next?: () => void;
    previous?: () => void;
    last?: boolean;
    deleteItem?: (itemData: any) => void;
    NeedNextBotom?: boolean;
}

export function MediaDetails({
    element,
    close,
    viewMode,
    next,
    previous,
    last,
    deleteItem,
    NeedNextBotom = false
}: Props): JSX.Element {
    const { height, width } = useWindowDimensions();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [displayDetails, setDisplayDetails] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
    const [viewErrorVideo, setViewErrorVideo] = useState(false);
    const pictureHaveDate = !element.videoUrl && element.photoCapturedOn ? true : false;
    const [needDisplayVideoNotAvailable, setNeedDisplayVideoNotAvailable] = useState(true);
    const pictureHavePlace =
        !element.videoUrl &&
        element.photoCapturedPlace &&
        element.photoCapturedPlace.replaceAll(' ', '').replaceAll('-', '') !== '';
    const isPortrait = useMemo(() => {
        if (height && width) {
            return height > width;
        }
        return false;
    }, [height, width]);
    const [timePlayed, setTimePlayed] = useState<number>(0);

    setTimeout(() => {
        setNeedDisplayVideoNotAvailable(false);
    }, 12000);

    const saveStats = (isHardSave: boolean = false): void => {
        if (isHardSave || element.videoUrl !== undefined) {
            if (user?.id) {
                saveMediaStats(
                    {
                        ...element,
                        watchTime: element.videoUrl === undefined ? 0 : Math.round(timePlayed)
                    },
                    user?.id
                );
            }
        }
    };

    useEffect(() => {
        if (
            !element.videoUrl &&
            !displayDetails &&
            (element.anecdote || (element.imageDetails && element.imageDetails.length > 0))
        ) {
            setDisplayDetails(true);
        }
    }, []);

    useEffect(() => {
        setTimePlayed(0);
        if (element.videoUrl === undefined) saveStats(true);
        if (
            displayDetails &&
            !(
                !element.videoUrl &&
                (element.anecdote || (element.imageDetails && element.imageDetails.length > 0))
            )
        ) {
            setDisplayDetails(false);
        }
    }, [element]);

    const knowMore = (): JSX.Element => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '0.5rem',
                    width: 'fit-content'
                }}
                onClick={() => setViewErrorVideo(true)}>
                {/* <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '1.8rem',
                        margin: '0rem',
                        width: '1.8rem',
                        borderRadius: '50%',
                        backgroundColor: '#E5007B',
                        color: '#FFFFFF',
                        cursor: 'pointer'
                    }}>
                    i
                </div> */}
                <img
                    src={infoLogo}
                    alt="infoLogo"
                    style={{
                        height: '1.8rem',
                        width: '1.8rem',
                        cursor: 'pointer'
                    }}
                />
                <div
                    style={{
                        color: '#fff',
                        backgroundColor: '#0D224B',
                        borderRadius: '0.5rem',
                        height: '2.125rem',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 1rem',
                        cursor: 'pointer'
                    }}>
                    {t('knowMore')}
                </div>
            </div>
        );
    };

    const deleteItemFunc = (): void => {
        if (user && element.authorId === user.userId) {
            let communityId = '';
            if (user.role === Roles.SENIOR || user.role === Roles.FAMILY) {
                communityId = user.familyCode ?? '';
            } else if (user.role === Roles.PRO) {
                communityId = user.establishmentCode ?? '';
            } else if (user.role === Roles.ADMIN) {
                communityId = user.id ?? '';
            }

            if (
                !Object.prototype.hasOwnProperty.call(element, 'videoUrl') &&
                typeof element.exerciseImage === 'string'
            ) {
                // img perso
                deleteExerciseItemCard(element.id).then(() => {
                    deleteItem && deleteItem(element);
                    saveStats();
                    close();
                });
            } else if (
                Object.prototype.hasOwnProperty.call(element, 'videoUrl') &&
                element.videoUrl.includes('firebase')
            ) {
                // video perso
                deletePersonalVideo(communityId, element.videoUrl).then(() => {
                    deleteItem && deleteItem(element);
                    saveStats;
                    close();
                });
            } else if (
                Object.prototype.hasOwnProperty.call(element, 'videoUrl') &&
                !element.videoUrl.includes('firebase')
            ) {
                // video youtube
                deletePersonalYoutubeVideo(communityId, element.videoUrl).then(() => {
                    deleteItem && deleteItem(element);
                    saveStats();
                    close();
                });
            }
        }
    };

    return (
        <div
            style={{
                ...classes['div-root']
                // marginTop:
                //     isPlatform('iphone') && height && width && height > width
                //         ? '50px'
                //         : isPlatform('ipad') && height && width && height > width
                //         ? '25px'
                //         : '0'
            }}>
            <ModalInfo
                sx={{ zIndex: 3601 }}
                isOpen={viewErrorVideo}
                closeModal={() => setViewErrorVideo(false)}
                title={t('errorVideoYt.title') ?? ''}
                maxHeight="95vh">
                <div
                    style={{
                        lineHeight: '1.5rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        gap: '1rem'
                    }}>
                    <p
                        style={{
                            margin: '0',
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            color: '#374653'
                        }}>
                        {t(`errorVideoYt.sub1.title`)}
                    </p>
                    <ol
                        style={{
                            margin: '0px',
                            padding: '0px',
                            fontSize: '1rem',
                            lineHeight: '1.3rem',
                            fontWeight: '400',
                            color: '#374653',
                            listStylePosition: 'inside'
                        }}>
                        <li>{t('errorVideoYt.sub1.li1')}</li>
                        <li>{t('errorVideoYt.sub1.li2')}</li>
                        <li>{t('errorVideoYt.sub1.li3')}</li>
                    </ol>
                    <p
                        style={{
                            margin: '0',
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            color: '#374653'
                        }}>
                        {t(`errorVideoYt.sub2.title`)}
                    </p>
                    <ol
                        style={{
                            margin: '0px',
                            padding: '0px',
                            fontSize: '1rem',
                            lineHeight: '1.3rem',
                            fontWeight: '400',
                            color: '#374653',
                            listStylePosition: 'inside'
                        }}>
                        {element.authorId === user?.userId && <li>{t('errorVideoYt.sub2.li1')}</li>}
                        <li>{t('errorVideoYt.sub2.li2')}</li>
                        <li>{t('errorVideoYt.sub2.li3')}</li>
                    </ol>
                    <Button
                        pink
                        onClick={() => {
                            window.open(element.videoUrl, '_blank');
                            setViewErrorVideo(false);
                        }}>
                        {t('errorVideoYt.lookOnYoutube')}
                    </Button>
                    {element.authorId === user?.userId && (
                        <Button
                            onClick={() => {
                                setViewErrorVideo(false);
                                setDisplayDeleteModal(true);
                            }}>
                            {t('errorVideoYt.deleteVideo')}
                        </Button>
                    )}
                </div>
            </ModalInfo>
            {displayDeleteModal && (
                <DeleteModal
                    sx={{ zIndex: 3602 }}
                    type={
                        !Object.prototype.hasOwnProperty.call(element, 'videoUrl')
                            ? 'picture'
                            : element.videoUrl.includes('firebase')
                            ? 'personal-video'
                            : 'youtube-video'
                    }
                    onClose={() => setDisplayDeleteModal(false)}
                    deleteFunc={() => deleteItemFunc()}
                />
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    transition: !isPortrait ? 'width 0.3s ease-in-out' : 'height 0.3s ease-in-out',
                    width: displayDetails && !isPortrait ? '65%' : '100%',
                    height: displayDetails && isPortrait ? '70%' : '100%',
                    position: 'relative'
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '1rem'
                    }}>
                    <Button
                        pink
                        outline
                        sx={{ ...classes['btn-close'] }}
                        onClick={() => {
                            saveStats();
                            close();
                        }}>
                        <img src={cross} alt="cross" />
                    </Button>
                    <Typography
                        variant="h1"
                        sx={{ ...classes['h1'], fontSize: isPortrait ? '1.5rem' : '2rem' }}>
                        {element.videoUrl ? element.title : element.answer}
                        {!element.videoUrl && (pictureHaveDate || pictureHavePlace) && (
                            <>
                                <br />
                                <span
                                    style={{
                                        ...classes['h2'],
                                        fontSize: isPortrait ? '1rem' : '1.5rem'
                                    }}>
                                    {pictureHaveDate &&
                                        strDateTolongStr(
                                            getDateFromTimestamp(element.photoCapturedOn)
                                        )}
                                    {pictureHaveDate && pictureHavePlace ? ' - ' : ''}
                                    {element.photoCapturedPlace}
                                </span>
                            </>
                        )}
                    </Typography>
                    <div style={{ width: '2rem' }}>
                        {!isPortrait &&
                            !element.videoUrl &&
                            !displayDetails &&
                            (element.anecdote ||
                                (element.imageDetails && element.imageDetails.length > 0)) && (
                                <img
                                    src={infoLogo}
                                    alt="infoLogo"
                                    className="infoAnimation"
                                    onClick={(): void => setDisplayDetails(true)}
                                    style={{
                                        // border: 'solid 5px white',
                                        minWidth: '2.2rem',
                                        maxWidth: '2.2rem',
                                        minHeight: '2.2rem',
                                        height: '2.2rem',
                                        borderRadius: '50%'
                                        // backgroundColor: '#E5007B'
                                    }}
                                />
                                // <button
                                //     className="infoAnimation"
                                //     type="button"
                                //     // eslint-disable-next-line react/jsx-props-no-spreading
                                // onClick={(): void => setDisplayDetails(true)}
                                // style={{
                                //     // border: 'solid 5px white',
                                //     minWidth: '2rem',
                                //     maxWidth: '2rem',
                                //     minHeight: '2rem',
                                //     height: '2rem',
                                //     borderRadius: '50%',
                                //     backgroundColor: '#E5007B'
                                // }}>
                                //     <Typography
                                //         sx={{
                                //             color: 'white',
                                //             fontSize: '1.3rem',
                                //             fontWeight: '700',
                                //             paddingTop: '5px'
                                //         }}
                                //         variant="p">
                                //         {'i'}
                                //     </Typography>
                                // </button>
                            )}
                    </div>
                </div>
                <div
                    style={{
                        height:
                            element.videoUrl && element.videoUrl.includes('youtu')
                                ? `calc(100% - 8rem)` // ${viewMode === "diapo" && isPortrait ? " - 9rem" : ""}
                                : '100%',
                        maxHeight:
                            element.videoUrl && element.videoUrl.includes('youtu')
                                ? `calc(100% - 8rem)` // ${viewMode === "diapo" && isPortrait ? " - 9rem" : ""}
                                : '100%',
                        width: '100%',
                        padding: '0 1rem',
                        // marginBottom: viewMode === "diapo" && isPortrait ? "4rem" : "0",
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative'
                    }}>
                    {element.videoUrl && (
                        <VideoPlayer
                            autoPlay
                            url={element.videoUrl}
                            setTimePlayed={(tp: number) => setTimePlayed(tp)}
                            sx={{
                                maxHeight: `calc(100vh - 70px - 2rem${viewMode === "diapo" && isPortrait ? " - 8rem" : ""})`,
                            }}
                            onEnded={() => {
                                setTimeout(() => {
                                    saveStats();
                                    next && next();
                                }, 1500);
                            }}
                            onMediaError={() => {
                                if (viewMode === 'diapo' && next) {
                                    setTimeout(() => {
                                        next();
                                    }, 2000);
                                } else {
                                    setTimeout(() => {
                                        window.open(
                                            `https://youtube.com/watch?v=${element.videoUrl.match(
                                                /[\w\\-]{11,}/
                                            )}`,
                                            '_blank'
                                        );
                                    }, 4000);
                                }
                            }}
                        />
                    )}
                    {element.exerciseImage && (
                        <div style={{ position: 'relative' }}>
                            <img
                                src={getImageKitUrlFrom(element.exerciseImage, 1024, 1024)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    maxWidth: '100%',
                                    // maxHeight: 'calc(100% - 2rem)',
                                    maxHeight: `calc(100vh - 72px ${
                                        pictureHavePlace || pictureHaveDate ? '- 40px' : ''
                                    } - 2rem ${NeedNextBotom ? '- 4rem' : ''})`,
                                    aspectRatio: '1 / 1',
                                    backgroundColor: 'black',
                                    marginBottom: viewMode === "diapo" && isPortrait ? "4rem" : "0",
                                    position: 'relative',
                                    borderRadius: '0.5rem'
                                }}
                            />
                            {isPortrait &&
                                !element.videoUrl &&
                                !displayDetails &&
                                (element.anecdote ||
                                    (element.imageDetails && element.imageDetails.length > 0)) && (
                                    <img
                                        src={infoLogo}
                                        alt="infoLogo"
                                        className="infoAnimation"
                                        onClick={(): void => setDisplayDetails(true)}
                                        style={{
                                            // border: 'solid 5px white',
                                            minWidth: '2.2rem',
                                            maxWidth: '2.2rem',
                                            minHeight: '2.2rem',
                                            height: '2.2rem',
                                            borderRadius: '50%',
                                            position: 'absolute',
                                            bottom: '-2.5rem',
                                            left: '0.25rem'
                                            // backgroundColor: '#E5007B'
                                        }}
                                    />
                                    // <button
                                    //     className="infoAnimation"
                                    //     type="button"
                                    //     // eslint-disable-next-line react/jsx-props-no-spreading
                                    //     onClick={(): void => setDisplayDetails(true)}
                                    //     style={{
                                    //         // border: 'solid 5px white',
                                    //         minWidth: '2rem',
                                    //         maxWidth: '2rem',
                                    //         minHeight: '2rem',
                                    //         height: '2rem',
                                    //         position: 'absolute',
                                    //         bottom: '-2.5rem',
                                    //         left: '0.25rem',
                                    //         borderRadius: '50%',
                                    //         backgroundColor: '#E5007B'
                                    //     }}>
                                    //     <Typography
                                    //         sx={{
                                    //             color: 'white',
                                    //             fontSize: '1.3rem',
                                    //             fontWeight: '700',
                                    //             paddingTop: '5px'
                                    //         }}
                                    //         variant="p">
                                    //         {'i'}
                                    //     </Typography>
                                    // </button>
                                )}
                        </div>
                    )}
                </div>
                {element.videoUrl && element.videoUrl.includes('youtu') && needDisplayVideoNotAvailable && (
                    <div
                        style={{
                            height: '3rem',
                            marginTop: '1rem',
                            marginBottom: viewMode === "diapo" && isPortrait ? "4.5rem" : '0.5rem',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '1rem'
                        }}>
                        <Typography
                            sx={{
                                fontSize: '0.8753rem',
                                fontWeight: '700',
                                textAlign: 'center',
                                color: '#000',
                                maxWidth: '50%'
                            }}
                            variant="p">
                            {t('videoNotAvailable')}
                        </Typography>
                        {knowMore()}
                    </div>
                )}
                {viewMode === 'diapo' && !isPortrait && (
                    <>
                        {previous && (
                            <Button
                                pink
                                outline
                                sx={{
                                    position: 'absolute',
                                    borderRadius: '50%',
                                    border: displayDetails
                                        ? 'solid var(--button-bg-color-pink)'
                                        : 'none',
                                    top: '50%',
                                    width: '8rem',
                                    height: '8rem',
                                    left: '1rem',
                                    transform: 'translateY(-50%)'
                                }}
                                onClick={() => {
                                    saveStats();
                                    previous();
                                }}>
                                <img src={skip_previous} alt="skip_previous" />
                            </Button>
                        )}
                        {next && !NeedNextBotom && (
                            <Button
                                pink
                                outline
                                sx={{
                                    position: 'absolute',
                                    borderRadius: '50%',
                                    border: displayDetails
                                        ? 'solid var(--button-bg-color-pink)'
                                        : 'none',
                                    top: '50%',
                                    width: '8rem',
                                    height: '8rem',
                                    right: '1rem',
                                    transform: 'translateY(-50%)'
                                }}
                                onClick={() => {
                                    saveStats();
                                    next();
                                }}>
                                <img
                                    src={skip_previous}
                                    alt="skip_previous"
                                    style={{ transform: 'rotate(180deg)' }}
                                />
                            </Button>
                        )}
                    </>
                )}
                {NeedNextBotom && (
                    <Button
                        pink
                        sx={{
                            width: isPortrait ? '60%' : '40%',
                            minWidth: 'fit-content',
                            margin: '1rem auto'
                        }}
                        onClick={() => {
                            saveStats();
                            next && next();
                        }}>
                        {t('moveOn')}
                    </Button>
                )}
            </div>
            <div
                style={{
                    transition: !isPortrait ? 'left 0.3s ease-in-out' : 'top 0.3s ease-in-out',
                    width: !isPortrait ? '35%' : '100%',
                    height: !isPortrait ? '100%' : '30%',
                    left: displayDetails ? (!isPortrait ? '65%' : 0) : '100%',
                    top: displayDetails ? (!isPortrait ? 0 : '70%') : '100%',
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                    backgroundColor: '#0F0046',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: viewMode === 'diapo' && isPortrait ? '4rem' : '0'
                }}>
                <div style={{ padding: '1rem', paddingBottom: isPortrait ? '0' : '1rem' }}>
                    <img
                        src={backArrow}
                        alt="backArrow"
                        onClick={() => setDisplayDetails(false)}
                        style={{
                            // position: 'absolute',
                            // top: '1rem',
                            // left: '1rem',
                            rotate: isPortrait ? '90deg' : '0deg',
                            cursor: 'pointer',
                            height: isPortrait ? '1.75rem' : '2.25rem',
                            width: 'auto'
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '1rem',
                        height: !isPortrait ? '100%' : '',
                        width: '100%',
                        gap: '1rem'
                        // overflowY: 'auto'
                    }}>
                    <Typography
                        sx={{
                            color: 'white',
                            fontSize: '1.3rem',
                            fontWeight: '700',
                            marginBottom: '1rem',
                            textAlign: 'left',
                            width: '100%'
                        }}
                        variant="p">
                        {element.anecdote &&
                            element.anecdote.split('\n').map((item: string) => (
                                <>
                                    {item}
                                    <br />
                                </>
                            ))}
                    </Typography>
                    {element.imageDetails &&
                        element.imageDetails.map((item: any) => (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: '0.5rem'
                                }}>
                                <img src={accountBox} alt="accountBox" />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '1.3rem',
                                        fontWeight: '700',
                                        textAlign: 'left',
                                        width: '100%'
                                    }}
                                    variant="p">
                                    {item.firstName} {item.lastName}
                                    {item.relation && (item.firstName || item.lastName)
                                        ? ' - '
                                        : ''}
                                    {user?.role === Roles.SENIOR
                                        ? item.relation
                                              .replaceAll('Son', 'Mon')
                                              .replaceAll('Sa', 'Ma')
                                        : item.relation}
                                </Typography>
                            </div>
                        ))}
                </div>
            </div>
            {viewMode === 'diapo' && isPortrait && !NeedNextBotom && (
                <>
                    {previous && (
                        <Button
                            outline
                            sx={{
                                position: 'absolute',
                                border: !displayDetails ? 'solid var(--button-bg-color)' : 'none',
                                bottom: '1rem',
                                width: 'calc(50% - 2rem)',
                                left: '1rem'
                            }}
                            onClick={() => {
                                saveStats();
                                previous();
                            }}>
                            {t('prev')}
                        </Button>
                    )}
                    {next && (
                        <Button
                            pink
                            sx={{
                                position: 'absolute',
                                // border: displayDetails ? 'solid var(--button-bg-color-pink)' : 'none',
                                bottom: '1rem',
                                width: !previous ? 'calc(100% - 2rem)' : 'calc(50% - 2rem)',
                                right: '1rem'
                            }}
                            onClick={() => {
                                saveStats();
                                next();
                            }}>
                            {last ? t('terminer') : t('next')}
                        </Button>
                    )}
                </>
            )}
        </div>
    );
}

type ControlsProps = {
    playing: boolean;
    setPlaying: Dispatch<SetStateAction<boolean>>;
    playedSeconds: number;
    duration: number;
    playerRef: MutableRefObject<ReactPlayer>;
};

const Controls = (props: ControlsProps) => {
    const seek = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.playerRef.current.seekTo(+e.target.value, 'seconds');
    };

    return (
        <div>
            <button onClick={() => props.setPlaying(!props.playing)}>
                {props.playing ? <Button>pause</Button> : <Button>play</Button>}
            </button>
            <input
                type="range"
                value={props.playedSeconds}
                min="0"
                max={props.duration}
                onChange={seek}
            />
        </div>
    );
};
