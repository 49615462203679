import React, { CSSProperties, Component, useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import { makeStyles, createStyles } from '@mui/styles';
import { blueGrey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useLongPress } from 'use-long-press';
import PersonIcon from '@mui/icons-material/Person';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Paper, Grid, Card } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../assets/closeIcon.png';
import { getModalFormQuestions } from '../../../../services/viewFunctions';
import { ExerciseModalForm } from '../../../../models/ExerciseModalForm';
// import { InputComponent } from '../../components/FormComponents/InputComponent';
// import { SelectComponent } from '../../components/SelectComponent';
import { FullScreenModal } from '../FullScreenModal';
// import { PatientHomeViewData } from '../../../../models/PatientHomeViewData';
import { ExerciseType } from '../../../../models/ExerciseType';
// import { MemoryExerciseViewData } from '../../../../models/MemoryExerciseViewData';
import { ReviewExerciseAlerts } from './ReviewExerciseAlerts';
import { ExerciseItem } from '../../../../models/ExerciseItem';
import { UserContext } from '../../../../providers/UserProvider';
import { Exercise } from '../../../../models/Exercise';
import { Roles } from '../../../../models/Roles';
import { getImageKitUrlFrom, strDateTolongStr, translateDate } from '../../../../utilities/utils';
import { UserDataProp } from '../../../../models/userDataProp';
// import { User } from '../../../../models/User';
// import { DatePickerCustom } from '../../components/DatePickerCustom';
import './FormModalAlert.css';
import useWindowDimensions from '../../../../utilities/useWindowDimensions';
import { MediaDetails } from '../../../../components/MediaDetails';

const classes: { [key: string]: CSSProperties } = {
    btnLayout: {
        width: '100%',
        marginLeft: '0px'
        // '@media (max-width: 598px)': {
        //     cursor: 'pointer',
        //     width: '40%',
        //     margin: '16px 5px 30px 5px'
        // },
        // '@media (min-width: 1024px)': {
        //     cursor: 'pointer',
        //     width: '40%',
        //     margin: '16px 5px 30px 5px'
        // }
    },
    input: {
        color: '#002F55'
    },
    selectLayout: {
        width: '100%',
        marginTop: '0px',
        color: 'rgba(0, 47, 85, 1)',
        backgroundColor: '#D8D7D766',
        fontSize: '1.2rem'
    },
    selectPlaceholder: {
        width: '100%',
        marginTop: '0px',
        color: 'rgba(172, 188, 199, 1)'
    },
    fontBlue: {
        // '& .MuiOutlinedInput-input': {
        //     color: '#004680 !important'
        // }
    },
    paper: {
        backgroundColor: '#D8D7D766',
        padding: '5px 0 5px 8px',
        minHeight: '40px'
    },
    parentContentDiv: {
        width: '90%',
        margin: '3% 5%',
        textAlign: 'left',
        maxHeight: '90vh',
        minHeight: '75vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    contentDiv: {
        maxHeight: '90vh',
        minHeight: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    btnScrollDown: {
        background: 'white !important',
        color: '#0F0046',
        transform: 'rotate(90deg)',
        position: 'fixed',
        bottom: '0.5rem',
        left: '45.5%',
        minWidth: '2rem',
        width: '2rem',
        height: 'fit-content',
        padding: '8px',
        borderRadius: '16px'
    },
    btnScrollUp: {
        background: 'white !important',
        color: '#0F0046',
        transform: 'rotate(90deg)',
        position: 'fixed',
        bottom: '10rem',
        left: '45.5%',
        minWidth: '2rem',
        width: '2rem',
        height: 'fit-content',
        padding: '8px',
        borderRadius: '16px'
    },
    btnScrollDisabled: {
        opacity: '0',
        cursor: 'default'
    }
};

const LogoClose = styled.img`
    height: 50px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
`;

const NextExerciseButton = styled(Button)(() => ({
    border: '3px solid #D00070',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#D00070',
    padding: '7%',
    margin: '15% 0%',
    '&:hover': {
        border: '3px solid #D00070'
    }
}));

interface FormModalProps {
    exerciseItemList?: ExerciseItem[];
    closeModal: Function;
    exerciseReceived: ExerciseItem;
    exercise: Exercise | undefined;
    difficultyLevel: number;
    displayInfo?: boolean;
    puzzleClueCount?: number;
    quizBackFunction?: Function;
}

export function FormModalAlert({
    exerciseItemList,
    closeModal,
    exerciseReceived,
    exercise,
    difficultyLevel,
    displayInfo = false,
    puzzleClueCount,
    quizBackFunction
}: FormModalProps): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [openFormModal, setOpenFormModal] = useState(true);
    const [openReview, setOpenReview] = useState(false);
    const [date, setDate] = useState('');
    const [personOnPicture, setPersonOnPicture] = useState<string[]>([]);
    const [showInfo, setShowInfo] = useState(
        !!(displayInfo && (exerciseReceived.anecdote || exerciseReceived.imageDetails.length))
    );
    const { height, width } = useWindowDimensions();
    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [hasContentToScroll, setHasContentToScroll] = useState<boolean>(false);

    useEffect(() => {
        const container = containerRef.current;
        if (container && container?.scrollHeight > container?.clientHeight) {
            setHasContentToScroll(true);
        }
    }, []);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;
        setScrollPosition(target.scrollTop);
    };

    const handleScrollButtonClick = (increment: number) => {
        if (containerRef.current) {
            containerRef.current.scrollTop += increment;
            setScrollPosition(containerRef.current.scrollTop);
        }
    };

    const updateMemoryExerciseCompleted = async (id: string): Promise<void> => {
        if (user && user.id && exercise) {
            quizBackFunction && quizBackFunction();
            // if (exercise) {
            //     history.push({
            //         pathname: `/quiz/${exercise.id}`,
            //         state: { exercisesItem: exerciseItemList, exercises: exercise }
            //     });
            // }
        }
    };

    const updateReorderExerciseCompleted = async (id: string): Promise<void> => {
        if (user && user.id && exercise) {
            if (exercise) {
                history.push({
                    pathname: `/reordon`,
                    state: { exerciseDone: true, exercises: exercise }
                });
            }
        }
    };

    const updatePuzzleExerciseCompleted = async (id: string): Promise<void> => {
        if (user && user.id && exercise) {
            setOpenReview(false);
            if (exercise.exerciseType === ExerciseType.PUZZLE) {
                setOpenReview(true);
            } else {
                setOpenFormModal(false);
                history.push({
                    pathname: `/quiz/${exercise.id}`,
                    state: { exercises: exerciseItemList }
                });
            }
        }
    };

    const onExerciseSelect = async (exerciseItem: ExerciseItem): Promise<void> => {
        if (exercise?.exerciseType) {
            switch (exercise.exerciseType) {
                case ExerciseType.QUIZ:
                    if (exerciseItem.id) {
                        updateMemoryExerciseCompleted(exerciseItem.id);
                    }
                    break;
                case ExerciseType.PUZZLE:
                    if (exerciseItem.id) {
                        updatePuzzleExerciseCompleted(exerciseItem.id);
                    }
                    break;
                case ExerciseType.ORDER:
                    if (exerciseItem.id) {
                        updateReorderExerciseCompleted(exerciseItem.id);
                    }
                    break;
                default:
                    history.push(`/404}`);
            }
        }
    };

    const [exerciseModalForm, setExerciseModalQuestions] = useState<ExerciseModalForm>({
        firstQuestion: '',
        secondQuestion: '',
        thirdQuestion: '',
        fourthQuestion: ''
    });

    // Open the summary of the picture
    useEffect(() => {
        const exerciseModalQuestions = getModalFormQuestions();
        if (exerciseModalQuestions) {
            setExerciseModalQuestions(exerciseModalQuestions);
        }
    }, []);

    const fetchPersonInPhoto = (): void => {
        const personNameList: string[] = [];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < exerciseReceived.imageDetails.length; i += 1) {
            const personName = `${exerciseReceived.imageDetails[i].firstName} ${exerciseReceived.imageDetails[i].lastName} - ${exerciseReceived.imageDetails[i].relation}`;
            personNameList.push(personName);
        }
        setPersonOnPicture(personNameList);
    };

    useEffect(() => {
        if (exerciseReceived.photoCapturedOn) {
            const dateToTranslate = exerciseReceived.photoCapturedOn;
            setDate(translateDate(dateToTranslate?.toString()));
        }
        fetchPersonInPhoto();
    }, []);

    // Close the summary modal and make appear the review for patient
    const handleCloseFormModal = (): void => {
        onExerciseSelect(exerciseReceived);
    };

    const peopleList = (): JSX.Element[] => {
        return exerciseReceived.imageDetails.map((people): JSX.Element => {
            return (
                <Typography
                    key={people.id}
                    sx={{
                        color: 'white',
                        fontSize: '20px !important',
                        paddingBottom: '20px',
                        textAlign: 'left',
                        lineHeight: '1.75rem',
                        paddingLeft: '40px',
                        paddingRight: '40px',
                        fontFamily: 'Luciole-Regular',
                        display: 'inline-block',
                        alignItems: 'center',
                        flex: '1',
                        flexWrap: 'wrap'
                    }}>
                    <PersonIcon
                        style={{
                            backgroundColor: '#FFF',
                            color: '#0F0046',
                            borderRadius: '5px',
                            height: '20px',
                            width: '20px'
                        }}
                    />
                    &nbsp;
                    {people.firstName} {people.lastName} - {people.relation}
                </Typography>
            );
        });
    };

    const bindLongPressInfo = useLongPress(
        () => {
            setShowInfo(true);
        },
        { threshold: 1000 }
    );

    const informationBtn = (): JSX.Element => {
        return (
            <button
                type="button"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindLongPressInfo()}
                onClick={(): void => setShowInfo(true)}
                className="infoAnimation"
                style={{
                    border: 'solid 5px white',
                    display: showInfo ? 'none' : 'block',
                    minWidth: '70px',
                    height: '70px',
                    borderRadius: '35px',
                    backgroundColor: '#E5007B',
                    position: 'absolute',
                    right: '50px',
                    top: '30px',
                    cursor: 'pointer'
                }}>
                <Typography
                    sx={{
                        color: 'white',
                        fontSize: '2rem !important',
                        fontWeight: '700'
                    }}
                    variant="body1">
                    {t('i')}
                </Typography>
            </button>
        );
    };

    const bindLongPressClose = useLongPress(
        () => {
            onExerciseSelect(exerciseReceived);
        },
        { threshold: 1000 }
    );

    const bindLongPressInfoClose = useLongPress(
        () => {
            setShowInfo(false);
        },
        { threshold: 1000 }
    );

    const goNext = async (): Promise<void> => {
        onExerciseSelect(exerciseReceived);
    };

    const goLongNext = async (): Promise<void> => {
        bindLongPressClose();
    };

    const modalForm = (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: '1300',
            background: 'white',
        }}>
            <MediaDetails
                element={exerciseReceived}
                close={goNext}
                viewMode="diapo"
                next={()=>goNext()}
                NeedNextBotom={true}
                last={true}
            />
        </div>
    );

    return (user?.role === Roles.FAMILY || user?.role === Roles.PRO) && openReview ? (
        <ReviewExerciseAlerts
            exercise={exercise}
            exerciseType={ExerciseType.PUZZLE}
            difficultyLevel={difficultyLevel}
            errorCount={0}
            clueCount={puzzleClueCount ?? 0}
        />
    ) : (
        <div>
            {modalForm}
            {/* <FullScreenModal
                bodyText={modalForm}
                isModelOpen={openFormModal}
                title=""
                buttonText=""
                next={goNext}
                longNext={goLongNext}
            /> */}
            {openReview && exercise && (
                <ReviewExerciseAlerts
                    exercise={exercise}
                    exerciseType={ExerciseType.PUZZLE}
                    difficultyLevel={difficultyLevel}
                    errorCount={0}
                    clueCount={puzzleClueCount ?? 0}
                />
            )}
        </div>
    );
}
