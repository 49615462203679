import React from 'react';

import styles from './styles.module.css';
import Heading from '../Heading';
import Action from '../Action';

import LeftArrow from './leftArrow.svg?react';
import Exit from './exit.svg?react';
import RightArrow from './rightArrow.svg?react';
import Clock from '../Clock';
import Points from '../Points';

interface GameHeaderProps {
    title?: string;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    rtnAction?: true;
    quitAction?: true;
    time?: number;
    color?: string;
    points?: number;
    onTouch?: () => void;
}

const RntButton = () => {
    return (
        <Action className={styles.rntButton} action={() => history.back()}>
            <div className={[styles.btn, styles.left].join(' ')}>
                <LeftArrow />
                <div className={styles.textBtn}>Retour</div>
            </div>
        </Action>
    );
}

const QuitButton = () => {
    return (
        <Action className={styles.quitButton} action='/home'>
            <div className={[styles.btn, styles.right].join(' ')}>
                <div className={styles.textBtn}>Quitter</div>
                <Exit />
                <RightArrow className={styles.rightArrow} />
            </div>
        </Action>
    );
}

const GameHeader: React.FC<GameHeaderProps> = ({ title, Icon, quitAction, time, points, rtnAction = true, color, onTouch }) => {
    return (
        <div className={styles.root} onTouchStart={() => onTouch ? onTouch() : null} onMouseDown={() => onTouch ? onTouch() : null}>
            {rtnAction && <RntButton />}
            <div className={styles.titleContainer}>
                {!!time && <Clock time={time} color={color} />}
                {Icon && <Icon className={styles.icon} />}
                {title && <div className={styles.title}>{title}</div>}
                {points !== undefined && <Points points={points} color={color} />}
            </div>
            {quitAction && <QuitButton />}
        </div>
    );
};

export default GameHeader;