import { Timestamp } from 'firebase/firestore';

export class MediaStatistics {
    mediaId: string;

    typeOfMedia: 'picture' | 'personalVideo' | 'youtubeVideo';

    date: Timestamp;

    videoDetails:
        | {
              videoUrl: string;
              authorId: string;
              watchTime: number;
          }
        | undefined;

    constructor(
        mediaId: string,
        typeOfMedia: 'picture' | 'personalVideo' | 'youtubeVideo',
        date: Timestamp,
        videoDetails:
            | {
                  videoUrl: string;
                  authorId: string;
                  watchTime: number;
              }
            | undefined
    ) {
        this.mediaId = mediaId;
        this.typeOfMedia = typeOfMedia;
        this.date = date;
        this.videoDetails = videoDetails;
    }
}
