import { CSSProperties, LegacyRef, useContext, useEffect, useState } from 'react';
import { Typography } from './Typography';
import {
    getImageKitUrlFrom,
    stringToColor,
} from '../utilities/utils';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Roles } from '../models/Roles';
import {
    deleteExerciseItemCard,
    deletePersonalVideo,
    deletePersonalYoutubeVideo,
} from '../services/cloudFirestore';
import PlayButtonVideo from '../assets/PlayButtonVideo.svg';
import PlayButtonYoutube from '../assets/PlayButtonYoutube.svg';
import videoUnavailableImage from '../assets/videoUnavailable.png';
import edit from '../assets/edit.png';
import closeBlue from '../assets/closeBlue.svg';
import { Button } from './Button';
import { t } from 'i18next';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { DeleteModal } from './DeleteModal';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        backgroundColor: 'var(---color-white)',
        color: 'var(--vignette-games-text-color)',
        padding: '0',
        boxShadow: '0px 4px 8px rgba(226, 199, 199, 0.7)',
        borderRadius: '8px',
        marginBottom: '1rem',
        position: 'relative',
        cursor: 'pointer'
        // maxWidth: '250px'
    },
    'img-exercise': {
        width: '100%',
        borderRadius: '8px 8px 0 0',
        aspectRatio: '1/1',
        objectFit: 'cover'
    },
    'img-video-container': {
        width: '100%',
        height: 'auto',
        position: 'relative',
        // borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        // padding: '5px 5px 5px',
        alignItems: 'center'
    },
    'img-play': {
        width: '30%',
        height: '30%',
        position: 'absolute',
        zIndex: 1
    },
    'img-video-yt': {
        width: '100%',
        borderRadius: '8px 8px 0 0',
        aspectRatio: '1/1',
        objectFit: 'cover'
    },
    'img-video-perso': {
        width: '100%',
        borderRadius: '8px 8px 0 0',
        aspectRatio: '1/1',
        objectFit: 'cover'
    },
    'img-author': {
        height: 'auto',
        width: '20%',
        aspectRatio: '1/1',
        borderRadius: '50%',
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
        zIndex: 1
    },
    'btn-edit': {
        padding: '2.5%',
        height: '12%',
        minHeight: '12%',
        aspectRatio: '1/1',
        width: '12%',
        borderRadius: '50%',
        position: 'absolute',
        bottom: '0.5rem',
        left: '0.5rem',
        backgroundColor: '#ffffff',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #BABABA'
    },
    'btn-edit-img': {
        height: '100%',
        width: '100%'
    },
    'div-author': {
        height: 'auto',
        width: '20%',
        aspectRatio: '1/1',
        borderRadius: '50%',
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF'
    },
    'div-edit': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 10,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: '0.5rem'
    },
    'btn-delete': {
        color: '#D00070',
        backgroundColor: '#f4e0eb',
        fontWeight: 'bold',
        border: 'none'
    },
    'btn-close': {
        padding: '2.5%',
        position: 'absolute',
        top: '0.5rem',
        right: '50%',
        transform: 'translateX(50%)',
        zIndex: 11,
        height: '12%',
        minHeight: '12%',
        width: 'min-content',
        aspectRatio: '1/1',
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        border: '2px solid var(--button-bg-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

interface VignetteAlbumProps {
    itemData: any;
    displayAlbumType: 'picture' | 'personal-video' | 'youtube-video' | null;
    deleteItem: (itemData: any) => void;
    editMedia: (
        id: string,
        type: 'picture' | 'personal-video' | 'youtube-video',
        videoUrl: string | undefined,
        thumbnail: string | undefined
    ) => void;
    onClick?: () => void;
}

export function VignetteAlbum({
    itemData,
    displayAlbumType,
    deleteItem,
    editMedia,
    onClick
}: VignetteAlbumProps): JSX.Element {
    const { height, width } = useWindowDimensions();
    const [thisType, setThisType] = useState<'picture' | 'personal-video' | 'youtube-video'>();
    const [isOnEdit, setIsOnEdit] = useState<boolean>(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [right, setRight] = useState<'write' | 'read' | null>(null);
    const [canvasImg] = useState<LegacyRef<HTMLCanvasElement> | undefined>();

    useEffect(() => {
        if (user && itemData.proList) {
            itemData.proList.forEach((pro: { [key: string]: 'write' | 'read' }) => {
                if (pro && Object.keys(pro)[0] === user.id) {
                    setRight(Object.values(pro)[0]);
                }
            });
        }
    }, [user, itemData.proList]);

    useEffect(() => {
        if (itemData) {
            if (Object.prototype.hasOwnProperty.call(itemData, 'videoUrl')) {
                if (itemData.videoUrl.includes('firebase')) {
                    setThisType('personal-video');
                } else {
                    setThisType('youtube-video');
                }
            } else {
                setThisType('picture');
            }
        }
    }, [itemData]);

    const deleteItemFunc = (): void => {
        if (user && (itemData.authorId === user.userId || itemData.authorId === user.id)) {
            let communityId = '';
            if (user.role === Roles.SENIOR || user.role === Roles.FAMILY) {
                communityId = user.familyCode ?? '';
            } else if (user.role === Roles.PRO) {
                communityId = user.establishmentCode ?? '';
            } else if (user.role === Roles.ADMIN) {
                communityId = user.id ?? '';
            }

            if (
                !Object.prototype.hasOwnProperty.call(itemData, 'videoUrl') &&
                typeof itemData.exerciseImage === 'string'
            ) {
                // img perso
                deleteExerciseItemCard(itemData.id).then(() => {
                    deleteItem(itemData);
                });
            } else if (
                Object.prototype.hasOwnProperty.call(itemData, 'videoUrl') &&
                itemData.videoUrl.includes('firebase')
            ) {
                // video perso
                deletePersonalVideo(communityId, itemData.videoUrl).then(() => {
                    deleteItem(itemData);
                });
            } else if (
                Object.prototype.hasOwnProperty.call(itemData, 'videoUrl') &&
                !itemData.videoUrl.includes('firebase')
            ) {
                // video youtube
                deletePersonalYoutubeVideo(communityId, itemData.videoUrl).then(() => {
                    deleteItem(itemData);
                });
            }
        }
    };

    return itemData.authorId === user?.id || true ? (
        <>
            {displayDeleteModal && (
                <DeleteModal
                    type={
                        !Object.prototype.hasOwnProperty.call(itemData, 'videoUrl')
                            ? 'picture'
                            : itemData.videoUrl.includes('firebase')
                            ? 'personal-video'
                            : 'youtube-video'
                    }
                    onClose={() => setDisplayDeleteModal(false)}
                    deleteFunc={() => deleteItemFunc()}
                />
            )}
            <div
                onClick={onClick}
                style={{
                    ...classes['div-root'],
                    ...{
                        display:
                            !displayAlbumType || displayAlbumType === thisType ? 'block' : 'none'
                    }
                    // ...(width && width < 554
                    //     ? { width: '100%' }
                    //     : width && width < 768
                    //     ? { width: 'calc(100% / 2 - 8px)' }
                    //     : width && width < 1024
                    //     ? { width: 'calc(100% / 3 - 12px)' }
                    //     : { width: 'calc(100% / 3 - 12px)' })
                }}>
                {itemData.authorPP && itemData.authorType && (
                    <img
                        src={
                            itemData.authorType === Roles.ADMIN
                                ? itemData.authorPP
                                : getImageKitUrlFrom(itemData.authorPP, 150, 150)
                        }
                        alt="exerciseAuthor"
                        style={{
                            ...classes['img-author'],
                            border:
                                itemData.authorType === Roles.ADMIN
                                    ? 'none'
                                    : itemData.authorType === Roles.PRO
                                    ? '2px solid #00FFFF'
                                    : '2px solid #E5007B'
                        }}
                    />
                )}
                {itemData.authorFirstName && itemData.authorLastName && !itemData.authorPP && (
                    <div
                        style={{
                            ...classes['div-author'],
                            border:
                                itemData.authorType === Roles.PRO
                                    ? '2px solid #00FFFF'
                                    : '2px solid #E5007B',
                            backgroundColor: stringToColor(
                                `${itemData.authorFirstName} ${itemData.authorLastName}`
                            )
                        }}>
                        {`${itemData.authorFirstName.split('')[0]}${
                            itemData.authorLastName.split('')[0]
                        }`}
                    </div>
                )}
                {itemData &&
                    !Object.prototype.hasOwnProperty.call(itemData, 'videoUrl') &&
                    typeof itemData.exerciseImage === 'string' && (
                        <div style={classes['img-video-container']}>
                            <img
                                style={{
                                    ...classes['img-exercise'],
                                    borderRadius:
                                        (Object.prototype.hasOwnProperty.call(
                                            itemData,
                                            'videoUrl'
                                        ) &&
                                            itemData.title) ||
                                        (!Object.prototype.hasOwnProperty.call(
                                            itemData,
                                            'videoUrl'
                                        ) &&
                                            itemData.answer)
                                            ? '8px 8px 0 0'
                                            : '8px'
                                }}
                                src={getImageKitUrlFrom(itemData.exerciseImage, 1024, 1024)}
                                alt="SampleImg"
                            />
                            {user && (itemData.authorId === user.userId || itemData.authorId === user.id || right !== null) && (
                                <Button
                                    sx={{
                                        ...classes['btn-edit']
                                    }}
                                    onClick={(e) => {
                                        setIsOnEdit(true);
                                        e.stopPropagation();
                                    }}>
                                    <img
                                        src={edit}
                                        alt="edit"
                                        style={{ ...classes['btn-edit-img'] }}
                                    />
                                </Button>
                            )}
                        </div>
                    )}

                {itemData &&
                    Object.prototype.hasOwnProperty.call(itemData, 'videoUrl') &&
                    !itemData.videoUrl.includes('firebase') && (
                        // (mediaTypeToShow === 'videoYT' || mediaTypeToShow === undefined) &&
                        // !itemData.videoUrl.includes('firebase') && (
                        <div style={classes['img-video-container']}>
                            {user && (itemData.authorId === user.userId || itemData.authorId === user.id || right !== null) && (
                                <Button
                                    sx={{
                                        ...classes['btn-edit']
                                    }}
                                    onClick={(e) => {
                                        setIsOnEdit(true);
                                        e.stopPropagation();
                                    }}>
                                    <img
                                        src={edit}
                                        alt="edit"
                                        style={{ ...classes['btn-edit-img'] }}
                                    />
                                </Button>
                            )}
                            <img
                                src={PlayButtonYoutube}
                                alt="PlayButtonYoutube"
                                style={classes['img-play']}
                            />
                            <img
                                style={{
                                    ...classes['img-video-yt'],
                                    ...(!itemData.title ? { borderRadius: '8px' } : {})
                                }}
                                src={`https://img.youtube.com/vi/${itemData.videoUrl.match(
                                    /[\w\\-]{11,}/
                                )}/0.jpg`}
                                onLoad={(e) => {
                                    // check dimensions of image if imgage is error from youtube
                                    const img = e.currentTarget;
                                    if (img.naturalWidth === 120 && img.naturalHeight === 90) {
                                        e.currentTarget.src = videoUnavailableImage;
                                    }
                                }}
                                alt="SampleImg"
                            />
                        </div>
                    )}
                {itemData &&
                    Object.prototype.hasOwnProperty.call(itemData, 'videoUrl') &&
                    itemData.videoUrl.includes('firebase') && (
                        // (mediaTypeToShow === 'videoYT' || mediaTypeToShow === undefined) &&
                        // !itemData.videoUrl.includes('firebase') && (
                        <div style={classes['img-video-container']}>
                            {user && (itemData.authorId === user.userId || itemData.authorId === user.id || right !== null) && (
                                <Button
                                    sx={{
                                        ...classes['btn-edit']
                                    }}
                                    onClick={(e) => {
                                        setIsOnEdit(true);
                                        e.stopPropagation();
                                    }}>
                                    <img
                                        src={edit}
                                        alt="edit"
                                        style={{ ...classes['btn-edit-img'] }}
                                    />
                                </Button>
                            )}
                            <img
                                src={PlayButtonVideo}
                                alt="PlayButtonVideo"
                                style={classes['img-play']}
                            />
                            <img
                                style={{
                                    ...classes['img-video-perso'],
                                    ...(!itemData.title ? { borderRadius: '8px' } : {})
                                }}
                                src={
                                    itemData.thumbnail
                                        ? getImageKitUrlFrom(itemData.thumbnail, 1024, 1024)
                                        : ''
                                }
                                alt="SampleImg"
                            />
                            {/* <video
                                style={{ ...classes['img-video-perso'], ...(!itemData.title ? {borderRadius: '8px'} : {}) }}
                                playsInline // required to tell iOS safari we don't want fullscreen
                                src={`${itemData.videoUrl}#t=0.01`}
                                // onCanPlay={(e) => {
                                //     const video = e.currentTarget;
                                //     // video.play();
                                //     const canvas = document.getElementById(
                                //         `canvas-${itemData.videoUrl}`
                                //     ) as HTMLCanvasElement;
                                //     canvas
                                //         .getContext('2d')
                                //         ?.drawImage(video, 0, 0, canvas.width, canvas.height);
                                // }}
                            /> */}
                        </div>
                    )}
                {((Object.prototype.hasOwnProperty.call(itemData, 'videoUrl') && itemData.title) ||
                    (!Object.prototype.hasOwnProperty.call(itemData, 'videoUrl') &&
                        itemData.answer)) && (
                    <Typography
                        sx={{
                            color: 'var(--vignette-games-text-color)',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            lineHeight: '1.2rem',
                            justifyContent: 'center',
                            padding: '1rem 0.5rem',
                            textAlign: 'center',
                            height: 'fit-content',
                            fontSize: '1rem',
                            fontWeight: '500'
                            // margin: '0.5rem 0px'
                        }}
                        children={undefined}
                        dangerouslySetInnerHTML={{
                            __html: Object.prototype.hasOwnProperty.call(itemData, 'videoUrl')
                                ? itemData.title
                                : itemData.answer
                        }}
                    />
                )}
                {isOnEdit && user && (itemData.authorId === user.userId || itemData.authorId === user.id || right !== null) && (
                    <div
                        style={classes['div-edit']}
                        onClick={(e): void => {
                            setIsOnEdit(false);
                            e.stopPropagation();
                        }}>
                        <Button
                            sx={classes['btn-close']}
                            onClick={(e): void => {
                                setIsOnEdit(false);
                                e.stopPropagation();
                            }}>
                            <img
                                src={closeBlue}
                                alt="close"
                                style={{ ...classes['btn-edit-img'] }}
                            />
                        </Button>
                        {right === 'read' && (
                            <Button
                                outline
                                onClick={(e): void => {
                                    e.stopPropagation();
                                    editMedia(
                                        itemData.id,
                                        thisType ?? 'picture',
                                        itemData.videoUrl,
                                        itemData.thumbnail
                                    );
                                }}>
                                {t('sendSenior')}
                            </Button>
                        )}
                        {(itemData.authorId === user.userId || itemData.authorId === user.id || right === 'write') && (
                            <Button
                                // sx={classes['btn-delete']}
                                outline
                                onClick={(e): void => {
                                    e.stopPropagation();
                                    // setDisplayDeleteModal(true);
                                    editMedia(
                                        itemData.id,
                                        thisType ?? 'picture',
                                        itemData.videoUrl,
                                        itemData.thumbnail
                                    );
                                }}>
                                {t('edit')}
                            </Button>
                        )}
                        {(itemData.authorId === user.userId || itemData.authorId === user.id) && (
                            <Button
                                sx={classes['btn-delete']}
                                onClick={(e): void => {
                                    e.stopPropagation();
                                    setDisplayDeleteModal(true);
                                }}>
                                {t('delete')}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </>
    ) : (
        <></>
    );
}
