import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UserContext } from './providers/UserProvider';
import { Roles } from './models/Roles';
import { Login } from './pages/basic/Login';
import { PageNotFound } from './pages/basic/PageNotFound';
import { Home } from './pages/home/Home';
import { useAuth0 } from '@auth0/auth0-react';
import Tourbillon from './pages/games/Tourbillon';
import { Register } from './pages/basic/Register';
import { ForgotPassword } from './pages/basic/ForgotPassword';
import { useTranslation } from 'react-i18next';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { isPlatform } from '@ionic/react';
import { ModalInfo } from './components/ModalInfo';
import { Typography } from './components/Typography';
import { Button } from './components/Button';
import { App } from '@capacitor/app';
import { addStatTimeUseApp } from './services/cloudFirestore';
import { StatTimeUseApp } from './models/StatTimeUseApp';
import { Timestamp } from 'firebase/firestore';
import LevelChoices from './pages/games/LevelChoices';
import PingPong from './pages/games/PingPong';
import Memory from './pages/games/Memory';
import Sudok from './pages/games/Sudok';
import Puzzle from './pages/games/Puzzle';
import Reordon from './pages/games/Reordon';
import MeliMots from './pages/games/MeliMots';
import Quizz from './pages/games/Quizz';

function PatientRoutes(): JSX.Element {
    return <Switch>{/* <Route path="/home" exact component={AdminDashboard} /> */}</Switch>;
}
function FamilyRoutes(): JSX.Element {
    return <Switch>{/* <Route path="/home" exact component={AdminDashboard} /> */}</Switch>;
}
function TherapistRoutes(): JSX.Element {
    return <Switch>{/* <Route path="/home" exact component={AdminDashboard} /> */}</Switch>;
}
function AdminRoutes(): JSX.Element {
    return <Switch>{/* <Route path="/home" exact component={AdminDashboard} /> */}</Switch>;
}

function setUserRoutes(): JSX.Element {
    const userDataProp = useContext(UserContext);
    const user = userDataProp?.user;
    if (user === null) {
        document.documentElement.style.setProperty('--ion-font-family', 'Poppins-Regular');
        return <Redirect to="/login" />;
    }
    const role = user?.role;
    switch (role) {
        case Roles.SENIOR:
            document.documentElement.style.setProperty('--ion-font-family', 'Poppins-Regular, sans-serif');
            return PatientRoutes();
        case Roles.FAMILY:
            document.documentElement.style.setProperty('--ion-font-family', 'Poppins-Regular');
            return FamilyRoutes();
        case Roles.PRO:
            document.documentElement.style.setProperty('--ion-font-family', 'Poppins-Regular');
            return TherapistRoutes();
        case Roles.ADMIN:
            document.documentElement.style.setProperty('--ion-font-family', 'Poppins-Regular');
            return AdminRoutes();
        default:
            document.documentElement.style.setProperty('--ion-font-family', 'Poppins-Regular');
            return <Route path="/401" component={PageNotFound} />;
    }
}

export function Router(): JSX.Element {
    const [openUpdate, setOpenUpdate] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth0();
    // const userDataProp: UserDataProp | null = useContext(UserContext);
    // const user = userDataProp?.user;

    useEffect(() => {
        let timeLaunched: Date;
        const handleAppFocus = () => {
            // console.log('App has come to the foreground!');
            timeLaunched = new Date();
        };

        const handleAppBlur = () => {
            if (timeLaunched && user?.sub?.replace('auth0|', '')) {
                const timeSpent = Math.round(
                    (new Date().getTime() - timeLaunched?.getTime()) / 1000
                );
                // console.log('App has come to the background!', timeSpent);
                if (timeSpent > 1) {
                    addStatTimeUseApp(
                        new StatTimeUseApp(
                            user?.sub?.replace('auth0|', ''),
                            Timestamp.fromDate(timeLaunched),
                            timeSpent
                        )
                    );
                }
            }
        };
        const handleAppStateChange = (state: { isActive: boolean }) => {
            // console.log('handleAppStateChange', user);
            // pourquoi user est null ici ?
            if (state.isActive) {
                handleAppFocus();
            } else {
                handleAppBlur();
            }
        };

        // Ajoutez les écouteurs d'événements
        App.addListener('appStateChange', handleAppStateChange);
        window.addEventListener('focus', handleAppFocus);
        window.addEventListener('blur', handleAppBlur);

        return () => {
            App.removeAllListeners();
            window.removeEventListener('focus', handleAppFocus);
            window.removeEventListener('blur', handleAppBlur);
        };
    }, [user]);

    const getAppUpdateInfo = async () => {
        const result = await AppUpdate.getAppUpdateInfo();
        // if (result.availableVersion !== result.currentVersion) {
        //     setOpenUpdate(true);
        // }
        if (
            isPlatform('android') &&
            parseInt(result.availableVersionCode ?? '') > parseInt(result.currentVersionCode)
        ) {
            setOpenUpdate(true);
        }
        if (isPlatform('ios')) {
            const arrayAvailable = (result.availableVersionName ?? '').split('.');
            const arrayCurrent = result.currentVersionName.split('.');
            if (
                parseInt(arrayCurrent[0]) < parseInt(arrayAvailable[0]) ||
                (parseInt(arrayCurrent[0]) === parseInt(arrayAvailable[0]) &&
                    parseInt(arrayCurrent[1]) < parseInt(arrayAvailable[1])) ||
                (parseInt(arrayCurrent[0]) &&
                    parseInt(arrayAvailable[0]) &&
                    parseInt(arrayCurrent[1]) === parseInt(arrayAvailable[1]) &&
                    parseInt(arrayCurrent[2]) < parseInt(arrayAvailable[2]))
            ) {
                setOpenUpdate(true);
            }
        }
        // return result;
    };
    if (isPlatform('mobile') && !isPlatform('mobileweb')) {
        getAppUpdateInfo();
    }
    return (
        <>
            <Switch>
                <Route path="/" exact>
                    <Redirect to="/home" />
                </Route>
                <Route path="/home" exact component={Home} />
                <Route path="/login" exact component={Login} />
                <Route path="/login/:email" exact component={Login} />
                <Route path="/register" exact component={Register} />
                <Route path="/register/:code" exact component={Register} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                <Route path="/profile" exact component={Home} />
                <Route path="/news" exact component={Home} />
                <Route path="/games" exact component={Home} />
                <Route path="/album" exact component={Home} />
                <Route path="/:game/:id/lvl" exact component={LevelChoices} />
                <Route path="/sudok/:id/lvl/:lvlId" exact component={Sudok} />
                <Route path="/tourbillon/:id/lvl/:lvlId" exact component={Tourbillon} />
                <Route path="/memory/:id/lvl/:lvlId" exact component={Memory} />
                <Route path="/pingpong/:id/lvl/:lvlId" exact component={PingPong} />
                <Route path="/memoriz/:id/lvl/:lvlId" exact component={Sudok} />
                <Route path="/puzzle/:id/lvl/:lvlId" exact component={Puzzle} />
                <Route path="/reordon/:id/lvl/:lvlId" exact component={Reordon} />
                <Route path="/melimots/:id/lvl/:lvlId" exact component={MeliMots} />
                <Route path="/quizz/:id/lvl/:lvlId" exact component={Quizz} />
                {setUserRoutes()}
                <Route path="/404" component={PageNotFound} />;
                <Route path="*">
                    <Redirect to="/404" />
                </Route>
            </Switch>
            <ModalInfo title={t('UpdateTitle') ?? ''} isOpen={openUpdate}>
                <>
                    <Typography variant="p" sx={{ textAlign: 'left' }}>
                        {t('UpdateText')}
                    </Typography>
                    <Button pink onClick={() => AppUpdate.openAppStore()}>
                        {t('UpdateButton')}
                    </Button>
                </>
            </ModalInfo>
        </>
    );
}
