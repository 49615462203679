import { ExerciseType } from './ExerciseType';
import { StatisticsDetails } from './StatisticsDetails';

export class SeniorStatistics {
    exerciseId: string;

    typeOfExercise: ExerciseType;

    details: StatisticsDetails[];

    constructor(
        exerciseId: string,
        typeOfExercise: ExerciseType,
        details: StatisticsDetails[]
    ) {
        this.exerciseId = exerciseId;
        this.typeOfExercise = typeOfExercise;
        this.details = details;
    }
}
