import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../utilities/utils.css';
import { ExerciseType } from '../models/ExerciseType';
import { Button } from './Button';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Select } from './Select';

const classes = {
    divRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '2rem'
    },
    divNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        backgroundColor: 'var(--color-white)',
        padding: '8px',
        height: '55px',
        borderRadius: '64px'
    },
    btnScroll: {
        width: 'fit-content',
        height: 'fit-content',
        padding: '10px 32px',
        borderRadius: '24px'
    }
} as Record<string, CSSProperties>;

interface MediaSelectorProps {
    setMediaType: Function;
    displayMediaType: 'picture' | 'personal-video' | 'youtube-video' | null;
}

export function MediaSelector({ setMediaType, displayMediaType }: MediaSelectorProps): JSX.Element {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const tabNav = [
        { name: t('allMedia'), value: null },
        { name: t('picture'), value: 'picture' },
        { name: t('personalVideo'), value: 'personal-video' },
        { name: t('youtubeVideo'), value: 'youtube-video' }
    ];

    // return <div style={classes.divoot']}></div>;
    return (
        <div style={classes.divRoot}>
            {width && width > 768 ? (
                <div style={classes.divNav}>
                    {tabNav.map((nav) => (
                        <Button
                            key={nav.name}
                            disabled={nav.value === displayMediaType}
                            sx={{
                                ...classes.btnScroll,
                                backgroundColor:
                                    nav.value === displayMediaType
                                        ? 'var(--nav-selected-bg-color)'
                                        : 'var(--color-white)',
                                color:
                                    nav.value === displayMediaType
                                        ? 'var(--color-white)'
                                        : 'var(--nav-text-color)'
                            }}
                            onClick={() => setMediaType(nav.value)}>
                            {nav.name}
                        </Button>
                    ))}
                </div>
            ) : (
                <Select
                    sxRoot={{ padding: '0px 0.5rem' }}
                    key="mediaType"
                    opts={[
                        { value: 'null', label: t('allMedia') },
                        { value: 'picture', label: t('picture') },
                        { value: 'personal-video', label: t('personalVideo') },
                        { value: 'youtube-video', label: t('youtubeVideo') }
                    ]}
                    value={displayMediaType || 'null'}
                    onChange={(e): void => {
                        if (e.target.value === 'null') setMediaType(null);
                        else setMediaType(e.target.value as ExerciseType);
                    }}
                />
            )}
        </div>
    );
}
