import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from './Button';
import { Exercise } from '../models/Exercise';
import { getImageKitUrlFrom, getUrlToGamePage } from '../utilities/utils';
import { PatientHomeData } from '../models/PatientHomeData';
import lock from '../assets/lock.svg';
import { Typography } from './Typography';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Patient } from '../models/Patient';
import { Roles } from '../models/Roles';
import albert from '../assets/albert.svg';
import { ProfileImage } from './ProfilImage';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        backgroundColor: 'var(--color-white)',
        color: 'var(--color-black)',
        // padding: '5px 5px 8px',
        gap: '8px',
        boxShadow: '0px 4px 8px rgba(226, 199, 199, 0.7)',
        borderRadius: '8px',
        marginBottom: '1rem',
        position: 'relative'
    },
    'div-img': {
        width: '100%',
        height: 'auto',
        position: 'relative',
        // padding: '5px 5px 1rem',
        border: '5px solid white',
        overflow: 'hidden',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    'img-exercise': {
        width: '100%',
        height: '100%',
        aspectRatio: '1/1',
        objectFit: 'cover'
    },
    // 'div-new': {
    //     position: 'absolute',
    //     top: '20px',
    //     right: '17px',
    //     backgroundColor: 'var(--btn-new-bg-color)',
    //     color: 'var(--btn-new-text-color)',
    //     padding: '0.25rem 0.5rem',
    //     borderRadius: '6px',
    //     width: 'fit-content',
    //     height: '1.5rem',
    //     fontSize: '0.75rem',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center'
    // },
    'div-msg': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'self-start',
        justifyContent: 'center',
        padding: '1rem 5px 0'
    },
    'div-msg-author': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '0.25rem',
        paddingLeft: '0.25rem',
        width: '100%',
        marginBottom: '0.5rem',
        gap: '8px'
    },
    'desc-lock': {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%,-50%)',
        // width: 'calc(100% - 10px)',
        width: '100%',
        textAlign: 'center',
        fontSize: '1.2rem',
        fontWeight: '600',
        color: '#FFFFFF',
        backgroundColor: 'rgba(0, 0, 0, 0.46)',
        padding: '1rem 0'
    },
    'img-lock': {
        // position: 'absolute',
        bottom: '5px',
        // left: '50%',
        // transform: 'translate(-50%,-50%)',
        width: '3.5rem'
    },
    'div-lock': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: 'calc(100% - 10px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px'
    },
    'img-profile': {
        height: '3.2rem',
        width: '3.2rem',
        borderRadius: '50%',
        objectFit: 'cover'
    }
};

interface VignetteHomeProps {
    itemData: PatientHomeData;
    seniorData: Patient | undefined;
}

export function VignetteHome({ itemData, seniorData }: VignetteHomeProps): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    // const exerciseNew =
    //     itemData &&
    //     itemData.exercise &&
    //     // itemData.exercise.patientList &&
    //     // itemData.exercise.patientList.length <= 1 &&
    //     ((itemData.exercise.review && itemData.exercise.review.length === 0) ||
    //         itemData.exercise.review === undefined)
    //         ? true
    //         : false;
    const [exerciseNew, setExerciseNew] = useState<boolean | null>(
        user?.role === Roles.SENIOR ? true : false
    );
    const [imgIsLoaded, setImgIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        seniorData?.statistics?.forEach((statistic) => {
            if (
                statistic.exerciseId === itemData.exercise?.id &&
                statistic.details &&
                statistic.details.length > 0
            ) {
                setExerciseNew(false);
            }
        });
    }, [seniorData]);

    return (
        <div
            onClick={(): void => history.push(getUrlToGamePage(itemData))}
            style={{
                ...classes['div-root'],
                ...(width && width < 554
                    ? { width: '100%' }
                    : width && width < 768
                        ? { width: 'calc(100% / 2 - 8px)' }
                        : width && width < 1024
                            ? { width: 'calc(100% / 3 - 12px)' }
                            : { width: 'calc(100% / 3 - 12px)' }),
                cursor: 'pointer'
            }}>
            <div style={classes['div-msg-author']}>
                {itemData.author && itemData.author.role !== Roles.ADMIN && (
                    // itemData.author.profilePhoto &&
                    // typeof itemData.author.profilePhoto === 'string' && (
                    // <img
                    //     src={getImageKitUrlFrom(itemData.author.profilePhoto, 150, 150)}
                    //     alt="profilePicture"
                    //     style={classes['img-profile']}
                    // />
                    <ProfileImage
                        width={150}
                        height={150}
                        name={itemData.author.firstName + ' ' + itemData.author.lastName}
                        url={itemData.author.profilePhoto}
                        sx={classes['img-profile']}
                    />
                )}
                {itemData.author && itemData.author.role === Roles.ADMIN && (
                    <img
                        src={albert}
                        alt="profilePicture"
                        style={{
                            ...classes['img-profile'],
                        }}
                    />
                )}
                <Typography sx={{ fontWeight: '600', color: 'var(--main-blue-color)' }}>
                    {itemData.author && itemData.author.role === Roles.ADMIN
                        ? `Albert de Stimul'in`
                        : itemData.author?.firstName}
                </Typography>
            </div>
            <div style={classes['div-img']}>
                {itemData && itemData.exerciseImage && (
                    <img
                        style={{
                            ...classes['img-exercise'],
                            filter: exerciseNew ? 'blur(3px)' : 'none'
                        }}
                        src={getImageKitUrlFrom(itemData.exerciseImage, 1024, 1024)}
                        alt={itemData.exercise.exerciseName}
                        onLoad={(): void => {
                            setImgIsLoaded(true);
                        }}
                    />
                )}

                {exerciseNew && imgIsLoaded && (
                    <div style={classes['div-lock']}>
                        <Typography
                            sx={classes['desc-lock']}
                            children={undefined}
                            dangerouslySetInnerHTML={{
                                __html: t('newExercise', {
                                    name:
                                        itemData.exercise.authorType === Roles.ADMIN
                                            ? t(`Albert de Stimul'in`)
                                            : itemData.author?.firstName
                                })
                            }} />
                        <img src={lock} alt={lock} style={classes['img-lock']} />
                    </div>
                )}
            </div>
            <Button
                pink
                sx={{ margin: '5px', width: 'calc(100% - 10px)' }}
                onClick={(): void => history.push(getUrlToGamePage(itemData))}>
                {t('runGames')}
            </Button>
        </div>
    );
}
