import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/User';
import { Typography } from '../Typography';
import statsIconGrows from '../../assets/statsIconGrows.svg';
import pictureIcon from '../../assets/pictureIcon.svg';
import videoIcon from '../../assets/videoIcon.svg';
import youtubeIcon from '../../assets/youtubeIcon.svg';
import littleArrow from '../../assets/littleArrow.svg';
import { Select } from '../Select';

import {
    SelectTimeline,
    StatToolkit,
    StatsArray,
    StatsArrayString,
    addData,
    addDataString,
    calcEvol,
    changetimeLine,
    classes,
    getLabelFromDate
} from '../ProfileStatisticsStyle';
import { lstGames } from '../ProfileStatisticsStyle';
import { ExerciseType } from '../../models/ExerciseType';
import {
    getExerciseItemCreatedByUser,
    getGamesCreatedByUser,
    getNumberOfExercises,
    getVideoPersonalCreatedByUser,
    getVideoYoutubeCreatedByUser
} from '../../services/cloudFirestore';
import { Button } from '../Button';
// import '../../utilities/utils.css';

import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from 'chart.js';
import Chart from 'chart.js/auto';
import { Doughnut, Line } from 'react-chartjs-2';
import { Patient } from '../../models/Patient';
import { getDateFromTimestamp2 } from '../../utilities/utils';
import { Timestamp } from 'firebase/firestore';
import { StimbotInfo } from './StimbotInfo';

import { lstMedias } from '../ProfileStatisticsStyle';
import { StatEvol } from './StatEvol';
import { labelsMonth } from '../ProfileStatisticsStyle';

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

interface Props {
    userDataList: Patient[];
}

export function StatsActifUsers({ userDataList }: Props): JSX.Element {
    const { t } = useTranslation();
    const [typeDataView, setTypeDataView] = useState<
        'all' | 'picture' | 'personalVideo' | 'youtubeVideo'
    >('all');
    const [importEvol, setImportEvol] = useState<string>('');
    const [nbMedia, setNbMedia] = useState<number>(0);

    const [endDate, setEndDate] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    );
    const [startDate, setStartDate] = useState<Date>(
        new Date(endDate.getFullYear(), endDate.getMonth() - 3, 1)
    );
    const [timeScale, setTimeScale] = useState<'day' | 'week' | 'month' | 'year'>('month');

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                ticks: {
                    precision: 0,
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                border: { dash: [4, 4] },
                grid: {
                    color: '#D8D8D8'
                },
                min: 0
                // max: 10,
                // ticks: {
                //     stepSize: 1
                // }
            },
            x: {
                ticks: {
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                grid: {
                    display: false,
                    lineWidth: 0
                }
            }
        }
    };

    const [data, setData] = useState<any | undefined>({
        labels: labelsMonth,
        datasets: [
            {
                fill: true,
                label: t('profile.statistics.ActifUsers'),
                data: [0, 0, 0, 0],
                borderColor: '#FF8089',
                borderWidth: 2,
                pointBackgroundColor: '#FF8089',
                pointRadius: 6,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#FF8089',
                pointHoverBorderColor: '#FFFFFF',
                pointHoverBorderWidth: 2,
                pointHitRadius: 10,
                pointBorderWidth: 2,
                pointBorderColor: '#FFFFFF',
                pointStyle: 'circle',
                backgroundColor: ({ chart: { ctx } }: any) => {
                    const bg = ctx.createLinearGradient(0, 0, 0, 250);
                    bg.addColorStop(0, 'rgba(255, 128, 137, 0.40)');
                    bg.addColorStop(1, 'rgba(255, 0, 137, 0.00)');

                    // More config for your gradient
                    return bg;
                }
            }
        ]
    });

    const calcData = async () => {
        let thisDataStatsActifUser: StatsArrayString = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: []
        };
        const dataStats2ActifUser: number[] = [];
        // get all games created by user
        await Promise.all(
            userDataList.map(async (userData) => {
                if (userData && userData.id) {
                    // cont actif user by image imported
                    await getExerciseItemCreatedByUser(userData.id).then((images) => {
                        images.forEach((image) => {
                            const date =
                                typeof image.createdDate === 'string'
                                    ? new Date(image.createdDate)
                                    : getDateFromTimestamp2(
                                          image.createdDate || new Timestamp(0, 0)
                                      );
                            if (date >= startDate && date <= endDate) {
                                // thisDataStatsPicture[month].push(1);
                                thisDataStatsActifUser = addDataString(
                                    timeScale,
                                    startDate,
                                    date,
                                    thisDataStatsActifUser,
                                    image.authorId || ''
                                );
                            }
                        });
                    });
                    // cont actif user by youtube video imported
                    await getVideoYoutubeCreatedByUser(userData.id).then((videos) => {
                        videos.forEach((video) => {
                            const date =
                                typeof video.createdDate === 'string'
                                    ? new Date(video.createdDate)
                                    : getDateFromTimestamp2(
                                          video.createdDate || new Timestamp(0, 0)
                                      );
                            if (date >= startDate && date <= endDate) {
                                // thisDataStatsYoutubeVideo[month].push(1);
                                thisDataStatsActifUser = addDataString(
                                    timeScale,
                                    startDate,
                                    date,
                                    thisDataStatsActifUser,
                                    video.authorId || ''
                                );
                            }
                        });
                    });
                    // cont actif user by personal video imported
                    await getVideoPersonalCreatedByUser(userData.id).then((videos) => {
                        videos.forEach((video) => {
                            const date =
                                typeof video.createdDate === 'string'
                                    ? new Date(video.createdDate)
                                    : getDateFromTimestamp2(
                                          video.createdDate || new Timestamp(0, 0)
                                      );

                            if (date >= startDate && date <= endDate) {
                                // thisDataStatsPersonalVideo[month].push(1);
                                thisDataStatsActifUser = addDataString(
                                    timeScale,
                                    startDate,
                                    date,
                                    thisDataStatsActifUser,
                                    video.authorId || ''
                                );
                            }
                        });
                    });

                    // cont actif user by game created
                    await getGamesCreatedByUser(userData.id).then((games) => {
                        games.forEach((game) => {
                            const date =
                                typeof game.createdDate === 'string'
                                    ? new Date(game.createdDate)
                                    : getDateFromTimestamp2(
                                          game.createdDate || new Timestamp(0, 0)
                                      );

                            const month = date.getMonth() + 1;

                            // const week = date.getDay();
                            if (date >= startDate && date <= endDate) {
                                thisDataStatsActifUser = addDataString(
                                    timeScale,
                                    startDate,
                                    date,
                                    thisDataStatsActifUser,
                                    game.authorId || ''
                                );
                            }
                        });
                    });

                    // cont actif user by view media
                    if (userData.mediaStatistics) {
                        userData.mediaStatistics.forEach((stat) => {
                            const date = getDateFromTimestamp2(stat.date);

                            if (
                                date >= startDate &&
                                date <= endDate &&
                                (typeDataView === 'all' || stat.typeOfMedia === typeDataView)
                            ) {
                                thisDataStatsActifUser = addDataString(
                                    timeScale,
                                    startDate,
                                    date,
                                    thisDataStatsActifUser,
                                    userData.id || ''
                                );
                            }
                        });
                    }

                    // cont actif user by exercise started or finished
                    if (userData.statistics) {
                        userData.statistics.forEach((stat) => {
                            if (stat.details) {
                                stat.details.forEach((detail) => {
                                    const date =
                                        typeof detail.date === 'string'
                                            ? new Date(detail.date)
                                            : getDateFromTimestamp2(detail.date);
                                    if (date >= startDate && date <= endDate) {
                                        thisDataStatsActifUser = addDataString(
                                            timeScale,
                                            startDate,
                                            date,
                                            thisDataStatsActifUser,
                                            userData.id || ''
                                        );
                                    }
                                });
                            }
                        });
                    }
                }
            })
        );

        const dataStatsAllActifUser: string[] = [];
        Object.keys(thisDataStatsActifUser).forEach((key) => {
            if (
                key === '1' ||
                key === '2' ||
                key === '3' ||
                key === '4' ||
                key === '5' ||
                key === '6' ||
                key === '7'
            ) {
                // get number of actif user (check different user)
                thisDataStatsActifUser[key].forEach((item) => {
                    if (!dataStatsAllActifUser.includes(item)) dataStatsAllActifUser.push(item);
                });
                dataStats2ActifUser.push(
                    thisDataStatsActifUser[key].filter((item, i, ar) => ar.indexOf(item) === i)
                        .length
                );
            }
        });

        setImportEvol(calcEvol(dataStats2ActifUser, timeScale));

        setNbMedia(dataStatsAllActifUser.length);

        setData({
            ...data,
            labels: getLabelFromDate(startDate, endDate, timeScale),
            datasets: [
                {
                    ...data.datasets[0],
                    data: dataStats2ActifUser
                }
            ]
        });
    };

    useEffect(() => {
        if (userDataList && userDataList.length > 0) {
            calcData();
        }
    }, [userDataList, typeDataView, startDate, endDate]);

    return (
        <div style={{ ...classes['stat-card-container'] }}>
            <div style={{ ...classes['stat-card-title-container'] }}>
                <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                    {t('profile.statistics.actifUsersTitle')}
                </Typography>
                <StatToolkit
                    value={nbMedia}
                    tooltipText={t('profile.statistics.actifUsersTooltip', { nb: nbMedia })}
                />
            </div>
            <div style={{ ...classes['stat-card'] }}>
                <StimbotInfo text={t('profile.statistics.actifUsersInfo')} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '1rem'
                    }}>
                    <Select
                        defaultValue="all"
                        onChange={(e) => {
                            setTypeDataView(
                                e.target.value as
                                    | 'all'
                                    | 'picture'
                                    | 'personalVideo'
                                    | 'youtubeVideo'
                            );
                        }}
                        sx={{
                            border: '1px solid #E8E8E8',
                            borderRadius: '1.5rem'
                        }}
                        opts={lstMedias.map((media) => ({
                            value: media.type,
                            label: media.name
                        }))}
                    />
                    <SelectTimeline
                        value={timeScale}
                        onChange={(data: {
                            val: string;
                            startDate: Date;
                            endDate: Date;
                            timeScale: 'day' | 'week' | 'month' | 'year';
                        }) => {
                            setEndDate(data.endDate);
                            setStartDate(data.startDate);
                            setTimeScale(data.timeScale);
                        }}
                    />
                </div>
                {data && <Line options={options} data={data} />}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1.5rem',
                        width: '100%'
                    }}>
                    <Button
                        sx={{ ...classes['white-btn'] }}
                        onClick={() => {
                            changetimeLine(
                                'prev',
                                timeScale,
                                endDate,
                                setEndDate,
                                startDate,
                                setStartDate
                            );
                        }}>
                        <img src={littleArrow} alt="littleArrow" />
                        {t('prev')}
                    </Button>
                    <Button
                        sx={{ ...classes['white-btn'] }}
                        onClick={() => {
                            changetimeLine(
                                'next',
                                timeScale,
                                endDate,
                                setEndDate,
                                startDate,
                                setStartDate
                            );
                        }}>
                        {t('next')}
                        <img
                            src={littleArrow}
                            alt="littleArrow"
                            style={{ transform: 'rotate(180deg)' }}
                        />
                    </Button>
                </div>

                <StatEvol evolNb={importEvol} />
            </div>
        </div>
    );
}
