import { MemoryExerciseViewData } from '../models/MemoryExerciseViewData';
import { PatientHomeViewData } from '../models/PatientHomeViewData';
import { ExerciseModalForm } from '../models/ExerciseModalForm';
import exercise1Icon from '../assets/exercise1.png';
import Img1Icon from '../assets/Img1.png';
import Img2Icon from '../assets/Img2.png';
import User from '../assets/User.png';
import photoToEnjoy from '../assets/photoToEnjoy.png';
import author from '../assets/author.png';
import { PatientModalViewData } from '../models/PatientModalViewData';
import { ExerciseType } from '../models/ExerciseType';
import exerciseIcon from '../assets/exercise.png';
import artImage from '../assets/artImage.png';
import familyImage from '../assets/familyImage.png';
import jeunesseImage from '../assets/jeunesseImage.png';
import lieuxImage from '../assets/lieuxImage.png';
import natureImage from '../assets/natureImage.png';
import quotidienImage from '../assets/quotidienImage.png';
import scienceImage from '../assets/scienceImage.png';
import sportImage from '../assets/sportImage.png';
import { PatientListViewData } from '../models/PatientListViewData';
import { ThemeExamplesViewData } from '../models/ThemeExamplesViewData';

// const patientList = [
//     {
//         id: '1',
//         name: 'Mme Annie Grannec',
//         noOfAssociates: '3',
//         profilePhoto: User,
//         dateOfBirth: '1980-06-11'
//     },
//     {
//         id: '2',
//         name: 'PatientName2',
//         noOfAssociates: '3',
//         profilePhoto: User,
//         dateOfBirth: '1980-06-11'
//     },
//     {
//         id: '3',
//         name: 'Mme Annie Grannec',
//         noOfAssociates: '3',
//         profilePhoto: User,
//         dateOfBirth: '1980-06-11'
//     },
//     {
//         id: '4',
//         name: 'Mme Annie Grannec',
//         noOfAssociates: '3',
//         profilePhoto: User,
//         dateOfBirth: '1980-06-11'
//     },
//     {
//         id: '5',
//         name: 'Mme Annie Grannec',
//         noOfAssociates: '3',
//         profilePhoto: User,
//         dateOfBirth: '1980-06-11'
//     },
//     {
//         id: '6',
//         name: 'Mme Annie Grannec',
//         noOfAssociates: '3',
//         profilePhoto: User,
//         dateOfBirth: '1980-06-11'
//     },
//     {
//         id: '7',
//         name: 'Mme Annie Grannec',
//         noOfAssociates: '3',
//         profilePhoto: User,
//         dateOfBirth: '1980-06-11'
//     }
// ];

// const patientData = [
//     {
//         id: '1',
//         exerciseType: ExerciseType.PUZZLE,
//         image: exercise1Icon,
//         exerciseTitle: 'Semaine du 26 juillet 2021 aux Glycines',
//         rating: 3
//     },
//     {
//         id: '2',
//         exerciseType: ExerciseType.PUZZLE,
//         image: exercise1Icon,
//         exerciseTitle: 'Très chic Paula !',
//         rating: 1
//     },
//     {
//         id: '3',
//         exerciseType: ExerciseType.MEMORY,
//         image: exerciseIcon,
//         exerciseTitle: 'Portraits de mes frères et soeurs',
//         rating: 1
//     },
//     {
//         id: '4',
//         exerciseType: ExerciseType.PUZZLE,
//         image: exercise1Icon,
//         exerciseTitle: 'Maison familiale de Grandchamp',
//         rating: 3
//     },
//     {
//         id: '5',
//         exerciseType: ExerciseType.MEMORY,
//         image: exerciseIcon,
//         exerciseTitle: 'Portraits de mes aïeux',
//         rating: 2
//     },
//     {
//         id: '6',
//         exerciseType: ExerciseType.MEMORY,
//         image: exerciseIcon,
//         exerciseTitle: 'Portraits de mes frères et soeurs',
//         rating: 1
//     },
//     {
//         id: '7',
//         exerciseType: ExerciseType.MEMORY,
//         image: exerciseIcon,
//         exerciseTitle: 'Portraits de mes frères et soeurs',
//         rating: 1
//     }
// ];

// const memoryExerciseData = [
//     {
//         id: '1',
//         exerciseImage: Img1Icon,
//         answerOptions: ['Sympa et bel homme ', 'Visite de ma famille', 'Promenade dans le jardin'],
//         answer: 'Visite de ma famille'
//     },
//     {
//         id: '2',
//         exerciseImage: Img2Icon,
//         answerOptions: [
//             'Sympa et bel homme ',
//             'Applaudissements',
//             'Picasso ou presque',
//             'Promenade dans le jardin'
//         ],
//         answer: 'Applaudissements'
//     },
//     {
//         id: '3',
//         exerciseImage: Img2Icon,
//         answerOptions: [
//             'Sympa et bel homme ',
//             'Applaudissements',
//             'Picasso ou presque',
//             'Aux fourneaux !',
//             'Visite de ma famille',
//             'Promenade dans le jardin'
//         ],
//         answer: 'Aux fourneaux !'
//     },
//     {
//         id: '4',
//         exerciseImage: Img1Icon,
//         answerOptions: [
//             'Sympa et bel homme ',
//             'Applaudissements',
//             'Picasso ou presque',
//             'Aux fourneaux !',
//             'Visite de ma famille',
//             'Promenade dans le jardin'
//         ],
//         answer: 'Visite de ma famille'
//     },
//     {
//         id: '5',
//         exerciseImage: Img2Icon,
//         answerOptions: [
//             'Sympa et bel homme ',
//             'Applaudissements',
//             'Picasso ou presque',
//             'Aux fourneaux !',
//             'Visite de ma famille',
//             'Promenade dans le jardin'
//         ],
//         answer: 'Aux fourneaux !'
//     },
//     {
//         id: '6',
//         exerciseImage: Img1Icon,
//         answerOptions: [
//             'Sympa et bel homme',
//             'Applaudissements',
//             'Picasso ou presque',
//             'Aux fourneaux !',
//             'Visite de ma famille',
//             'Promenade dans le jardin'
//         ],
//         answer: 'Sympa et bel homme'
//     }
// ];

// const themeExamples = [
//     {
//         id: '1',
//         exerciseImage: artImage,
//         theme: 'Art et culture',
//         otherThemes: [
//             'Cinéma',
//             'Danse',
//             'Dessin',
//             'Divertissements',
//             'Gastronomie',
//             'Journalisme',
//             'Littérature',
//             'Musique',
//             'Peinture',
//             'Photographie',
//             'Poésie',
//             'Sculpture',
//             'Théâtre',
//             'Vidéo'
//         ]
//     },
//     {
//         id: '2',
//         exerciseImage: familyImage,
//         theme: 'Famille et amis',
//         otherThemes: [
//             "Amis d'enfance",
//             'Arrière grand-mère / père',
//             'Arrière-petite-fille / fils',
//             'Belle-fille / beau-fils',
//             'Belles-soeurs / beaux-frères',
//             'Conjoint(e)',
//             'Cousin(e)',
//             'Fille / fils',
//             'Grand-mère / père',
//             'Nièces / neveux',
//             'Parents',
//             'Petite-fille / fils',
//             'Soeurs / frères'
//         ]
//     },
//     {
//         id: '3',
//         exerciseImage: jeunesseImage,
//         theme: 'Jeunesse',
//         otherThemes: ['Ecole', 'Education', 'Rencontres inter-générationnelles', '...']
//     },
//     {
//         id: '4',
//         exerciseImage: lieuxImage,
//         theme: 'Lieux et voyages',
//         otherThemes: [
//             'Bibliothèque',
//             'Château',
//             'Continents',
//             'Département',
//             'Dolmen',
//             'Ecole',
//             'Ferme',
//             'Fontaine',
//             'Gare',
//             'Hameau',
//             'Hôtel',
//             'Hôtel de ville',
//             'Ile',
//             'Immeuble',
//             'Lieu de culte',
//             'Lieu-dit',
//             'Maison',
//             'Manoir',
//             'Pays',
//             'Pont',
//             'Région',
//             'Village',
//             'Ville'
//         ]
//     },
//     {
//         id: '5',
//         exerciseImage: natureImage,
//         theme: 'Nature',
//         otherThemes: [
//             'Animaux de la ferme',
//             'Animaux domestiques',
//             'Animaux préhistoriques',
//             'Animaux sauvages',
//             'Arbres',
//             'Feu',
//             'Fleurs',
//             'Fruits et légumes',
//             'Lac, rivière, océan',
//             'Paysages campagne',
//             'Paysages mer',
//             'Paysages montagne',
//             'Plantes',
//             'Soleil',
//             'Terre'
//         ]
//     },
//     {
//         id: '6',
//         exerciseImage: quotidienImage,
//         theme: 'Quotidien',
//         otherThemes: [
//             'Bricolage',
//             'Compostage',
//             'Couverts',
//             'Cuisine',
//             'Jardinage',
//             'Ménage',
//             'Métier',
//             'Rangement',
//             'Repassage',
//             '(Super)marché',
//             'Vaisselle'
//         ]
//     },
//     {
//         id: '7',
//         exerciseImage: scienceImage,
//         theme: 'Sciences',
//         otherThemes: [
//             '(Astro)physique',
//             '(Physico)chimie',
//             'Anthropologie',
//             'Archéologie',
//             'Astronomie',
//             'Biologie',
//             'Economie',
//             'Espace',
//             'Géophysique',
//             'Histoire',
//             'Informatique',
//             'Linguistique',
//             'Mathématiques',
//             'Médecine',
//             'Paléontologie',
//             'Psychologie',
//             'Sociologie',
//             'Zoologie'
//         ]
//     },
//     {
//         id: '8',
//         exerciseImage: sportImage,
//         theme: 'Sport',
//         otherThemes: [
//             'Automobile',
//             'Basket-ball',
//             'Bâteau',
//             'Course à pieds',
//             'Cyclisme',
//             'Danse',
//             'Football',
//             'Golf',
//             'Gym',
//             'Handball',
//             'Hippisme',
//             'Natation',
//             'Planche à voile',
//             'Randonnée',
//             'Rugby',
//             'Ski',
//             'Tennis'
//         ]
//     }
// ];

// const modalData = {
//     authorName: 'Iris Soignon',
//     exerciseDetails: memoryExerciseData,
//     videoToAssist:
//         'https://players.brightcove.net/1752604059001/VJCJXL3Ye_default/index.html?videoId=4093643993001',
//     videoLink: 'https://youtube.com/embed/kLH5xBbIBnY',
//     photo: photoToEnjoy,
//     authorPhoto: author
// };

const ModalFormQuestions = {
    firstQuestion: 'Personne(s) sur cette photo',
    secondQuestion: 'Anecdote(s)',
    thirdQuestion: 'Lieu',
    fourthQuestion: 'Date'
};

// export const getPatientsList = (): PatientListViewData[] => {
//     return patientList;
// };

export const getModalFormQuestions = (): ExerciseModalForm => {
    return ModalFormQuestions;
};

// export const getPatientModalDetails = (): PatientModalViewData => {
//     return modalData;
// };

// export const getPatientDashboard = (): PatientHomeViewData[] => {
//     return patientData;
// };

// export const getMemoryExercises = (): MemoryExerciseViewData[] => {
//     return memoryExerciseData;
// };

// export const getMemoryExercise = (id: string): MemoryExerciseViewData | null => {
//     const results = memoryExerciseData.filter((data: MemoryExerciseViewData) => {
//         return data.id === id;
//     });
//     return results[0];
// };

export const videoLinkPuzzlin = `https://firebasestorage.googleapis.com/v0/b/stimul-in-dev-environment.appspot.com/o/2022%2014%2002%20-%20Vid%C3%A9o%20intro%20personnalisation%20Puzzl'in.mp4?alt=media&token=ce4d9e44-2a7c-4363-8a5a-1c249fdeeda5`;

export const videoLinkSouvenin = `https://firebasestorage.googleapis.com/v0/b/stimul-in-dev-environment.appspot.com/o/2022%2014%2002%20-%20Vid%C3%A9o%20intro%20personnalisation%20Puzzl'in.mp4?alt=media&token=ce4d9e44-2a7c-4363-8a5a-1c249fdeeda5`;

// export const getThemeExamples = (): ThemeExamplesViewData[] => {
//     return themeExamples;
// };
