import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/User';
import { Typography } from '../Typography';
import statsIconGrows from '../../assets/statsIconGrows.svg';
import statEvolMore from '../../assets/statEvolMore.svg';
import statEvolLess from '../../assets/statEvolLess.svg';
import statEvolLike from '../../assets/statEvolLike.svg';
import smileysSatisfait from '../../assets/smileysSatisfait.svg';
import smileysNoOpinion from '../../assets/smileysNoOpinion.svg';
import smileysNotSatisfait from '../../assets/smileysNotSatisfait.svg';
import starBackground from '../../assets/starBackground.svg';
import { Select } from '../Select';

import { SelectTimeRange, SelectTimeline, classes } from '../ProfileStatisticsStyle';
import { lstGamesToUnsort as lstGames } from '../ProfileStatisticsStyle';
import { ExerciseType } from '../../models/ExerciseType';
import { getNumberOfExercises } from '../../services/cloudFirestore';
import { Button } from '../Button';
import { Patient } from '../../models/Patient';
import { getDateFromTimestamp2 } from '../../utilities/utils';
import { Review } from '../../models/Review';

interface Props {
    // nbReview: { loved: number; satisfactory: number; disliked: number };
    userDataList: Patient[];
}

export function StatsSatisfaction({ userDataList }: Props): JSX.Element {
    const { t } = useTranslation();
    const [thisSatisfaction, setThisSatisfaction] = useState<
        'satisfait' | 'noOpinion' | 'notSatisfait'
    >();
    const [thisSmiley, setThisSmiley] = useState<string>();
    const [maxListView, setMaxListView] = useState<number>(4);
    const [countExerciceType, setCountExerciceType] = useState<{
        allGames: number;
        [ExerciseType.QUIZ]: number;
        [ExerciseType.MEMORIZ]: number;
        [ExerciseType.PUZZLE]: number;
        [ExerciseType.SUDOKU]: number;
        [ExerciseType.ORDER]: number;
        [ExerciseType.PONG]: number;
        [ExerciseType.TOURBILLON]: number;
        [ExerciseType.MELIMOTS]: number;
    }>({
        allGames: 0,
        [ExerciseType.QUIZ]: 0,
        [ExerciseType.MEMORIZ]: 0,
        [ExerciseType.PUZZLE]: 0,
        [ExerciseType.SUDOKU]: 0,
        [ExerciseType.ORDER]: 0,
        [ExerciseType.PONG]: 0,
        [ExerciseType.TOURBILLON]: 0,
        [ExerciseType.MELIMOTS]: 0,
    });

    const [exerciceSatisfaction, setExerciceSatisfaction] = useState<{
        [ExerciseType.QUIZ]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.MEMORIZ]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.PUZZLE]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.SUDOKU]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.ORDER]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.PONG]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.TOURBILLON]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.MELIMOTS]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
    }>();
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [startDate, setStartDate] = useState<Date>(
        new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            new Date().getDate() - 30,
            new Date().getHours(),
            new Date().getMinutes(),
            new Date().getSeconds()
        )
    );
    const [timeRange, setTimeRange] = useState<
        'fromStart' | 'last24h' | 'last7d' | 'last30d' | 'last12m'
    >('last30d');
    const [evolSatisfaction, setEvolSatisfaction] = useState<'+' | '-' | '='>();

    const calcData = (thisStartDate: Date, thisEndDate: Date) => {
        let thisExerciceSatisfaction = {
            [ExerciseType.QUIZ]: new Array<string>(),
            [ExerciseType.MEMORIZ]: new Array<string>(),
            [ExerciseType.PUZZLE]: new Array<string>(),
            [ExerciseType.SUDOKU]: new Array<string>(),
            [ExerciseType.ORDER]: new Array<string>(),
            [ExerciseType.PONG]: new Array<string>(),
            [ExerciseType.TOURBILLON]: new Array<string>(),
            [ExerciseType.MELIMOTS]: new Array<string>()
        };
        userDataList.map(async (userData) => {
            if (userData.reviewStatistics) {
                userData.reviewStatistics.forEach((stat) => {
                    const date =
                        typeof stat.date === 'string'
                            ? new Date(stat.date)
                            : getDateFromTimestamp2(stat.date);
                    if (date >= thisStartDate && date <= thisEndDate) {
                        if (stat.exerciseType) {
                            thisExerciceSatisfaction[stat.exerciseType].push(stat.review);
                        }
                    }
                });
            }
        });

        let tempExerciceSatisfaction: {
            [ExerciseType.QUIZ]: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait';
            [ExerciseType.MEMORIZ]: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait';
            [ExerciseType.PUZZLE]: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait';
            [ExerciseType.SUDOKU]: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait';
            [ExerciseType.ORDER]: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait';
            [ExerciseType.PONG]: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait';
            [ExerciseType.TOURBILLON]: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait';
            [ExerciseType.MELIMOTS]: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait';
        } = {
            [ExerciseType.QUIZ]: undefined,
            [ExerciseType.MEMORIZ]: undefined,
            [ExerciseType.PUZZLE]: undefined,
            [ExerciseType.SUDOKU]: undefined,
            [ExerciseType.ORDER]: undefined,
            [ExerciseType.PONG]: undefined,
            [ExerciseType.TOURBILLON]: undefined,
            [ExerciseType.MELIMOTS]: undefined
        };

        let globalCount = {
            satisfait: 0,
            noOpinion: 0,
            notSatisfait: 0
        };
        Object.keys(thisExerciceSatisfaction).forEach((key) => {
            let tempCount = {
                satisfait: 0,
                noOpinion: 0,
                notSatisfait: 0
            };

            thisExerciceSatisfaction[key as ExerciseType].forEach((review) => {
                if (review === Review.LOVED) {
                    tempCount.satisfait += 1;
                    globalCount.satisfait += 1;
                } else if (review === Review.SATISFACTORY) {
                    tempCount.noOpinion += 1;
                    globalCount.noOpinion += 1;
                } else if (review === Review.DISLIKED) {
                    tempCount.notSatisfait += 1;
                    globalCount.notSatisfait += 1;
                }
            });

            // get max value of tempCount
            let maxValue = 0;
            let maxKey: undefined | 'satisfait' | 'noOpinion' | 'notSatisfait' = undefined;
            Object.keys(tempCount).forEach((key) => {
                if (tempCount[key as keyof typeof tempCount] > maxValue) {
                    maxValue = tempCount[key as keyof typeof tempCount];
                    maxKey = key as 'satisfait' | 'noOpinion' | 'notSatisfait';
                }
            });
            tempExerciceSatisfaction[key as ExerciseType] = maxKey;
        });

        let tempSatisfaction: 'satisfait' | 'noOpinion' | 'notSatisfait' = 'noOpinion';
        let tempSmiley: string = smileysNoOpinion;
        // get satisfaction of all games
        if (
            globalCount.satisfait > globalCount.noOpinion &&
            globalCount.satisfait > globalCount.notSatisfait
        ) {
            // setThisSatisfaction('satisfait');
            tempSatisfaction = 'satisfait';
            tempSmiley = smileysSatisfait;
        } else if (
            globalCount.noOpinion > globalCount.satisfait &&
            globalCount.noOpinion > globalCount.notSatisfait
        ) {
            // setThisSatisfaction('noOpinion');
            tempSatisfaction = 'noOpinion';
            tempSmiley = smileysNoOpinion;
        } else if (
            globalCount.notSatisfait > globalCount.satisfait &&
            globalCount.notSatisfait > globalCount.noOpinion
        ) {
            // setThisSatisfaction('notSatisfait');
            tempSatisfaction = 'notSatisfait';
            tempSmiley = smileysNotSatisfait;
        } else {
            // setThisSatisfaction('noOpinion');
            tempSatisfaction = 'noOpinion';
            tempSmiley = smileysNoOpinion;
        }

        return {
            satisfaction: tempExerciceSatisfaction,
            thisSatisfaction: tempSatisfaction,
            smiley: tempSmiley
        };
    };

    useEffect(() => {
        const thisTimeRange = calcData(startDate, endDate);
        if (thisTimeRange) {
            setExerciceSatisfaction(thisTimeRange.satisfaction);
            setThisSatisfaction(thisTimeRange.thisSatisfaction);
            setThisSmiley(thisTimeRange.smiley);
        }
        let lastTimeRange: any | undefined = undefined;
        switch (timeRange) {
            case 'fromStart':
                break;
            case 'last24h':
                lastTimeRange = calcData(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate(),
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds() - 24 * 60 * 60
                    ),
                    startDate
                );
                break;
            case 'last7d':
                lastTimeRange = calcData(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() - 7,
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    ),
                    startDate
                );
                break;
            case 'last30d':
                lastTimeRange = calcData(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() - 30,
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    ),
                    startDate
                );
                break;
            case 'last12m':
                lastTimeRange = calcData(
                    new Date(
                        startDate.getFullYear() - 1,
                        startDate.getMonth(),
                        startDate.getDate(),
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    ),
                    startDate
                );
                break;
        }

        if (thisTimeRange && lastTimeRange) {
            if (
                thisTimeRange.thisSatisfaction === 'satisfait' &&
                lastTimeRange.thisSatisfaction !== 'satisfait'
            ) {
                setEvolSatisfaction('+');
            } else if (
                thisTimeRange.thisSatisfaction === 'notSatisfait' &&
                lastTimeRange.thisSatisfaction !== 'notSatisfait'
            ) {
                setEvolSatisfaction('-');
            } else if (
                thisTimeRange.thisSatisfaction === 'noOpinion' &&
                lastTimeRange.thisSatisfaction === 'satisfait'
            ) {
                setEvolSatisfaction('-');
            } else if (
                thisTimeRange.thisSatisfaction === 'noOpinion' &&
                lastTimeRange.thisSatisfaction === 'notSatisfait'
            ) {
                setEvolSatisfaction('+');
            } else {
                setEvolSatisfaction('=');
            }
        }

        const tempCountExerciceType = {
            allGames: 0,
            [ExerciseType.QUIZ]: 0,
            [ExerciseType.MEMORIZ]: 0,
            [ExerciseType.PUZZLE]: 0,
            [ExerciseType.SUDOKU]: 0,
            [ExerciseType.ORDER]: 0,
            [ExerciseType.PONG]: 0,
            [ExerciseType.TOURBILLON]: 0,
            [ExerciseType.MELIMOTS]: 0
        };
        userDataList.map(async (userData) => {
            if (userData && userData.statistics) {
                userData.statistics.forEach((stat) => {
                    if (stat.typeOfExercise) {
                        if (!tempCountExerciceType[stat.typeOfExercise]) {
                            tempCountExerciceType[stat.typeOfExercise] = 0;
                        }
                        if (stat.details) {
                            stat.details.forEach((detail) => {
                                const date =
                                    typeof detail.date === 'string'
                                        ? new Date(detail.date)
                                        : getDateFromTimestamp2(detail.date);

                                if (date >= startDate && date <= endDate) {
                                    tempCountExerciceType[stat.typeOfExercise] += 1;
                                }
                            });
                        }
                    }
                });
            }
        });

        // sort lstGames by most played
        lstGames.sort((a, b) => {
            const aCount = tempCountExerciceType[a.type];
            const bCount = tempCountExerciceType[b.type];
            if (aCount > bCount) return -1;
            if (aCount < bCount) return 1;
            return 0;
        });

        setCountExerciceType(tempCountExerciceType);
    }, [userDataList, startDate, endDate]);

    const spanSatisfaction = (
        satisfaction: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined
    ): JSX.Element => {
        const strText = satisfaction
            ? satisfaction?.charAt(0).toUpperCase() + satisfaction?.slice(1)
            : '';
        const strImg: {
            satisfait: { img: string; color: string };
            noOpinion: { img: string; color: string };
            notSatisfait: { img: string; color: string };
        } = {
            satisfait: { img: smileysSatisfait, color: '#DDFAD3' },
            noOpinion: { img: smileysNoOpinion, color: '#F2F2F2' },
            notSatisfait: { img: smileysNotSatisfait, color: '#FFC1C1' }
        };
        return satisfaction !== undefined ? (
            <span
                style={{
                    borderRadius: '0.5rem',
                    backgroundColor: strImg[satisfaction].color,
                    padding: '0.25rem 0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '0.25rem',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    color: '#0D224B'
                }}>
                <img
                    src={strImg[satisfaction].img}
                    alt={`smileys${satisfaction?.charAt(0).toUpperCase()}`}
                    style={{ height: '1rem', width: '1rem' }}
                />
                {t(strText)}
            </span>
        ) : (
            <div />
        );
    };

    return (
        <div style={{ ...classes['stat-card-container'] }}>
            <div style={{ ...classes['stat-card-title-container'] }}>
                <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                    {t('profile.statistics.satisfactionTitle')}
                </Typography>
                {/* <StatToolkit value={}/> */}
            </div>
            <div style={{ ...classes['stat-card-blue'] }}>
                <div style={{ ...classes['stat-card-blue-staisfaction'] }}>
                    {thisSatisfaction && (
                        <img
                            src={starBackground}
                            alt="starBackground"
                            style={{
                                ...classes['star-bg'],
                                // faire en sorte que l'image prenne toute la place disponible
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                // height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    )}
                    {thisSatisfaction && (
                        <div
                            style={{
                                height: '3.75rem',
                                width: '3.75rem',
                                backgroundColor: '#FF0089',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2
                            }}>
                            <img
                                src={thisSmiley}
                                alt="smiley"
                                style={{ height: '1.75rem', width: '1.75rem' }}
                            />
                        </div>
                    )}
                    {thisSatisfaction !== 'noOpinion' &&
                        thisSatisfaction && ( //HERE2
                            <Typography
                                variant="p"
                                sx={{ ...classes['stat-card-blue-title'], zIndex: 2 }}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        userDataList.length === 1
                                            ? t(
                                                  `profile.statistics.satisfactionActual.${thisSatisfaction}`,
                                                  {
                                                      name:
                                                          userDataList.length === 1 &&
                                                          userDataList[0]
                                                              ? userDataList[0].firstName
                                                              : ''
                                                  }
                                              ) || ''
                                            : t(
                                                  `profile.statistics.satisfactionActual.all.${thisSatisfaction}`
                                              ) || ''
                                }}
                                children={undefined}
                            />
                        )}
                </div>
                {timeRange !== 'fromStart' && evolSatisfaction && (
                    <div style={{ ...classes['stat-card-evol-container'], zIndex: 2 }}>
                        {/* <img src={statsIconGrows} alt="statsIconGrows" style={{ ...classes['icon'] }} /> */}
                        <img
                            src={
                                evolSatisfaction === '+'
                                    ? statEvolMore
                                    : evolSatisfaction === '-'
                                    ? statEvolLess
                                    : statEvolLike
                            }
                            alt="statEvolLike"
                            style={{ ...classes['icon'] }}
                        />
                        <Typography
                            variant="p"
                            sx={{ ...classes['stat-card-evol-text'] }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    t(`profile.statistics.satisfaction${evolSatisfaction}`) || ''
                            }}
                            children={undefined}></Typography>
                    </div>
                )}
            </div>
            <div
                style={{
                    ...classes['stat-card'],
                    ...classes['stat-card-no-top'],
                    zIndex: 2,
                    gap: '0px'
                }}>
                {/* <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                    {t('profile.statistics.satisfactionMorePlayGame')}
                </Typography> */}
                <SelectTimeRange
                    sx={{
                        zIndex: 2,
                        marginBottom: '1.5rem'
                    }}
                    defaultValue="last30d"
                    onChange={(value) => {
                        setStartDate(value.startDate);
                        setEndDate(value.endDate);
                        setTimeRange(value.timeRange);
                    }}
                />

                {lstGames.map(
                    (game: { type: ExerciseType; name: string; img: string }, index: number) => {
                        if (index < maxListView) {
                            return (
                                <div key={`gamesList${index}`}>
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            width: '100%',
                                            gap: '0.5rem',
                                            padding: '0.5rem 0'
                                        }}>
                                        <img
                                            src={game.img}
                                            alt="game"
                                            style={{ ...classes['games-icon'] }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                                justifyContent: 'center',
                                                width: '100%',
                                                gap: '0.125rem'
                                            }}>
                                            <Typography
                                                variant="p"
                                                sx={{ ...classes['stat-card-title'] }}>
                                                {t(game.name)}
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: '0.5rem'
                                                }}>
                                                <span
                                                    style={{
                                                        fontSize: '0.875rem',
                                                        fontWeight: '500',
                                                        color: '#0D224B',
                                                        backgroundColor: '#F2F2F2',
                                                        padding: '0.25rem 0.5rem',
                                                        borderRadius: '0.5rem'
                                                    }}>
                                                    {countExerciceType[game.type]}{' '}
                                                    {t(
                                                        `part${
                                                            countExerciceType[game.type] > 1
                                                                ? 's'
                                                                : ''
                                                        }`
                                                    )}
                                                </span>
                                                {exerciceSatisfaction &&
                                                    exerciceSatisfaction[game.type] &&
                                                    spanSatisfaction(
                                                        exerciceSatisfaction[game.type]
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr
                                        style={{
                                            width: '100%',
                                            height: '0.0625rem',
                                            backgroundColor: '#EFEFEF',
                                            border: 'none'
                                            // margin: '0.5rem 0'
                                        }}
                                    />
                                </div>
                            );
                        }
                    }
                )}

                <Button
                    onClick={() => {
                        maxListView <= lstGames.length && setMaxListView(maxListView + 4);
                        maxListView > lstGames.length && setMaxListView(4);
                    }}>
                    {maxListView <= lstGames.length ? t('showMore') : t('showLess')}
                </Button>
            </div>
        </div>
    );
}
