import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { Button } from '../../../components/Button/index';
import Heading from '../../../components/Heading';
import Einstein from './assets/Einstein.svg?react';
import EinsteinMobile from './assets/EinsteinMobile.svg?react';

import styles from './styles.module.css';
import useWindowDimensions from '../../../utilities/useWindowDimensions';

interface PreviewProps {
    title?: string;
    Picture: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    onSkip: () => void;
}

export const Preview = ({
    title,
    Picture = '',
    onSkip = () => {}
}: PreviewProps) => {
    const [countDown, setCountDown] = useState(10);
    const { width } = useWindowDimensions();

    setTimeout(() => {
        if (countDown > 0) {
            setCountDown(countDown - 1);
        }
    }, 1000);

    useEffect(() => {
        if (countDown === 0) {
            onSkip();
        }
    }, [countDown]);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.blur} />
                {/* {title && <Heading className={styles.title}>{title}</Heading>} */}
                <div className={styles.content}>
                    {width < 768 && <EinsteinMobile className={styles.einstein} />}
                    {width >= 768 && <Einstein className={styles.einstein} />}
                    <div className={styles.pictureContainer}>
                        {typeof Picture === 'string'
                            ? <div className={styles.picture} style={{ backgroundImage: `url(${Picture})`, backgroundSize: 'cover' }} />
                            : <Picture className={styles.picture}></Picture>
                        }
                        <div className={styles.countDown}>{countDown}</div>
                    </div>
                </div>
            </div>
            <Button className={styles.skipButton} theme='SECONDARY' action={onSkip} label={t('Passer')} />
        </>
    );
};

export default Preview;
