import React, { useImperativeHandle, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// @ts-ignore
import { Jigsaw } from './Jigsaw';
import helpIcon from '../../assets/helpIcon.svg';
import {
    getExercise,
    getExerciseItem,
} from '../../services/cloudFirestore';
import { UserContext } from '../../providers/UserProvider';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';
import { isPlatform } from '@ionic/react';

import { useGame } from '../../contexts/GameContext';
import Preview from '../../pages/games/Preview';
import { Loader } from '../Loader';

export const Puzzle = React.forwardRef((_, ref) => {
    const parentElement = document.getElementById('game-root');
    const [preview, setPreview] = useState<boolean>(true);
    const { startTimer, stopTimer, endGame, displayInstruction, showUi, displayFunfact, closeFunfact, endAnimation } = useGame();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { id } = useParams<{ id: string }>();
    const { lvlId } = useParams<{ lvlId: string }>();
    const difficultyLevel = parseInt(lvlId);
    const canvasRef = useRef<HTMLDivElement>(null);
    const [puzzleImage, setPuzzleImage] = useState<{ picture: string, title: string }>();
    const [clueCount, setClueCount] = useState<number>(0);
    const [puzzleInstance, setPuzzleInstance] = useState<undefined | Jigsaw>(undefined);
    const [exerciseItem, setExerciseItem] = useState<any>();

    useImperativeHandle(ref, () => ({
        tips: () => {
            if (puzzleInstance) puzzleInstance.onClueClick();
        },
    }));

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            const globalExercise = await getExercise(id);
            if (localStorage.getItem('reload') === 'true') {
                localStorage.setItem('reload', 'false');
                window.location.reload();
            }
            if (globalExercise) {
                if (
                    globalExercise.exerciseImages &&
                    globalExercise.exerciseImages[0] &&
                    typeof globalExercise.exerciseImages[0] === 'string'
                ) {
                    const item = await getExerciseItem(globalExercise.exerciseImages[0]);
                    setExerciseItem(item);
                    if (item) {
                        if (item.exerciseImage && typeof item.exerciseImage === 'string') {
                            setPuzzleImage({ picture: item.exerciseImage, title: globalExercise.exerciseName || '' });
                        }
                    }
                }
            }
        };
        getExerciseList();
    }, [id]);

    // The puzzl'in exercice is done
    const onSuccess = async (clue: number): Promise<void> => {
        setClueCount(clue);
        stopTimer();
        endAnimation(async () => displayFunfact({
            title: puzzleImage?.title || '',
            text: exerciseItem?.anecdote,
            Picture: puzzleImage?.picture,
            onClose: () => {
                closeFunfact();
                endGame({
                    clueCount,
                });
            },
        }));

    };

    const renderPuzzle = (): void => {
        if (user?.role === Roles.SENIOR) {
            localStorage.setItem('startTime', new Date().toString());
        }
        if (puzzleImage) {
            let xPieces;
            let yPieces;
            switch (difficultyLevel) {
                case 1:
                    xPieces = 2;
                    yPieces = 2;
                    break;
                case 2:
                    xPieces = 3;
                    yPieces = 2;
                    break;
                case 3:
                    xPieces = 3;
                    yPieces = 3;
                    break;
                case 4:
                    xPieces = 4;
                    yPieces = 3;
                    break;
                case 5:
                    xPieces = 4;
                    yPieces = 4;
                    break;
                case 6:
                    xPieces = 4;
                    yPieces = 5;
                    break;
                case 7:
                    xPieces = 5;
                    yPieces = 5;
                    break;
                case 8:
                    xPieces = 7;
                    yPieces = 7;
                    break;
                case 9:
                    xPieces = 10;
                    yPieces = 10;
                    break;
                case 10:
                    xPieces = 12;
                    yPieces = 12;
                    break;
                default:
                    xPieces = 2;
                    yPieces = 2;
                    break;
            }
            const sizeByLvl =
                isPlatform('mobile') && (isPlatform('ios') || isPlatform('android'))
                    ? [
                        Math.min(window.innerWidth, window.innerHeight) * 0.8,
                        Math.min(window.innerWidth, window.innerHeight) * 0.6,
                        Math.min(window.innerWidth, window.innerHeight) * 0.5,
                        Math.min(window.innerWidth, window.innerHeight) * 0.5,
                        Math.min(window.innerWidth, window.innerHeight) * 0.5,
                        Math.min(window.innerWidth, window.innerHeight) * 0.5,
                        Math.min(window.innerWidth, window.innerHeight) * 0.5,
                        Math.min(window.innerWidth, window.innerHeight) * 0.5,
                        Math.min(window.innerWidth, window.innerHeight) * 0.5,
                        Math.min(window.innerWidth, window.innerHeight) * 0.5
                    ]
                    : [300, 300, 350, 400, 400, 400, 400, 400, 400, 400];
            setPuzzleInstance(Jigsaw(
                parentElement?.offsetWidth,
                parentElement?.offsetHeight,
                'puzzle.jpg',
                getImageKitUrlFrom(
                    puzzleImage.picture,
                    sizeByLvl[difficultyLevel - 1],
                    sizeByLvl[difficultyLevel - 1]
                ),
                xPieces,
                yPieces,
                onSuccess,
                (clue: number) => {
                    setClueCount(clue);
                },
                helpIcon,
                !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios'))
            ));
        }
    };

    useEffect(() => {
        if (!puzzleImage || difficultyLevel === 0 || !canvasRef?.current) return;
        renderPuzzle();
        return (): void => {
            const createScript = document.getElementById('ZIM');
            const zimScript = document.getElementById('zimjs');
            createScript?.remove();
            zimScript?.remove();
        };
    }, [difficultyLevel, puzzleImage]);

    if (!puzzleImage) return <Loader />;

    const onSkipPreview = (): void => {
        setPreview(false);
        showUi();
        startTimer();
        displayInstruction();
    };

    return (
        <>
            {preview && <Preview title={puzzleImage.title} Picture={puzzleImage.picture} onSkip={() => onSkipPreview()} />}
            <div id="middle" ref={canvasRef} style={{ width: '100%', height: '100%', pointerEvents: preview ? 'none' : 'all', opacity: preview ? 0 : 1, position: 'absolute' }}></div>
        </>
    );
});