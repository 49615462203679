import { useRef, useState } from "react"
import { GameProvider, GameRef } from "../../../providers/GameProvider";
import { sudok } from "../../../models/Games";
import { Sudok } from "../../../components/Sudok";
import { useParams } from "react-router-dom";

export default () => {
    const sudokRef = useRef<GameRef>(null);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);

    const getTips = () => {
        if (!sudokRef.current) return;
        sudokRef.current?.tips();
    };

    const resetGame = () => {
        setRefreshKey(refreshKey + 1);
    };

    const colors = {
        primary: sudok.color,
        secondary: sudok.secondaryColor,
        border: sudok.border,
    };

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            onReset={resetGame}
            tipsEnabled
            instructionEnabled
            background={sudok.background}
            instructionMessage={sudok.instructions?.find((instruction) => instruction.maxLvl >= lvl)?.text}
        >
            <Sudok key={refreshKey} ref={sudokRef} />
        </GameProvider>
    );
};