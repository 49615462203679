import React, { CSSProperties, ReactElement, useState } from 'react';
import check from '../assets/check.png';
import { Typography } from './Typography';
import { t } from 'i18next';
import { PopupModal } from './PopupModal';
import { ListOffer } from './ListOffer';
import { Button } from './Button';
import { patientUpdateEndDate } from '../services/cloudFirestore';
import { Timestamp } from '@firebase/firestore';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        display: 'flex',
        paddingTop: '1rem',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        gap: '1.5rem'
    },
    title: {
        color: '#333',
        fontWeight: '600',
        fontSize: '1.5rem',
        width: '100%',
        textAlign: 'left'
    },
    'sub-title': {
        color: '#333',
        fontWeight: '400',
        fontSize: '1rem',
        width: '100%',
        textAlign: 'left'
    },
    'subscribe-p': {
        width: '100%',
        fontSize: '1rem',
        fontWeight: '400',
        textAlign: 'left'
    },
    'subscribe-check': {
        width: '1.2rem',
        height: '1.2rem'
    },
    'subscribe-args': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
        paddingTop: '0.5rem'
    },
    'btn-disabled': {
        color: '#6C6C6C',
        backgroundColor: '#F2F2F2',
        border: '2px solid #6C6C6C'
    }
};

interface Props {
    headerTitle?: string;
    isOpen: boolean;
    closeModal?: null | (() => void);
    seniorId: string;
    body?: ReactElement;
    width?: string;
    height?: string;
    minHeight?: string;
    minWidth?: string;
}

export function PopupEndTestPeriod({ isOpen, seniorId, closeModal = null }: Props): JSX.Element {
    const [offer, setOffer] = useState<'monthly' | 'yearly' | 'free'>();
    return (
        <PopupModal
            isOpen={isOpen}
            closeModal={() => (window.location.href = 'https://stimulin.fr')}
            headerTitle={t('popupEndTestPeriod.headerTitle') ?? ''}
            height="80%"
            minWidth="300px">
            {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} /> */}
            <div style={{ ...classes['div-root'] }}>
                <Typography variant="h4" sx={{ ...classes['title'] }}>
                    {t('popupEndTestPeriod.title')}
                </Typography>
                <Typography variant="h4" sx={{ ...classes['sub-title'] }}>
                    {t('popupEndTestPeriod.subTitle')}
                </Typography>
                <ListOffer offer={offer} setOffer={(val) => setOffer(val)} />
                <div style={{}}>
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                        <div style={classes['subscribe-args']} key={`popupEndTestPeriod-${item}`}>
                            <img src={check} alt="check" style={classes['subscribe-check']} />
                            <Typography variant="p" sx={classes['subscribe-p']}>
                                {t(`chosenPlanLitle-arg${item}`)}
                            </Typography>
                        </div>
                    ))}
                </div>
                <Button
                    outlinedDisable={offer === undefined ? true : false}
                    pink
                    sx={
                        offer === undefined ? { ...classes['btn-disabled'] } : { ...classes['btn'] }
                    }
                    onClick={async () => {
                        if (!offer) window.location.href = 'https://stimulin.fr';
                        else {
                            const endDate = new Date().getTime() + 3 * 24 * 60 * 60 * 1000;
                            await patientUpdateEndDate(seniorId, Timestamp.fromMillis(endDate));
                        }

                        if (offer === 'monthly') {
                            window.location.href = 'https://buy.stripe.com/28o2ai6ic6qfg367sD';
                        } else if (offer === 'yearly') {
                            window.location.href = 'https://buy.stripe.com/00gg18fSMcOD048bIU';
                        }
                        closeModal && closeModal();
                    }}>
                    {t(offer ? 'next' : 'noThanks')}
                </Button>
                <Typography>&nbsp;</Typography>
            </div>
        </PopupModal>
    );
}
