import _ from 'lodash';
import { Exercise } from '../../models/Exercise';
import { ExerciseItem } from '../../models/ExerciseItem';
import { MediaInformation } from '../../models/MediaInformation';
import { User } from '../../models/User';
import {
    getExerciseItem,
    getPersonalVideo,
    getPersonalVideos,
    getPersonalYoutubeVideo,
    getFamilyPicturesForMedia,
    getExerciseItemsByAutor,
    getPersonalVideosByAutor,
    getDocId
} from '../../services/cloudFirestore';
import { Roles } from '../../models/Roles';
import { Timestamp } from 'firebase/firestore';

const uidToDisable = [
    'fea7072c-0de2-4b12-94b2-f45ae1c1ba9d',
    'd77f15f4-250e-44bc-9c0a-54c40859c634',
    '62f600e2-3293-492a-9d43-aaba73db5fe1',
    '90e2d8d2-a560-4539-aaa0-ded9a43ad82c'
];
const checkArrayExerciseItem = (array: ExerciseItem[], itemToCheck: ExerciseItem): boolean => {
    const found = array.some((el) => el.id === itemToCheck.id);
    return found;
};
const checkArrayMediaInformation = (array: MediaInformation[], itemToCheck: string): boolean => {
    const found = array.some((el) => el.videoUrl === itemToCheck);
    return found;
};

const getMediaInformation = (
    object: MediaInformation[],
    valueCompare: string
): MediaInformation | undefined => {
    const result = Object.entries(object).find(([key, value]) => {
        if (value.videoUrl === valueCompare) {
            return true;
        }
        return false;
    });
    if (result && result[1]) {
        return result[1];
    }
    return undefined;
};

const getAllVideoItem = async (
    user: User,
    adminListId: string[]
): Promise<MediaInformation[] | null> => {
    let list: MediaInformation[] = [];
    if (user && user.familyCode && user.establishmentCode) {
        if (adminListId && adminListId.length > 0) {
            adminListId.forEach(async (element) => {
                const videoPatientFromAdmin = await getPersonalVideo(element);
                const videoYoutubePatientFromAdmin = await getPersonalYoutubeVideo(element);
                if (videoPatientFromAdmin && videoPatientFromAdmin.length > 0) {
                    list = list.concat(videoPatientFromAdmin);
                }
                if (videoYoutubePatientFromAdmin && videoYoutubePatientFromAdmin.length > 0) {
                    list = list.concat(videoYoutubePatientFromAdmin);
                }
            });
        }
        const videoPatientFromFamily = await getPersonalVideo(user.familyCode);
        const videoPatientFromEstablishment = await getPersonalVideo(user.establishmentCode);
        const videoYoutubePatientFromFamily = await getPersonalYoutubeVideo(user.familyCode);
        const videoYoutubePatientFromEstablishment = await getPersonalYoutubeVideo(
            user.establishmentCode
        );
        if (videoPatientFromFamily && videoPatientFromFamily.length > 0) {
            list = list.concat(videoPatientFromFamily);
        }
        if (videoPatientFromEstablishment && videoPatientFromEstablishment.length > 0) {
            list = list.concat(videoPatientFromEstablishment);
        }
        if (videoYoutubePatientFromFamily && videoYoutubePatientFromFamily.length > 0) {
            list = list.concat(videoYoutubePatientFromFamily);
        }
        if (
            videoYoutubePatientFromEstablishment &&
            videoYoutubePatientFromEstablishment.length > 0
        ) {
            list = list.concat(videoYoutubePatientFromEstablishment);
        }
    }
    if (list.length > 0) {
        return list;
    }
    return null;
};

// export const getPatientVideoItem = async (
//     user: User,
//     exerciseList: Exercise[],
//     adminListId: string[]
// ): Promise<any> => {
//     const listVideo = await getAllVideoItem(user, adminListId);
//     const pictureList: ExerciseItem[] = [];
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     const mediaArray: any = [];
//     await Promise.all(
//         exerciseList.map(async (exercise) => {
//             if (exercise.exerciseImages) {
//                 if (exercise.exerciseImages.length > 1) {
//                     exercise.exerciseImages.forEach(async (element) => {
//                         if (element && uidToDisable.includes(element) === false) {
//                             const item = await getExerciseItem(element);
//                             if (item && checkArrayExerciseItem(pictureList, item) === false) {
//                                 pictureList.unshift(item);
//                                 mediaArray.unshift(item);
//                             }
//                         }
//                     });
//                 }
//                 if (
//                     exercise.exerciseImages.length === 1 &&
//                     exercise.exerciseImages[0] &&
//                     uidToDisable.includes(exercise.exerciseImages[0]) === false
//                 ) {
//                     const item = await getExerciseItem(exercise.exerciseImages[0]);
//                     if (item && checkArrayExerciseItem(pictureList, item) === false) {
//                         pictureList.unshift(item);
//                         mediaArray.unshift(item);
//                     }
//                 }
//             }
//             if (
//                 exercise.rewardPhoto &&
//                 listVideo &&
//                 typeof exercise.rewardPhoto === 'string' &&
//                 checkArrayMediaInformation(mediaArray, exercise.rewardPhoto) === false
//             ) {
//                 const mediaItem = getMediaInformation(listVideo, exercise.rewardPhoto);
//                 if (mediaItem !== undefined) {
//                     mediaArray.unshift(mediaItem);
//                 }
//             }
//             if (
//                 exercise.rewardVideo &&
//                 listVideo &&
//                 typeof exercise.rewardVideo === 'string' &&
//                 checkArrayMediaInformation(mediaArray, exercise.rewardVideo) === false
//             ) {
//                 const mediaItem = getMediaInformation(listVideo, exercise.rewardVideo);
//                 if (mediaItem !== undefined) {
//                     mediaArray.unshift(mediaItem);
//                 }
//             }
//         })
//     );
//     // setMediaList(_.orderBy(mediaArray, ['createdDate'], ['desc']));
//     return _.orderBy(mediaArray, ['createdDate'], ['desc']);
// };

export const getPatientVideoItem = async (user: User): Promise<any> => {
    if (user && user.familyCode && user.establishmentCode && user.id) {
        const youtubeVideoList = await getPersonalVideos(
            user.familyCode,
            user.establishmentCode,
            'youtubeVideo',
            user.id
        );
        const videoList = await getPersonalVideos(
            user.familyCode,
            user.establishmentCode,
            'video',
            user.id
        );
        if (youtubeVideoList) {
            youtubeVideoList.forEach((item) => {
                videoList?.push(item);
            });
        }
        const pictureList: {
            authorType: Roles | undefined;
            createdDate: Timestamp | null | undefined;
            data: ExerciseItem;
        }[] = await getFamilyPicturesForMedia(user?.familyCode, user.id, user?.userId ?? '', user?.establishmentCode);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mediaArray: any = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tmpMediaArray: any[] = [];

        videoList?.forEach((item) => {
            tmpMediaArray.push(item);
        });
        pictureList.forEach((item) => {
            tmpMediaArray.push(item);
        });
        tmpMediaArray.sort(
            (
                x: {
                    authorType: Roles | undefined;
                    createdDate: Timestamp | null | undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: any;
                },
                y: {
                    authorType: Roles | undefined;
                    createdDate: Timestamp | null | undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: any;
                }
            ): number => {
                if (x.createdDate && y.createdDate) {
                    const date1 = new Date(x.createdDate.toDate()).getTime();
                    const date2 = new Date(y.createdDate.toDate()).getTime();

                    return date2 - date1;
                }
                return 0;
            }
        );
        tmpMediaArray.sort(
            (
                x: {
                    authorType: Roles | undefined;
                    createdDate: Timestamp | null | undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: any;
                },
                y: {
                    authorType: Roles | undefined;
                    createdDate: Timestamp | null | undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: any;
                }
            ): number => {
                if (x.authorType === Roles.ADMIN && y.authorType !== Roles.ADMIN) {
                    return 1;
                }
                if (x.authorType !== Roles.ADMIN && y.authorType === Roles.ADMIN) {
                    return -1;
                }
                return 0;
            }
        );
        tmpMediaArray.forEach((item) => {
            mediaArray.push({...item.data, authorType: item.authorType, id: item.id ?? item.data.id});
        });
        return mediaArray;
    }
};

export const getProPictureVideoItem = async (user: User): Promise<any> => {
    if (user.userId && user.id && user.role === Roles.PRO && user.establishmentCode) {
        const userIdFirebase = await getDocId(user.id);
        let mediaArray: any = [];
        const tempPicture = await getExerciseItemsByAutor(user.id, user.userId, user.establishmentCode);
        if (tempPicture) {
            mediaArray = tempPicture;
        }
        const tempMedia = await getPersonalVideosByAutor(user.id, user.establishmentCode, userIdFirebase);
        if (tempMedia) {
            mediaArray = mediaArray.concat(tempMedia);
        }


        mediaArray.sort(
            (
                x: {
                    createdDate: Timestamp | null | undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: any;
                },
                y: {
                    createdDate: Timestamp | null | undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: any;
                }
            ): number => {
                if (x.createdDate && y.createdDate) {
                    const date1 = new Date(x.createdDate.toDate()).getTime();
                    const date2 = new Date(y.createdDate.toDate()).getTime();

                    return date2 - date1;
                }
                return 0;
            }
        );


        return mediaArray;
    }
};

export const getAdminPictureVideoItem = async (user: User): Promise<any> => {
    if (user.userId && user.id && user.role === Roles.ADMIN) {
        let mediaArray: any = [];
        const tempPicture = await getExerciseItemsByAutor(user.userId, user.id, user.establishmentCode ?? '');
        if (tempPicture) {
            mediaArray = tempPicture;
        }
        const tempMedia = await getPersonalVideosByAutor(user.userId, user.userId, user.id);
        if (tempMedia) {
            mediaArray = mediaArray.concat(tempMedia);
        }


        mediaArray.sort(
            (
                x: {
                    createdDate: Timestamp | null | undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: any;
                },
                y: {
                    createdDate: Timestamp | null | undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data: any;
                }
            ): number => {
                if (x.createdDate && y.createdDate) {
                    const date1 = new Date(x.createdDate.toDate()).getTime();
                    const date2 = new Date(y.createdDate.toDate()).getTime();

                    return date2 - date1;
                }
                return 0;
            }
        );


        return mediaArray;
    }
};