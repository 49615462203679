import firestore, { Timestamp } from 'firebase/firestore';
import { CompletedMemoryExercise } from './CompletedMemoryExercise';
import { FormData } from './FormData';
import { FormField } from './FormField';
import { SeniorStatistics } from './SeniorStatistics';
import { Review } from './Review';
import { Status } from './Status';
import { User } from './User';
import { MediaStatistics } from './MediaStatistics';
import { ReviewStatistics } from './ReviewStatistics';

export const levelOptionList: string[] = [
    'Aucune idée',
    'Aucun diplôme ou certificat d’études primaires',
    'Brevet des collèges',
    'CAP, BEP ou équivalent',
    'Baccalauréat, brevet professionnel ou équivalent',
    'Diplôme du supérieur court (niveau bac + 2)',
    'Diplôme du supérieur long',
    'Non communiqué'
];

export const optionList: string[] = [
    'Aucune idée',
    'Autres personnes sans activité professionnelle',
    'Agriculteurs exploitants',
    "Artisans, commerçants et chefs d'entreprise",
    'Cadres et professions intellectuelles supérieures',
    'Professions Intermédiaires',
    'Employés',
    'Ouvriers',
    'Non communiqué'
];

const genderList = ['Femme', 'Homme', 'Mixte'];

export class Patient extends User implements FormData {
    sex?: string | undefined;

    level?: string | undefined;

    legalReferent?: string | undefined;

    category?: string | undefined;

    establishmentName?: string | undefined;

    familyCode?: string | undefined;

    establishmentCode?: string | undefined;

    startDate?: Timestamp | undefined;

    endDate?: Timestamp | undefined;

    address?: string | undefined;

    pincode?: string | undefined;

    city?: string | undefined;

    familyMembers?: string[] | undefined;

    memoryGiveUp?: number | undefined;

    memoryDifficultyLevel?: number | undefined;

    memorinGiveUp?: number | undefined;

    memorinDifficultyLevel?: number | undefined;

    sudokuDifficultyLevel?: number | undefined;

    pongDifficultyLevel?: number | undefined;

    puzzleGiveUp?: number | undefined;

    sudokuGiveUp?: number | undefined;

    pongGiveUp?: number | undefined;

    reorderGiveUp?: number | undefined;

    puzzleDifficultyLevel?: number | undefined;

    reorderDifficultyLevel?: number | undefined;

    completedExerciseList?: CompletedMemoryExercise[] | undefined;

    review?: Review[] | undefined;

    status?: Status | undefined;

    statistics?: SeniorStatistics[] | undefined;
    
    mediaStatistics?: MediaStatistics[] | undefined;

    reviewStatistics?: ReviewStatistics[] | undefined;

    memoryCounter?: number | undefined;

    memorinCounter?: number | undefined;

    puzzleCounter?: number | undefined;

    sudokuCounter?: number | undefined;

    reorderCounter?: number | undefined;

    memorinCounterLoose?: number | undefined;

    createdDate?: Timestamp | undefined;

    constructor(
        id?: string,
        role?: 'PATIENT' | 'FAMILY' | 'THERAPIST' | 'ADMIN',
        emailId?: string,
        firstName?: string,
        lastName?: string,
        dob?: Timestamp,
        profilePhoto?: string | File,
        sex?: string,
        level?: string,
        legalReferent?: string,
        category?: string,
        establishmentName?: string,
        familyCode?: string,
        establishmentCode?: string,
        startDate?: Timestamp,
        endDate?: Timestamp,
        address?: string,
        pincode?: string,
        city?: string,
        familyMembers?: string[],
        memoryGiveUp?: number,
        memoryDifficultyLevel?: number,
        memorinGiveUp?: number,
        memorinDifficultyLevel?: number,
        sudokuDifficultyLevel?: number,
        pongDifficultyLevel?: number,
        puzzleGiveUp?: number,
        sudokuGiveUp?: number,
        pongGiveUp?: number,
        reorderGiveUp?: number,
        puzzleDifficultyLevel?: number,
        reorderDifficultyLevel?: number,
        completedExerciseList?: CompletedMemoryExercise[],
        review?: Review[],
        status?: Status,
        statistics?: SeniorStatistics[],
        mediaStatistics?: MediaStatistics[],
        reviewStatistics?: ReviewStatistics[],
        memoryCounter?: number,
        memorinCounter?: number,
        puzzleCounter?: number,
        sudokuCounter?: number,
        reorderCounter?: number,
        memorinCounterLoose?: number,
        createdDate?: Timestamp,
        isSubscribed?: boolean,
        needDelete?: boolean,
        needViewOnboarding?: boolean
    ) {
        super({ id, role, emailId, firstName, lastName, dob, profilePhoto, isSubscribed, needDelete, needViewOnboarding });
        this.sex = sex;
        this.level = level;
        this.legalReferent = legalReferent;
        this.category = category;
        this.establishmentName = establishmentName;
        this.familyCode = familyCode;
        this.establishmentCode = establishmentCode;
        this.startDate = startDate;
        this.endDate = endDate;
        this.address = address;
        this.pincode = pincode;
        this.city = city;
        this.familyMembers = familyMembers;
        this.memoryGiveUp = memoryGiveUp;
        this.memoryDifficultyLevel = memoryDifficultyLevel;
        this.memorinGiveUp = memorinGiveUp;
        this.memorinDifficultyLevel = memorinDifficultyLevel;
        this.sudokuDifficultyLevel = sudokuDifficultyLevel;
        this.pongDifficultyLevel = pongDifficultyLevel;
        this.puzzleGiveUp = puzzleGiveUp;
        this.sudokuGiveUp = sudokuGiveUp;
        this.pongGiveUp = pongGiveUp;
        this.reorderGiveUp = reorderGiveUp;
        this.puzzleDifficultyLevel = puzzleDifficultyLevel;
        this.reorderDifficultyLevel = reorderDifficultyLevel;
        this.completedExerciseList = completedExerciseList;
        this.review = review;
        this.status = status;
        this.statistics = statistics;
        this.mediaStatistics = mediaStatistics;
        this.reviewStatistics = reviewStatistics;
        this.memoryCounter = memoryCounter;
        this.memorinCounter = memorinCounter;
        this.puzzleCounter = puzzleCounter;
        this.sudokuCounter = sudokuCounter;
        this.reorderCounter = reorderCounter;
        this.memorinCounterLoose = memorinCounterLoose;
        this.createdDate = createdDate;
    }

    firstStepFormFields?: FormField[] | undefined = [
        new FormField({ placeholder: 'patientLastName', field: 'lastName' }),
        new FormField({
            placeholder: 'complexityLevel',
            field: 'level',
            type: 'select',
            options: levelOptionList
        }),
        new FormField({ placeholder: 'patientFirstName', field: 'firstName' }),
        new FormField({
            placeholder: 'socialCategory',
            field: 'category',
            type: 'select',
            options: optionList
        }),
        new FormField({ placeholder: 'dob', field: 'dob', type: 'date', required: false }),
        new FormField({
            placeholder: 'nameEstablishment',
            field: 'establishmentCode',
            type: 'select',
            dropdownOptions: 'establishment'
        }),
        new FormField({
            placeholder: 'gender',
            field: 'sex',
            type: 'select',
            options: genderList
        })
    ];

    secondStepFormFields?: FormField[] | undefined = [
        new FormField({ placeholder: 'email', field: 'emailId', type: 'email' }),
        new FormField({ placeholder: 'password', field: 'password', type: 'password' }),
        new FormField({
            placeholder: 'familyCode',
            field: 'familyCode'
        }),
        new FormField({ placeholder: 'startDate', field: 'startDate', type: 'date' }),
        new FormField({ placeholder: 'endDate', field: 'endDate', type: 'date' })
    ];
}

export const PatientConverter: firestore.FirestoreDataConverter<Patient> = {
    toFirestore(patient) {
        return {
            role: patient.role,
            emailId: patient.emailId,
            firstName: patient.firstName,
            lastName: patient.lastName,
            dob: patient.dob,
            profilePhoto: patient.profilePhoto,
            sex: patient.sex,
            level: patient.level,
            legalReferent: patient.legalReferent,
            category: patient.category,
            establishmentName: patient.establishmentName,
            familyCode: patient.familyCode,
            establishmentCode: patient.establishmentCode,
            startDate: patient.startDate,
            endDate: patient.endDate,
            address: patient.address,
            pincode: patient.pincode,
            city: patient.city,
            familyMembers: patient.familyMembers,
            memoryGiveUp: patient.memoryGiveUp,
            memoryRating: patient.memoryDifficultyLevel,
            memorinGiveUp: patient.memorinGiveUp,
            memorinRating: patient.memorinDifficultyLevel,
            sudokuRating: patient.sudokuDifficultyLevel,
            pongRating: patient.pongDifficultyLevel,
            puzzleGiveUp: patient.puzzleGiveUp,
            sudokuGiveUp: patient.sudokuGiveUp,
            pongGiveUp: patient.pongGiveUp,
            reorderGiveUp: patient.reorderGiveUp,
            puzzleRating: patient.puzzleDifficultyLevel,
            reorderRating: patient.reorderDifficultyLevel,
            completedExerciseList: patient.completedExerciseList,
            review: patient.review,
            status: patient.status,
            statistics: patient.statistics,
            mediaStatistics: patient.mediaStatistics,
            reviewStatistics: patient.reviewStatistics,
            memoryCounter: patient.memoryCounter,
            memorinCounter: patient.memorinCounter,
            puzzleCounter: patient.puzzleCounter,
            sudokuCounter: patient.sudokuCounter,
            reorderCounter: patient.reorderCounter,
            memorinCounterLoose: patient.memorinCounterLoose,
            isSubscribed: patient.isSubscribed,
            needDelete: patient.needDelete,
            needViewOnboarding: patient.needViewOnboarding,
        };
    },
    fromFirestore(snapshot, options): Patient {
        const data = snapshot.data(options);
        return new Patient(
            snapshot.id,
            data.role,
            data.emailId,
            data.firstName,
            data.lastName,
            data.dob,
            data.profilePhoto,
            data.sex,
            data.level,
            data.legalReferent,
            data.category,
            data.establishmentName,
            data.familyCode,
            data.establishmentCode,
            data.startDate,
            data.endDate,
            data.address,
            data.pincode,
            data.city,
            data.familyMembers,
            data.memoryGiveUp,
            data.memoryDifficultyLevel,
            data.memorinGiveUp,
            data.memorinDifficultyLevel,
            data.sudokuDifficultyLevel,
            data.pongDifficultyLevel,
            data.puzzleGiveUp,
            data.sudokuGiveUp,
            data.pongGiveUp,
            data.reorderGiveUp,
            data.puzzleDifficultyLevel,
            data.reorderDifficultyLevel,
            data.completedExerciseList,
            data.review,
            data.status,
            data.statistics,
            data.mediaStatistics,
            data.reviewStatistics,
            data.memoryCounter,
            data.memorinCounter,
            data.puzzleCounter,
            data.sudokuCounter,
            data.reorderCounter,
            data.memorinCounterLoose,
            data.createdDate,
            data.isSubscribed,
            data.needDelete,
            data.needViewOnboarding
        );
    }
};
