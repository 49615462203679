import React, { CSSProperties } from 'react';
import '../utilities/utils.css';
import { ExerciseType } from '../models/ExerciseType';

export const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem'
        // padding: '1rem 32px'
    },
    'top-bg': {
        height: '12.5rem',
        width: '100%',
        backgroundColor: '#1C0D4B',
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        zIndex: -1
    },
    'top-graph-bg': {
        position: 'absolute',
        bottom: '0.5rem',
        right: '0',
        zIndex: -1,
        WebkitMaskImage: 'linear-gradient(to right, transparent, transparent 20%, #1C0D4B)'
    },
    headText: {
        color: 'white',
        fontSize: '0.875rem',
        fontWeight: '500',
        textAlign: 'left'
    },
    headTextBold: {
        color: 'white',
        fontSize: '2rem',
        fontWeight: '800',
        textAlign: 'left'
    },
    'stat-card-container': {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        width: '100%',
        height: 'fit-content'
    },
    'stat-card-title-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    'stat-card-title': {
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#0D224B'
    },
    'stat-card-subtitle': {
        fontSize: '1rem',
        fontWeight: '600',
        color: '#0D224B'
    },
    'stat-card-toolkit': {
        // fontSize: '0.875rem',
        // fontWeight: '600',
        backgroundColor: '#1C0D4B',
        minWidth: '2.5rem',
        minHeight: '1.125rem',
        // color: 'white',
        // padding: '0px 0.5rem',
        borderRadius: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        // letterSpacing: '0.075rem'
    },
    'stat-card': {
        borderRadius: '1rem',
        marginTop: '0.75rem',
        boxShadow: '0px 4px 12px 0px rgba(164, 161, 196, 0.74)',
        width: '100%',
        height: 'fit-content',
        padding: '1rem 1.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem'
    },
    'stat-card-no-top': {
        marginTop: '0rem',
        borderTopLeftRadius: '0rem',
        borderTopRightRadius: '0rem'
    },
    'stat-card-blue': {
        width: '100%',
        height: 'fit-content',
        backgroundColor: '#0D224B',

        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
        marginTop: '0.75rem',
        // boxShadow: '0px 4px 12px 0px rgba(164, 161, 196, 0.74)',
        padding: '1rem 1.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem'
    },
    'stat-card-evol-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '0.5rem',
        padding: '0.75rem',
        backgroundColor: '#EBF2FF',
        borderRadius: '0.5rem'
    },
    'stat-card-evol-text': {
        fontSize: '0.875rem',
        fontWeight: '500',
        textAlign: 'left',
        color: '#1C0D4B',
        lineHeight: '1.25rem'
    },
    'stat-card-blue-staisfaction': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: '0.125rem',
        // padding: '1.5rem',
        paddingTop: '0.5rem',
        position: 'relative'
    },
    'stat-card-blue-title': {
        fontSize: '1rem',
        fontWeight: '500',
        textAlign: 'left',
        color: '#FFFFFF'
    },
    'star-bg': {
        position: 'absolute',
        // top: '-1.5rem',
        // left: '0',
        top: '0'
        // zIndex: 1
    },
    'games-icon': {
        height: '3rem',
        width: '3rem'
    },
    'select-white': {
        backgroundColor: 'transparent',
        border: 'white 1px solid',
        borderRadius: '1.5rem',
        color: 'white',
        height: '2.5rem',
        padding: '0 2rem 0 1rem'
    },
    'select-white-root': {
        height: '2.5rem'
    },
    'white-btn': {
        backgroundColor: 'white',
        color: '#0D224B',
        fontSize: '0.875rem',
        fontWeight: '500',
        border: '1px solid #E8E8E8',
        borderRadius: '1.5rem',
        minHeight: '2.5rem',
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

import memorizIcon from '../assets/duo.svg';
import pongIcon from '../assets/pong.svg';
import puzzleIcon from '../assets/puzzle.svg';
import quizIcon from '../assets/quizz.svg';
import sudokuIcon from '../assets/sudok.svg';
import orderIcon from '../assets/reordon.svg';
import { t } from 'i18next';
import { Select } from './Select';
import { translateDay } from '../utilities/utils';
import { forEach } from 'lodash';
import { Typography } from './Typography';

export const lstGames: { type: ExerciseType; name: string; img: string }[] = [
    { type: ExerciseType.MEMORIZ, name: 'memorin', img: memorizIcon },
    { type: ExerciseType.PONG, name: "PONG'IN", img: pongIcon },
    { type: ExerciseType.PUZZLE, name: "PUZZL'IN", img: puzzleIcon },
    { type: ExerciseType.QUIZ, name: 'Quiz', img: quizIcon },
    { type: ExerciseType.ORDER, name: 'reorder', img: orderIcon },
    { type: ExerciseType.SUDOKU, name: 'Sudoku', img: sudokuIcon }
];

export const lstGamesToUnsort: { type: ExerciseType; name: string; img: string }[] = [
    { type: ExerciseType.MEMORIZ, name: 'memorin', img: memorizIcon },
    { type: ExerciseType.PONG, name: "PONG'IN", img: pongIcon },
    { type: ExerciseType.PUZZLE, name: "PUZZL'IN", img: puzzleIcon },
    { type: ExerciseType.QUIZ, name: 'Quiz', img: quizIcon },
    { type: ExerciseType.ORDER, name: 'reorder', img: orderIcon },
    { type: ExerciseType.SUDOKU, name: 'Sudoku', img: sudokuIcon }
];

export const lstGamesForSelect: { type: ExerciseType | 'all'; name: string }[] = [
    { type: 'all', name: t('all') },
    { type: ExerciseType.MEMORIZ, name: t('memorin') },
    { type: ExerciseType.PUZZLE, name: t("PUZZL'IN") },
    { type: ExerciseType.QUIZ, name: t('Quiz') },
    { type: ExerciseType.ORDER, name: t('reorder') }
];

export const lstMedias: { type: string; name: string }[] = [
    { type: 'all', name: t('all') },
    { type: 'picture', name: t('picture') },
    { type: 'personalVideo', name: t('personalVideo') },
    { type: 'youtubeVideo', name: t('youtube') }
];

export const labelsMonth = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
];

export const labelsDay = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
export const labelsMonthShort = {
    1: 'Janv.',
    2: 'Févr.',
    3: 'Mars',
    4: 'Avr.',
    5: 'Mai',
    6: 'Juin',
    7: 'Juill.',
    8: 'Août',
    9: 'Sept.',
    10: 'Oct.',
    11: 'Nov.',
    12: 'Déc.'
};

//  make interface for statToolkit
interface PropsStatToolkit {
    value: number | string;
    disabledTooltip?: boolean;
    tooltipText?: string | null;
}
export function StatToolkit({ value, disabledTooltip, tooltipText }: PropsStatToolkit): JSX.Element {
    return (
        <div style={{ ...classes['stat-card-toolkit'] }} className={tooltipText ? 'tooltipContainer' : ''}>
            <div className={`tooltip${disabledTooltip ? " disabled" : ""}`}>
                {tooltipText}
            </div>
            <Typography
                variant="p"
                sx={{
                    fontSize: '0.875rem',
                    fontWeight: '600',
                    color: 'white',
                    padding: '0.25rem 0.5rem',
                    letterSpacing: '0.075rem',
                    textAlign: 'center',
                    lineHeight: '1rem',
                    // textTransform: 'uppercase',
                    whiteSpace: 'nowrap'
                }}>
                {typeof value === 'number' && value < 9 ? `0${value}` : value}
            </Typography>
        </div>
    );
}

//  make interface for SelectTimeline
interface Props {
    value?: string;
    onChange?: (data: {
        val: string;
        startDate: Date;
        endDate: Date;
        timeScale: 'day' | 'week' | 'month' | 'year';
    }) => void;
    sx?: CSSProperties;
    sxRoot?: CSSProperties;
    optColorBlue?: boolean;
    arrowWhite?: boolean;
}
export function SelectTimeline({
    onChange,
    value,
    sx,
    sxRoot,
    optColorBlue,
    arrowWhite
}: Props): JSX.Element {
    return (
        <Select
            // sxRoot={{ ...classes['select-white-root'] }}
            optColorBlue={optColorBlue}
            arrowWhite={arrowWhite}
            sxRoot={sxRoot}
            sx={{
                border: '1px solid #E8E8E8',
                borderRadius: '1.5rem',
                display: 'flex',
                flexDirection: 'row',
                // gap: '0.5rem',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx
            }}
            defaultValue="month"
            value={value}
            opts={[
                { value: 'day', label: t('profile.statistics.day') },
                { value: 'week', label: t('profile.statistics.week') },
                { value: 'month', label: t('profile.statistics.month') },
                { value: 'year', label: t('profile.statistics.year') }
                // { value: 'define', label: t('profile.statistics.define') }
            ]}
            onChange={(e) => {
                if (onChange) {
                    let startDate;
                    let endDate;
                    let timeScale: 'day' | 'week' | 'month' | 'year' = 'day';
                    switch (e.target.value) {
                        case 'day':
                            endDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                                23,
                                59,
                                59
                            );
                            startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() - 6,
                                0,
                                0,
                                0
                            );
                            timeScale = 'day';
                            break;
                        case 'week':
                            const lastDayOfWeek = new Date();
                            lastDayOfWeek.setDate(
                                lastDayOfWeek.getDate() - lastDayOfWeek.getDay() + 6
                            );
                            endDate = new Date(
                                lastDayOfWeek.getFullYear(),
                                lastDayOfWeek.getMonth(),
                                lastDayOfWeek.getDate() + 1,
                                23,
                                59,
                                59
                            );
                            const firstDayOfWeek = new Date();
                            firstDayOfWeek.setDate(
                                firstDayOfWeek.getDate() - firstDayOfWeek.getDay() - 20
                            );
                            startDate = new Date(
                                firstDayOfWeek.getFullYear(),
                                firstDayOfWeek.getMonth(),
                                firstDayOfWeek.getDate(),
                                0,
                                0,
                                0
                            );
                            timeScale = 'week';
                            break;
                        case 'month':
                            const endDateMonth = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth() + 1,
                                0
                            );
                            endDate = new Date(
                                endDateMonth.getFullYear(),
                                endDateMonth.getMonth(),
                                endDateMonth.getDate(),
                                23,
                                59,
                                59
                            );
                            startDate = new Date(
                                endDateMonth.getFullYear(),
                                endDateMonth.getMonth() - 3,
                                1
                            );
                            timeScale = 'month';
                            break;
                        case 'year':
                            endDate = new Date(new Date().getFullYear(), 11, 31, 23, 59, 59);
                            startDate = new Date(new Date().getFullYear() - 3, 0, 1, 0, 0, 0);
                            timeScale = 'year';
                            break;
                        default:
                            break;
                    }
                    if (startDate && endDate)
                        onChange({ val: e.target.value, startDate, endDate, timeScale });
                }
            }}
        />
    );
}

//  make interface for SelectTimeRange
interface PropsTimeRange {
    value?: string;
    defaultValue?: string;
    onChange?: (data: {
        val: string;
        startDate: Date;
        endDate: Date;
        timeRange: 'fromStart' | 'last24h' | 'last7d' | 'last30d' | 'last12m';
    }) => void;
    sx?: CSSProperties;
    sxRoot?: CSSProperties;
    optColorBlue?: boolean;
    arrowWhite?: boolean;
}
export function SelectTimeRange({
    onChange,
    value,
    defaultValue,
    sx,
    sxRoot,
    optColorBlue,
    arrowWhite
}: PropsTimeRange): JSX.Element {
    return (
        <Select
            // sxRoot={{ ...classes['select-white-root'] }}
            optColorBlue={optColorBlue}
            arrowWhite={arrowWhite}
            sxRoot={sxRoot}
            defaultValue={defaultValue}
            sx={{
                border: '1px solid #E8E8E8',
                borderRadius: '1.5rem',
                display: 'flex',
                flexDirection: 'row',
                // gap: '0.5rem',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx
            }}
            // value={value}
            opts={[
                { value: 'last24h', label: t('profile.statistics.last24h') },
                { value: 'last7d', label: t('profile.statistics.last7d') },
                { value: 'last30d', label: t('profile.statistics.last30d') },
                { value: 'last12m', label: t('profile.statistics.last12m') },
                { value: 'fromStart', label: t('profile.statistics.fromStart') }
            ]}
            onChange={(e) => {
                if (onChange) {
                    let startDate;
                    let endDate;
                    const timeRange: 'fromStart' | 'last24h' | 'last7d' | 'last30d' | 'last12m' =
                        e.target.value;
                    switch (e.target.value) {
                        case 'fromStart':
                            endDate = new Date();
                            startDate = new Date(0);
                            break;
                        case 'last24h':
                            endDate = new Date();
                            startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                                new Date().getHours(),
                                new Date().getMinutes(),
                                new Date().getSeconds() - 24 * 60 * 60
                            );
                            break;
                        case 'last7d':
                            endDate = new Date();
                            startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() - 7,
                                new Date().getHours(),
                                new Date().getMinutes(),
                                new Date().getSeconds()
                            );
                            break;
                        case 'last30d':
                            endDate = new Date();
                            startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() - 30,
                                new Date().getHours(),
                                new Date().getMinutes(),
                                new Date().getSeconds()
                            );
                            // timeScale = 'year';
                            break;
                        case 'last12m':
                            endDate = new Date();
                            startDate = new Date(
                                new Date().getFullYear() - 1,
                                new Date().getMonth(),
                                new Date().getDate(),
                                new Date().getHours(),
                                new Date().getMinutes(),
                                new Date().getSeconds()
                            );
                            break;
                        default:
                            break;
                    }
                    if (startDate && endDate)
                        onChange({ val: e.target.value, startDate, endDate, timeRange });
                }
            }}
        />
    );
}

export interface StatsArray {
    1: number[];
    2: number[];
    3: number[];
    4: number[];
    5: number[];
    6: number[];
    7: number[];
}
export interface StatsArrayString {
    1: string[];
    2: string[];
    3: string[];
    4: string[];
    5: string[];
    6: string[];
    7: string[];
}

export const getLabelFromDate = (
    startDate: Date,
    endDate: Date,
    timeScale: 'day' | 'week' | 'month' | 'year'
): string[] => {
    let labels: string[] = [];
    switch (timeScale) {
        case 'day':
            // console.log(startDate, startDate.getDay());
            const day = [
                startDate,
                new Date(new Date(startDate).setDate(startDate.getDate() + 1)),
                new Date(new Date(startDate).setDate(startDate.getDate() + 2)),
                new Date(new Date(startDate).setDate(startDate.getDate() + 3)),
                new Date(new Date(startDate).setDate(startDate.getDate() + 4)),
                new Date(new Date(startDate).setDate(startDate.getDate() + 5)),
                new Date(new Date(startDate).setDate(startDate.getDate() + 6))
            ];
            const numberTo2digits = (num: number) => {
                if (num < 10) return '0' + num.toString();
                else return num.toString();
            };
            labels = [
                translateDay(startDate.getDay(), true) +
                    ' ' +
                    numberTo2digits(day[0].getDate()) +
                    '/' +
                    numberTo2digits(day[0].getMonth() + 1),
                translateDay((startDate.getDay() + 1) % 7, true) +
                    ' ' +
                    numberTo2digits(day[1].getDate()) +
                    '/' +
                    numberTo2digits(day[1].getMonth() + 1),
                translateDay((startDate.getDay() + 2) % 7, true) +
                    ' ' +
                    numberTo2digits(day[2].getDate()) +
                    '/' +
                    numberTo2digits(day[2].getMonth() + 1),
                translateDay((startDate.getDay() + 3) % 7, true) +
                    ' ' +
                    numberTo2digits(day[3].getDate()) +
                    '/' +
                    numberTo2digits(day[3].getMonth() + 1),
                translateDay((startDate.getDay() + 4) % 7, true) +
                    ' ' +
                    numberTo2digits(day[4].getDate()) +
                    '/' +
                    numberTo2digits(day[4].getMonth() + 1),
                translateDay((startDate.getDay() + 5) % 7, true) +
                    ' ' +
                    numberTo2digits(day[5].getDate()) +
                    '/' +
                    numberTo2digits(day[5].getMonth() + 1),
                translateDay((startDate.getDay() + 6) % 7, true) +
                    ' ' +
                    numberTo2digits(day[6].getDate()) +
                    '/' +
                    numberTo2digits(day[6].getMonth() + 1)
            ];
            break;
        case 'week':
            forEach([0, 1, 2, 3], (i) => {
                const thisDate = new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + i * 7
                );
                const firstDayOfYear = new Date(thisDate.getFullYear(), 0, 1);
                const pastDaysOfYear = (thisDate.getTime() - firstDayOfYear.getTime()) / 86400000;
                const weekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
                labels.push('S.' + weekNumber.toString());
            });
            break;
        case 'month':
            const date1 = new Date(
                startDate.getFullYear(),
                startDate.getMonth() + 0,
                startDate.getDate(),
                startDate.getHours(),
                startDate.getMinutes(),
                startDate.getSeconds()
            );
            const date2 = new Date(
                startDate.getFullYear(),
                startDate.getMonth() + 1,
                startDate.getDate(),
                startDate.getHours(),
                startDate.getMinutes(),
                startDate.getSeconds()
            );
            const date3 = new Date(
                startDate.getFullYear(),
                startDate.getMonth() + 2,
                startDate.getDate(),
                startDate.getHours(),
                startDate.getMinutes(),
                startDate.getSeconds()
            );
            const date4 = new Date(
                startDate.getFullYear(),
                startDate.getMonth() + 3,
                startDate.getDate(),
                startDate.getHours(),
                startDate.getMinutes(),
                startDate.getSeconds()
            );
            labels = [
                labelsMonthShort[
                    (date1.getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
                ] +
                    ' ' +
                    date1.getFullYear().toString().slice(-2),
                labelsMonthShort[
                    (date2.getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
                ] +
                    ' ' +
                    date2.getFullYear().toString().slice(-2),
                labelsMonthShort[
                    (date3.getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
                ] +
                    ' ' +
                    date3.getFullYear().toString().slice(-2),
                labelsMonthShort[
                    (date4.getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
                ] +
                    ' ' +
                    date4.getFullYear().toString().slice(-2)
            ];
            break;
        case 'year':
            labels = [
                startDate.getFullYear().toString(),
                (startDate.getFullYear() + 1).toString(),
                (startDate.getFullYear() + 2).toString(),
                (startDate.getFullYear() + 3).toString()
            ];
            break;
        default:
            break;
    }
    return labels;
};

export const getWeekNumber = (date: Date, addWeek: number = 0): number => {
    // warning sunday is the last day of the week not the first
    // date.setDate(date.getDate() + addWeek * 7);
    const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 7 * addWeek,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    );
    const firstDayOfYear = new Date(newDate.getFullYear(), 0, 1);
    const pastDaysOfYear = (newDate.getTime() - firstDayOfYear.getTime()) / 86400000;
    const weekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);

    return weekNumber;
};

export const changetimeLine = (
    str: 'next' | 'prev',
    timeScale: 'day' | 'week' | 'month' | 'year',
    endDate: Date,
    setEndDate: (date: Date) => void,
    startDate: Date,
    setStartDate: (date: Date) => void
) => {
    if (str === 'next') {
        switch (timeScale) {
            case 'day':
                setEndDate(
                    new Date(
                        endDate.getFullYear(),
                        endDate.getMonth(),
                        endDate.getDate() + 1,
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                    )
                );
                setStartDate(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() + 1,
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    )
                );
                break;
            case 'week':
                setEndDate(
                    new Date(
                        endDate.getFullYear(),
                        endDate.getMonth(),
                        endDate.getDate() + 7,
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                    )
                );
                setStartDate(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() + 7,
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    )
                );
                break;
            case 'month':
                setEndDate(
                    new Date(
                        endDate.getFullYear(),
                        endDate.getMonth() + 1,
                        endDate.getDate(),
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                    )
                );
                setStartDate(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth() + 1,
                        startDate.getDate(),
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    )
                );
                break;
            case 'year':
                setEndDate(
                    new Date(
                        endDate.getFullYear() + 1,
                        endDate.getMonth(),
                        endDate.getDate(),
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                    )
                );
                setStartDate(
                    new Date(
                        startDate.getFullYear() + 1,
                        startDate.getMonth(),
                        startDate.getDate(),
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    )
                );
                break;
        }
    } else {
        switch (timeScale) {
            case 'day':
                setEndDate(
                    new Date(
                        endDate.getFullYear(),
                        endDate.getMonth(),
                        endDate.getDate() - 1,
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                    )
                );
                setStartDate(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() - 1,
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    )
                );
                break;
            case 'week':
                setEndDate(
                    new Date(
                        endDate.getFullYear(),
                        endDate.getMonth(),
                        endDate.getDate() - 7,
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                    )
                );
                setStartDate(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() - 7,
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    )
                );
                break;
            case 'month':
                setEndDate(
                    new Date(
                        endDate.getFullYear(),
                        endDate.getMonth() - 1,
                        endDate.getDate(),
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                    )
                );
                setStartDate(
                    new Date(
                        startDate.getFullYear(),
                        startDate.getMonth() - 1,
                        startDate.getDate(),
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    )
                );
                break;
            case 'year':
                setEndDate(
                    new Date(
                        endDate.getFullYear() - 1,
                        endDate.getMonth(),
                        endDate.getDate(),
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                    )
                );
                setStartDate(
                    new Date(
                        startDate.getFullYear() - 1,
                        startDate.getMonth(),
                        startDate.getDate(),
                        startDate.getHours(),
                        startDate.getMinutes(),
                        startDate.getSeconds()
                    )
                );
                break;
        }
    }
};

export const addData = (
    timeScale: 'day' | 'week' | 'month' | 'year',
    startDate: Date,
    date: Date,
    thisData: StatsArray,
    dataToAdd: number = 1
): StatsArray => {
    switch (timeScale) {
        case 'day':
            const weekArray = [
                startDate.getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 1
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 2
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 3
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 4
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 5
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 6
                ).getDate()
            ];

            const indexDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = (weekArray.indexOf(date.getDate()) +
                1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
            if (indexDay !== 0 && thisData[indexDay]) {
                thisData[indexDay].push(dataToAdd);
            }
            break;
        case 'week':
            const weekArray2 = [
                getWeekNumber(startDate),
                getWeekNumber(startDate, 1),
                getWeekNumber(startDate, 2),
                getWeekNumber(startDate, 3)
            ];
            const indexWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = (weekArray2.indexOf(
                getWeekNumber(date)
            ) + 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
            if (indexWeek !== 0 && thisData[indexWeek]) {
                thisData[indexWeek].push(dataToAdd);
            }
            break;
        case 'month':
            const monthArray = [
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate()
                ).getMonth() + 1,
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 1,
                    startDate.getDate()
                ).getMonth() + 1,
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 2,
                    startDate.getDate()
                ).getMonth() + 1,
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 3,
                    startDate.getDate()
                ).getMonth() + 1
            ];
            const indexMonth: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = (monthArray.indexOf(
                date.getMonth() + 1
            ) + 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
            if (indexMonth !== 0 && thisData[indexMonth]) {
                thisData[indexMonth].push(dataToAdd);
            }
            break;
        case 'year':
            const yearArray = [
                startDate.getFullYear(),
                startDate.getFullYear() + 1,
                startDate.getFullYear() + 2,
                startDate.getFullYear() + 3
            ];
            const indexYear: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = (yearArray.indexOf(
                date.getFullYear()
            ) + 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
            if (indexYear !== 0 && thisData[indexYear]) {
                thisData[indexYear].push(dataToAdd);
            }
            break;
    }

    return thisData;
};

export const addDataString = (
    timeScale: 'day' | 'week' | 'month' | 'year',
    startDate: Date,
    date: Date,
    thisData: StatsArrayString,
    dataToAdd: string
): StatsArrayString => {
    switch (timeScale) {
        case 'day':
            const weekArray = [
                startDate.getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 1
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 2
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 3
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 4
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 5
                ).getDate(),
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate() + 6
                ).getDate()
            ];

            const indexDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = (weekArray.indexOf(date.getDate()) +
                1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
            if (indexDay !== 0 && thisData[indexDay]) {
                thisData[indexDay].push(dataToAdd);
            }
            break;
        case 'week':
            const weekArray2 = [
                getWeekNumber(startDate),
                getWeekNumber(startDate, 1),
                getWeekNumber(startDate, 2),
                getWeekNumber(startDate, 3)
            ];
            const indexWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = (weekArray2.indexOf(
                getWeekNumber(date)
            ) + 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
            if (indexWeek !== 0 && thisData[indexWeek]) {
                thisData[indexWeek].push(dataToAdd);
            }
            break;
        case 'month':
            const monthArray = [
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate()
                ).getMonth() + 1,
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 1,
                    startDate.getDate()
                ).getMonth() + 1,
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 2,
                    startDate.getDate()
                ).getMonth() + 1,
                new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 3,
                    startDate.getDate()
                ).getMonth() + 1
            ];
            const indexMonth: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = (monthArray.indexOf(
                date.getMonth() + 1
            ) + 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
            if (indexMonth !== 0 && thisData[indexMonth]) {
                thisData[indexMonth].push(dataToAdd);
            }
            break;
        case 'year':
            const yearArray = [
                startDate.getFullYear(),
                startDate.getFullYear() + 1,
                startDate.getFullYear() + 2,
                startDate.getFullYear() + 3
            ];
            const indexYear: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = (yearArray.indexOf(
                date.getFullYear()
            ) + 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
            if (indexYear !== 0 && thisData[indexYear]) {
                thisData[indexYear].push(dataToAdd);
            }
            break;
    }

    return thisData;
};

export const calcEvol = (
    dataStats: number[],
    timeScale: 'day' | 'week' | 'month' | 'year'
): string => {
    let evol;
    let evolPercent;
    // const evol = dataStats2[3] - dataStats2[2];
    // const evolPercent = Math.round((evol / dataStats2[2]) * 100);
    if (timeScale === 'day') {
        evol = dataStats[6] - dataStats[5];
        evolPercent = Math.round((evol / dataStats[2]) * 100);
    } else {
        evol = dataStats[3] - dataStats[2];
        evolPercent = Math.round((evol / dataStats[2]) * 100);
    }
    if (evolPercent === -Infinity) {
        return '-100';
    } else {
        return `${evolPercent > 0 ? '+' : ''}${
            evolPercent === Infinity ? 100 : !evolPercent ? 0 : evolPercent
        }`;
    }
};
