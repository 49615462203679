/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState, useContext, CSSProperties, useRef } from 'react';
import click from '../../assets/click.png';
import './reorder1.css';
import { Exercise } from '../../models/Exercise';
import { UserDataProp } from '../../models/userDataProp';
import { UserContext } from '../../providers/UserProvider';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import { getImageKitUrlFromCroped } from '../../utilities/utils';

import styles from './styles.module.css';
import { Typography } from '../Typography';
import Card from '../Card';

interface ImageSlicerProps {
    imageSrc: string;
    numberOfSlices: number;
    actualNumber: number;
    setActualNumber: React.Dispatch<React.SetStateAction<number>>;
    validNumber: boolean[];
    setValidNumber: React.Dispatch<React.SetStateAction<boolean[]>>;
    difficultyLevel: number;
    intruderNumber: number;
    setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setNbModal: React.Dispatch<React.SetStateAction<number>>;
    setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
    exercise: Exercise | undefined;
    reorderError: number;
    setReorderError: React.Dispatch<React.SetStateAction<number>>;
    slices: { key: number; src: string | undefined; alt: string; activeClue: boolean }[];
    setSlices: React.Dispatch<
        React.SetStateAction<
            { key: number; src: string | undefined; alt: string; activeClue: boolean }[]
        >
    >;
    itemToShake: string;
    setItemToShake: React.Dispatch<React.SetStateAction<string>>;
    isGameLoaded: boolean;
    setIsGameLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ImageSlicer({
    imageSrc,
    numberOfSlices,
    actualNumber,
    setActualNumber,
    validNumber,
    setValidNumber,
    difficultyLevel,
    intruderNumber,
    setIsSuccess,
    setNbModal,
    setOpenSuccessModal,
    exercise,
    reorderError,
    setReorderError,
    slices,
    setSlices,
    itemToShake,
    setItemToShake,
    isGameLoaded,
    setIsGameLoaded
}: ImageSlicerProps): JSX.Element {
    const [isFirstError, setIsFirstError] = useState<boolean>(true);
    const [wrongChoice, setWrongChoice] = useState<boolean>(false);
    const intruder = [
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2F15f34105-ebee-4e67-83d9-09914052c287.png?alt=media&token=634fa14e-9d8a-4342-9de3-898df531d73f',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2Fea8d066c-9e45-4685-8ac2-7c7c20a7caff.jpeg?alt=media&token=6363775a-6d75-42d2-bf36-5663bb59ffa6',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2F36a2b332-8eab-4471-b462-bf4e543b150e.jpeg?alt=media&token=80060fe9-857c-4aea-884e-b6b30cbdf3c6',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2Fe9c7d5e3-e2de-4610-a75c-38bac82f1851.jpeg?alt=media&token=f6b26abc-dea6-4a4f-b789-5111106b3fbe',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2F1e069dfc-ef7c-4b3c-8230-1e52ff4a841e.webp?alt=media&token=06b69d39-e174-47cf-8f78-5dac0da01246',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2F903c3fdd-204f-4b9d-8f83-c740d154609b.png?alt=media&token=0c648604-ffcd-4133-93fa-0dcace43b11d',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2F16f29093-90ef-4d57-96b2-c11967573288.png?alt=media&token=20b85328-8c7b-4a90-b3c7-430eb6aa89e3',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2Faf71f510-f2be-472f-be93-3827ee7a2587.png?alt=media&token=a4efee7c-2fa6-41e0-9962-6cb4062f67d0',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2F0c9210f3-8088-48ea-bd1c-df7c3d738bf9.png?alt=media&token=7d73747c-930a-4725-bdaf-ca3e94024386',
        'https://firebasestorage.googleapis.com/v0/b/stimul-in.appspot.com/o/admin%2F6a5893a0-4022-475a-91f2-ca7b595951d2.png?alt=media&token=41ecd72b-7a67-4268-9851-3501cd952da2'
    ];
    const [intruderSlices, setIntruderSlices] = useState<
        { key: number; src: string | undefined; alt: string; activeClue: boolean }[]
    >([]);
    const { height, width } = useWindowDimensions();

    function isSorted(arr: { key: number }[]): boolean {
        let counter = 0;

        arr.forEach((value, index, array) => {
            if (index === 0) {
                return true;
            }
            if (Math.floor(value.key / 100) === 1) {
                return true;
            }
            if (value.key >= array[index - 1].key) {
                counter += 1;
            }

            return value.key >= array[index - 1].key;
        });

        if (
            (difficultyLevel === 1 && counter === 1) ||
            (difficultyLevel < 7 && counter >= 2) ||
            (difficultyLevel >= 7 && counter >= 1)
        ) {
            return true;
        }
        return false;
    }
    const [isSlicesSet, setIsSlicesSet] = useState<boolean>(false);
    const [isOriginalPictureSliced, setIsOriginalPictureSliced] = useState<boolean>(false);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    useEffect(() => {
        function setupIntruders() {
            const tempIntruderSlices: {
                key: number;
                src: string | undefined;
                alt: string;
                activeClue: boolean;
            }[] = [];
            let imagesLoaded = 0;

            intruder.sort(() => Math.random() - 0.5);

            const selectedIntruders = intruder.slice(0, 2);

            // eslint-disable-next-line array-callback-return
            selectedIntruders.map((link) => {
                const imageObj = new Image();
                imageObj.src = link;
                imageObj.onload = () => {
                    const cropWidth = imageObj.width;
                    const cropHeight = imageObj.height;

                    for (let i = 0; i < numberOfSlices; i += 1) {
                        const newIntruderSlice = {
                            key: 100 + i + numberOfSlices * imagesLoaded,
                            src: getImageKitUrlFromCroped(
                                link,
                                cropWidth,
                                Math.trunc(cropHeight / numberOfSlices),
                                0,
                                Math.trunc(cropHeight / numberOfSlices) * i
                            ),
                            alt: `intruderSlice ${i + numberOfSlices * imagesLoaded}`,
                            activeClue: false
                        };
                        tempIntruderSlices.push(newIntruderSlice);
                        if (
                            tempIntruderSlices.length ===
                            numberOfSlices * selectedIntruders.length
                        ) {
                            setIntruderSlices(tempIntruderSlices);
                        }
                    }
                    imagesLoaded += 1;
                };
            });
        }

        if (isGameLoaded === false) {
            setupIntruders();
        }
    }, []);

    useEffect(() => {
        function concatSlices() {
            let randomIndex = Math.floor(Math.random() * intruderSlices.length);
            let tempSlices: {
                key: number;
                src: string | undefined;
                alt: string;
                activeClue: boolean;
            }[] = [...slices];

            for (let i = numberOfSlices - intruderNumber; i < numberOfSlices; i += 1) {
                while (
                    // eslint-disable-next-line @typescript-eslint/no-loop-func
                    tempSlices.some((item) => item.key === intruderSlices[randomIndex].key)
                ) {
                    randomIndex = Math.floor(Math.random() * intruderSlices.length);
                }
                const newSlice: {
                    key: number;
                    src: string | undefined;
                    alt: string;
                    activeClue: boolean;
                }[] = [...tempSlices];

                newSlice[i] = intruderSlices[randomIndex];
                tempSlices = newSlice;
            }

            tempSlices.sort((a, b) => a.key - b.key);
            tempSlices.sort(() => Math.random() - 0.5);
            while (isSorted(tempSlices)) {
                tempSlices.sort(() => Math.random() - 0.5);
            }
            setSlices(tempSlices);
            setIsGameLoaded(true);
        }

        if (intruderSlices.length === numberOfSlices * 2) {
            if (isSlicesSet === true) {
                concatSlices();
            }
        }
    }, [intruderSlices, isSlicesSet]);

    useEffect(() => {
        function setupAnswers() {
            const imageObj = new Image();

            imageObj.src = imageSrc;
            imageObj.onload = () => {
                const cropWidth = imageObj.width;
                const cropHeight = imageObj.height;
                let tempSlices: {
                    key: number;
                    src: string | undefined;
                    alt: string;
                    activeClue: boolean;
                }[] = [];
                let tmpNumber: boolean[] = [];
                setActualNumber(0);
                for (let i = 0; i < numberOfSlices; i += 1) {
                    const newSlice: {
                        key: number;
                        src: string | undefined;
                        alt: string;
                        activeClue: boolean;
                    }[] = [...tempSlices];
                    newSlice[i] = {
                        key: i,
                        src: getImageKitUrlFromCroped(
                            imageSrc,
                            cropWidth,
                            Math.trunc(cropHeight / numberOfSlices),
                            0,
                            Math.trunc(cropHeight / numberOfSlices) * i
                        ),
                        alt: `Slice ${i}`,
                        activeClue: false
                    };
                    tempSlices = newSlice;
                    const newNumber: boolean[] = [...tmpNumber];
                    newNumber[i] = false;
                    tmpNumber = newNumber;
                }
                setValidNumber(tmpNumber);
                setSlices(tempSlices);
                setIsOriginalPictureSliced(true);
            };
        }

        if (isGameLoaded === false) {
            setupAnswers();
        }
    }, []);

    useEffect(() => {
        function getSelectedSlices(nbSlices: number) {
            if (nbSlices !== slices.length) {
                let tempSlices: {
                    key: number;
                    src: string | undefined;
                    alt: string;
                    activeClue: boolean;
                }[] = [];
                slices.sort(() => Math.random() - 0.5);
                const selectedSlices = slices.slice(0, nbSlices);

                selectedSlices.sort((a, b) => a.key - b.key);
                for (let i = 0; i < nbSlices; i += 1) {
                    const newSlice: {
                        key: number;
                        src: string | undefined;
                        alt: string;
                        activeClue: boolean;
                    }[] = [...tempSlices];
                    newSlice[i] = {
                        key: i,
                        src: selectedSlices[i].src,
                        alt: `Slice ${i}`,
                        activeClue: false
                    };
                    tempSlices = newSlice;
                }
                setSlices(tempSlices);
            }
            setIsSlicesSet(true);
        }

        if (isOriginalPictureSliced) {
            getSelectedSlices(numberOfSlices - intruderNumber);
        }
    }, [isOriginalPictureSliced]);

    function handleClickOnSlice(
        key: number,
        slice: { key: number; src: string | undefined; alt: string; activeClue: boolean }
    ) {
        let tmpNumber = [];
        const newNumber = [...validNumber];

        if (itemToShake === '') {
            if (key === actualNumber && validNumber[key] === false) {
                newNumber[key] = true;
                tmpNumber = newNumber;
                setValidNumber(tmpNumber);
                setActualNumber(actualNumber + 1);
                const newState = slices.map((chosenSlice) => {
                    if (chosenSlice.key === actualNumber) {
                        return { ...chosenSlice, activeClue: false };
                    }
                    return chosenSlice;
                });
                setSlices(newState);
            } else if (key > actualNumber) {
                setReorderError(reorderError + 1);
                setWrongChoice(true);
                setItemToShake(slice.alt);
                // stop shake
                setTimeout(() => {
                    setItemToShake('');
                    setWrongChoice(false);
                }, 2000);
                if (isFirstError) {
                    setIsSuccess(false);
                    setNbModal(1);
                    setTimeout(async () => {
                        setOpenSuccessModal(true);
                    }, 1000);
                    setTimeout(async () => {
                        setOpenSuccessModal(false);
                        setIsFirstError(false);
                    }, 3000);
                }
            }
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: difficultyLevel === 10 && width && width >= 480 ? 'row' : 'column',
                width: 'auto',
                height: 'auto',
                alignItems: width && width < 480 ? 'flex-start' : 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                padding: '2%',
                paddingBottom: 'calc(2% + 5rem)',
                gap: '1rem',
            }}>
            {actualNumber < 6 &&
                isGameLoaded &&
                slices.map((slice) => (
                    <div
                        key={`card+button-${slice.key}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            justifyContent: width && width < 480 ? 'flex-start' : 'center',
                            alignContent: width && width < 480 ? 'flex-start' : 'center',
                            width: '100%',
                            height: 'auto',
                            margin: '0.4%',
                            marginBlock: difficultyLevel === 10 ? '2%' : '',
                            flexBasis: 'calc(50% - 2%)'
                        }}>
                        {slice.activeClue === true && (
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                            <img
                                onClick={() => handleClickOnSlice(slice.key, slice)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        handleClickOnSlice(slice.key, slice);
                                    }
                                }}
                                src={click}
                                alt="click"
                                className="clueAnimation"
                                style={{
                                    cursor: 'pointer',
                                    display: 'block',
                                    position: 'absolute',
                                    top: '50%',
                                    left: width && width < 480 ? '40%' : '55%',
                                    height: 'auto',
                                    width: width && width < 480 ? '20%' : '10%',
                                    transform: 'translate(-26px, -40px) rotate(-45deg)',
                                    zIndex: '3'
                                }}
                            />
                        )}
                        <Card
                            padding={false}
                            className={
                                itemToShake === slice.alt
                                    ? wrongChoice === true
                                        ? 'itemShake'
                                        : 'itemFadeout'
                                    : ''
                            }
                            style={{
                                ...{
                                    height: 'auto',
                                    width:
                                        width && width < 480
                                            ? difficultyLevel === 10
                                                ? '100%'
                                                : '70%'
                                            : width && width < 768
                                                ? difficultyLevel === 10
                                                    ? '60%'
                                                    : '45%'
                                                : width && width < 1024
                                                    ? difficultyLevel === 10
                                                        ? '60%'
                                                        : '40%'
                                                    : difficultyLevel === 10
                                                        ? '60%'
                                                        : '30%',
                                    borderRadius:
                                        width && width < 480
                                            ? difficultyLevel === 10
                                                ? 'var(--radius-m)'
                                                : 'var(--radius-l)'
                                            : difficultyLevel === 10
                                                ? 'var(--radius-l)'
                                                : 'var(--radius-xl)',
                                    boxShadow: '0px 3px 15px #A0BDD6',
                                    position: 'relative',
                                    backgroundColor: 'transparent !important',
                                    padding: 'var(--space-xs)',
                                }
                            }}
                            key={`card-${slice.key}`}>
                            <div
                                role="button"
                                tabIndex={0}
                                key={`button-${slice.key}`}
                                onClick={() => handleClickOnSlice(slice.key, slice)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        handleClickOnSlice(slice.key, slice);
                                    }
                                }}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    justifyItems: 'center',
                                    display: 'flex',
                                    width: '100%',
                                    height: 'auto'
                                }}>
                                <img
                                    key={slice.key}
                                    src={slice.src}
                                    alt={slice.alt}
                                    style={{
                                        borderRadius: 'var(--radius-l)',
                                        width: '100%',
                                        cursor: 'pointer',
                                        transform: difficultyLevel === 9 ? 'scale(1.5)' : 'none'
                                    }}
                                />
                            </div>
                        </Card>
                        <div
                            role="button"
                            tabIndex={-1}
                            key={`box-${slice.key}`}
                            onClick={() => handleClickOnSlice(slice.key, slice)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' || event.key === ' ') {
                                    handleClickOnSlice(slice.key, slice);
                                }
                            }}
                            style={{
                                width:
                                    width && width < 480
                                        ? '20%'
                                        : difficultyLevel === 10
                                            ? '10%'
                                            : '5%',
                                height: 'auto',
                                border: '2px solid',
                                borderRadius: '0.81rem',
                                marginLeft: '5%',
                                boxShadow: '6.04px 6.04px 24.16px 0px #52525214',
                                cursor: 'pointer',
                                aspectRatio: '1',
                                alignContent: 'center',
                                justifyContent: 'center',
                                display: 'grid',
                                backgroundColor:
                                    // eslint-disable-next-line no-nested-ternary
                                    validNumber[slice.key] === true
                                        ? '#00DF76'
                                        : itemToShake === slice.alt && wrongChoice === true
                                            ? '#EEF300'
                                            : 'white',
                                color:
                                    // eslint-disable-next-line no-nested-ternary
                                    validNumber[slice.key] === true
                                        ? '#00DF76'
                                        : itemToShake === slice.alt && wrongChoice === true
                                            ? '#EEF300'
                                            : '#004680'
                            }}
                            className={
                                itemToShake === slice.alt && wrongChoice === false
                                    ? 'itemFadeout'
                                    : ''
                            }>
                            <Typography
                                sx={{
                                    fontFamily: 'Luciole-Regular',
                                    color: validNumber[slice.key] === true ? 'white' : 'black',
                                    fontWeight: '600',
                                    fontSize: 'x-large'
                                }}>
                                {validNumber[slice.key] &&
                                    actualNumber !== 0 &&
                                    actualNumber > slice.key
                                    ? slice.key + 1
                                    : ''}
                            </Typography>
                        </div>
                    </div>
                ))}
        </div>
    );
}
