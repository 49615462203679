import ReactPlayer from 'react-player';
import { useHistory } from 'react-router';
import { t } from 'i18next';

import Gift from './assets/gift.svg?react';
import Stars from './assets/stars.svg?react';
import Heading from '../../../components/Heading';
import styles from './styles.module.css';
import { VideoPlayer } from '../../../components/VideoPlayer';

interface RewardProps {
    congratulation?: {
        title?: string;
        msg?: string;
        video?: string;
    };
    onNext?: () => void;
};

const Reward: React.FC<RewardProps> = ({ congratulation, onNext }) => {
    const history = useHistory();

    const handleOnClick = (path: string) => {
        history.push(path);
    };

    return (
        <div className={styles.container}>
            <div className={styles.videoContainer}>
                {congratulation?.video &&
                    <>
                        <VideoPlayer url={congratulation.video} autoPlay={true} sx={{ borderRadius: 'var(--radius-l)', overflow: 'hidden' }} />
                        <Stars className={[styles.stars, styles.topLeftCorner].join(' ')} />
                    </>
                }
            </div>

            {onNext && <div className={styles.actionsContainer}>
                <div className={styles.actions} onClick={() => onNext()}>
                    <div className={styles.cta}>
                        <Heading center className={styles.label}>{t('Continuer')}</Heading>
                    </div>
                </div>
            </div>}
        </div >
    );
};

export default Reward;