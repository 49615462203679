import React, { useState, useMemo, useImperativeHandle, useEffect } from "react";
import { useParams } from "react-router";

import { GameRef } from "../../providers/GameProvider";
import Cta from "./Cta";
import GameGrid from "./GameGrid";
import { option } from "./interfaces";
import { getRandomTheme, getComplexity, resetOptionsStatus, generateGameOptions, shuffle } from "./utils";
import styles from './styles.module.css';
import { useGame } from "../../contexts/GameContext";
import Preview from "../../pages/games/Preview";
import { useTranslation } from "react-i18next";

export const Tourbillon = React.forwardRef<GameRef>((_, ref) => {
    const { t } = useTranslation();
    const [forceUpdate, setForceUpdate] = useState(false);
    const [options, setOptions] = useState<option[]>([]);
    const item = useMemo(() => getRandomTheme(), [forceUpdate]);
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);
    const complexity = useMemo(() => getComplexity(lvl), [lvl, forceUpdate]);
    const [state, setState] = useState<'preview' | 'game' | 'fact'>('preview');
    const [won, setWon] = useState(false);
    const { endAnimation, startTimer, stopTimer, resetTimer, time, writeMessage, displayFunfact, closeFunfact, endGame, displayInstruction, showUi, hideUi } = useGame();
    const [clueCount, setClueCount] = useState(0);
    const [errorCount, setErrorCount] = useState(0);

    useImperativeHandle(ref, () => ({
        tips: () => {
            // Makes some cards glowing to help the player
            if (options.some((e) => e.status === 'glow')) return;
            setClueCount((count) => count + 1);
            const correctOption = options.find((opt) => opt.isCorrect);
            const incorrectOptions = shuffle(options.filter((opt) => !opt.isCorrect));
            const tips = [correctOption, ...incorrectOptions.slice(0, lvl > 8 ? 3 : lvl > 5 ? 2 : lvl > 1 ? 1 : 0)];
            if (lvl > 1) {
                writeMessage({ text: t('La bonne figure se trouve parmi ces propositions') });
            } else {
                writeMessage({ text: t('Voici la figure correcte…') });
            }
            setOptions((old) => old.map((opt) => {
                if (tips.some((tip) => tip && tip.id === opt.id)) {
                    return { ...opt, status: 'glow' } as option;
                }
                return opt;
            }));
        },
        reset: () => {
            setState('preview');
            hideUi();
            closeFunfact();
            setWon(false);
            resetTimer();
            setForceUpdate((prev) => !prev);
        },
    }));

    // Start the timer when the game starts
    useEffect(() => {
        if (state === 'game' && time === 0) {
            startTimer();
            displayInstruction();
        }
    }, [time, state]);

    // Display the funfact after the game
    useEffect(() => {
        if (state === 'fact') {
            endAnimation(async () => displayFunfact({
                title: item.title, text: item.funFact, Picture: item.picture, onClose: () => {
                    closeFunfact();
                    endGame({
                        clueCount,
                        errorCount,
                    });
                }
            }));
        }
    }, [state]);

    // Handle the click on an option, update the status of the option
    const handleOnClick = (id: option["id"]) => {
        if (won) return;
        const hasWon = options.find((opt) => opt.isCorrect && opt.id === id);
        setOptions((old) =>
            old.map((opt) => {
                if (opt.id === id) {
                    if (opt.isCorrect) {
                        writeMessage({ color: 'success', text: t('Bravo !') });
                        stopTimer();
                        return { ...opt, status: 'correct' } as option;
                    } else {
                        setErrorCount((count) => count + 1);
                        writeMessage({ color: 'wrong', text: t('Retentez votre chance ! Choisissez une autre proposition.') });
                        return { ...opt, status: 'incorrect' } as option;
                    }
                }
                return opt;
            })
        );

        // Reset the status of the options after 3 seconds
        if (!hasWon) {
            const timer = setTimeout(() => resetOptionsStatus(id, setOptions), 3000);
            return () => clearTimeout(timer);
        } else {
            setWon(true);
            if (item.funFact) {
                // Change the state to display the fun fact
                const timer = setTimeout(() => setState('fact'), 3000);
                return () => clearTimeout(timer);
            } else {
                const timer = setTimeout(() => {
                    hideUi();
                    endGame({
                        clueCount,
                        errorCount,
                    });
                }, 3000);
                return () => clearTimeout(timer);
            }
        }
    };

    // Generate the options for the game
    useEffect(() => {
        setOptions(generateGameOptions(complexity));
    }, [lvl, complexity]);

    const onSkipPreview = () => {
        setState('game');
        showUi();
    };

    if (state === 'preview') {
        return <Preview
            title={item.title}
            Picture={item.picture}
            onSkip={() => onSkipPreview()}
        />;
    }

    return (
        <>
            <div className={styles.layout}>
                <GameGrid Picture={item.picture} options={options} onClick={(id) => handleOnClick(id)} lvl={lvl} />
                <div className={styles.cta}>
                    <Cta onClick={() => { setState('preview'); hideUi() }} />
                </div>
            </div>
        </>
    );
});