import React, { CSSProperties, useState, useEffect, useContext } from 'react';
import { UserDataProp } from '../../../models/userDataProp';
import { UserContext } from '../../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button';
import { logOut } from '../../../services/firebaseAuth';
import { ProfileImage } from '../../../components/ProfilImage';
import { Typography } from '../../../components/Typography';
import useWindowDimensions from '../../../utilities/useWindowDimensions';
import userIcon from '../../../assets/user.svg';
import groupIcon from '../../../assets/members.svg';
import subscriptionIcon from '../../../assets/subscription.svg';
import addIcon from '../../../assets/addMember.png';
import passwordIcon from '../../../assets/password.svg';
import inspiNotebook from '../../../assets/inspiNotebook.svg';
import trashbinIcon from '../../../assets/trashbin.svg';
import statisticsIcon from '../../../assets/statistics.svg';
import viewOnboarding from '../../../assets/viewOnboarding.png';
import allBenef from '../../../assets/allBenef.jpg';
import { PersonalInformations } from '../../../components/PersonalInformations';
import { ChangePassword } from '../../../components/ChangePassword';
import { AddMember } from '../../../components/AddMember';
import { Subscription } from '../../../components/Subscription';
import { FamilyMembers } from '../../../components/FamilyMembers';
import { ProfileStatistics } from '../../../components/ProfileStatistics';
import { getImageKitUrlFrom } from '../../../utilities/utils';
import { useHistory } from 'react-router';
import { Roles } from '../../../models/Roles';
import { GalaxiePro } from '../../../components/GalaxiePro';
import {
    getAllUser,
    getPatientFromEstablishment
} from '../../../services/cloudFirestore';
import { DisplayUser } from '../../../components/DisplayUser';
import { User } from '../../../models/User';
import { Patient } from '../../../models/Patient';
import { InspiNotebook } from '../../../components/InspiNotebook';
import { LogoutButton } from '../../../components/LogoutButton';
import { updateNeedViewOnboarding } from '../../../stores/User';
import { DeleteAccount } from '../../../components/DeleteAccount';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem 32px'
    },
    header: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        paddingBottom: '4rem',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px'
    },
    profile: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        padding: '1rem 0.5rem',
        alignSelf: 'flex-start'
    },
    'profile-image-root': {
        display: 'flex',
        flexDirection: 'row'
    },
    'profile-name': {
        justifySelf: 'center'
    },
    'icons-root': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        paddingBottom: '1rem'
    },
    'icon-root': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        margin: '1rem 0'
    },
    'icon-picture': {
        position: 'relative',
        height: '2.5rem',
        width: 'auto',
        alignSelf: 'flex-start'
    },
    'icon-text': {
        cursor: 'pointer',
        alignSelf: 'center',
        marginLeft: '1rem'
    },
    'back-button': {
        position: 'absolute',
        width: 'fit-content',
        left: '1%'
    },
    'close-button': {
        position: 'absolute',
        width: 'fit-content',
        right: '1%'
    }
};

interface Props {
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    setStepTitle: React.Dispatch<React.SetStateAction<string>>;
    displayOnboarding: () => void;
}

export function Profile({ step, setStep, setStepTitle, displayOnboarding }: Props): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [page, setPage] = useState<string>('MyProfile');
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const [photo, setPhoto] = useState<string | File | undefined>(undefined);
    const [focusedUserId, setFocusedUserId] = useState<string>('');
    const history = useHistory();
    const [userSeniorList, setUserSeniorList] = useState<Patient[]>();
    const [userForStatistics, setUserForStatistics] = useState<Patient[] | User[] | null>(null);

    useEffect(() => {
        const getUserLists = async (): Promise<void> => {
            if (user && user.role === Roles.PRO && user.establishmentCode) {
                const userSeniorList = await getPatientFromEstablishment(user.establishmentCode);
                setUserSeniorList(userSeniorList ?? []);
            } else if (user && user.role === Roles.ADMIN) {
                const userSeniorList = await getAllUser();
                setUserSeniorList(userSeniorList ?? []);
            }
        };
        getUserLists();
    }, [user]);

    useEffect(() => {
        if (step !== 2) {
            setUserForStatistics(null);
        }
    }, [step, page]);

    useEffect(() => {
        if (typeof user?.profilePhoto === 'string') {
            setPhoto(user?.profilePhoto);
        } else if (typeof user?.profilePhoto !== undefined && user?.profilePhoto) {
            setPhoto(URL.createObjectURL(user?.profilePhoto));
        }
        // if (user?.role === Roles.SENIOR && page === 'MyFamilyMembers') {
        //     setStepTitle(t('MyLuckyStars') ?? '');
        // } else {
        //     setStepTitle(t(page) ?? '');
        // }
        if (user?.role === Roles.PRO && page === 'MyStatistics') {
            setStepTitle(t('MyStatisticsPro') ?? '');
        } else {
            setStepTitle(t(page) ?? '');
        }
    }, [page]);

    useEffect(() => {
        if (!user?.firstName || !user?.lastName) {
            setStep(1);
            setFocusedUserId(user?.id?.toString() ?? '');
            setPage('PersonalInformations');
        }
    }, [user, step, page]);

    const getElement = (): JSX.Element => {
        switch (page) {
            case 'PersonalInformations':
                return step === 1 || step === 2 ? (
                    <PersonalInformations
                        setPhoto={setPhoto}
                        photo={photo}
                        step={step}
                        setStep={setStep}
                    />
                ) : (
                    (setPage('MyProfile'), getProfilePage())
                );
            case 'ChangePassword':
                return step === 1 ? (
                    <ChangePassword user={user} photo={photo} />
                ) : (
                    (setPage('MyProfile'), getProfilePage())
                );
            case 'AddMember':
                return step === 1 ? (
                    <AddMember user={user} />
                ) : (
                    (setPage('MyProfile'), getProfilePage())
                );
            case 'MySubscription':
                return step === 1 ? (
                    <Subscription userId={user?.id?.toString()} />
                ) : (
                    (setPage('MyProfile'), getProfilePage())
                );
            case 'MyFamilyMembers':
                return user?.role === Roles.PRO && (step === 1 || step === 2) ? (
                    <GalaxiePro step={step} setStep={setStep} setStepTitle={setStepTitle} />
                ) : step === 1 ? (
                    <FamilyMembers
                        user={user}
                        step={step}
                        setStep={setStep}
                        setFocusedUserId={setFocusedUserId}
                    />
                ) : step === 2 ? (
                    <PersonalInformations
                        setPhoto={setPhoto}
                        photo={photo}
                        step={step}
                        setStep={setStep}
                    />
                ) : (
                    (setPage('MyProfile'), getProfilePage())
                );
            case 'MyStatistics':
                return step === 1 ? (
                    <>
                        {user?.role !== Roles.PRO && user?.role !== Roles.ADMIN && (
                            <ProfileStatistics
                                userList={user ? [user] : user}
                                setStepTitle={setStepTitle}
                            />
                        )}
                        {user?.role === Roles.ADMIN && (
                            <ProfileStatistics
                                userList={userSeniorList}
                                setStepTitle={setStepTitle}
                            />
                        )}
                        {user?.role === Roles.PRO && userForStatistics === null && step === 1 && (
                            <div
                                style={{
                                    padding: '1rem',
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'auto'
                                }}>
                                <DisplayUser
                                    useImage={allBenef}
                                    selectAll
                                    selectAllTitle={t('AllMySeniors') ?? ''}
                                    onClick={() => {
                                        if (userSeniorList) {
                                            setUserForStatistics(userSeniorList);
                                        }
                                    }}
                                />
                                {userSeniorList?.map((thisUser) => (
                                    <DisplayUser
                                        user={thisUser}
                                        onClick={() => {
                                            setUserForStatistics([thisUser]);
                                            setStep(2);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        {user?.role === Roles.PRO && userForStatistics !== null && (
                            <ProfileStatistics
                                userList={userForStatistics}
                                setStepTitle={setStepTitle}
                            />
                        )}
                    </>
                ) : step === 2 && user?.role === Roles.PRO && userForStatistics !== null ? (
                    <ProfileStatistics userList={userForStatistics} setStepTitle={setStepTitle} />
                ) : (
                    (setPage('MyProfile'), getProfilePage())
                );
            case 'inspiNotebook':
                return step === 1 || step === 2 ? (
                    <InspiNotebook step={step} setStep={setStep} />
                ) : (
                    (setPage('MyProfile'), getProfilePage())
                );
            case 'deleteAccount':
                return (
                    <DeleteAccount step={step} setStep={setStep} />
                );
            default:
                return getProfilePage();
        }
    };

    const getProfilePage = (): JSX.Element => {
        return (
            <div
                style={{
                    ...classes['root']
                }}>
                <div
                    style={{
                        ...classes['MyProfile'],
                        ...{
                            alignItems: 'flex-start',
                            width: width && width < 768 ? '95%' : '100%'
                        }
                    }}>
                    <div
                        style={{
                            ...classes['profile-image-root'],
                            ...{
                                flexDirection: 'row'
                            }
                        }}>
                        <ProfileImage
                            width={56}
                            height={56}
                            name={`${user?.firstName} ${user?.lastName}`}
                            url={
                                typeof photo !== 'string'
                                    ? photo
                                    : getImageKitUrlFrom(photo, 150, 150)
                            }
                        />
                        <Typography
                            variant="h3"
                            sx={{
                                ...classes['profile-name'],
                                padding: '0 1rem'
                            }}>
                            {user?.firstName + ' ' + user?.lastName}
                        </Typography>
                    </div>
                    <div
                        style={{
                            ...classes['icons-root'],
                            ...{
                                width: width && width < 768 ? '100%' : '60%'
                            }
                        }}>
                        <div
                            style={{
                                ...classes['icon-root']
                            }}>
                            <img
                                src={userIcon}
                                style={{ ...classes['icon-picture'] }}
                                alt="user icon"
                            />
                            <Typography
                                variant="p"
                                sx={{ ...classes['icon-text'] }}
                                onClick={(): void => {
                                    if (user?.id) {
                                        setFocusedUserId(user?.id?.toString());
                                        setStep(step + 1);
                                        setPage('PersonalInformations');
                                    }
                                }}>
                                {t('PersonalInformations')}
                            </Typography>
                        </div>
                        {/* <div
                            style={{
                                ...classes['icon-root']
                            }}>
                            <img
                                src={passwordIcon}
                                style={{ ...classes['icon-picture'] }}
                                alt="password icon"
                            />
                            <Typography
                                variant="p"
                                sx={{ ...classes['icon-text'] }}
                                onClick={(): void => {
                                    setStep(step + 1);
                                    setPage('ChangePassword');
                                }}>
                                {t('ChangePassword')}
                            </Typography>
                        </div> */}
                        <div
                            style={{
                                ...classes['icon-root']
                            }}>
                            <img
                                src={groupIcon}
                                style={{ ...classes['icon-picture'] }}
                                alt="group icon"
                            />
                            <Typography
                                variant="p"
                                sx={{ ...classes['icon-text'] }}
                                onClick={(): void => {
                                    setStep(step + 1);
                                    setPage('MyFamilyMembers');
                                }}>
                                {t('MyFamilyMembers')}
                                {/* {user?.role === Roles.SENIOR
                                    ? t('MyLuckyStars')
                                    : t('MyFamilyMembers')} */}
                            </Typography>
                        </div>
                        {(user?.establishmentName === "Stimul'in" ||
                            user?.establishmentName === "Stimul'in Part'Lib" ||
                            user?.establishmentName === 'Vivatech users') && (<div
                                style={{
                                    ...classes['icon-root']
                                }}>
                                <img
                                    src={subscriptionIcon}
                                    style={{ ...classes['icon-picture'] }}
                                    alt="subscription icon"
                                />
                                <Typography
                                    variant="p"
                                    sx={{ ...classes['icon-text'] }}
                                    onClick={(): void => {
                                        setStep(step + 1);
                                        setPage('MySubscription');
                                    }}>
                                    {t('MySubscription')}
                                </Typography>
                            </div>)}
                        {user?.role !== Roles.PRO && (
                            <div
                                style={{
                                    ...classes['icon-root']
                                }}>
                                <img
                                    src={addIcon}
                                    style={{ ...classes['icon-picture'] }}
                                    alt="add-user icon"
                                />
                                <Typography
                                    variant="p"
                                    sx={{ ...classes['icon-text'] }}
                                    onClick={(): void => {
                                        setStep(step + 1);
                                        setPage('AddMember');
                                    }}>
                                    {t('AddMember')}
                                </Typography>
                            </div>
                        )}
                        <div
                            style={{
                                ...classes['icon-root']
                            }}>
                            <img
                                src={statisticsIcon}
                                style={{ ...classes['icon-picture'] }}
                                alt="statistics icon"
                            />
                            <Typography
                                variant="p"
                                sx={{ ...classes['icon-text'] }}
                                onClick={(): void => {
                                    setStep(step + 1);
                                    setPage('MyStatistics');
                                }}>
                                {user?.role === Roles.PRO
                                    ? t('MyStatisticsPro')
                                    : t('MyStatistics')}
                            </Typography>
                        </div>
                        <div
                            style={{
                                ...classes['icon-root']
                            }}>
                            <img
                                src={viewOnboarding}
                                style={{ ...classes['icon-picture'] }}
                                alt="onboarding icon"
                            />
                            <Typography
                                variant="p"
                                sx={{ ...classes['icon-text'] }}
                                onClick={async (): Promise<void> => {
                                    userDataProp.token &&
                                        await updateNeedViewOnboarding(userDataProp.token, true).then(
                                            () => {
                                                history.push('/home');
                                                displayOnboarding();
                                            }
                                        );
                                }}>
                                {t('ViewOnboarding')}
                            </Typography>
                        </div>
                        {(user?.role === Roles.PRO || user?.role === Roles.ADMIN) && (
                            <div
                                style={{
                                    ...classes['icon-root']
                                }}>
                                <img
                                    src={inspiNotebook}
                                    style={{ ...classes['icon-picture'] }}
                                    alt="add-user icon"
                                />
                                <Typography
                                    variant="p"
                                    sx={{ ...classes['icon-text'] }}
                                    onClick={(): void => {
                                        setStep(step + 1);
                                        setPage('inspiNotebook');
                                    }}>
                                    {t('inspiNotebook')}
                                </Typography>
                            </div>
                        )}
                        {user?.role !== Roles.PRO &&
                            !(user?.establishmentName === "Stimul'in" ||
                            user?.establishmentName === "Stimul'in Part'Lib" ||
                            user?.establishmentName === 'Vivatech users') && (<div
                                style={{
                                    ...classes['icon-root']
                                }}>
                                <img
                                    src={trashbinIcon}
                                    style={{ ...classes['icon-picture'] }}
                                    alt="delete account icon"
                                />
                                <Typography
                                    variant="p"
                                    sx={{ ...classes['icon-text'] }}
                                    onClick={(): void => {
                                        setStep(step + 1);
                                        setPage('deleteAccount');
                                    }}>
                                    {t('deleteAccount')}
                                </Typography>
                            </div>)}
                    </div>
                    <LogoutButton />
                </div>
            </div>
        );
    };

    return getElement();
}
