import React from 'react';

import style from './style.module.css';

type HeadingProps = {
    [x: string]: any;
};

type Headings = {
    [key: number]: React.FC<HeadingProps>;
    fallback: React.FC<HeadingProps>;
};

const headings: Headings = {
    1: ({ ...props }) => <h1 {...props} />,
    2: ({ ...props }) => <h2 {...props} />,
    3: ({ ...props }) => <h3 {...props} />,
    4: ({ ...props }) => <h4 {...props} />,
    5: ({ ...props }) => <h5 {...props} />,
    6: ({ ...props }) => <h6 {...props} />,
    fallback: ({ ...props }) => <div {...props} />,
};

const Heading = ({ h = 1, seo = h, center = false, className = '', ...props }: HeadingProps) => {
    const Head: React.FC<HeadingProps> = headings[parseInt(seo, 10)] || headings.fallback;
    return <Head className={`${center ? style.center : ''} ${style[`h${h}`]} ${className}`} {...props} />;
};

export default React.memo(Heading);
