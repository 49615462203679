import React from 'react';

import styles from './styles.module.css';

import Einstein from './assets/einstein.svg?react';
import { Button } from '../../../components/Button/index';
import { useTranslation } from 'react-i18next';

export interface FunfactProps {
    title: string;
    Picture?: React.FC<React.SVGProps<SVGSVGElement>> | string;
    color?: string;
    text?: string;
    onClose: () => void;
}

const Funfact: React.FC<FunfactProps> = ({ text, title, color, Picture, onClose }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            {text && <div className={[styles.textContainer, !Picture ? styles.textContainerPlain : ''].join(' ')}>
                <Einstein
                    className={styles.einstein}
                    style={{ '--fill-color': color } as React.CSSProperties}
                />
                <div className={styles.text}>
                    {text}
                </div>
            </div>}
            {Picture ?
                <div className={styles.pictureContainer}>
                    <div className={styles.title}>{title}</div>
                    {typeof Picture === 'string' ? <div className={styles.picture} style={{ backgroundImage: `url(${Picture})`, backgroundSize: 'cover' }} /> :
                        <Picture
                            className={styles.picture}
                            style={{ '--fill-color': 'var(--color-primary)' } as React.CSSProperties}
                        />
                    }
                    <Button
                        className={styles.button}
                        label={t('Passer à la suite')}
                        action={onClose}
                    />
                </div>
                : <>
                    <Button
                        style={{ backgroundColor: color } as React.CSSProperties}
                        className={[styles.button, styles.floating].join(' ')}
                        label={t('Passer à la suite')}
                        action={onClose}
                    />
                </>
            }
            {text && <div className={styles.gradient} />}
        </div>
    );
};

export default Funfact;