import { CSSProperties } from 'react';
import warningDelete from '../assets/warningDelete.png';
import cross from '../assets/cross.svg';
import { Typography } from './Typography';
import { t } from 'i18next';
import { Button } from './Button';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    'div-modal': {
        width: '80vw',
        height: '80vh',
        backgroundColor: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '20px',
        color: 'var(--main-text-color)',
        position: 'relative'
    },
    title: {
        textAlign: 'center',
        fontSize: '1.75rem',
        fontWeight: '700'
    },
    warning: {
        fontSize: '1rem',
        fontWeight: '500',
        textAlign: 'center',
        color: 'var(--main-pink-color)'
    },
    desc: {
        fontSize: '1rem',
        fontWeight: '600',
        textAlign: 'center'
    },
    'button-container': {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        gap: '1rem'
    },
    btn: {
        fontWeight: '600'
    },
    'btn-close': {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        cursor: 'pointer',
        borderRadius: '50%',
        width: '2.5rem',
        height: '2.5rem',
        border: '1px solid #e4e7e9'
    }
};

interface DeleteModalProps {
    deleteFunc: () => void;
    onClose: () => void;
    type: 'picture' | 'personal-video' | 'youtube-video' | 'game';
    sx?: CSSProperties;
}

export function DeleteModal({ deleteFunc, onClose, type, sx }: DeleteModalProps): JSX.Element {
    return (
        <div style={{...classes['div-root'], ...sx}} onClick={onClose}>
            <div style={classes['div-modal']}>
                <img src={cross} alt="cross" style={classes['btn-close']} onClick={onClose} />
                <div style={{ maxWidth: '50%', maxHeight: '50%' }}>
                    {(type === 'picture' || type === 'personal-video') && (
                        <img
                            src={warningDelete}
                            alt="warning"
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    )}
                </div>
                <div style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h2" sx={classes['title']}>
                        {type === 'game' && t('deleteModal.titleGame')}
                        {type === 'picture' && t('deleteModal.titlePicture')}
                        {type === 'personal-video' && t('deleteModal.titlePersonalVideo')}
                        {type === 'youtube-video' && t('deleteModal.titleYoutubeVideo')}
                    </Typography>
                    <Typography sx={classes['warning']}>
                        {type === 'picture' && t('deleteModal.warningPicture')}
                        {type === 'personal-video' && t('deleteModal.warningPersonalVideo')}
                        {type === 'youtube-video' && t('deleteModal.warningYoutubeVideo')}
                    </Typography>
                    <Typography sx={classes['desc']}>
                        {(type === 'picture' || type === 'personal-video') && (
                            <>
                                <span>{t('deleteModal.desc1')}</span>
                                <span style={{ textDecoration: 'underline' }}>
                                    {t('deleteModal.desc2')}
                                </span>
                                <span>{t('deleteModal.desc3')}</span>
                                <span style={{ textDecoration: 'underline' }}>
                                    {t('deleteModal.desc4')}
                                </span>
                            </>
                        )}
                        {type === 'game' && t('deleteModal.descGame')}
                    </Typography>
                </div>
                <div style={classes['button-container']}>
                    <Button sx={classes['btn']} pink outline onClick={deleteFunc}>
                        {type !== 'game' ? t('deleteModal.delete') : t('YES')}
                    </Button>
                    <Button sx={classes['btn']} outline onClick={onClose}>
                        {type !== 'game' ? t('deleteModal.cancel') : t('NO')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
