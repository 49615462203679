import React, { CSSProperties, useContext } from 'react';
import { IonFooter, isPlatform } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import navAlbum from '../assets/nav-album.svg';
import navForMe from '../assets/nav-for-me.svg';
import navGames from '../assets/nav-games.svg';
import navAlbumPink from '../assets/nav-album-pink.svg';
import navForMePink from '../assets/nav-for-me-pink.svg';
import navGamesPink from '../assets/nav-games-pink.svg';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Button } from './Button';

const classes: { [key: string]: CSSProperties } = {
    'ion-footer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'row',
        paddingLeft: '1rem',
        paddingRight: '0.5rem',
        height: 'fit-content',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        backgroundColor: '#FFFFFF',
        width: '100%',
        borderTop: '2px solid #C0C0C0',
        color: 'var(--ion-text-color)'
    },
    'footer-div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: 'var(--main-blue-color)',
        fontSize: '1rem',
        height: '100%',
        width: '25%',
        cursor: 'pointer'
    },
    'img-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '13px'
    },
    'btn-PCI': {
        width: '25%',
        padding: '1rem',
        // backgroundColor: '#0D224B',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    }
};

interface FooterProps {
    setPage: (page: 'forMe' | 'games' | 'album') => void;
    page: 'forMe' | 'games' | 'album';
    displayImport: () => void;
    isOnBoarding?: boolean;
    stepOnBoarding?: number;
}

export function Footer({
    setPage,
    page,
    displayImport,
    isOnBoarding,
    stepOnBoarding
}: FooterProps): JSX.Element {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();

    return (
        <IonFooter
            style={{
                ...classes['ion-footer'],
                paddingBottom: '0.5rem'
            }}>
            {isOnBoarding && (
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3400
                    }}
                />
            )}
            {isOnBoarding && stepOnBoarding === 1 && (
                <div
                    style={{
                        ...classes['footer-div'],
                        flexDirection: width && width < 600 ? 'column' : 'row',
                        gap: width && width < 600 ? '5px' : '10px'
                    }}
                />
            )}
            <div
                onClick={(): void => setPage('forMe')}
                style={{
                    ...classes['footer-div'],
                    flexDirection: width && width < 600 ? 'column' : 'row',
                    gap: width && width < 600 ? '5px' : '10px',
                    ...(isOnBoarding &&
                        stepOnBoarding === 1 && {
                            zIndex: 3500,
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            position: 'absolute',
                            left: '0.5rem',
                            width: 'calc(25% - 0.375rem + 1rem)',
                            height: '90%'
                        })
                }}>
                <div
                    style={{
                        ...classes['img-container'],
                        background:
                            page === 'forMe'
                                ? '#fff'
                                : '#1C0D4B',
                        border: page === 'forMe' ? '2px solid #E5007B' : 'none',
                        boxShadow:
                            page === 'forMe' ? '0px 2px 12.74005px 0px rgba(229, 0, 123, 0.51)' : 'none',
                        minHeight: width && width < 600 ? '2rem' : '3rem',
                        minWidth: width && width < 600 ? '2rem' : '3rem'
                    }}>
                    <img
                        src={page === 'forMe' ? navForMePink : navForMe}
                        alt="navForMe"
                        style={{
                            height: width && width < 600 ? '1rem' : '1.5rem',
                            width: width && width < 600 ? '1rem' : '1.5rem'
                        }}
                    />
                </div>
                <span style={{ fontWeight: page === 'forMe' ? '600' : '500' }}>{t('ForMe')}</span>
            </div>
            {isOnBoarding && stepOnBoarding === 2 && (
                <div
                    style={{
                        ...classes['footer-div'],
                        flexDirection: width && width < 600 ? 'column' : 'row',
                        gap: width && width < 600 ? '5px' : '10px'
                    }}
                />
            )}
            <div
                onClick={(): void => setPage('games')}
                style={{
                    ...classes['footer-div'],
                    flexDirection: width && width < 600 ? 'column' : 'row',
                    gap: width && width < 600 ? '5px' : '10px',
                    ...(isOnBoarding &&
                        stepOnBoarding === 2 && {
                            zIndex: 3500,
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            position: 'absolute',
                            left: 'calc(25% + 0.625rem)',
                            width: 'calc(25% - 0.375rem)',
                            height: '90%'
                        })
                }}>
                <div
                    style={{
                        ...classes['img-container'],
                        background:
                            page === 'games'
                                ? '#fff'
                                : '#1C0D4B',
                        border: page === 'games' ? '2px solid #E5007B' : 'none',
                        boxShadow:
                            page === 'games' ? '0px 2px 12.74005px 0px rgba(229, 0, 123, 0.51)' : 'none',
                        minHeight: width && width < 600 ? '2rem' : '3rem',
                        minWidth: width && width < 600 ? '2rem' : '3rem'
                    }}>
                    <img
                        src={page === 'games' ? navGamesPink : navGames}
                        alt="navGames"
                        style={{
                            height: width && width < 600 ? '1rem' : '1.5rem',
                            width: width && width < 600 ? '1rem' : '1.5rem'
                        }}
                    />
                </div>
                <span style={{ fontWeight: page === 'games' ? '600' : '500' }}>{t('Games')}</span>
            </div>
            {isOnBoarding && (stepOnBoarding === 3 || stepOnBoarding === 5) && (
                <div
                    style={{
                        ...classes['footer-div'],
                        flexDirection: width && width < 600 ? 'column' : 'row',
                        gap: width && width < 600 ? '5px' : '10px'
                    }}
                />
            )}
            <div
                onClick={(): void => setPage('album')}
                style={{
                    ...classes['footer-div'],
                    flexDirection: width && width < 600 ? 'column' : 'row',
                    gap: width && width < 600 ? '5px' : '10px',
                    ...(isOnBoarding &&
                        (stepOnBoarding === 3 || stepOnBoarding === 5) && {
                            zIndex: 3500,
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            position: 'absolute',
                            left: 'calc(50% + 0.625rem - 0.125rem)',
                            width: 'calc(25% - 0.375rem - 0.5rem)',
                            height: '90%'
                        })
                }}>
                <div
                    style={{
                        ...classes['img-container'],
                        background:
                            page === 'album'
                                ? '#fff'
                                : '#1C0D4B',
                        border: page === 'album' ? '2px solid #E5007B' : 'none',
                        boxShadow:
                            page === 'album' ? '0px 2px 12.74005px 0px rgba(229, 0, 123, 0.51)' : 'none',
                        minHeight: width && width < 600 ? '2rem' : '3rem',
                        minWidth: width && width < 600 ? '2rem' : '3rem'
                    }}>
                    <img
                        src={page === 'album' ? navAlbumPink : navAlbum}
                        alt="navAlbum"
                        style={{
                            height: width && width < 600 ? '1rem' : '1.5rem',
                            width: width && width < 600 ? '1rem' : '1.5rem'
                        }}
                    />
                </div>
                <span style={{ fontWeight: page === 'album' ? '600' : '500' }}>{t('Album')}</span>
            </div>
            {isOnBoarding &&
                (stepOnBoarding === 3 || stepOnBoarding === 2 || stepOnBoarding === 5) && (
                    <div
                        style={{
                            ...classes['btn-PCI'],
                            backgroundColor: '#fff'
                        }}
                    />
                )}
            <div
                style={{
                    width: '25%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 0.5rem',
                    ...(isOnBoarding &&
                        (stepOnBoarding === 3 || stepOnBoarding === 2 || stepOnBoarding === 5) && {
                            zIndex: 3500,
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            position: 'absolute',
                            right: '0.5rem',
                            width: 'calc(25% - 0.375rem)',
                            height: '90%'
                        })
                }}>
                <Button
                    pink
                    onClick={(): void => displayImport()}
                    sx={{
                        ...classes['btn-PCI'],
                        width: '100%'
                    }}>
                    {page === 'forMe' ? t('play') : null}
                    {page === 'games'
                        ? width && width < 600
                            ? t('create')
                            : t('createGames')
                        : null}
                    {page === 'album'
                        ? width && width < 600
                            ? t('import')
                            : t('importMedia')
                        : null}
                </Button>
            </div>
        </IonFooter>
    );
}
