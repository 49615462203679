import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';

import Card from '../../../components/Card';
import LevelBar from '../../../components/LevelBar';
import GameHeader from '../../../components/GameHeader';
import { tourbillon, pingpong, memory, sudok, puzzle, reordon, melimots, quizz } from '../../../models/Games';
import EinsteinSpeaking from '../../../components/EinsteinSpeaking';
import styles from './styles.module.css';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from "@capacitor/screen-orientation";

const LevelChoices: React.FC = () => {
    const { game: gameTitle, id } = useParams<{ game: string, id: string }>();
    const game = {
        tourbillon,
        pingpong,
        memory,
        sudok,
        puzzle,
        reordon,
        melimots,
        quizz
    }[gameTitle] || null;

    if (!game) {
        console.error('Game not found');
        return <Redirect to="/home" />;
    }

    useEffect(() => {
        if (game.orientation === 'landscape') {
            try {
                const platform = Capacitor.getPlatform();
                if (platform !== 'web') {
                    ScreenOrientation.lock({
                        orientation: 'landscape',
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    useEffect(() => {
        if (gameTitle === 'puzzle') {
            localStorage.setItem('reload', 'true');
        }
    }, [gameTitle]);

    return (
        <div className={styles.root}>
            <GameHeader title={game.name} Icon={game.icon} quitAction />
            <EinsteinSpeaking text="Choisissez un niveau de difficulté" />
            <div className={styles.grid}>
                {Array.from({ length: 10 }, (_, index) => {
                    return (
                        <Card
                            key={index}
                            title={`Force ${index + 1}`}
                            className={styles.card}
                            contentClassName={styles.levelBar}
                            margin={false}
                            action={`/${gameTitle}/${id}/lvl/${index + 1}`}
                        >
                            <LevelBar level={index + 1} color={game.color} />
                        </Card>
                    )
                })}
            </div>
        </div>
    );
};

export default LevelChoices;