/* eslint-disable no-nested-ternary */
import React, { CSSProperties, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import pompomgirl from '../assets/pompomgirl.png';
import coupe from '../assets/coupe.png';
import choisir from '../assets/choisir.png';
import strongWoman from '../assets/strongWoman.png';
import applaudissements from '../assets/applaudissements.png';
import finger from '../assets/finger.png';
import { Fireworks } from './confetti/firework';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { isPlatform } from '@ionic/react';
import { Typography } from './Typography';

interface SuccessModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    isSuccess: boolean;
    nbModal: number;
    isFirework?: boolean;
    isPlaySound?: boolean;
    isReorder?: boolean;
}

export function SuccessModal({
    isModelOpen,
    setModelOpen,
    isSuccess,
    nbModal = 0,
    isFirework,
    isPlaySound,
    isReorder
}: SuccessModalProps): JSX.Element {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const isMobile = !isPlatform('tablet') && (isPlatform('android') || isPlatform('ios'));

    const classes: { [key: string]: CSSProperties } = {
        'div-root': {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 3000
        },
        modal: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            zIndex: 3001,
            width: 'fit-content',
            height: 'fit-content',
            maxWidth: '95%',
            maxHeight: '95%'
        },
        'modal-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            zIndex: 3003,
            flexDirection: width && width < 480 ? 'column' : 'row'
        },
        'modal-text': {
            color: 'var(--main-blue-color)',
            textAlign: 'center',
            fontSize: isMobile ? '1.3rem' : isPlatform('ios') ? '1.5rem' : '4rem'
        },
        'modal-img': {
            objectFit: 'inherit',
            alignSelf: 'center',
            justifySelf: 'center',
            height: isMobile ? '5rem' : width && width < 480 ? '8rem' : '200px',
            width: isMobile ? '5rem' : width && width < 480 ? '8rem' : '200px'
        }
    };

    const body = (
        <>
            {isSuccess && nbModal === 0 ? ( // Normal Success
                <div
                    style={{
                        ...classes['modal-content']
                    }}>
                    <img
                        src={pompomgirl}
                        alt="pompomgirl"
                        style={{
                            ...classes['modal-img']
                        }}
                    />
                    <Typography
                        variant="h2"
                        sx={{
                            ...classes['modal-text'],
                            fontSize: isMobile ? '2.2rem' : isPlatform('ios') ? '2.2rem' : '4rem'
                        }}>
                        {t('Bravo1')}
                        <br />
                        {t('Bravo2')}
                    </Typography>
                    <img
                        src={coupe}
                        alt="coupe"
                        style={{
                            ...classes['modal-img']
                        }}
                    />
                </div>
            ) : isSuccess && nbModal === 1 ? ( // First Success
                <div
                    style={{
                        ...classes['modal-content']
                    }}>
                    <img
                        src={strongWoman}
                        alt="pompomgirl"
                        style={{
                            ...classes['modal-img']
                        }}
                    />
                    <Typography
                        variant="h2"
                        sx={{
                            ...classes['modal-text']
                        }}>
                        {t('firstBravo1')}
                        <br />
                        {t('firstBravo2')}
                    </Typography>
                </div>
            ) : isSuccess && nbModal === 2 ? ( // second Success
                <div
                    style={{
                        ...classes['modal-content']
                    }}>
                    <img
                        src={applaudissements}
                        alt="pompomgirl"
                        style={{
                            ...classes['modal-img']
                        }}
                    />
                    <Typography
                        variant="h2"
                        sx={{
                            ...classes['modal-text']
                        }}>
                        {t('secondBravo1')}
                        <br />
                        {t('secondBravo2')}
                    </Typography>
                    {/* <Logo src={coupe} alt="coupe" style={{ height: '200px', width: '200px' }} /> */}
                </div>
            ) : !isSuccess && nbModal === 1 ? (
                // Not Success for Memor'in
                <div
                    style={{
                        ...classes['modal-content']
                    }}>
                    <Typography
                        variant="h2"
                        sx={{
                            ...classes['modal-text']
                        }}>
                        {t('chooseAnotherOptionMemorin1')}
                        {!isReorder && (
                            <>
                                <br />
                                {t('chooseAnotherOptionMemorin2')}
                            </>
                        )}
                        <br />
                        {t('chooseAnotherOptionMemorin3')}
                    </Typography>
                    <img
                        src={finger}
                        alt="finger"
                        style={{
                            ...classes['modal-img']
                        }}
                    />
                </div>
            ) : (
                // Not Success
                <div
                    style={{
                        ...classes['modal-content']
                    }}>
                    <img
                        src={choisir}
                        alt="choisir"
                        style={{
                            ...classes['modal-img']
                        }}
                    />
                    <Typography
                        variant="h2"
                        sx={{
                            ...classes['modal-text']
                        }}>
                        {t('chooseAnotherOption1')}
                        <br />
                        {t('chooseAnotherOption2')}
                    </Typography>
                </div>
            )}
        </>
    );
    return (
        <div>
            <div style={{ ...classes['div-root'], display: isModelOpen ? 'flex' : 'none' }}>
                {isFirework && isModelOpen && (
                    <div
                        style={{
                            zIndex: '3001',
                            position: 'fixed',
                            right: '0',
                            bottom: '0',
                            top: '0',
                            left: '0'
                        }}>
                        <Fireworks play />
                    </div>
                )}
                <div
                    style={{
                        ...classes['modal'],
                        borderRadius: isMobile ? '50% / 20%' : '10% / 50%',
                        padding: isMobile ? '45px' : '45px'
                    }}>
                    {body}
                </div>
            </div>
        </div>
    );
}
