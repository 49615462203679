import React from 'react';

import styles from './styles.module.css';
import Heading from '../Heading';
import Einstein from './einstein.svg?react';
import Ellipse from './ellipse.svg?react';

interface Props {
    text: string;
}

const EinsteinSpeaking: React.FC<Props> = ({ text }) => {
    return (
        <div className={styles.root}>
            <Einstein className={styles.einstein} />
            <Ellipse className={styles.ellipse} />
            <Heading center className={styles.text}>{text}</Heading>
        </div>
    );
};

export default EinsteinSpeaking;