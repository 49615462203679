import TourbillonSvg from '../assets/tourbillon.svg?react';
import PongSvg from '../assets/pong.svg?react';
import DuoSvg from '../assets/duo.svg?react';
import SudokSvg from '../assets/sudok.svg?react';
import PuzzleSvg from '../assets/puzzle.svg?react';
import ReordonSvg from '../assets/reordon.svg?react';
import MeliMotsSvg from '../assets/melimots.svg?react';
import QuizzSvg from '../assets/quizz.svg?react';
import { ExerciseType } from './ExerciseType';

enum Orientation {
    LANDSCAPE = 'landscape',
    PORTRAIT = 'portrait',
}

interface Instructions {
    maxLvl: number;
    text: string;
}

interface Game {
    name: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    color: string;
    secondaryColor: string;
    border: string;
    background: string;
    path: string;
    type: ExerciseType;
    instructions?: Instructions[];
    orientation: Orientation;
}

export const tourbillon: Game = {
    name: 'Tourbillon',
    icon: TourbillonSvg,
    color: '#FF6565',
    secondaryColor: '#B56363',
    background: 'radial-gradient(56.98% 56.98% at 50% 50%, #8DA8BB 0%, #2C536F 100%)',
    border: 'linear-gradient(109.52deg, #FFFDE6 -3.08%, #FF6565 70.24%, #9E1C1C 132.7%) 1',
    path: '/tourbillon',
    type: ExerciseType.TOURBILLON,
    instructions: [{
        maxLvl: 10,
        text: `Voici la règle du jeu :
1.  Retrouvez l’image qui correspond au modèle que vous venez d’observer
2.  Placez votre doigt sur la “bonne image”`,
    }],
    orientation: Orientation.PORTRAIT,
}

export const pingpong: Game = {
    name: 'Ping & Pong',
    icon: PongSvg,
    color: '#546FFF',
    secondaryColor: '#001CB0',
    border: 'linear-gradient(166.78deg, #FFF7E9 -7.33%, #C3CDFF 20.25%, #C3CDFF 65.53%, #FFF7E9 96.75%) 1',
    background: '#FFFFFF',
    path: '/pingpong',
    type: ExerciseType.PONG,
    orientation: Orientation.LANDSCAPE,
}

export const memory: Game = {
    name: 'Duo',
    icon: DuoSvg,
    color: '#FF9900',
    secondaryColor: '#B36205',
    border: 'linear-gradient(166.78deg, #FFF7E9 -7.33%, #FF9900 38.98%, #FFD596 69.69%, #FFF7E9 96.75%) 1',
    background: '#FFFFFF',
    path: '/memory',
    type: ExerciseType.MEMORIZ,
    instructions: [{
        maxLvl: 10,
        text: `Voici la règle du jeu :
1.  Retrouvez 2 images identiques pour former une paire
2.  Placez votre doigt sur une 1ère carte pour la retourner puis sur une 2ème
3.  Mémorisez la position des cartes et reformez peu à peu les “bons duos”`,
    }],
    orientation: Orientation.PORTRAIT,
}

export const sudok: Game = {
    name: 'Grilles et chiffres',
    icon: SudokSvg,
    color: '#A32EFF',
    secondaryColor: '#5D00A6',
    border: 'linear-gradient(166.78deg, #FFF7E9 -7.33%, #D196FF 28.06%, #EDD5FF 70.21%, #FBEFFF 96.75%) 1',
    background: '#FFFFFF',
    path: '/sudok',
    type: ExerciseType.SUDOKU,
    instructions: [{
        maxLvl: 10,
        text: `Voici la règle du jeu :
1.  Retrouvez les chiffres manquants dans la grille
2.  Placez votre doigt sur une case vide puis sélectionnez un chiffre. Attention, ce chiffre doit apparaitre une seule fois sur chaque ligne, colonne et région.`,
    }],
    orientation: Orientation.PORTRAIT,
}

export const puzzle: Game = {
    name: 'En morceaux',
    icon: PuzzleSvg,
    color: '#00D192',
    secondaryColor: '#14815B',
    border: 'linear-gradient(109.33deg, #FFFDE6 -3.08%, #00D192 53.59%, #C9F8EA 101.87%) 1',
    background: '#FFFFFF',
    path: '/puzzle',
    type: ExerciseType.PUZZLE,
    instructions: [{
        maxLvl: 10,
        text: `Voici la règle du jeu:
1.  Recomposez l’image que vous venez d’observer
2.  Déplacez les pièces avec votre index pour les repositionner au ”bon endroit”`,
    }],
    orientation: Orientation.LANDSCAPE,
}

export const reordon: Game = {
    name: 'Un peu d’ordre',
    icon: ReordonSvg,
    color: '#26D7E6',
    secondaryColor: '#128DA1',
    border: 'linear-gradient(166.78deg, #FFF7E9 -7.33%, #26D7E6 27.21%, #7ABEE5 69.69%, #FFF7E9 96.75%) 1',
    background: '#FFFFFF',
    path: '/reordon',
    type: ExerciseType.ORDER,
    instructions: [{
        maxLvl: 4,
        text: `Voici la règle du jeu :
1.  Recomposez l’image que vous venez d’observer
2.  Placez votre doigt sur la pièce qui correspond à l’extrémité haute de l’image.
3.  Recommencez avec les pièces restantes pour les remettre dans le “bon ordre”, de haut en bas.`,
    }, {
        maxLvl: 8,
        text: `Voici la règle du jeu :
1.  Recomposez l’image que vous venez d’observer
2.  Placez votre doigt sur la pièce qui correspond à l’extrémité haute de l’image.
3.  Recommencez avec les pièces restantes pour les remettre dans le “bon ordre”, de haut en bas. Attention aux intrus...`
    }, {
        maxLvl: 10,
        text: `Voici la règle du jeu :
1.  Retrouvez l’unique pièce extraite de l’image que vous venez d’observer
2.  Placez votre doigt sur cette pièce. Attention aux intrus...`,
    }],
    orientation: Orientation.PORTRAIT,
}

export const melimots: Game = {
    name: 'Méli-Mots',
    icon: MeliMotsSvg,
    color: '#28B2FF',
    secondaryColor: '#00639B',
    border: 'linear-gradient(109.33deg, #FEFBE5 -3.08%, #28B2FF 54.12%, #308BBF 101.87%) 1',
    background: 'radial-gradient(50% 50% at 50% 50%, #015D91 0%, #013E62 100%)',
    path: '/melimots',
    type: ExerciseType.MELIMOTS,
    instructions: [{
        maxLvl: 10,
        text: `Voici la règle du jeu :
1.  Recomposez un mot à partir de ces lettres.
2.  Déplacez les lettres avec votre index pour les positionner dans la “bonne case” blanche.`,
    }],
    orientation: Orientation.LANDSCAPE,
}

export const quizz: Game = {
    name: 'Bon titre',
    icon: QuizzSvg,
    color: '#F2D600',
    secondaryColor: '#C8AA3A',
    border: 'linear-gradient(109.52deg, #FFF7E9 -3.08%, #F2D600 70.24%, #FAF2AA 132.7%) 1',
    background: '#FFFFFF',
    path: '/quizz',
    type: ExerciseType.QUIZ,
    instructions: [{
        maxLvl: 2,
        text: `Voici la règle du jeu :
1.  Retrouvez le titre qui correspond à cette image
2.  Placez votre doigt sur ce “bon titre”`,
    }, {
        maxLvl: 5,
        text: `Voici la règle du jeu :
1.  Retrouvez les lettres manquantes pour reformer des mots et des titres
2.  Retrouvez le titre qui correspond à cette image
3.  Placez votre doigt sur le “bon titre”`,
    }, {
        maxLvl: 6,
        text: `Voici la règle du jeu :
1.  Retrouvez le titre qui correspond à l’image que vous avez vu précédemment
2.  Placez votre doigt sur le “bon titre”`,
    }, {
        maxLvl: 10,
        text: `Voici la règle du jeu :
1.  Retrouvez les lettres manquantes pour reformer des mots et des titres
2.  Retrouvez le titre qui correspond à l’image que vous venez de visualiser
3.  Placez votre doigt sur le “bon titre”`,
    }],
    orientation: Orientation.PORTRAIT,
}
